import './reports.scss';
import React, {useEffect, useRef, useState} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Storage, Translate, translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Button, Row, Col, Input, Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { enUS, ar } from 'date-fns/locale';
import moment from 'moment/moment';
import {Link} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import EventIcon from '@mui/icons-material/Event';
import ButtonMui from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import SettingsIcon from '@mui/icons-material/Settings';
import { getBranchByAreaManager, getBranchByBankList, getBranchListByBankId, getEntity as getBranch } from '../../entities/branch/branch.reducer';
import {
  getAllEntities as getBankEntities,
  getEntityByUserId as getBankByUserId,
  getEntity as getBankEntity,
  getEntityByBranchId as getBankByBranchId
} from '../../entities/bank/bank.reducer';
import { Chart, registerables } from 'chart.js';
import { Pie, Line } from 'react-chartjs-2';
Chart.register(...registerables);
import { AUTHORITIES } from 'app/config/constants';

import {
  getEntitiesForCustomerReport,
  getBankStaffPerformance,
  getValueSold,
  getValueSoldInKWD,
  getReports,
  getReportsInKWD,
  getBranchPerformance,
  getBranchPerformanceInKWD,
  getTotalAmountForReportTable,
  getTotalAmountForReportTableInKWD,
  getReportsPrint,
  getReportsPrintInKWD,
} from 'app/entities/quotation/quotation.reducer';
import { getUsers, getAllBankStaffFromBranch, getUserByAlshmokhAdmin, getBankStaffListByBranchList, getUserByIdAsOtherRoles } from '../administration/user-management/user-management.reducer';
import { getEntities as getCommodities, getTopCommodities } from 'app/entities/commodity/commodity.reducer';
import { getAllEntities as getCurrencyIndex } from 'app/entities/currency-index/currency-index.reducer';
import { MultiSelect } from 'react-multi-select-component';
import { IQuotation } from "app/shared/model/quotation.model";
import { ICommodity } from 'app/shared/model/commodity.model';


interface Option {
  label: string;
  value: number;
}


export const Reports = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);
  const quotationReport = useAppSelector(state => state.quotation.totalCustomer);
  const myAccount = account && Object.keys(account).length > 0 ? account : Storage.session.get('account');
  const notificationEntity = useAppSelector(state => state.notificationRecord.entities);
  const bank = useAppSelector(state => state.bank.entity);
  const banks = useAppSelector(state => state.bank.entities);
  const branches = useAppSelector(state => state.branch.entities);
  const branch = useAppSelector(state => state.branch.entity);
  const quotations = useAppSelector(state => state.quotation.entities);
  const currencyIndexList = useAppSelector(state => state.currencyIndex.entities);
  const reportsPrint = useAppSelector(state => state.quotation.reportPrint);
  // const bank = useAppSelector(state => )
  const users = useAppSelector(state => state.userManagement.users);
  const commodities = useAppSelector(state => state.commodity.entities);
  const valueSold = useAppSelector(state => state.quotation.valueSold);
  const totalAmountForTable = useAppSelector(state => state.quotation.entity);
  const [totalAmountForReports, setTotalAmountForReports] = useState(0);
  const [totalAmountForExcel, setTotalAmountForExcel] = useState(0);
  const valueSoldLoading = useAppSelector(state => state.quotation.valueSoldLoadingSuccess);
  const performance = useAppSelector(state => state.quotation.performance);
  const branchPerformance = useAppSelector(state => state.quotation.branchPerformance);
  const user = useAppSelector(state => state.userManagement.user);
  const [selectedBank, setSelectedBank] = useState<Option[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<Option[]>([]);
  const [selectedUser, setSelectedUser] = useState<Option[]>([]);
  const [selectedCommodity, setSelectedCommodity] = useState<Option[]>([]);
  const currentDateTime = new Date();
  const [fromDate, setFromDate] = useState<Date | null>(moment().subtract(7, 'day').toDate());
  const [toDate, setToDate] = useState<Date | null>(currentDateTime);
  const [dateError, setDateError] = useState<string>();
  const topCommodityList = useAppSelector(state => state.commodity.topCommodities);
  const [filter, setFilter] = useState(false);
  const [openCalendarFrom, setOpenCalendarFrom] = useState(false);
  const [openCalendarTo, setOpenCalendarTo] = useState(false);
  const [queryDate, setQueryDate] = useState('');
  const [pageNo, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedBankName, setSelectedBankName] = useState('');
  const [selectedBranchName, setSelectedBranchName] = useState('');
  const totalCount = useAppSelector(state => state.quotation.totalItems);
  const [branchPerformanceOption, setBranchPerformanceOption] = useState(`${translate('global.menu.report.chart.totalValueSoldTitle')}`);
  const [kuwaitDinarCurrencyIndexId, setKuwaitDinarCurrencyIndexId]=useState(0);
  const [malaysiaRinggitCurrencyIndexId, setMalaysiaRinggitCurrencyIndexId]=useState(0);
  const [print, setPrint]=useState(false);
  const [kwdToMyr, setKwdToMyr]=useState(0);
  const tempDate = new Date();
  const formatTwoDigits = digit => ('0' + digit).slice(-2);
  const today_date = `${tempDate.getFullYear()}/${formatTwoDigits(tempDate.getMonth() + 1)}/${formatTwoDigits(tempDate.getDate())}`;
  const duplicateQuotations = [] as Array<IQuotation>;
  const duplicateQuotationsPrint = [] as Array<IQuotation>;
  const [quotationPrintInKWD, setQuotationPrintInKWD] = React.useState([] as any);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const defaultCurrency = currencyIndexList?.filter(c => c?.id === branch?.bank?.currencyId || c?.id === branches[0]?.bank?.currencyId);
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const bankCommoditiesArr = [] as Array<ICommodity>;
  const areaManagerCondition = branches?.length > 0 && branches[0]?.id;
  const [userList, setUserList]  = useState<any[]>([]);

  const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-input': {
      textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
    },
  });

  function bankCommoditiesFunction() {
    // bank commodities for bank staff, branch manager, area manager, and auditor
    if (Array.isArray(bank?.commodities) && bank?.commodities?.length) {
      for (let i = 0; i < bank?.commodities?.length; i++) {
        bankCommoditiesArr.push(bank?.commodities[i]);
      }
    }
    else {
      // bank commodities for bank manager
      if (banks[0]?.commodities?.length > 0 && banks[0]?.commodities) {
        for (let i = 0; i < banks[0]?.commodities?.length; i++) {
          bankCommoditiesArr.push(banks[0]?.commodities[i]);
        }
      }
      // bank commodities for other
      else {
        for (let i = 0; i < commodities?.length; i++) {
          bankCommoditiesArr.push(commodities[i]);
        }
      }
    }
  }
  bankCommoditiesFunction();

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  }
  const labelTooltip = tooltipItems => {
    return tooltipItems.label;
  };

  const legendMargin = {
    id: 'legendMargin',
    beforeInit(chart, args, options) {
      const fitValue = chart.legend.fit;

      chart.legend.fit = function fit() {
        fitValue.bind(chart.legend)();
        return (this.width += 10);
      };
    },
  };

  useEffect(()=>{
    let total=0;

    quotations?.length >0 && quotations?.map(quotation => total=total+quotation?.totalAmount);
    setTotalAmountForReports(total);
  },[quotations]);

  useEffect(()=>{
    let total=0;

    reportsPrint?.length >0 && reportsPrint?.map(quotation => total=total+quotation?.totalAmount);
    setTotalAmountForExcel(total);
  },[reportsPrint]);

  useEffect(() => {
    dispatch(getBranchByBankList(selectedBank?.map(item => item?.value)));
    setFilter(false);
    selectedBank && selectedBank?.map((b,index)=>
    {index===0 ? setSelectedBankName(b?.label) :  setSelectedBankName(selectedBankName.concat(" , ",b?.label))}
    );
  }, [selectedBank]);

  useEffect(() => {
    dispatch(getBankStaffListByBranchList(selectedBranch?.map(item => item?.value?.toString())))
      .then(res => {
        setUserList(res.payload['data']);
      });
    setFilter(false);
  }, [selectedBranch]);

  useEffect(() => {
    setFilter(false);
  }, [selectedUser,selectedCommodity]);

  useEffect(() => {
    dispatch(getCommodities({}));
    dispatch(getCurrencyIndex({}));
    dispatch(getUsers({}));
    if (account?.authorities?.includes(AUTHORITIES.BRANCH_MANAGER)) {
      dispatch(getBranch(account?.branchId));
      dispatch(getAllBankStaffFromBranch(account?.branchId))
        .then(res => {
          setUserList(res.payload['data']);
        });
      dispatch(getBankByBranchId(account?.branchId));
    } else if (account?.authorities?.includes(AUTHORITIES.AUDITOR)) {
      dispatch(getBankEntity(account?.bankId)).then(res => dispatch(getBranchListByBankId(res.payload['data'].id)));
    } else if (account?.authorities?.includes(AUTHORITIES.BANK_MANAGER)) {
      dispatch(getBankByUserId(account?.id)).then(res => dispatch(getBranchListByBankId(res.payload['data'].id)));
    } else if (account?.authorities?.includes(AUTHORITIES.AREA_MANAGER) && areaManagerCondition) {
      dispatch(getBranchByAreaManager(account?.id));
      dispatch(getBankByBranchId(areaManagerCondition));
    } else if (account?.authorities?.includes(AUTHORITIES.BANK_STAFF)) {
      dispatch(getBranch(account?.branchId));
      dispatch(getBankByBranchId(account?.branchId));
    } else if(account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER) || account?.authorities?.includes(AUTHORITIES.ALSHMOKH_ADMIN) || account?.authorities?.includes(AUTHORITIES.ADMIN)){
        dispatch(getBankEntities({}));
      }

  }, [areaManagerCondition]);

  const [statusCheck, setStatusCheck] = useState([]);
  const handleChange = event => {
    if (event.target.checked) {
      setStatusCheck(
        [...statusCheck, event.target.value].sort(function (a, b) {
          return statusType.indexOf(a) - statusType.indexOf(b);
        })
      );
      setPage(0);
    } else {
      setStatusCheck(statusCheck.filter(id => id !== event.target.value));
      setPage(0);
    }
  };

  const getStaffFullName = (createdUser) => {
    if (!createdUser || typeof createdUser !== 'object') {
      return '';
    }

    const { firstName, lastName } = createdUser;
    if (!firstName || !lastName) {
      return '';
    }

    const fullName = `${firstName} ${lastName}`;
    return fullName;
  };

  // const createStaffList = () => {
  //   return reportsPrint?.map(q => ({
  //     userId: q?.bank?.userId,
  //     staffName: getStaffFullName(q?.createdUser)
  //   }));
  // }

  const getDataToExportExcel = () => {
    const maxLength = Math.max(...(reportsPrint?.map(q => q.branch?.name.length) || [])); // Find the longest branch name

    const updatedQuotations = reportsPrint?.map(q => ({
      ...q,
      ecertNo1: (q?.bursaAPIBidCertId !== null) ? q?.bursaAPIBidCert?.ecertNo : '-' ,
      formattedCreatedDate: moment(q?.createdDate).format('yyyy/MM/DD'),
      buy:  (q?.bursaAPIBidCertId !== null) ? moment(q?.bursaAPIBidCert?.purchaseTimeDate).format('yyyy/MM/DD HH:mm:ss.SSS') : '-',
      transfer: (q?.bursaAPIOtcCertId !== null) ?  moment( q?.bursaAPIOtcCert?.reportingTimeDate).format('yyyy/MM/DD HH:mm:ss.SSS') : '-',
      sell:(q?.bursaAPIStbCertId !== null) ?   moment( q?.bursaAPIStbCert?.sellingTimeDate).format('yyyy/MM/DD HH:mm:ss.SSS') : '-',
      createdDate: moment( q?.branch?.createdDate).format('yyyy/MM/DD'),
      amount: (q?.amount !== null) ? q?.amount?.toLocaleString() : 0,
      fee: ((q?.fee || 0) + (q?.commission || 0)).toLocaleString(),
      feeCurrencyIndex: (q?.feeCurrencyIndex !== null) ? q?.feeCurrencyIndex : '-',
      totalAmount: (q?.totalAmount!==null) ? q?.totalAmount?.toLocaleString(): 0,
      createdBy: getStaffFullName(q?.createdUser),
      approverName: (q?.approvedBy !== null && q?.approvedBy !== 0) ? q?.approver?.firstName+" "+q?.approver?.lastName : "-",
      status: (q?.cancelOrder ? 'Cancelled' : q?.status),
      branchName: `${q?.branch?.name} ${' '.repeat(maxLength - (q?.branch?.name.length || 0))} ${q?.branch?.createdDate ? moment(q?.branch?.createdDate).format('yyyy/MM/DD') : ' '}`,
      currency: (q?.buyCurrency !== null) ? q?.buyCurrency : '-'
    }));
    const dataToExport = updatedQuotations?.map( ({id, ecertNo1, formattedCreatedDate,buy,transfer,sell, customer: {name: customerName, ic: customerIC},
                                             commodity: {name: commodityName }, currency, commodityQuantity, amount, fee, feeCurrencyIndex,
                                             bank: {name: bankName}, branchName, createdBy, approverName, status}) => ({
         id,
         ecertNo1,
         formattedCreatedDate,
         buy,
         transfer,
         sell,
         customerName,
         customerIC,
         bankName,
         branchName,
         createdBy,
         commodityName ,
         currency,
         commodityQuantity,
         amount,
         fee,
         feeCurrencyIndex,
         approverName,
         status
    }) )

    return dataToExport;
  };

  function translateStatus(q) {
    if(q?.cancelOrder){
      if(Storage.session.get('locale') === 'en') return "Cancelled";
      else return "ملغاة";
    }
    else{
      if(Storage.session.get('locale') === 'en') return q?.status;
      else{
        switch (q?.status) {
          case "Kept":
            return "احتفاظ";
          case "Transferred":
            return "تحويل للعميل";
          case "Sold":
            return "البيع";
          case "Quoted":
            return "عرض سعر";
          case "Bought":
            return "شراء";
          default:
            return "";
        }
      }
    }
  }

  const getDataToExportExcelArabic = () => {
    const maxLength = Math.max(...(reportsPrint?.map(q => q.branch?.name.length) || [])); // Find the longest branch name

    const updatedQuotations = reportsPrint?.map(q => ({
      ...q,
      ecertNo1: q?.bursaAPIBidCertId !== null ? q?.bursaAPIBidCert?.ecertNo : '-',
      formattedCreatedDate: moment(q?.createdDate).format('yyyy/MM/DD'),
      buy:  (q?.bursaAPIBidCertId !== null) ? moment(q?.bursaAPIBidCert?.purchaseTimeDate).format('yyyy/MM/DD HH:mm:ss.SSS') : '-',
      transfer: (q?.bursaAPIOtcCertId !== null) ?  moment( q?.bursaAPIOtcCert?.reportingTimeDate).format('yyyy/MM/DD HH:mm:ss.SSS') : '-',
      sell:(q?.bursaAPIStbCertId !== null) ?   moment( q?.bursaAPIStbCert?.sellingTimeDate).format('yyyy/MM/DD HH:mm:ss.SSS') : '-',
      amount: (q?.amount !== null) ? q?.amount?.toLocaleString() : 0,
      fee: ((q?.fee || 0) + (q?.commission || 0)).toLocaleString(),
      feeCurrencyIndex: (q?.feeCurrencyIndex !== null) ? q?.feeCurrencyIndex : '-',
      totalAmount: (q?.totalAmount!==null) ? q?.totalAmount?.toLocaleString(): 0,
      createdDate: moment( q?.branch?.createdDate).format('yyyy/MM/DD'),
      createdBy: getStaffFullName(q?.createdUser),
      approverName: (q?.approvedBy !== null && q?.approvedBy !== 0) ? q?.approver?.firstName+" "+q?.approver?.lastName : "-",
      status: translateStatus(q),
      branchName: `${q?.branch?.name} ${' '.repeat(maxLength - (q?.branch?.name.length || 0))} ${q?.branch?.createdDate ? moment(q?.branch?.createdDate).format('yyyy/MM/DD') : ' '}`,
      currency: (q?.buyCurrency !== null) ? q?.buyCurrency : '-'
    }));
    const dataToExport = updatedQuotations?.map( ({id, ecertNo1, formattedCreatedDate,buy,transfer,sell, customer: {name: customerName, ic: customerIC },
                                                    commodity: {name: commodityName },currency, commodityQuantity, amount, fee, feeCurrencyIndex,
                                                    bank: {name: bankName}, branchName, createdBy, approverName, status}) => ({
      status,
      approverName,
      feeCurrencyIndex,
      fee,
      amount,
      commodityQuantity,
      currency,
      commodityName,
      createdBy,
      branchName,
      bankName,
      customerIC,
      customerName,
      sell,
      transfer,
      buy,
      formattedCreatedDate,
      ecertNo1,
      id
    }) )

    return dataToExport;
  };

  function addRow(ws, data, section) {
    const borderStyles = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    const row = ws.addRow(data);
    // console.log('addRow', section, data);
    row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      if (section?.border) {
        cell.border = borderStyles;
      }
      if (section?.money && typeof cell.value === 'number') {
        cell.alignment = { horizontal: 'right', vertical: 'middle' };
        cell.numFmt = '$#,##0.00;[Red]-$#,##0.00';
      }
      if (section?.alignment) {
        cell.alignment = section.alignment;
      } else {
        cell.alignment = { vertical: 'middle' };
      }
      if (section?.font) {
        cell.font = section.font;
      }
      if (section?.fill) {
        cell.fill = section.fill;
      }
    });
    if (section?.height > 0) {
      row.height = section.height;
    }
    return row;
  }

  async function handleExportExcel() {
    const fileName = "report";
    const data = getDataToExportExcel();
    const dataArabic = getDataToExportExcelArabic();
    const widths = [{width: 20}, {width: 30},
      {width: 20}, {width: 20}, {width: 20}, {width: 20},
       {width: 20}, {width: 30}, {width: 15}, {width: 15}, {width: 12}, {width: 20}, {width: 30}, {width: 15}];
    if(data?.length > 0){
      if(Storage.session.get('locale') === 'en') {
        const wb = new ExcelJS.Workbook();
        const ws = wb.addWorksheet('My Sheet');

        const headerTitle = ["Quotation No",
          "Bursa Reference Number",
          "Date Time",
          "Buy",
          "Transfer",
          "Sell",
          "Customer Name",
          "Customer ID",
          "Bank Name",
          "Branch Name",
          "Bank Staff Name",
          "Commodity",
          "Currency",
          "Quantity (T)",
          `Amount`,
          `Fee`,
          `Fee Currency`,
          "Approved by",
          "Status"];
        const columns = headerTitle.length;

        const headerCustom = {
          border: true,
          money: false,
          height: 30,
          font: { size: 15, bold: false, color: { argb: 'FFFFFF' } },
          alignment: { horizontal: 'center', vertical: 'middle' },
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'FF0000',
            },
          },
        };
        const dataCustom = {
          border: true,
          money: false,
          height: 0,
          font: { size: 12, bold: false, color: { argb: '000000' } },
          alignment: { horizontal: 'left', vertical: 'left' },
          fill: null,
        };
        const footerCustom = {
          border: true,
          money: false,
          height: 30,
          font: { size: 15, bold: true, color: { argb: 'FFFFFF' } },
          alignment: null,
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: '0000FF',
            },
          },
        };
        if (widths && widths.length > 0) {
          ws.columns = widths;
        }
        // ws.addRow(header);
        // data.forEach(( rowData ) => {
        //   ws.addRow(Object.values(rowData));
        // })
        addRow(ws, headerTitle, headerCustom);
        data.forEach(( rowData ) => {
          addRow(ws, Object.values(rowData), dataCustom);
        })

        // const footerTitle = ["", "Total", "", "", "", "", "", "", "", "", "", "", Number(totalAmountForExcel).toLocaleString()];
        // addRow(ws, footerTitle, footerCustom);

        const buf = await wb.xlsx.writeBuffer();
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
      }
      else{
        const wb = new ExcelJS.Workbook();
        const ws = wb.addWorksheet('My Sheet');

        const headerTitle2 = ["الحالة", "جهة الموافقة", "عملة الرسوم", `الرسوم `, `المبلغ `, "الكمية (طن)", "عملة", "سلعة",
        "اسم المستخدم", "اسم الفرع", "اسم البنك", "رقم المدني بالعميل",
        "العميل","بيع","تحويل","شراء", "تاريخ الوقت", "رقم مرجع البورصة", "رقم عرض السعر"];
        const columns = headerTitle2.length;

        const headerCustom = {
          border: true,
          money: false,
          height: 30,
          font: { size: 15, bold: false, color: { argb: 'FFFFFF' } },
          alignment: { horizontal: 'center', vertical: 'middle' },
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'FF0000',
            },
          },
        };
        const dataCustom = {
          border: true,
          money: false,
          height: 0,
          font: { size: 12, bold: false, color: { argb: '000000' } },
          alignment: { horizontal: 'right', vertical: 'right' },
          fill: null,
        };
        const footerCustom = {
          border: true,
          money: false,
          height: 30,
          font: { size: 15, bold: true, color: { argb: 'FFFFFF' } },
          alignment: { horizontal: 'right', vertical: 'right' },
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: '0000FF',
            },
          },
        };
        if (widths && widths.length > 0) {
          ws.columns = widths;
        }
        addRow(ws, headerTitle2, headerCustom);
        dataArabic.forEach(( rowData ) => {
          addRow(ws, Object.values(rowData), dataCustom);
        })

        // const footerTitle = ["", "", Number(totalAmountForExcel).toLocaleString(), "", "", "", "", "", "", "", "", "", "المجموع"];
        // addRow(ws, footerTitle, footerCustom);

        const buf = await wb.xlsx.writeBuffer();
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
      }
    }

  };
  // handleExportExcel();
  const handleBankChange = event => {
    setSelectedBank(event.target.value);
    setFilter(false);

    const bankName = event.target.value;
    banks?.map(a => {
      if (bankName === String(a?.id)) {
        setSelectedBankName(a?.name);
      }
    });
  };

  const handleBranchChange = event => {
    setSelectedBranch(event.target.value);
    setFilter(false);

    const branchName = event.target.value;
    branches?.map(b => {
      if (branchName === String(b?.id)) {
        setSelectedBranchName(b?.name);
      }
    });
  };

  const handleBankUserChange = event => {
    setSelectedUser(event.target.value);
    setFilter(false);
  };

  const handleCommodityChange = event => {
    setSelectedCommodity(event.target.value);
    setFilter(false);
  };

  const handleFromDateChange = (newDate: Date | null) => {
    setDateError(undefined);
    setFromDate(newDate);
    setFilter(false);
  };

  const handleToDateChange = (newDate: Date | null) => {
    setDateError(undefined);
    setToDate(newDate);
    setFilter(false);
  };

  const handleBtnClick = () => {
    setPage(0);
    if (fromDate > toDate) {
      setDateError('From date cannot be larger than to date');
    }
    setFilter(true);

    let updatedSelectedBank = selectedBank;
    let updatedSelectedBranch = selectedBranch;
    let updatedSelectedUser = selectedUser;
    if (account?.authorities?.includes(AUTHORITIES.BRANCH_MANAGER) && branch) {
      updatedSelectedBank = [{
        label: branch?.bank?.name,
        value: branch?.bank?.id
      }];

      updatedSelectedBranch = [{
        label: branch?.name,
        value: branch?.id
      }];
    }   else if (account.authorities.includes(AUTHORITIES.AREA_MANAGER) && branches) {
      selectedBranch.length===0 && branches.map(br =>{
        const selectBankData={
          label: br.bank.name,
          value: br.bank.id,
        }
        updatedSelectedBank.push(selectBankData);
        const selectBranchData={
          label: br.name,
          value: br.id,
        }
        updatedSelectedBranch.push(selectBranchData);
      });
    } else if (account.authorities.includes(AUTHORITIES.AUDITOR) && bank ) {
      updatedSelectedBank = [{
        label: bank?.name,
        value: bank?.id
      }];
    } else if (account.authorities.includes(AUTHORITIES.BANK_MANAGER)  && banks) {
      updatedSelectedBank = [{
        label: banks[0]?.name,
        value: banks[0]?.id
      }];

    } else if (account.authorities.includes(AUTHORITIES.BANK_STAFF) && branch){
      updatedSelectedBank = [{
        label: branch?.bank?.name,
        value: branch?.bank?.id
      }];

      updatedSelectedBranch = [{
        label: branch?.name,
        value: branch?.id
      }];

      updatedSelectedUser = [{
        label: account?.login,
        value: account?.id
      }];
    }

    localStorage.setItem('filterBank', JSON.stringify(updatedSelectedBank));
    localStorage.setItem('filterBranch', JSON.stringify(updatedSelectedBranch));
    localStorage.setItem('filterUser', JSON.stringify(updatedSelectedUser));
    localStorage.setItem('filterCommodity', JSON.stringify(selectedCommodity));
    localStorage.setItem('filterFromDate', moment(fromDate).format('yyyy/MM/DD'));
    localStorage.setItem('filterToDate', moment(toDate).format('yyyy/MM/DD'));
  };

  function createData(quoteNo, poNo, dateTime, customer, commodity, quantity, amount, fee, total, approver, status) {
    return { quoteNo, poNo, dateTime, customer, commodity, quantity, amount, fee, total, approver, status };
  }

  const totalNewCustomer = () => {
    let numberOfNewCustomer = 0;
    let beforeNewCustId = 0;
    let conditionSameCustId = false;

    quotationReport &&
      quotationReport?.filter(m => {
        if (beforeNewCustId !== m.customerId) {
          numberOfNewCustomer++;
          conditionSameCustId = false;
        } else {
          if (!conditionSameCustId) {
            numberOfNewCustomer--;
            conditionSameCustId = true;
          }
        }
        beforeNewCustId = m.customerId;
      });

    return numberOfNewCustomer;
  };

  const totalReturnCustomer = () => {
    let numberOfReturnCustomer = 0;
    let beforeCustID = 0;
    let conditionDone = false;

    quotationReport &&
      quotationReport?.filter(n => {
        if (beforeCustID === n.customerId) {
          if (!conditionDone) {
            numberOfReturnCustomer++;
            conditionDone = true;
          }
        } else {
          conditionDone = false;
        }

        beforeCustID = n.customerId;
      });

    return numberOfReturnCustomer;
  };

  const statusType = [`${translate('home.status.quoted')}`, `${translate('home.status.bought')}`, `${translate('home.status.transferred')}`, `${translate('home.status.sold')}`, `${translate('home.status.kept')}`
    // , `${translate('home.status.cancelled')}`
  ];
  const statusType2 = [`Quoted`, `Bought`, `Transferred`, `Sold`, `Kept`,
    // `Cancelled`
  ];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgba(255,255,255,0)",
      color: theme.palette.common.white,
      fontWeight: 'bold',
      letterSpacing: 1,
      textAlign:Storage.session.get('locale') === 'en' ?'left':'right',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign:Storage.session.get('locale') === 'en' ?'left':'right',
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const customStyles = {
    dropdownIndicator: base => ({
      ...base,
      color: "red" // Custom colour
    })
  };

  function ccyFormat(num) {
    return `${num.toFixed(2)}`;
  }
  function subtotal(items) {
    return items?.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const selectedBankIdArr=[];
  const selectedBranchIdArr=[];
  const selectedUserNameArr=[];
  const selectedCommodityIdArr=[];
  selectedBank && selectedBank?.map(b=>selectedBankIdArr.push(b?.value?.toString()));
  selectedBranch && selectedBranch?.map(b=>selectedBranchIdArr.push(b?.value?.toString()));
  selectedBranchIdArr.length===0  && branches?.map(br => selectedBranchIdArr.push(br?.id.toString()));
  selectedUser && selectedUser?.map(b=>selectedUserNameArr.push(b?.label));
  selectedCommodity && selectedCommodity?.map(b=>selectedCommodityIdArr.push(b?.value?.toString()));

  useEffect(() => {
    moment.suppressDeprecationWarnings = true;
    const momentToDate=moment(toDate).add(1, 'day').format('yyyy/MM/DD');
    const obj={
      bankId: branches ? branches[0]?.bank?.id.toString():"",
      branchId: !selectedBranchIdArr.length ? "":selectedBranchIdArr,
      bankUserId:!selectedUserNameArr.length ? "":selectedUserNameArr,
      commodityId:!selectedCommodityIdArr.length ? "":selectedCommodityIdArr,
      fromDate:moment(fromDate).format('yyyy/MM/DD'),
      toDate:momentToDate
    };

    if(filter) {
      window.localStorage.setItem('storingColor', JSON.stringify(0));
      if (account.authorities.includes(AUTHORITIES.BRANCH_MANAGER) && branch) {
        dispatch(getValueSold({
          ...obj,
          bankId: branch.bank?.id.toString(),
          branchId: branch.id?.toString(),
        }));
        dispatch(getTotalAmountForReportTable({
          ...obj,
          bankId: branch.bank?.id.toString(),
          branchId: branch.id?.toString(),
          status:statusCheck,
          page:0,
          size:rowsPerPage
        }));
        dispatch(getTopCommodities({
          ...obj,
          bankId: branch.bank?.id.toString(),
          branchId: branch.id?.toString(),
        }));
        dispatch(getReports({
          ...obj,
          bankId: branch.bank?.id.toString(),
          branchId: branch.id?.toString(),
          status:statusCheck,
          page:pageNo,
          size:rowsPerPage,
        }));
        dispatch(getReportsPrint({
          ...obj,
          bankId: branch.bank?.id.toString(),
          branchId: branch.id?.toString(),
          status:statusCheck,
        }));
        dispatch(getEntitiesForCustomerReport({
          ...obj,
          bankId: branch.bank?.id.toString(),
          branchId: branch.id?.toString(),
        }));
        dispatch(getBankStaffPerformance({
          ...obj,
          bankId: branch.bank?.id.toString(),
          branchId: branch.id?.toString(),
        }));
      } else if (account.authorities.includes(AUTHORITIES.AREA_MANAGER) && branches) {


        dispatch(getValueSold({
          ...obj,
          branchId: selectedBranchIdArr.length ? selectedBranchIdArr : "",
        }));
        dispatch(getTotalAmountForReportTable({...obj,branchId: selectedBranch.map(br => br.value.toString()).join(", ").toString(), status:statusCheck, page:0,
          size:rowsPerPage}));
        dispatch(getTopCommodities({
          ...obj,
          branchId: selectedBranchIdArr.length ? selectedBranchIdArr : "",
        }));
        dispatch(getReports({...obj,
          branchId: selectedBranchIdArr.length ? selectedBranchIdArr : "",
          status:statusCheck,
          page:pageNo,
          size:rowsPerPage}));
        dispatch(getReportsPrint({...obj,
          branchId: selectedBranchIdArr.length ? selectedBranchIdArr : "",
          status:statusCheck}));
        dispatch(getEntitiesForCustomerReport({...obj,
          branchId: selectedBranchIdArr.length ? selectedBranchIdArr : "",
        }));
        dispatch(getBankStaffPerformance({...obj,
          branchId: selectedBranchIdArr.length ? selectedBranchIdArr : "",
        }));
        dispatch(getBranchPerformance( {
          ...obj,
          branchId: selectedBranchIdArr.length ? selectedBranchIdArr : "",
          selectOption: branchPerformanceOption
        }))
      } else if (account.authorities.includes(AUTHORITIES.AUDITOR) && bank) {
        dispatch(getValueSold({
          ...obj,
          bankId: bank?.id.toString(),
        }));
        dispatch(getTotalAmountForReportTable({
          ...obj,
          bankId: bank?.id.toString(),
          status:statusCheck,
          page:0,
          size:rowsPerPage
        }));
        dispatch(getTopCommodities({
          ...obj,
          bankId: bank?.id.toString(),
        }));
        dispatch(getReports({
          ...obj,
          bankId: bank?.id.toString(),
          status:statusCheck,
          page:pageNo,
          size:rowsPerPage
        }));
        dispatch(getReportsPrint({
          ...obj,
          bankId: bank?.id.toString(),
          status:statusCheck,
        }));
        dispatch(getBranchPerformance( {
          ...obj,
          bankId: !selectedBankIdArr.length ? "" : selectedBankIdArr,
          selectOption:branchPerformanceOption
        }))
        dispatch(getEntitiesForCustomerReport({
          ...obj,
          bankId: bank?.id.toString(),
        }));
        dispatch(getBankStaffPerformance({
          ...obj,
          bankId: bank?.id.toString(),
        }));
      } else if (account.authorities.includes(AUTHORITIES.BANK_MANAGER) && banks) {
        dispatch(getValueSold({
          ...obj,
          bankId: banks[0]?.id.toString(),
        }));
        dispatch(getTotalAmountForReportTable({
          ...obj,
          bankId: banks[0]?.id.toString(),
          status:statusCheck,
          page:0,
          size:rowsPerPage
        }));
        dispatch(getTopCommodities({
          ...obj,
          bankId: banks[0]?.id.toString(),
        }));
        dispatch(getReports({
          ...obj,
          bankId: banks[0]?.id.toString(),
          status:statusCheck,
          page:pageNo,
          size:rowsPerPage
        }));
        dispatch(getReportsPrint({
          ...obj,
          bankId: banks[0]?.id.toString(),
          status:statusCheck,
        }));
        dispatch(getBranchPerformance( {
          ...obj,
          bankId: !selectedBankIdArr.length ? "" : selectedBankIdArr,
          selectOption:branchPerformanceOption
        }))
        dispatch(getEntitiesForCustomerReport({
          ...obj,
          bankId: banks[0]?.id.toString(),
        }));
        dispatch(getBankStaffPerformance({
          ...obj,
          bankId: banks[0]?.id.toString(),
        }));
      } else if (account.authorities.includes(AUTHORITIES.BANK_STAFF) && branch){
        dispatch(getValueSold({
          ...obj,
          bankId: branch.bank?.id.toString(),
          branchId: branch.id?.toString(),
          bankUserId:account.login
        }));
        dispatch(getTotalAmountForReportTable({
          ...obj,
          bankId: branch.bank?.id.toString(),
          branchId: branch.id?.toString(),
          bankUserId:account.login,
          status:statusCheck,
          page:0,
          size:rowsPerPage
        }));
        dispatch(getTopCommodities({
          ...obj,
          bankId: branch.bank?.id.toString(),
          branchId: branch.id?.toString(),
          bankUserId:account.login
        }));
        dispatch(getReports({
          ...obj,
          bankId: branch.bank?.id.toString(),
          branchId: branch.id?.toString(),
          bankUserId:account.login,
          status:statusCheck,
          page:pageNo,
          size:rowsPerPage
        }));
        dispatch(getReportsPrint({
          ...obj,
          bankId: branch.bank?.id.toString(),
          branchId: branch.id?.toString(),
          bankUserId:account.login,
          status:statusCheck,
        }));
        dispatch(getEntitiesForCustomerReport({
          ...obj,
          bankId: branch.bank?.id.toString(),
          branchId: branch.id?.toString(),
          bankUserId:account.login
        }));
        dispatch(getBankStaffPerformance({
          ...obj,
          bankId: branch.bank?.id.toString(),
          branchId: branch.id?.toString(),
          bankUserId:account.login
        }));
      }
      else {
        dispatch(getValueSoldInKWD({
          ...obj,
          bankId: !selectedBankIdArr.length ? "" : selectedBankIdArr,
        }));
        dispatch(getTotalAmountForReportTableInKWD({
          ...obj,
          bankId: !selectedBankIdArr.length ? "" : selectedBankIdArr,
          status:statusCheck,
          page:0,
          size:rowsPerPage
        }));
        dispatch(getTopCommodities({
          ...obj,
          bankId: !selectedBankIdArr.length ? "" : selectedBankIdArr,
        }));
        dispatch(getReports({
          ...obj,
          bankId: !selectedBankIdArr.length ? "" : selectedBankIdArr,
          status:statusCheck,
          page:pageNo,
          size:rowsPerPage,
        }));
        dispatch(getReportsPrint({
          ...obj,
          bankId: !selectedBankIdArr.length ? "" : selectedBankIdArr,
          status:statusCheck,
        }));
        dispatch(getEntitiesForCustomerReport( {
          ...obj,
          bankId: !selectedBankIdArr.length ? "" : selectedBankIdArr,
        }))
        dispatch(getBankStaffPerformance( {
          ...obj,
          bankId: !selectedBankIdArr.length ? "" : selectedBankIdArr,
        }))
        dispatch(getBranchPerformanceInKWD( {
          ...obj,
          bankId: !selectedBankIdArr.length ? "" : selectedBankIdArr,
          selectOption:branchPerformanceOption
        }))
      }
    }

  }, [account,selectedUser,selectedCommodity,selectedBranch,filter,fromDate,toDate,statusCheck,pageNo,rowsPerPage,branchPerformanceOption]);


  const dates = [];
  const xValue = [];
  function getDatesInRange(startDate, endDate) {
    while (moment(startDate).startOf('day') <= moment(endDate).startOf('day')) {
      dates.push(moment(startDate).format('yyyy/MM/DD'));
      startDate.setDate(startDate.getDate() + 1);
    }
  }
  const d1 = new Date(fromDate);
  const d2 = new Date(toDate);
  valueSold && filter && getDatesInRange(d1, d2);
  valueSoldLoading &&
    dates?.map(d => {
      const find = valueSold?.find(v => v?.day === d);
      if (find) {
        xValue.push(find?.totalAmount);
      } else {
        xValue.push(0);
      }
    });

  const getRgb = function() {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
  };

  const getRandomColor = (colorsCount) => {
    let i = 0;
    const newColorArray = [];
    while (i < colorsCount) {
      newColorArray.push(getRgb());
      i++;
    }
    return newColorArray;
  };

//   const lineColor = getRandomColor(performanceData.datasets.length);
//   sessionStorage.setItem("color", JSON.stringify(lineColor));
//   const saveColor = JSON.parse(window.sessionStorage.getItem("color"));

  const performanceData = {
    labels: [],
    datasets: [],
  };
  const branchPerformanceData = {
    labels: [],
    datasets: [],
  };

  function getDatePerformance(startDate, endDate) {
    while (moment(startDate).startOf('day') <= moment(endDate).startOf('day')) {
      performanceData.labels.push(moment(startDate).format('yyyy/MM/DD'));
      branchPerformanceData.labels.push(moment(startDate).format('yyyy/MM/DD'));
      startDate.setDate(startDate.getDate() + 1);
    }
  }
  const fd = new Date(fromDate);
  const td = new Date(toDate);

  const storeColor = JSON.parse(window.localStorage.getItem('storingColor')) || 0;
  const branchStoreColor = JSON.parse(window.localStorage.getItem('branchStoringColor')) || 0;

  function runPerformanceData() {
    performance.forEach(function (e) {
      let labelIndex = performanceData.labels.indexOf(e.date);
      if (labelIndex === -1) {
        labelIndex = performanceData.labels.length;
        // performanceData.labels.push(getDatePerformance(fd, td));
        performanceData.datasets.forEach(function (dataset) {
          dataset.data.push(0);
        });
      }

      let performanceCreatedBy = performanceData.datasets.filter(function (a) {
        return a.label === e.createdBy;
      })[0];

      if (storeColor === 0) {
        const randomColor = getRandomColor(performanceData.datasets.length+1);
        window.localStorage.setItem('color', JSON.stringify(randomColor));
      }
      const lineColor = JSON.parse(window.localStorage.getItem('color'));

      if (performanceCreatedBy === undefined) {
        performanceCreatedBy = {
          label: e.createdBy,
          data: performanceData?.labels?.map(function () {
            return 0;
          }),
          backgroundColor: lineColor[performanceData.datasets.length],
          borderColor: lineColor[performanceData.datasets.length],
          borderWidth: 1.5,
        };
        performanceData.datasets.push(performanceCreatedBy);
      }
      performanceCreatedBy.data[labelIndex] = e.totalPo;
    });
    window.localStorage.setItem('storingColor', JSON.stringify(1));
  }

  performance && filter && getDatePerformance(fd, td);
  performance && runPerformanceData();

  branchPerformance &&
  branchPerformance.forEach(function (e) {
    let labelIndex = branchPerformanceData.labels.indexOf(e.date);
    if (labelIndex === -1) {
      labelIndex = branchPerformanceData.labels.length;
      // performanceData.labels.push(getDatePerformance(fd, td));
      branchPerformanceData.datasets.forEach(function (dataset) {
        dataset.data.push(0);
      });
    }

    let performanceBranch = branchPerformanceData.datasets.filter(function (a) {
      return a.label === e.branch;
    })[0];
    if (branchStoreColor === 0) {
      const randomColor = getRandomColor(branchPerformanceData.datasets.length);
      window.localStorage.setItem('BranchColor', JSON.stringify(randomColor));
    }
    const lineColor = JSON.parse(window.localStorage.getItem('BranchColor'));
    if (performanceBranch === undefined) {
      performanceBranch = {
        label: e.branch,
        data: branchPerformanceData?.labels?.map(function () {
          return 0;
        }),
        backgroundColor: lineColor[branchPerformanceData.datasets.length],
        borderColor: lineColor[branchPerformanceData.datasets.length],
        borderWidth: 1.5,
      };
      branchPerformanceData.datasets.push(performanceBranch);
    }
    performanceBranch.data[labelIndex] = e.total;
    window.localStorage.setItem('branchStoringColor', JSON.stringify(1));
  });

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  }
  const [checkboxState, setCheckboxState] = React.useState({
    selectAllChecked: (Storage.session.get('tableView')) ? Storage.session.get('tableView').selectAllChecked : true,
    quotationChecked: (Storage.session.get('tableView')) ? Storage.session.get('tableView').quotationChecked : true,
    poChecked: (Storage.session.get('tableView')) ? Storage.session.get('tableView').poChecked : true,
    dateChecked: (Storage.session.get('tableView')) ? Storage.session.get('tableView').dateChecked : true,
    buyChecked: (Storage.session.get('tableView')) ? Storage.session.get('tableView').buyChecked : true,
    transferChecked: (Storage.session.get('tableView')) ? Storage.session.get('tableView').transferChecked : true,
    sellChecked: (Storage.session.get('tableView')) ? Storage.session.get('tableView').sellChecked : true,
    customerChecked: (Storage.session.get('tableView')) ? Storage.session.get('tableView').customerChecked : true,
    commodityChecked: (Storage.session.get('tableView')) ? Storage.session.get('tableView').commodityChecked : true,
    quantityChecked: (Storage.session.get('tableView')) ? Storage.session.get('tableView').quantityChecked : true,
    amountChecked: (Storage.session.get('tableView')) ? Storage.session.get('tableView').amountChecked : true,
    feeChecked: (Storage.session.get('tableView')) ? Storage.session.get('tableView').feeChecked : true,
    feeCurrencyChecked: (Storage.session.get('tableView')) ? Storage.session.get('tableView').feeCurrencyChecked : true,
    totalValueChecked: false,
    approverChecked: (Storage.session.get('tableView')) ? Storage.session.get('tableView').approverChecked : true,
    statusChecked: (Storage.session.get('tableView')) ? Storage.session.get('tableView').statusChecked : true,
    currencyChecked: (Storage.session.get('tableView')) ? Storage.session.get('tableView').currencyChecked : true,
    totalAmountChecked: (Storage.session.get('tableView')) ? Storage.session.get('tableView').totalAmountChecked : true,
  });

  const handleCheckedAll = (event) => {

    setCheckboxState({
      selectAllChecked: event.target.checked,
      quotationChecked: true,
      poChecked: event.target.checked,
      dateChecked: event.target.checked,
      buyChecked: event.target.checked,
      transferChecked: event.target.checked,
      sellChecked: event.target.checked,
      customerChecked: event.target.checked,
      commodityChecked: event.target.checked,
      quantityChecked: event.target.checked,
      amountChecked: event.target.checked,
      feeChecked: event.target.checked,
      feeCurrencyChecked: event.target.checked,
      totalValueChecked: false,
      approverChecked: event.target.checked,
      statusChecked: event.target.checked,
      currencyChecked: event.target.checked,
      totalAmountChecked:event.target.checked,
    });

    window.sessionStorage.setItem('tableView', JSON.stringify({
      selectAllChecked: event.target.checked,
      quotationChecked: true,
      poChecked: event.target.checked,
      dateChecked: event.target.checked,
      transferChecked: event.target.checked,
      buyChecked: event.target.checked,
      sellChecked: event.target.checked,
      customerChecked: event.target.checked,
      commodityChecked: event.target.checked,
      quantityChecked: event.target.checked,
      amountChecked: event.target.checked,
      feeChecked: event.target.checked,
      feeCurrencyChecked: event.target.checked,
      totalValueChecked: false,
      approverChecked: event.target.checked,
      statusChecked: event.target.checked,
      currencyChecked: event.target.checked,
      totalAmountChecked: event.target.checked,
    }));

  };
  const handleChecked = (event) => {
    setCheckboxState({
      ...checkboxState,
      [event.target.name]: event.target.checked,
    });
    window.sessionStorage.setItem('tableView', JSON.stringify({
      ...checkboxState,
      [event.target.name]: event.target.checked,
    }));
  };
  const {
    selectAllChecked,
    quotationChecked,
    poChecked,
    dateChecked,
    transferChecked,
    buyChecked,
    sellChecked,
    customerChecked,
    commodityChecked,
    quantityChecked,
    amountChecked,
    feeChecked,
    feeCurrencyChecked,
    totalValueChecked,
    approverChecked,
    statusChecked,
    currencyChecked,
    totalAmountChecked
  } = checkboxState;

  const errorCheckbox = [
    quotationChecked,
    poChecked,
    dateChecked,
    transferChecked,
    buyChecked,
    sellChecked,
    customerChecked,
    commodityChecked,
    quantityChecked,
    amountChecked,
    feeChecked,
    feeCurrencyChecked,
    totalValueChecked,
    approverChecked,
    statusChecked,
    currencyChecked,
    totalAmountChecked
  ].filter((v) => v).length < 2;


  let responsiveFont = createTheme();
  responsiveFont = responsiveFontSizes(responsiveFont);

  const handleBankSelect = event => {
    setSelectedBank(event);
    setSelectedBranch([]);
    setSelectedUser([]);
  };

  const handleBranchSelect = event => {
    setSelectedBranch(event);
    setSelectedUser([]);
  };
  const componentRef = useRef();

  const handlePrint = () => {
    if(filter){
      const authToken = Storage.session.get('jhi-authenticationToken');
      Storage.local.set('jhi-authenticationToken', authToken);
      localStorage.setItem('print','true');

    }
  };

  useEffect(() => {
    if (filter) {
      statusCheck.length ? localStorage.setItem('status', String(statusCheck)) : localStorage.setItem('status', 'All');
      selectedCommodity.length ? localStorage.setItem('commodity', selectedCommodity?.map(({label}) => (label)).join(', ')) : localStorage.setItem('commodity', 'All');
      localStorage.setItem('period', moment(fromDate).format('yyyy/MM/DD') + ' - ' + moment(toDate).format('yyyy/MM/DD'));
      // localStorage.setItem('totalAmount',String(totalAmountForTable?.totalAmount));
      localStorage.setItem('totalAmount', String(totalAmountForReports));
      localStorage.setItem('currency', account?.authorities?.includes(AUTHORITIES.ADMIN) ||
      account?.authorities?.includes(AUTHORITIES.ALSHMOKH_ADMIN) ||
      account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER) ? 'KWD' : defaultCurrency[0]?.name);
      if (account?.authorities?.includes(AUTHORITIES.BANK_STAFF)) {
        localStorage.setItem('bank', branch?.bank?.name);
        localStorage.setItem('branch', branch?.name);
        localStorage.setItem('userName', account?.login);

      } else if (account?.authorities?.includes(AUTHORITIES.BRANCH_MANAGER)) {
        localStorage.setItem('bank', branch?.bank?.name);
        localStorage.setItem('branch', branch?.name);
        selectedUserNameArr.length ? localStorage.setItem('userName', String(selectedUserNameArr)) : localStorage.setItem('userName', 'All');

      } else if (account?.authorities?.includes(AUTHORITIES.BANK_MANAGER) || account?.authorities?.includes(AUTHORITIES.AUDITOR)) {

        if (account?.authorities?.includes(AUTHORITIES.BANK_MANAGER)) {
          localStorage.setItem('bank', banks[0]?.name);
        } else {
          localStorage.setItem('bank', bank?.name);
        }
        selectedBranch.length ? localStorage.setItem('branch', selectedBranch?.map(({label}) => (label)).join(', ')) : localStorage.setItem('branch', 'All');
        selectedUserNameArr.length ? localStorage.setItem('userName', String(selectedUserNameArr)) : localStorage.setItem('userName', 'All');

      } else if (account?.authorities?.includes(AUTHORITIES.AREA_MANAGER)) {
        localStorage.setItem('bank', branches.map(b => b.bank.name).join(', '));
        localStorage.setItem('branch', branches.map(b => b.name).join(', '));
        selectedBranch.length ? localStorage.setItem('branch', selectedBranch?.map(({label}) => (label)).join(', ')) : localStorage.setItem('branch', 'All');
        selectedUserNameArr.length ? localStorage.setItem('userName', String(selectedUserNameArr)) : localStorage.setItem('userName', 'All');

      } else {
        selectedBank.length ? localStorage.setItem('bank', selectedBank?.map(({label}) => (label)).join(', ')) : localStorage.setItem('bank', 'All');
        selectedBranch.length ? localStorage.setItem('branch', selectedBranch?.map(({label}) => (label)).join(', ')) : localStorage.setItem('branch', 'All');
        selectedUserNameArr.length ? localStorage.setItem('userName', String(selectedUserNameArr)) : localStorage.setItem('userName', 'All');

      }

      window.localStorage.setItem('tableView', JSON.stringify({
        ...checkboxState,
      }));
      localStorage.setItem('print', 'false');

    } else {
      localStorage.removeItem('period');
      localStorage.removeItem('bank');
      localStorage.removeItem('branch');
      localStorage.removeItem('userName');
      localStorage.removeItem('tableView');
      localStorage.removeItem('status');
      localStorage.removeItem('commodity');
      localStorage.removeItem('totalAmount');
      localStorage.removeItem('currency');
    }
  },[statusCheck,selectedCommodity,fromDate,toDate,selectedBank,selectedBranch,selectedUserNameArr,checkboxState, filter]);

  return (
    <Container>

      <div className="mt-4"  ref={componentRef}>
        <Grid container spacing={2} sx={{ alignItems: 'center' }}>
          <Grid item sm={6} md={6} sx={{ mb: 0, justifyContent: 'flex-start' }}>
            <ThemeProvider theme={responsiveFont}>
              <Typography variant="h4" component="div">
                <Translate contentKey="global.menu.report.reportTitle" />
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item sm={6} md={6} sx={{ mb: 0, display: 'inline-flex', justifyContent: 'flex-end' }} hidden>
            {(myAccount.authorities.includes(AUTHORITIES.ADMIN) || myAccount.authorities.includes(AUTHORITIES.ALSHMOKH_ADMIN)) && (
              <Link to="/">
                <Translate contentKey= "global.menu.report.detailedReportBtn" />
              </Link>
            )}
          </Grid>
        </Grid>


        <div className="filter-select">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Row className="select-row">
                <Col>
                  <Label>
                    <Translate contentKey="home.bank" />
                  </Label>
                </Col>
                <Col>
                  {account?.authorities?.includes(AUTHORITIES.BRANCH_MANAGER) ||
                  account?.authorities?.includes(AUTHORITIES.AUDITOR) ||
                  account?.authorities?.includes(AUTHORITIES.BANK_MANAGER) ||
                  account?.authorities?.includes(AUTHORITIES.AREA_MANAGER) ||
                  account?.authorities?.includes(AUTHORITIES.BANK_STAFF) ? (
                    <Input type="text" placeholder={branch?.bank?.name || bank?.name || banks[0]?.name || branches[0]?.bank?.name} disabled />
                  ) : (
                    <MultiSelect
                      options={banks?.map(item => ({
                        label: item?.name,
                        value: item?.id,
                      }))}
                      value={selectedBank}
                      onChange={handleBankSelect}
                      labelledBy="Select"
                      className="dropdown-style"
                      overrideStrings={{
                        "allItemsAreSelected": `${translate('global.menu.report.multiSelect.allItemsAreSelected')}`,
                        "noOptions": `${translate('global.menu.report.multiSelect.noOptions')}`,
                        "search": `${translate('home.search')}`,
                        "selectAll": `${translate('global.menu.report.multiSelect.selectAll')}`,
                        "selectSomeItems": `${translate('global.menu.report.multiSelect.select')}`,
                      }}
                    />
                  )}
                </Col>
              </Row>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Row className="select-row">
                <Col>
                  <Label>
                    <Translate contentKey="home.branch" />
                  </Label>
                </Col>
                <Col>
                  {account?.authorities?.includes(AUTHORITIES.BRANCH_MANAGER) || account?.authorities?.includes(AUTHORITIES.BANK_STAFF) ? (
                    <Input type="text" placeholder={branch?.name} disabled />
                  ) : (
                    <MultiSelect
                      options={branches?.map(item => ({
                        label: item?.name,
                        value: item?.id,
                      }))}
                      value={selectedBranch}
                      onChange={handleBranchSelect}
                      labelledBy="Select"
                      className="dropdown-style"
                      overrideStrings={{
                        "allItemsAreSelected": `${translate('global.menu.report.multiSelect.allItemsAreSelected')}`,
                        "noOptions": `${translate('global.menu.report.multiSelect.noOptions')}`,
                        "search": `${translate('home.search')}`,
                        "selectAll": `${translate('global.menu.report.multiSelect.selectAll')}`,
                        "selectSomeItems": `${translate('global.menu.report.multiSelect.select')}`,
                      }}
                    />
                  )}
                </Col>
              </Row>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Row className="select-row">
                <Col>
                  <Label>
                    <Translate contentKey= "global.menu.report.bankUser" />
                  </Label>
                </Col>
                <Col>
                  {!account?.authorities?.includes(AUTHORITIES.BANK_STAFF) ? (
                    <MultiSelect
                      options={userList?.map(item => ({
                        value: item?.id,
                        label: item?.login,
                      }))}
                      value={selectedUser}
                      onChange={setSelectedUser}
                      labelledBy="Select"
                      className="dropdown-style"
                      overrideStrings={{
                        "allItemsAreSelected": `${translate('global.menu.report.multiSelect.allItemsAreSelected')}`,
                        "noOptions": `${translate('global.menu.report.multiSelect.noOptions')}`,
                        "search": `${translate('home.search')}`,
                        "selectAll": `${translate('global.menu.report.multiSelect.selectAll')}`,
                        "selectSomeItems": `${translate('global.menu.report.multiSelect.select')}`,
                      }}
                    />
                  ) : (
                    <Input type="text" placeholder={account?.login} disabled />
                  )}
                </Col>
              </Row>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Row className="select-row">
                <Col>
                  <Label>
                    <Translate contentKey="home.commodity" />
                  </Label>
                </Col>
                <Col>
                  {/* <Input type="select" onChange={handleCommodityChange}>
                    <option value={0} disabled selected>
                      Choose Commodity
                    </option>
                    {commodities.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Input> */}
                  <MultiSelect
                    options={bankCommoditiesArr?.map(item => ({
                      label: item?.name,
                      value: item?.id,
                    }))}
                    value={selectedCommodity}
                    onChange={setSelectedCommodity}
                    labelledBy="Select"
                    ClearIcon="hidden"
                    // closeOnChangedValue
                    className="dropdown-style"
                    overrideStrings={{
                      "allItemsAreSelected": `${translate('global.menu.report.multiSelect.allItemsAreSelected')}`,
                      "noOptions": `${translate('global.menu.report.multiSelect.noOptions')}`,
                      "search": `${translate('home.search')}`,
                      "selectAll": `${translate('global.menu.report.multiSelect.selectAll')}`,
                      "selectSomeItems": `${translate('global.menu.report.multiSelect.select')}`,
                    }}
                  />
                </Col>
              </Row>
            </Grid>
          </Grid>
        </div>

        <div className="mt-4">
          <Grid container spacing={2} sx={{ alignItems: 'flex-end' }}>
            <Grid item xs={12} sm={6} md={3} sx={{ justifyContent: 'flex-start' }}>
              <Row className="select-row">
                <Col>
                  <Label>
                    <Translate contentKey= "global.menu.report.fromDate" />
                  </Label>
                </Col>
                <Col>
                  <LocalizationProvider locale={Storage.session.get('locale') === 'en' ? enUS : ar} dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      toolbarTitle={translate('entity.action.selectDate')}
                      okText={translate('entity.action.ok')}
                      cancelText={translate('entity.action.cancel')}
                      disableFuture
                      inputFormat="yyyy/MM/dd"
                      mask="____/__/__"
                      views={['year', 'month', 'day']}
                      value={fromDate}
                      onChange={handleFromDateChange}
                      open={openCalendarFrom}
                      onOpen={() => setOpenCalendarFrom(true)}
                      onClose={() => setOpenCalendarFrom(false)}
                      renderInput={params => (
                        <StyledTextField
                          size="small"
                          fullWidth
                          {...params}
                          onClick={(e) => setOpenCalendarFrom(true)}
                          InputProps={{
                            style: { borderBottom: 'none' },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end" onClick={(e) => setOpenCalendarFrom(true)}>
                                  <EventIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Col>
              </Row>
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{ justifyContent: 'flex-start' }}>
              <Row className="select-row">
                <Col>
                  <Label>
                    <Translate contentKey= "global.menu.report.toDate" />
                  </Label>
                </Col>
                <Col>
                  <LocalizationProvider locale={Storage.session.get('locale') === 'en' ? enUS : ar} dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      toolbarTitle={translate('entity.action.selectDate')}
                      okText={translate('entity.action.ok')}
                      cancelText={translate('entity.action.cancel')}
                      disableFuture
                      inputFormat="yyyy/MM/dd"
                      mask="____/__/__"
                      views={['year', 'month', 'day']}
                      value={toDate}
                      onChange={handleToDateChange}
                      open={openCalendarTo}
                      onOpen={() => setOpenCalendarTo(true)}
                      onClose={() => setOpenCalendarTo(false)}
                      renderInput={params => (
                        <StyledTextField
                          size="small"
                          fullWidth
                          {...params}
                          onClick={(e) => setOpenCalendarTo(true)}
                          InputProps={{
                            style: { borderBottom: 'none' },
                            endAdornment: (
                              <InputAdornment position="end" onClick={(e) => setOpenCalendarTo(true)}>
                                <IconButton edge="end">
                                  <EventIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Col>
              </Row>
            </Grid>
            <Grid item sm={12} md={3}>
              <p>{dateError}</p>
            </Grid>
            <Grid item sm={12} md={3} sx={{ display: 'inline-flex', justifyContent: 'flex-end' }}>
              <Button color="primary" onClick={handleBtnClick}>
                <Translate contentKey= "global.menu.report.filterBtn" />
              </Button>
            </Grid>
          </Grid>
        </div>

        <div className="mt-4">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={7}>
              <Paper className="chart-paper">
                <h5>
                  <Translate contentKey= "global.menu.report.chart.valueSoldTitle"
                    interpolate={{ currency: account?.authorities?.includes(AUTHORITIES.ADMIN) ||
                    account?.authorities?.includes(AUTHORITIES.ALSHMOKH_ADMIN) ||
                    account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER) ? 'KWD' : defaultCurrency[0]?.name }} />
                </h5>
                &nbsp;
                <div>
                  <Line
                    data={{
                      labels: Object.values(dates),
                      datasets: [
                        {
                          label: 'Value Sold',
                          data: xValue.length !== 0 && Object.values(xValue),
                          borderColor: '#1E3A8A',
                          backgroundColor: '#1E3A8A',
                          borderWidth: 1,
                        },
                      ],
                    }}
                    height={420}
                    width={120}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        title: {
                          display: false,
                          text: 'Value Sold',
                        },
                        legend: {
                          display: false,
                          position: 'right',
                        },
                      },
                    }}
                  />
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <Paper className="chart-paper-performance">
                {myAccount.authorities.includes(AUTHORITIES.BANK_STAFF) && <h5><Translate contentKey= "global.menu.report.chart.userPerformanceTitle" /></h5>}
                {!myAccount.authorities.includes(AUTHORITIES.BANK_STAFF) && <h5><Translate contentKey= "global.menu.report.chart.staffPerformanceTitle" /></h5>}
                <Typography variant="body2" component="div">
                  <Translate contentKey= "global.menu.report.chart.staffPerformanceSubtitle" />
                </Typography>
                &nbsp;
                <div>
                  <Line
                    data={performanceData}
                    height={160}
                    width={120}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        yAxis: {
                          min: 0,
                        },
                      },
                      plugins: {
                        legend: {
                          labels: {
                            usePointStyle: true,
                            font: {
                              size: 10,
                            },
                          },
                          display: true,
                          position: 'right',
                        },
                      },
                    }}
                  />
                </div>
              </Paper>
              <Grid container spacing={2} className="mt-1" sx={{ alignItems: 'stretch' }}>
                <Grid item xs={12} sm={6} md={6}>
                  <Paper className="chart-paper">
                    {myAccount.authorities.includes(AUTHORITIES.BANK_STAFF) ?
                      <h5><Translate contentKey= "global.menu.report.chart.userClients" /></h5>
                      :
                      <h5><Translate contentKey= "global.menu.report.chart.clients" /></h5>}
                    <Typography variant="body2" component="div">
                      {moment(fromDate).format('yyyy/MM/DD')} - {moment(toDate).format('yyyy/MM/DD')}
                    </Typography>
                    <div>
                      <Pie
                        data={{
                          labels: [`${translate('global.menu.report.chart.returnCustomers')}: ${totalReturnCustomer()}`,
                            `${translate('global.menu.report.chart.newCustomers')}: ${totalNewCustomer()}`],
                          datasets: [
                            {
                              label: 'Clients',
                              data: [totalReturnCustomer(), totalNewCustomer()],
                              backgroundColor: ['#1E3A8A', '#7B8DBF'],
                            },
                          ],
                        }}
                        //  height={162}
                        //  width={240}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                          plugins: {
                            title: {
                              display: false,
                              text: 'Clients',
                            },
                            legend: {
                              labels: {
                                usePointStyle: true,
                                font: {
                                  size: 10,
                                },
                              },
                              display: true,
                              position: 'top',
                            },
                            tooltip: {
                              callbacks: {
                                label: labelTooltip,
                              },
                            },
                          },
                        }}
                        plugins={[legendMargin]}
                      />
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Paper className="chart-paper">
                    <h5>
                      <Translate contentKey="global.menu.report.chart.topCommodityTitle">Top Commodity</Translate>
                    </h5>
                    <Typography variant="body2" component="div">
                      <Translate contentKey="global.menu.report.chart.topCommoditySubtitle" />
                    </Typography>
                    <TableContainer sx={{ maxHeight: 140 }} className="commodity-list">
                       <ol>
                        <table className="commodity-name">
                          {topCommodityList &&
                            topCommodityList?.map(result => (
                            <tbody key={result?.id}>
                              <tr>
                                <td className="px-1">
                                  <li>{result?.name}</li>
                                </td>
                                <td className="px-1">
                                  <div style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>{result?.soldCommodities}</div>
                                </td>
                              </tr>
                            </tbody>
                            ))}
                        </table>
                      </ol>
                    </TableContainer>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {(myAccount.authorities.includes(AUTHORITIES.BANK_MANAGER) ||
                myAccount.authorities.includes(AUTHORITIES.AUDITOR) ||
                myAccount.authorities.includes(AUTHORITIES.AREA_MANAGER) ||
                myAccount.authorities.includes(AUTHORITIES.ALSHMOKH_ADMIN) ||
                myAccount.authorities.includes(AUTHORITIES.ALSHMOKH_USER)) &&
                  <Paper className="chart-paper">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={8} md={9} style={{ justifyContent: 'flex-start' }}>
                        {myAccount.authorities.includes(AUTHORITIES.AUDITOR) &&
                          <h5>{translate('global.menu.report.chart.branchesPerformanceTitle')} ({bank?.name})</h5>}
                        {myAccount.authorities.includes(AUTHORITIES.BANK_MANAGER) &&
                          <h5>{translate('global.menu.report.chart.branchesPerformanceTitle')} ({banks[0]?.name})</h5>}
                        {(!myAccount.authorities.includes(AUTHORITIES.BANK_MANAGER) ||
                          !myAccount.authorities.includes(AUTHORITIES.AUDITOR)) && selectedBank.length!==0 &&
                          <h5>{translate('global.menu.report.chart.branchesPerformanceTitle')} ({selectedBankName})</h5>}
                        {selectedBank.length===0 &&
                          <h5 hidden={myAccount.authorities.includes(AUTHORITIES.BANK_MANAGER) || myAccount.authorities.includes(AUTHORITIES.AUDITOR)}>
                          {translate('global.menu.report.chart.branchesPerformanceTitle')} </h5>}
                        {branchPerformanceOption === `${translate('global.menu.report.chart.totalValueSold')}` ?
                          <Typography variant="body2" component="div">
                            <Translate contentKey= "global.menu.report.chart.totalValueSold"
                              interpolate={{ currency: account?.authorities?.includes(AUTHORITIES.ADMIN) ||
                              account?.authorities?.includes(AUTHORITIES.ALSHMOKH_ADMIN) ||
                              account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER) ? 'KWD' : defaultCurrency[0]?.name }} />
                          </Typography>
                          :
                          <Typography variant="body2" component="div">{translate('global.menu.report.chart.totalPurchaseOrders')}</Typography>
                        }
                      </Grid>
                      <Grid item xs={12} sm={4} md={3} style={{ justifyContent: 'flex-end' }}>
                        <Input type="select" className="select-style" onChange={(e)=>setBranchPerformanceOption(e.target.value)} >
                          <option value={`${translate('global.menu.report.chart.totalValueSoldTitle')}`}>
                            {translate('global.menu.report.chart.totalValueSoldTitle')}
                          </option>
                          <option value={`${translate('global.menu.report.chart.totalPurchaseOrders')}`}>
                            {translate('global.menu.report.chart.totalPurchaseOrders')}
                          </option>
                        </Input>
                      </Grid>
                    </Grid>
                    &nbsp;
                    <div>
                      <Line
                        data={branchPerformanceData}
                        height={160}
                        width={120}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                          scales: {
                            yAxis: {
                              min: 0,
                            },
                          },
                          plugins: {
                            legend: {
                              labels: {
                                usePointStyle: true,
                                font: {
                                  size: 10,
                                },
                              },
                              display: true,
                              position: 'right',
                            },
                          },
                        }}
                      />
                    </div>
                  </Paper>
                }
            </Grid>
          </Grid>
        </div>

        <Grid container spacing={1} sx={{ mt: '40px', alignItems: 'center' }}>
          <Grid item xs={12} sm={12} md={12} lg={4} sx={{ justifyContent: 'flex-start', flexDirection: 'row', display: 'inline-flex' }}>
            <div>
              {(myAccount.authorities.includes(AUTHORITIES.ADMIN) || myAccount.authorities.includes(AUTHORITIES.ALSHMOKH_ADMIN)) && (
                <div>
                  <Button
                    color="primary"
                    outline
                    onClick={handleClick}
                    >
                    <SettingsIcon sx={{ fontSize: '20px', marginTop: '-4px' }} /> <Translate contentKey="global.menu.report.tableViewBtn" />
                  </Button>
                  <Menu
                    id="tableViewMenu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <FormControl
                      required
                      error={errorCheckbox}
                    >
                      <FormGroup>
                        <MenuItem>
                          <FormControlLabel
                            label={translate('home.table.selectAll')}
                            control={<Checkbox
                              checked={selectAllChecked}
                              onChange={handleCheckedAll}
                              name="selectAllChecked"
                            />}
                          />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            label={translate('home.table.orderNo')}
                            control={<Checkbox
                              checked={quotationChecked}
                              onChange={handleChecked}
                              name="quotationChecked"
                              disabled
                              />}
                            />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            label={translate('home.table.poNo')}
                            control={<Checkbox
                              checked={poChecked}
                              onChange={handleChecked}
                              name="poChecked"
                              />}
                            />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            label={translate('home.table.dateTime')}
                            control={<Checkbox
                              checked={dateChecked}
                              onChange={handleChecked}
                              name="dateChecked"
                              />}
                            />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            label={translate('home.table.buy')}
                            control={<Checkbox
                              checked={buyChecked}
                              onChange={handleChecked}
                              name="buyChecked"
                              />}
                            />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            label={translate('home.table.transfer')}
                            control={<Checkbox
                              checked={transferChecked}
                              onChange={handleChecked}
                              name="transferChecked"
                              />}
                            />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            label={translate('home.table.sell')}
                            control={<Checkbox
                              checked={sellChecked}
                              onChange={handleChecked}
                              name="sellChecked"
                              />}
                            />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            label={translate('home.table.customerName')}
                            control={<Checkbox
                              checked={customerChecked}
                              onChange={handleChecked}
                              name="customerChecked"
                              />}
                            />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            label={translate('home.table.commodity')}
                            control={<Checkbox
                              checked={commodityChecked}
                              onChange={handleChecked}
                              name="commodityChecked"
                              />}
                            />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                              label={translate('home.table.currency')}
                              control={<Checkbox
                                  checked={currencyChecked}
                                  onChange={handleChecked}
                                  name="currencyChecked"
                              />}
                          />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            label={translate('home.table.quantity')}
                            control={<Checkbox
                              checked={quantityChecked}
                              onChange={handleChecked}
                              name="quantityChecked"
                              />}
                            />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            label={translate('home.table.amountDefault')}
                            control={<Checkbox
                              checked={amountChecked}
                              onChange={handleChecked}
                              name="amountChecked"
                              />}
                            />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                              label={translate('home.table.feeDefault')}
                            control={<Checkbox
                              checked={feeChecked}
                              onChange={handleChecked}
                              name="feeChecked"
                              />}
                            />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            label={translate('home.table.feeCurrency')}
                            control={<Checkbox
                              checked={feeCurrencyChecked}
                              onChange={handleChecked}
                              name="feeCurrencyChecked"
                            />}
                          />
                        </MenuItem>
                        <MenuItem hidden>
                          <FormControlLabel
                              label={translate('home.table.totalValueDefault')}
                              control={<Checkbox
                                  checked={totalAmountChecked}
                                  onChange={handleChecked}
                                  name="totalAmountChecked"
                              />}
                          />
                        </MenuItem>
                        {
                          // Conditional rendering to check if MenuItem should be hidden
                          !(account?.authorities?.includes(AUTHORITIES.ALSHMOKH_ADMIN) || account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER)) ? (
                              <MenuItem hidden>
                                <FormControlLabel
                                    label={translate('home.table.totalAllValue')}
                                    control={<Checkbox
                                        checked={totalValueChecked}
                                        onChange={handleChecked}
                                        name="totalValueChecked"
                                    />}
                                />
                              </MenuItem>
                          ) : (
                              // Render a hidden MenuItem or nothing at all
                              <MenuItem style={{ display: 'none' }} hidden>
                                <FormControlLabel
                                    label={<Translate contentKey="home.table.totalAllValue" interpolate={{ currency: defaultCurrency[0]?.name }} />}
                                    control={
                                      <Checkbox
                                          checked={false} // Always false when hidden
                                          name="totalValueChecked"
                                      />
                                    }
                                />
                              </MenuItem>
                          )
                        }
                        <MenuItem>
                          <FormControlLabel
                            label={translate('home.table.approver')}
                            control={<Checkbox
                              checked={approverChecked}
                              onChange={handleChecked}
                              name="approverChecked"
                              />}
                            />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            label={translate('home.table.status')}
                            control={<Checkbox
                              checked={statusChecked}
                              onChange={handleChecked}
                              name="statusChecked"
                              />}
                            />
                        </MenuItem>
                      </FormGroup>
                      <FormHelperText>
                        {translate('global.menu.report.errCheckboxMsg')}
                      </FormHelperText>
                    </FormControl>
                  </Menu>
                </div>
              )}
            </div>
            &nbsp;
            <Dropdown isOpen={dropdownOpen} toggle={toggle} variant="contained" onClick={handleButtonClick}>
              <DropdownToggle caret color="primary"><FontAwesomeIcon icon="print" /> <Translate contentKey="global.menu.report.exportBtn"/></DropdownToggle>
            <DropdownMenu>
              <MenuItem data-export-type="excel">
                <div>
                  <DropdownItem onClick={handleExportExcel}>
                    <Translate contentKey="global.menu.report.excelBtn" />
                  </DropdownItem >
                </div>
              </MenuItem>
              <MenuItem data-export-type="pdf">
                <div>
                  <Link
                    to={{
                      pathname: "/report-print",
                    }}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <DropdownItem onClick={handlePrint}>
                      <Translate contentKey="global.menu.report.printBtn" />
                    </DropdownItem >
                  </Link>
                </div>
              </MenuItem>
            </DropdownMenu>
            </Dropdown>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={7} sx={{ justifyContent: 'flex-end' }}>
            <Box className="status-filter-box">
              <FormGroup sx={{ flexDirection: {xs: 'column', sm: 'row', md: 'row', }, alignItems: 'flex-start' }}>
                <FormLabel className="form-label-status">
                  <Translate contentKey="home.status.title">Status:</Translate>
                </FormLabel>
                {statusType?.map((status, index) => (
                  <FormControlLabel
                    key={index}
                    control={<Checkbox onChange={handleChange} />}
                    label={status}
                    value={statusType2[index]}
                    sx={{
                      '& .MuiSvgIcon-root': { fontSize: {md: 20, sm: 18, xs: 16} },
                      '& .MuiTypography-root': { fontSize: {md: 16, sm: 14, xs: 14} },
                      mx: 1
                    }}
                  />
                ))}
              </FormGroup>
            </Box>
          </Grid>
        </Grid>
        <Paper className="paper-table mt-3 mb-4">
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow className={"enhanced-table-reports"}>
                  <StyledTableCell hidden={!quotationChecked}>{translate('home.table.orderNo')}</StyledTableCell>
                  <StyledTableCell hidden={!poChecked}>{translate('home.table.poNo')}</StyledTableCell>
                  <StyledTableCell hidden={!dateChecked}>{translate('home.table.dateTime')}</StyledTableCell>
                  <StyledTableCell hidden={!buyChecked}>{translate('home.table.buy')}</StyledTableCell>
                  <StyledTableCell hidden={!transferChecked}>{translate('home.table.transfer')}</StyledTableCell>
                  <StyledTableCell hidden={!sellChecked}>{translate('home.table.sell')}</StyledTableCell>
                  <StyledTableCell hidden={!customerChecked}>{translate('home.table.customerName')}</StyledTableCell>
                  <StyledTableCell hidden={!commodityChecked}>{translate('home.table.commodity')}</StyledTableCell>
                  <StyledTableCell hidden={!currencyChecked}>{translate('home.table.currency')}</StyledTableCell>
                  <StyledTableCell hidden={!quantityChecked}>{translate('home.table.quantity')}</StyledTableCell>
                  <StyledTableCell hidden={!amountChecked}>{translate('home.table.amountDefault')}</StyledTableCell>
                  <StyledTableCell hidden={!feeChecked}>{translate('home.table.feeDefault')}</StyledTableCell>
                  <StyledTableCell hidden={!feeCurrencyChecked}>{translate('home.table.feeCurrency')}</StyledTableCell>
                  <StyledTableCell hidden>{translate('home.table.totalValueDefault')}</StyledTableCell>
                  <StyledTableCell hidden={!approverChecked}>{translate('home.table.approver')}</StyledTableCell>
                  <StyledTableCell hidden={!statusChecked}>{translate('home.table.status')}</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {filter && quotations?.length>0 && quotations?.map(q => (
                  <StyledTableRow key={q?.id}>
                    <StyledTableCell hidden={!quotationChecked}>{q?.id}</StyledTableCell>
                    <StyledTableCell hidden={!poChecked}>{q?.bursaAPIBidCertId !== null ? q?.bursaAPIBidCert?.ecertNo : '-'}</StyledTableCell>
                    <StyledTableCell hidden={!dateChecked}>{moment(q?.createdDate).format('yyyy/MM/DD')}</StyledTableCell>
                    <StyledTableCell hidden={!buyChecked}>{ (q?.bursaAPIBidCertId !== null) ? moment(q?.bursaAPIBidCert?.purchaseTimeDate).format('yyyy/MM/DD HH:mm:ss.SSS') : '-'}</StyledTableCell>
                    <StyledTableCell hidden={!transferChecked}>{ (q?.bursaAPIOtcCertId !== null) ?  moment( q?.bursaAPIOtcCert?.reportingTimeDate).format('yyyy/MM/DD HH:mm:ss.SSS') : '-'}</StyledTableCell>
                    <StyledTableCell hidden={!sellChecked}>{ (q?.bursaAPIStbCertId !== null) ?   moment( q?.bursaAPIStbCert?.sellingTimeDate).format('yyyy/MM/DD HH:mm:ss.SSS') : '-'}</StyledTableCell>
                    <StyledTableCell hidden={!customerChecked}>{q?.customer.name}</StyledTableCell>
                    <StyledTableCell hidden={!commodityChecked}>{q?.commodity.name}</StyledTableCell>
                    <StyledTableCell hidden={!currencyChecked}>{q?.buyCurrency}</StyledTableCell>
                    <StyledTableCell hidden={!quantityChecked}>{q?.commodityQuantity?.toLocaleString()}</StyledTableCell>
                    <StyledTableCell hidden={!amountChecked}>{q?.amount!==null ? q?.amount?.toLocaleString() : 0}</StyledTableCell>
                    <StyledTableCell hidden={!feeChecked}>{((q?.fee || 0) + (q?.commission || 0)).toLocaleString()}</StyledTableCell>
                    <StyledTableCell hidden={!feeCurrencyChecked}>{q?.feeCurrencyIndex}</StyledTableCell>
                    <StyledTableCell hidden>{q?.totalAmount!==null ? q?.totalAmount?.toLocaleString(): 0}</StyledTableCell>
                    <StyledTableCell hidden={!approverChecked}>{(q?.approvedBy !== null && q.approvedBy !== 0) ? q?.approver?.firstName+" "+q?.approver?.lastName : '-'}</StyledTableCell>
                    <StyledTableCell hidden={!statusChecked}>{translateStatus(q)}</StyledTableCell>
                  </StyledTableRow>
                ))}
                <TableRow hidden={!totalValueChecked}>
                  <TableCell />
                  <TableCell
                    colSpan={11}
                    style={{ textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right' }}
                    >
                    <b>{translate('home.table.total')}</b>
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    style={{ textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right', display: 'none' }}
                    >
                    <b>{Number(totalAmountForReports).toLocaleString()}</b>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className={Storage.session.get('locale') === 'en' ? "table-pagination" : "table-pagination-arabic"}
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={pageNo}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={translate('home.table.rowsPerPage')}
          />
        </Paper>
      </div>

            {/*   {   */}
            {/*     reportsPrint?.length>0 && localStorage.setItem("printQuo",JSON.stringify(reportsPrint))   */}
            {/*   }   */}

            {/*   {   */}
            {/*     createStaffList()?.length>0 && localStorage.setItem("staff", JSON.stringify(createStaffList()))   */}
            {/*   }   */}

    </Container>
  );
};

export default Reports;
