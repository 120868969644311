import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Input, Label } from 'reactstrap';
import { Storage, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntity, updateEntity, createEntity, reset, getEntities, uploadImage } from './bank.reducer';
import {
  getAllEntities as getCurrencies,
  updateEntity as updateCurrencyIndex
} from 'app/entities/currency-index/currency-index.reducer';
import { getAllEntities as getCurrencyRates } from 'app/entities/currency-rates/currency-rates.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getAllEntities as getCommodities, updateEntity as updateCommodity } from 'app/entities/commodity/commodity.reducer';
import ImageUploading from 'react-images-uploading';
import Resizer from "react-image-file-resizer";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import AddAPhoto from '@mui/icons-material/AddAPhoto'
import { createTheme, responsiveFontSizes, ThemeProvider, styled } from "@mui/material/styles";
import { countryList } from "app/entities/bank/country";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import 'app/modules/account/currency/currency.css';
import axios from "axios";
import { formatDate } from 'app/shared/util/date-utils';
import PhoneInput from 'react-phone-input-2';
import Badge from '@mui/material/Badge';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { enUS, ar } from 'date-fns/locale';
import IconButton from '@mui/material/IconButton';
import EventIcon from '@mui/icons-material/Event';
import S3 from 'react-aws-s3';
import AWS from 'aws-sdk';
import moment from "moment";

export const BankUpdate = (props: RouteComponentProps<{ id: string }>) => {

  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);
  const [isNew] = useState(!props.match.params || !props.match.params.id);
  const bankEntity = useAppSelector(state => state.bank.entity);
  const bankList = useAppSelector(state => state.bank.entities);
  const commodityEntities = useAppSelector(state => state.commodity.entities);
  const commodityEntity = useAppSelector(state => state.commodity.entity);
  const currencyIndexList = useAppSelector(state => state.currencyIndex.entities);
  const currencyRateList = useAppSelector(state => state.currencyRates.entities);
  const loading = useAppSelector(state => state.bank.loading);
  const updating = useAppSelector(state => state.bank.updating);
  const updateSuccess = useAppSelector(state => state.bank.updateSuccess);
  const countryHasNoCode = ['as', 'ai', 'aq', 'bm', 'bv', 'ky', 'cx', 'cc', 'ck', 'fk', 'fo', 'tf', 'gi', 'gl', 'gg', 'hm', 'im', 'je', 'yt', 'ms', 'nu', 'nf', 'mp', 'pn', 'bl', 'sh', 'mf', 'pm', 'sx', 'gs', 'sj', 'tk', 'um', 'vg', 'vi', 'wf', 'eh', 'ax'];

  const [previewPic, setPreviewPic] = useState(null);
  const [translatedDescriptions, setTranslatedDescriptions] = useState({});
  const [preventMultipleClick, setPreventMultipleClick] = useState(false);
  const [commodityValidationMsg, setCommodityValidationMsg] = useState('');

  const [openCalendar, setOpenCalendar] = useState(false);
  const [valueAgreementDate, setValueAgreementDate] = React.useState(null);
  const [agreementDateValidationMsg, setAgreementDateValidationMsg] = useState("");
  const [selectedFeeCurrency, setSelectedFeeCurrency] = useState('Transaction Currency');

  const [imageFromS3, setImageFromS3] = useState(null);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await axios.get('/api/config/bucketName');
        const bucketName = response.data.bucketName;
        const imgURL = bankEntity?.imgURL;
        if (bucketName && imgURL !== undefined && imgURL !==null) {
          const imageFromS3Url = `https://${bucketName}.s3.me-south-1.amazonaws.com/bankLogo/${imgURL}`;
          setImageFromS3(imageFromS3Url);
        } else {
          setImageFromS3(null);
        }
      } catch (error) {
        console.error('Error fetching bucket name:', error);
      }
    };
    fetchConfig();
  }, [bankEntity]);

  const isBackToDetail = props.location.pathname === `/bank/${bankEntity?.id}/edit` && props.location.search === '';
  const handleClose = () => {
    window.location.assign(`/bank/${bankEntity?.id}`);
  };

  useEffect(() => {
    dispatch(getEntities({}));
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id))
        .then(
          res => {
            setValueId(res.payload['data']?.id);
            setValueBankName(res.payload['data']?.name);
            setValueBankDesc(res.payload['data']?.description);
            setValueImageURL(res.payload['data']?.imgURL);
            setCurrencyName(res.payload['data']?.currencyId);
            setCurrencyName(currencyIndexList.find(currency => currency?.id === res.payload['data']?.currencyId)?.name);
            setCountryName(res.payload['data']?.country);
            setAmount(res.payload['data']?.transactionAmountLimit?.toLocaleString());
            const amountLimit = res.payload['data']?.transactionAmountLimit;
            if (amountLimit) {
              const selectedAmount = toEnglishDigits(amountLimit.toString()).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              setAmountLimitDisplayed(selectedAmount);
            }
            // setAmountLimitDisplayed(res.payload['data']?.transactionAmountLimit?.toLocaleString());
            setApprovalPeriodTime(res.payload['data']?.approvalPeriod);
            setTransactionForms(res.payload['data']?.transaction_forms);
            setValueOTP(res.payload['data']?.otp);
            setSelectedFeeCurrency(res.payload['data']?.feeCurrency);

            if (res.payload['data']?.commodities?.length > 0) {
              const validCommodities = res.payload['data'].commodities.filter(commodity => commodity && commodity.id !== null);
              if (validCommodities.length > 0) {
                setCommodityActive(validCommodities);
                setIsChecked(validCommodities.map(commodity => ({ id: commodity.id })));
              } else {
                setCommodityActive([]);
                setIsChecked([]);
              }
            } else {
              setCommodityActive([]);
              setIsChecked([]);
            }
            setValueDisplayTransactionFee(res.payload['data']?.displayTransactionFee);
            setValueDisplayFeeInTransactionCurrency(res.payload['data']?.displayFeeInTransactionCurrency)
            setValueAgreementDate(res.payload['data']?.agreementDate);
          }
        );
    }
  }, [currencyIndexList]);

  useEffect(() => {
    if (!isNew && bankEntity?.commodities?.length > 0) {

    // console.warn("bankEntity?.commodities?.length"+bankEntity?.commodities[0].id);
      const tempArray = [];
      // console.warn("commodityActive.length "+commodityActive.length);
      for (let i = 0; i < bankEntity?.commodities?.length; i++) {

        tempArray.push({
          id: bankEntity?.commodities[i]?.id
        })
      }
      setIsChecked(tempArray);
    }
  }, [bankEntity?.commodities]);

  useEffect(() => {
    dispatch(getCurrencies({}));
    dispatch(getCurrencyRates({}));
    dispatch(getCommodities({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const [valueId, setValueId] = React.useState(bankEntity?.id);
  const [valueBankName, setValueBankName] = React.useState('');
  const [valueBankDesc, setValueBankDesc] = React.useState('');
  const [valueImageURL, setValueImageURL] = React.useState('');
  const [currencyName, setCurrencyName] = useState('');
  const [countryName, setCountryName] = React.useState('');
  const [countryNameArabic, setCountryNameArabic] = React.useState('');
  const [approvalPeriodTime, setApprovalPeriodTime] = React.useState(5);
  const filterCurrency = currencyRateList.filter((c) => c.buyFrom.name === currencyName)[0];
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
  const [valueOTP, setValueOTP] = useState(false);
  const [transactionForms, setTransactionForms] = useState(false);
  const [valueDisplayTransactionFee, setValueDisplayTransactionFee] = useState(false);
  const [valueDisplayFeeInTransactionCurrency, setValueDisplayFeeInTransactionCurrency] = useState(false);
  const [errMsgCurrency, setErrMsgCurrency] = useState('');
  const [imgName, setImgName] = React.useState('');
  const [imgInfo, setImgInfo] = React.useState('');
  const [imgFormat, setImgFormat] = React.useState('');
  const [imgType, setImgType] = React.useState('');
  const [isLarge, setIsLarge] = useState(false);
  const [errorMsgImgSize, setErrorMsgImgSize] = useState('');
  const [isFormatIncorrect, setIsFormatIncorrect] = useState(false);
  const [errorMsgImgFormat, setErrorMsgImgFormat] = useState('');
  const [commodityActive, setCommodityActive] = useState([]);
  const [isChecked, setIsChecked] = useState([]);
  const [checkboxState, setCheckboxState] = useState({});
  const [amount, setAmount] = useState(1);
  const [amountLimitDisplayed, setAmountLimitDisplayed] =  useState('');
  const [valueCountryCode, setValueCountryCode] = useState('');
  const temp = {};
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  
  useEffect(() => {
    if (Storage.session.get('locale') === 'ar-ly'
      && commodityEntities !== undefined
      && commodityEntities !== null
      && commodityEntities.length !== 0
    ) {
      const translateDescriptions = async () => {
        const translatedDescriptions2 = {};
        for (const item of commodityEntities) {
          const data = {
            q: item?.description,
            source: 'en',
            target: 'ar'
          };
          if (data.q !== null && data.q !== "" && data.q !== undefined) {
            try {
              const response = await axios.post('https://translate.argosopentech.com/translate', data);
              translatedDescriptions2[item?.id] = response?.data?.translatedText;
            } catch (error) {
              console.error('Translation error:', error);
            }
          }

        }
        setTranslatedDescriptions(translatedDescriptions2);
      };

      translateDescriptions();
    }
  }, [commodityEntities]);
  const toBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const resizeFile = (file) => new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      "PNG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

  const fileUploadRef = React.useRef(null);
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const fileName = e.target.files[0].name;
      const fileSize = e.target.files[0].size;
      const fileFormat = e.target.files[0].type;
      const uploaded = e.target.files[0];
      // setImgName(fileName);
      setImgInfo(e.target.files[0]);
      setImgFormat(e.target.files[0].name.split('.')[1]);
      setImgType(e.target.files[0].type);
      setPreviewPic(URL.createObjectURL(uploaded));

      // checking the image resolution
      const img = new Image();
      img.src = window.URL.createObjectURL(e.target.files[0]);
      img.onload = function () {
        if (img.width > 500 || img.height > 500) {
          resizeFile(uploaded).then(c => setValueImageURL(c as string));
        } else {
          toBase64(uploaded).then(c => setValueImageURL(c as string));
        }
      };

      const fileSizeIsLarge = fileSize > 1048576;
      if (fileSizeIsLarge) {
        setIsLarge(true);
        setErrorMsgImgSize(`${translate('comexApp.bank.home.errorFileToLarge')}`);
      } else {
        setIsLarge(false);
        setErrorMsgImgSize("");
      }

      const formatAllowed = (fileFormat === "image/jpg" || fileFormat === "image/jpeg" || fileFormat === "image/gif" || fileFormat === "image/png");
      if (!formatAllowed) {
        setIsFormatIncorrect(true);
        setErrorMsgImgFormat(`${translate('comexApp.bank.home.errorFileFormatIncorrect')}`);
      } else {
        setIsFormatIncorrect(false);
        setErrorMsgImgFormat("");
      }

      // when the file name is same
      const name = e.target.files[0].name.split('.')[0];
      const format = e.target.files[0].name.split('.')[1];
      let num = 0;
      let curName = `${name}`;
      let fileNameExist = bankList?.filter(b => b.imgURL === curName + `.${format}`).length;
      if (fileNameExist) {
        while(fileNameExist) {
          curName = `${name}(${++num})`;
          fileNameExist = bankList?.filter(b => b.imgURL === curName + `.${format}`).length;
          if (fileNameExist === 0) {
            if (curName.includes(" ")) {
              setImgName(curName.replace(" ", "_") + `.${format}`);
            } else {
              setImgName(curName + `.${format}`);
            }
          }
        }
        return curName;
      } else {
        if (e.target.files[0].name.includes(" ")) {
          setImgName(e.target.files[0].name.replace(" ", "_"));
        } else {
          setImgName(e.target.files[0].name);
        }
      }
    }
  }

  // const strImage = valueImageURL?.replace(/^data:image\/[a-z]+;base64,/, "");
  const imgFromDb = (bankEntity?.imgURL);
  const imgStringToNumber = imgFromDb?.split(',').map(i => Number(i));
  const decodedImgString = String.fromCharCode.apply(null, imgStringToNumber);

  const handleValueName = event => {
    const changeBankName = event.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
    setValueBankName(changeBankName);
  };

  const handleValueDesc = event => {
    const changeBankDesc = event.target.value;
    setValueBankDesc(changeBankDesc);
  };

  const [switchStateOTP, setSwitchStateOTP] = React.useState({
    required: true,
    notRequired: false,
  });
  const handleOTP = (event) => {
    const { checked } = event.target;
    const otpEnabled = bankEntity?.otp;
    setSwitchStateOTP({
      required: checked,
      notRequired: checked,
    });
    setValueOTP(checked);
  };

  const [switchStateTransactionForms, setSwitchStateTransactionForms] = React.useState({
    required: true,
    notRequired: false,
  });

  const handleTransactionForms = (event) => {
    const { checked } = event.target;
    setSwitchStateTransactionForms({
      required: checked,
      notRequired: checked,
    })
    setTransactionForms(checked);
  };

  const [switchStateDisplayTransactionFee, setSwitchStateDisplayTransactionFee] = React.useState({
    required: true,
    notRequired: false,
  });

  const [switchStateDisplayFeeInTransactionCurrency, setSwitchStateDisplayFeeInTransactionCurrency] = React.useState({
    required: true,
    notRequired: false,
  });

  const handleDisplayTransactionFee = (event) => {
    const { checked } = event.target;
    setSwitchStateDisplayTransactionFee({
      required: checked,
      notRequired: checked,
    })
    setValueDisplayTransactionFee(checked);
  };

  const handleDisplayFeeInTransactionCurrency = (event) => {
    const { checked } = event.target;
    setSwitchStateDisplayFeeInTransactionCurrency({
      required: checked,
      notRequired: checked,
    })
    setValueDisplayFeeInTransactionCurrency(checked);
  };

  const handleImageURL = event => {
    const changeImageURL = event.target.value;
    setValueImageURL(changeImageURL);
  };
  const defaultValues = () =>
    isNew
      ? {}
      : {
        ...bankEntity,
        currencyId: currencyIndexList.find(currency => currency.name === currencyName)?.id,
      };


  function searchCommodityArr(key, array) {
    for (let i = 0; i < array?.length; i++) {
      if (array[i]?.id === key) {
        return array[i];
      }
    }
  }

  const handleCheckBox = item => (event) => {
    const isCheckedNow = event.target.checked;
    let newIsChecked = [...isChecked];
    let newCommodityActive = [...commodityActive];

    if (isCheckedNow) {
      newIsChecked = [...newIsChecked, { id: item.id }];
      newCommodityActive = [...newCommodityActive, item];
    } else {
      newIsChecked = newIsChecked.filter(c => c.id !== item.id);
      newCommodityActive = newCommodityActive.filter(c => c.id !== item.id);
    }

    setIsChecked(newIsChecked);
    setCommodityActive(newCommodityActive);
    setCommodityValidationMsg(newIsChecked.length > 0 ? '' : translate('comexApp.bank.errorSelectOneCommodity'));
  };

  const handlerCurrency = event => {
    const selectedCurrency = event.target.value;
    setCurrencyName(selectedCurrency);
  };
  let responsiveFont = createTheme();
  responsiveFont = responsiveFontSizes(responsiveFont);
  const handleCountry = event => {
    const selectedCountry = event.target.value;
    if (Storage.session.get('locale') === 'en') {
      setCountryName(selectedCountry);
      const countryIndex = countryList.find(c => c.Desc_en === selectedCountry);
      setCountryNameArabic(countryIndex['Desc_ar']);
    }
    else {
      setCountryNameArabic(selectedCountry);
      const countryIndex = countryList.find(c => c.Desc_ar === selectedCountry);
      setCountryName(countryIndex['Desc_en']);
    }
  }

  const handleApprovalPeriod = event => {
    const timeSelected = event.target.value;
    setApprovalPeriodTime(timeSelected);
  }
  const handleBack = () => {
    props.history.goBack();
  }
  const validateCurrency = () => {
    setIsSaveButtonClicked(true);

    if (filterCurrency?.buyFrom?.active === false) {
      setErrMsgCurrency(translate('comexApp.quotation.currencyIsInactive'));
    } else {
      setErrMsgCurrency('');
    }
  }

  function toEnglishDigits(str) {
    let e = '۰'.charCodeAt(0);

    // convert arabic indic digits [٠١٢٣٤٥٦٧٨٩]
    e = '٠'.charCodeAt(0);
    str = str.replace(/[٠-٩]/g, function (t) {
      return t.charCodeAt(0) - e;
    });
    return str;
  }

  const handlerAmountLimit = event => {
    const amountValue = event.target.value.replace(/[^0-9.]/g, '');
    setAmount(toEnglishDigits(amountValue));
    const selectedAmount = toEnglishDigits(amountValue).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setAmountLimitDisplayed(selectedAmount);
  };

  // Remove characters (if any) from Amount Input when the user clicks or focusOut of the amount input
  const handleDigits = event => {
    const amountValue = event.target.value.replace(/[^0-9.]/g, "");
    setAmount(amountValue);
    const formattedAmount = amountValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setAmountLimitDisplayed(formattedAmount);
  }

  const valueAmountLimit = parseFloat(amountLimitDisplayed.replace(/,/g, ''));

  const findCountryCode = countryList?.find(country => country?.Desc_en === countryName)?.code?.toLowerCase();
  const findCountryHasNoCode = countryHasNoCode?.find(n => n === findCountryCode);
  const isCountryHasCode = countryName !== "" && findCountryHasNoCode === undefined;

  function countryCode(value) {
    setTimeout(() => setValueCountryCode(value), 0);
    return value;
  }

    useEffect(() => {
        const fetchImageUrl = async () => {
            try {
                const response = await axios.get('/api/getFileURL', {
                    params: {                        
                        folderPath: 'bankLogo',
                        fileName: bankEntity.imgURL,
                    }
                });

                const imgURL = response['data']?.imgURL;

                // Check if the image URL is valid
                if (imgURL) {
                    // Optionally re-fetch the image to ensure it's ready
                    // For example, a dummy request to check if the image exists
                    const imageCheck = await fetch(imgURL, { method: 'HEAD' });
                    if (imageCheck.ok) {
                      setImageUrl(imgURL);
                    }
                }

                // setImageUrl(response.data);
                // console.log('Image URL:', response.data); // Log or use the URL as needed
            } catch (error) {
                console.error('Error fetching image URL:', error);
            }
        };

        if (bankEntity?.imgURL) {
            fetchImageUrl();
        }
    }, [bankEntity]);

  const saveEntity = async (values) => {
    setPreventMultipleClick(true);
    if (isChecked.length === 0) {
      setCommodityValidationMsg(translate('comexApp.bank.errorSelectOneCommodity'));
      setPreventMultipleClick(false);
      return;
    } else {
      setCommodityValidationMsg('');
    }
    if (valueAgreementDate === null || valueAgreementDate === "Invalid date") {
      setAgreementDateValidationMsg(translate('entity.validation.required'));
      setPreventMultipleClick(false);
      return;
    } else {
      setAgreementDateValidationMsg("");
    }
    const amountLimitCheck = valueAmountLimit === 0;    
    if (!amountLimitCheck) {
      if (isNew) {
        const entity = {
          ...bankEntity,
          ...values,
          currencyId: currencyIndexList.find(currency => currency.name === currencyName)?.id,
          imgURL: imgInfo !== '' ? imgName : null,
          reportForBankStaff: false,
          reportForBranchManager: false,
          reportForAreaManager: false,
          reportForBankManager: false,
          reportForAuditor: false,
          approvalPeriod: approvalPeriodTime,
          country: countryName,
          name: valueBankName,
          otp: valueOTP,
          transaction_forms: transactionForms,
          agreementDate: valueAgreementDate,
          displayTransactionFee : valueDisplayTransactionFee,
          displayFeeInTransactionCurrency : valueDisplayFeeInTransactionCurrency,
          transactionAmountLimit: valueAmountLimit,
          countryCode: isCountryHasCode === true ? valueCountryCode : "",
          commodities: isChecked,
          feeCurrency: selectedFeeCurrency
        };
        await dispatch(createEntity(entity));
      } else {
        if (previewPic !== null) {
          // remove existing image URL
          if (bankEntity?.imgURL !== null) {
              const deleteImage = async () => {
                  try {
                      const response = await axios.delete('/api/delete', {
                        params: {
                              key: "bankLogo/" + bankEntity.imgURL
                          }
                      });
                      console.warn('Object deleted successfully:', response.data);
                  } catch (error) {
                      console.error('Error deleting object:', error);
                  }
              };      
              try {
                  await deleteImage();
              } catch (err) {
                  console.error(err);
              }
          }    
          const entity = {
            ...bankEntity,
            ...values,
            currencyId: currencyIndexList.find(currency => currency.name === currencyName)?.id,
            imgURL: imgName,
            country: countryName,
            name: valueBankName,
            approvalPeriod: approvalPeriodTime,
            otp: valueOTP,
            transaction_forms: transactionForms,
            displayTransactionFee : valueDisplayTransactionFee,
            displayFeeInTransactionCurrency : valueDisplayFeeInTransactionCurrency,
            agreementDate: valueAgreementDate,
            transactionAmountLimit: valueAmountLimit,
            countryCode: isCountryHasCode === true ? valueCountryCode : "",
            commodities: isChecked,
            feeCurrency: selectedFeeCurrency
          };
          if (filterCurrency?.buyFrom?.active === true) {
            await dispatch(updateEntity(entity));
          }
        } else {
          const entity = {
            ...bankEntity,
            ...values,
            currencyId: currencyIndexList.find(currency => currency.name === currencyName)?.id,
            country: countryName,
            name: valueBankName,
            approvalPeriod: approvalPeriodTime,
            otp: valueOTP,
            transaction_forms: transactionForms,
            displayTransactionFee : valueDisplayTransactionFee,
            displayFeeInTransactionCurrency : valueDisplayFeeInTransactionCurrency,
            agreementDate: valueAgreementDate,
            transactionAmountLimit: valueAmountLimit,
            countryCode: isCountryHasCode === true ? valueCountryCode : "",
            commodities: isChecked,
            feeCurrency: selectedFeeCurrency
          };
          if (filterCurrency?.buyFrom?.active === true) {
            await dispatch(updateEntity(entity));
          }
        }
      }

      if (previewPic !== null) {
        const handleUpload = async () => {
          const formData = new FormData();
          formData.append('file', imgInfo);
          try {
              const response = await axios.post(`/api/upload`, formData, {
                  headers: {
                      'Content-Type': 'multipart/form-data',
                  },
                  params: {
                    'uploadPath': 'bankLogo',
                    'newFileName': imgName,
                  }
              });
              console.warn('File uploaded successfully:', response.data);
          } catch (error) {
              console.error('Error uploading file:', error);
          }
        };      
        handleUpload();
      }
    }
    if (amountLimitCheck === true) {
      setPreventMultipleClick(false);
    }
  };

  const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-input': {
      textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
    },
  });

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <ThemeProvider theme={responsiveFont}>
            <Typography variant="h4" component="div" className='mb-4'>
              {translate('comexApp.bank.home.createOrEditLabel')}
            </Typography>
          </ThemeProvider>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={bankEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="bank-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                  hidden
                />
              ) : null}
              <ValidatedField
                label={translate('comexApp.bank.imgURL')}
                id="bank-imgURL"
                name="imgURL"
                data-cy="imgURL"
                value={valueImageURL || ''}
                //                 onChange={handleImageURL}
                readOnly
                style={{ marginBottom: -20 }}
                //                 validate={{
                //                     required: { value: true, message: translate('entity.validation.required') },}}
                onClick={(event) => fileUploadRef.current.click()}
                hidden
              />
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: Storage.session.get('locale') === 'en' ? 'right' : 'left' }}
                  onClick={(event) => fileUploadRef.current.click()}
                  style={{ cursor: 'pointer', alignContent: 'center' }}
                  badgeContent={
                    <div className= 'circle-edit'>
                      <EditIcon sx={{ width: 24, height: 'auto', color: '#ffffff', margin: '12px' }} />
                    </div>
                  }
                >
                <Avatar
                  // src={previewPic === null ? imageFromS3 : previewPic}
                  src={(previewPic === null && imageFromS3 !== null) ? imageFromS3 : previewPic}
                  style={{ cursor: 'pointer', alignContent: 'center', border: '1px solid #D9D9D9' }}
                  sx={{ width: 168, height: 168 }}
                  // onClick={(event) => fileUploadRef.current.click()}
                >
                  <AddAPhoto sx={{ width: 56, height: 56 }} />
                </Avatar>
                </Badge>
              </div>
              <Input
                innerRef={fileUploadRef}
                type="file"
                accept=".jpg,.jpeg,.gif,.png"
                data-max-size="1048576"
                onChange={handleImageChange}
                style={{ display: 'none' }}
              />
              <p hidden={isFormatIncorrect === true} className="mt-1 mb-0" style={{ color: "red", fontWeight: 400 }}>{errorMsgImgSize}</p>
              <p className="mt-1 mb-0" style={{ color: "red", fontWeight: 400 }}>{errorMsgImgFormat}</p>
              &nbsp;
              <ValidatedField
                label={translate('comexApp.bank.name')}
                id="bank-name"
                name="name"
                data-cy="name"
                type="text"
                value={valueBankName}
                onChange={handleValueName}
                required
                style={{ marginBottom: "0rem" }}
                validate={{
                  required: { value: valueBankName === "", message: translate('entity.validation.required') },
                  validate: v =>
                    bankList.find(bank => bank?.name?.toLowerCase() === v.toLowerCase()) === undefined ||
                    bankEntity?.id !== undefined ||
                    translate('entity.validation.bankNameAlreadyExists'),
                }}
              >
              </ValidatedField>
              <div style={{ marginTop: "-15px", marginBottom: "16px" }} >
                <small style={{ fontStyle: "italic", paddingBottom: "30px" }}>{translate('entity.validation.bankNameValidate')}</small>
              </div>

              {!isNew ? (
                <ValidatedField
                  label={translate("comexApp.bank.details.startDate")}
                  name='createdDate'
                  type='text'
                  readOnly
                  value={formatDate(bankEntity?.createdDate)}
                >
                </ValidatedField>
              ) : null}

              <div>
                <Label>{translate("comexApp.bank.details.agreementDate")}</Label>
                <Box className="box-datePicker">
                  <LocalizationProvider locale={Storage.session.get('locale') === 'en' ? enUS : ar} dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      toolbarTitle={translate('entity.action.selectDate')}
                      okText={translate('entity.action.ok')}
                      cancelText={translate('entity.action.cancel')}
                      mask='____/__/__'
                      inputFormat="yyyy/MM/dd"
                      views={['year', 'month', 'day']}
                      value={valueAgreementDate}
                      onChange={newValue => {
                        setValueAgreementDate(moment(newValue).format('MM-DD-yyyy').toLocaleString());
                        setAgreementDateValidationMsg("");
                      }}
                      open={openCalendar}
                      onOpen={() => setOpenCalendar(true)}
                      onClose={() => setOpenCalendar(false)}
                      renderInput={(params) => {
                        return (
                          <StyledTextField
                            size="small"
                            fullWidth
                            {...params}
                            onClick={(e) => setOpenCalendar(true)}
                            InputProps={{
                              placeholder: "yyyy/mm/dd",
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton edge="end" onClick={(e) => setOpenCalendar(true)}>
                                    <EventIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        );
                      }}
                    />
                  </LocalizationProvider>
                </Box>
                {agreementDateValidationMsg && (
                  <Label className="p-0" style={{ fontSize: 14, color: 'red', fontWeight: 400 }}>
                    {agreementDateValidationMsg}
                  </Label>
                )}
              </div>
              <br/>

              <ValidatedField
                label={translate('comexApp.bank.description')}
                id="bank-description"
                name="description"
                data-cy="description"
                type="text"
                value={valueBankDesc}
                onChange={handleValueDesc}
                required
                validate={{
                  required: { value: valueBankDesc === "", message: translate('entity.validation.required') },
                }}
              />
              <Grid container spacing={2}>
                <Grid item md={6} sm={6} xs={5}>
                  <ValidatedField
                    id="currency_name_option"
                    name="currency_name"
                    data-cy="currency_name"
                    label={translate('comexApp.bank.defaultCurrency')}
                    type="select"
                    onChange={handlerCurrency}
                    value={currencyName}
                    required
                    validate={{
                      required: { value: currencyName === '', message: translate('entity.validation.required') },
                    }}
                  >
                    <option key="0" />
                    {currencyRateList
                      ? currencyRateList
                        .filter(item => item.buyFrom.active)
                        .map(item => (
                          <option value={item.buyFrom.name} key={item.id}>
                            {item.buyFrom.name}
                          </option>
                        ))
                      : null}
                  </ValidatedField>
                  {isSaveButtonClicked === true && filterCurrency?.buyFrom?.active === false &&
                    <Label className="p-0" style={{ fontSize: 14, color: "red", fontWeight: 400 }}>
                      {errMsgCurrency}
                    </Label>
                  }
                </Grid>
                <Grid item md={6} sm={6} xs={7}>
                  <ValidatedField
                    label={translate('comexApp.bank.transactionLimit')}
                    id="transaction-limit"
                    name="transaction-limit"
                    data-cy="transaction-limit"
                    type="text"
                    onChange={handlerAmountLimit}
                    onBlur={handleDigits}
                    value={amountLimitDisplayed}
                    required
                    style={{
                      textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
                    }}
                    />
                  {valueAmountLimit === 0 && (
                    <Label className="p-0" style={{ fontSize: 14, color: 'red', fontWeight: 400 }}>
                      {translate('entity.validation.required')}
                    </Label>
                  )}
                </Grid>
                </Grid>
                 
                    <ValidatedField
                    id="fee-currency"
                    name="fee-currency"
                    data-cy="fee-currency"
                    label={translate('comexApp.bank.feeCurrency')}
                    type="select"
                    value={selectedFeeCurrency}
                    onChange={(e) => setSelectedFeeCurrency(e.target.value)}
                    validate={{
                      required: { value: true, message:translate('entity.validation.required')},
                    }} 
                    >
                      <option value={'Transaction Currency'} key={'Transaction Currency'}
                      defaultValue={'Transaction Currency'}>{translate('comexApp.bank.transactionCurrency')}</option>
                      <option value={'Bank Default Currency'} key={'Bank Default Currency'}>{translate('comexApp.bank.bankDefaultCurrency')}</option>
                    </ValidatedField>

              <Grid container spacing={2}>
                <Grid item md={10} sm={8} xs={7}>
                  <ValidatedField
                    id="country"
                    name="country"
                    data-cy="country"
                    label={translate('comexApp.bank.country')}
                    type="select"
                    onChange={handleCountry}
                    value={Storage.session.get('locale') === 'en' ? countryName : countryList.find(c => c.Desc_en === countryName)['Desc_ar']}
                    required
                    validate={{
                      required: { value: countryName === '', message: translate('entity.validation.required') },
                    }}
                  >
                    {Storage.session.get('locale') === 'en' ? (countryList
                      ? Object.keys(countryList).map((key, index) => (
                        <option key={index} value={countryList[key]['Desc_en']}>
                          {countryList[key]['Desc_en']}
                        </option>
                      ))
                      : null
                    )
                      : (countryList
                        ? Object.keys(countryList).map((key, index) => (
                          <option key={index} value={countryList[key]['Desc_ar']}>
                            {countryList[key]['Desc_ar']}
                          </option>
                        ))
                        : null
                      )
                    }
                  </ValidatedField>
                </Grid>
                <Grid item md={2} sm={4} xs={5}>
                  <Label>{translate('comexApp.bank.countryCode')}</Label>
                  {isCountryHasCode === true ? (
                    <PhoneInput
                      country={`${findCountryCode}`}
                      placeholder=""
                      disabled
                      disableDropdown
                      isValid={(value) => {
                        if (countryName !== '') {
                          countryCode(value);
                        }
                        return true;
                      }}
                      inputStyle={{
                        paddingTop: '18px',
                        paddingBottom: '18px',
                        textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
                        direction: 'ltr',
                      }}
                    />
                  ):(
                    <div>
                      <PhoneInput
                        country=""
                        placeholder=""
                        disabled
                        disableDropdown
                        inputStyle={{
                          paddingTop: '18px',
                          paddingBottom: '18px',
                          textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
                          direction: 'ltr',
                        }}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
              <label htmlFor="approvedCommodity" style={{ margin: '0px 0px 16px' }}>{translate('comexApp.bank.approvedCommodity')}</label>
              <Row className="justify-content-center">
                <Col xs={11} sm={7} md={7} className="py-2 d-flex justify-content-between commodityTable">
                  <table className="align-self-center table-margin-space table-padding-space scrollable-table" style={{ padding: '0px', width: '100%' }}>
                    <thead>
                    </thead>
                    <tbody>

                      {commodityEntities
                        // .filter(item => item.buyFrom.active)
                        .map(item => (
                          <React.Fragment key={item?.name}>
                            <tr style={{ padding: '0px' }}>
                              <td>
                                <Checkbox
                                  className="mx-1"
                                  checked={searchCommodityArr(item.id, isChecked) ? true : false}
                                  onChange={handleCheckBox(item)}
                                  size="medium"
                                />
                              </td>
                              <td />
                              <td style={{ paddingTop: '0px' }}>
                                <p style={{ marginTop: '10px' }}>{item?.name}</p>
                                <p style={{ marginTop: '0px', fontSize: '12px' }}> {Storage.session.get('locale') === 'ar-ly'
                                  ? translatedDescriptions[item.id]
                                  : item?.description}
                                </p>
                              </td>

                            </tr>

                          </React.Fragment>
                        ))}
                    </tbody>
                  </table>
                </Col>
              </Row>
              <br />
              {commodityValidationMsg && (
                <Row className="justify-content-center">
                  <Col xs={11} sm={7} md={7} className="text-center">
                    <div style={{ color: 'red', marginBottom: '10px' }}>
                      {commodityValidationMsg}
                    </div>
                  </Col>
                </Row>
              )}
              <br />
              <br></br>
              <Row>
                <Col xs="6" style={{ width: 'fit-content' }}>
                  <label htmlFor="approvalPeriod">{translate('comexApp.bank.approvalPeriod')}</label>
                </Col>
                <Col xs="6" style={{ width: "fit-content" }}>
                  <div className="d-inline-flex">
                    <ValidatedField
                      id="approvalPeriod"
                      name="approvalPeriod"
                      data-cy="approvalPeriod"
                      type="number"
                      // defaultValue={5}
                      onChange={handleApprovalPeriod}
                      value={approvalPeriodTime}
                      required
                      validate={{
                        required: { value: true, message: translate('entity.validation.required') },
                        pattern: {
                          value: /^\d+$/,
                          message: translate('entity.validation.number')
                        },
                      }}
                    />
                    <span className="ms-2">
                      {translate('comexApp.bank.minutes')}
                      <Tooltip title={translate('comexApp.bank.approvalPeriodToolTip')}>
                        <InfoOutlinedIcon style={{ fontSize: '0.875rem', verticalAlign: 'left', marginLeft: '0.2rem' }} />
                      </Tooltip>
                    </span>
                  </div>
                </Col>
              </Row>
              <Grid container spacing={2}>
                <Grid item md={2} sm={6} xs={12}>
                  <div className="otp-login mt-4 mt-sm-0 py-2 px-4" style={{
                    display: 'inline-flex', flexDirection: 'row',
                    justifyContent: 'space-between', backgroundColor: `${valueOTP ? '#0f3e58' : '#959595'}`
                  }}>
                    <Typography variant="body1" className="my-2" fontWeight="bold" color="white" style={{ whiteSpace: 'nowrap', fontSize: '0.795rem' }}>
                      <Translate contentKey="comexApp.bank.otp" />
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      {bankEntity?.otp ? (
                        <Tooltip title="">
                          <Switch
                            aria-label="required"
                            color="info"
                            edge="end"
                            checked={bankEntity?.otp ? switchStateOTP.required : switchStateOTP.notRequired}
                            onChange={handleOTP}
                            name="otp"
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="">
                          <Switch
                            aria-label="notRequired"
                            color="info"
                            edge="end"
                            checked={bankEntity?.otp ? switchStateOTP.required : switchStateOTP.notRequired}
                            onChange={handleOTP}
                            name="otp"
                          />
                        </Tooltip>
                      )}
                    </Box>
                  </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="otp-login mt-4 mt-sm-0 py-2 px-4" style={{
                    display: 'inline-flex', flexDirection: 'row',
                    justifyContent: 'space-between', backgroundColor: `${transactionForms ? '#5BA7BF' : '#959595'}`
                  }}>
                    <Typography variant="body1" className="my-2" fontWeight="bold" color="white" style={{ whiteSpace: 'nowrap', fontSize: '0.795rem' }}>
                      <Translate contentKey="comexApp.bank.transactionForm" />
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      {bankEntity?.transaction_forms ? (
                        <Tooltip title="">
                          <Switch
                            aria-label="required"
                            color="info"
                            edge="end"
                            checked={bankEntity?.transaction_forms ? switchStateTransactionForms.required : switchStateTransactionForms.notRequired}
                            onChange={handleTransactionForms}
                            name="transaction_forms"
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="">
                          <Switch
                            aria-label="notRequired"
                            color="info"
                            edge="end"
                            checked={bankEntity?.transaction_forms ? switchStateTransactionForms.required : switchStateTransactionForms.notRequired}
                            onChange={handleTransactionForms}
                            name="transaction_forms"
                          />
                        </Tooltip>
                      )}
                    </Box>
                  </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="otp-login mt-4 mt-sm-0 py-2 px-4" style={{
                    display: 'inline-flex', flexDirection: 'row',
                    justifyContent: 'space-between', backgroundColor: `${valueDisplayTransactionFee ? '#5BA7BF' : '#959595'}`
                  }}>
                    <Typography variant="body1" className="my-2" fontWeight="bold" color="white" style={{ whiteSpace: 'nowrap', fontSize: '0.795rem' }}>
                      <Translate contentKey="comexApp.bank.displayTransactionFee" />
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      {bankEntity?.displayTransactionFee ? (
                        <Tooltip title="">
                          <Switch
                            aria-label="required"
                            color="info"
                            edge="end"
                            checked={bankEntity?.displayTransactionFee ? switchStateDisplayTransactionFee.required : switchStateDisplayTransactionFee.notRequired}
                            onChange={handleDisplayTransactionFee}
                            name="display-transaction-fee"
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="">
                          <Switch
                            aria-label="notRequired"
                            color="info"
                            edge="end"
                            checked={bankEntity?.displayTransactionFee ? switchStateDisplayTransactionFee.required : switchStateDisplayTransactionFee.notRequired}
                            onChange={handleDisplayTransactionFee}
                            name="display-transaction-fee"
                          />
                        </Tooltip>
                      )}
                    </Box>
                  </div>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <div className="otp-login mt-4 mt-sm-0 py-2 px-4" style={{
                      display: 'inline-flex', flexDirection: 'row',
                      justifyContent: 'space-between', backgroundColor: `${valueDisplayFeeInTransactionCurrency ? '#5BA7BF' : '#959595'}`
                    }}>
                      <Typography variant="body1" className="my-2" fontWeight="bold" color="white" style={{ whiteSpace: 'nowrap', fontSize: '0.795rem' }}>
                        <Translate contentKey="comexApp.bank.displayFeeInTransactionCurrency" />
                      </Typography>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {bankEntity?.displayFeeInTransactionCurrency ? (
                          <Tooltip title="">
                            <Switch
                              aria-label="required"
                              color="info"
                              edge="end"
                              checked={bankEntity?.displayFeeInTransactionCurrency ? switchStateDisplayFeeInTransactionCurrency.required : switchStateDisplayFeeInTransactionCurrency.notRequired}
                              onChange={handleDisplayFeeInTransactionCurrency}
                              name="display-fee-in-transaction-currency"
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip title="">
                            <Switch
                              aria-label="notRequired"
                              color="info"
                              edge="end"
                              checked={bankEntity?.displayFeeInTransactionCurrency ? switchStateDisplayFeeInTransactionCurrency.required : switchStateDisplayFeeInTransactionCurrency.notRequired}
                              onChange={handleDisplayFeeInTransactionCurrency}
                              name="display-fee-in-transaction-currency"
                            />
                          </Tooltip>
                        )}
                      </Box>
                    </div>
                  </Grid>
              </Grid>
              <div style={{ marginTop: 40 }}>
                <Button
                  onClick={handleBack}
                >
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <Translate contentKey="entity.action.back">Back</Translate>
                </Button>
                &nbsp;
                <Button
                  style={{ fontFamily: 'Roboto, sans-serif' }}
                  color="primary" id="save-entity"
                  data-cy="entityCreateSaveButton"
                  type="submit"
                  disabled={updating || isLarge === true || isFormatIncorrect === true || commodityValidationMsg !== '' ||
                    agreementDateValidationMsg !== ""}
                  onClick={validateCurrency}
                >
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default BankUpdate;
