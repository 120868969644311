import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './commodity-log.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const CommodityLogDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const commodityLogEntity = useAppSelector(state => state.commodityLog.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="commodityLogDetailsHeading">
          <Translate contentKey="comexApp.commodityLog.detail.title">CommodityLog</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{commodityLogEntity.id}</dd>
          <dt>
            <span id="commodityPrice">
              <Translate contentKey="comexApp.commodityLog.commodityPrice">Commodity Price</Translate>
            </span>
          </dt>
          <dd>{commodityLogEntity.commodityPrice?.toLocaleString()}</dd>
          <dt>
            <Translate contentKey="comexApp.commodityLog.commodity">Commodity</Translate>
          </dt>
          <dd>{commodityLogEntity.commodity ? commodityLogEntity.commodity.name : ''}</dd>
        </dl>
        <Button tag={Link} to="/commodity-log" replace data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default CommodityLogDetail;
