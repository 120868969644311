import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ICustomer, ICustomerQueryParam, defaultValue } from 'app/shared/model/customer.model';
import { verifyOTP } from 'app/modules/administration/user-management/user-management.reducer';

const initialState: EntityState<ICustomer> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

const apiUrl = 'api/customers';

// Actions

// Add the resetPassword action
interface ResetPasswordPayload {
  customerIc: string;
  bankId: number | null;
}

export const resetPassword = createAsyncThunk(
  'customer/resetPassword',
  async ({ customerIc, bankId }: ResetPasswordPayload, thunkAPI) => {
    try {
      const response = await axios.post(`${apiUrl}/reset-password`, null, {
        params: { customerIc, bankId },
      });
      return { data: response.data, status: response.status };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        throw error;
      }
    }
  }
);


export const getEntities = createAsyncThunk('customer/fetch_entity_list', async ({ page, size, sort, search }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&search=${search}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<ICustomer[]>(requestUrl);
});


export const getEntitiesWithFilter = createAsyncThunk('customer/fetch_entity_list_with_filter', async ({ page, size, sort, bankId, search}: IQueryParams) => {
  const requestUrl = `${apiUrl}/filter${sort ? `?page=${page}&size=${size}&sort=${sort}&bank=${bankId}&search=${search}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<ICustomer[]>(requestUrl);
});

export const getAllEntities = createAsyncThunk('customer/fetch_entity_all_list', async (entity: IQueryParams) => {
  const requestUrl = `${apiUrl}/all-lists`;
  return axios.get<ICustomer[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'customer/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ICustomer>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntityByIc = createAsyncThunk(
  'customer/fetch_entity-by-ic',
  async (ic: string) => {
    const requestUrl = `${apiUrl}/findByIc?ic=${ic}`;
    return axios.get<ICustomer>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntityByIcAndBankId = createAsyncThunk(
  'customer/fetch_entity-by-ic-and-bankid',
  async ({ ic, bId }: ICustomerQueryParam) => {
    const requestUrl = `${apiUrl}/findByIcAndBankId?ic=${ic}&bankId=${bId}`;
    return axios.get<ICustomer>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getUniqueCustomerListByBankIdinQuotation = createAsyncThunk(
  'customer/fetch_unique_entities_customer_list_by_bank_id_in_quotation',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/getUniqueCustomerListByBankIdinQuotation/${id}`;
    return axios.get<ICustomer[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getUniqueCustomerListByBranchIdinQuotation = createAsyncThunk(
  'customer/fetch_unique_entities_customer_list_by_branch_id_in_quotation',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/getUniqueCustomerListByBranchIdinQuotation/${id}`;
    return axios.get<ICustomer[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'customer/create_entity',
  async (entity: ICustomer, thunkAPI) => {
    const result = await axios.post<ICustomer>(apiUrl, cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'customer/update_entity',
  async (entity: ICustomer, thunkAPI) => {
    const result = await axios.put<ICustomer>(`${apiUrl}/${entity.id}`, cleanEntity(entity));

    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'customer/partial_update_entity',
  async (entity: ICustomer, thunkAPI) => {
    const result = await axios.patch<ICustomer>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'customer/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<ICustomer>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const verifyExistingCustomerPhoneNumber = createAsyncThunk(
  'customer/verify_existing_customer_phone_number',
  async (
    { phoneNumber, customerIc }: { phoneNumber: string; customerIc?: string },
    { rejectWithValue }
  ) => {
    try {
      const customerIcString = customerIc ? String(customerIc).trim() : '';
      let requestUrl = `${apiUrl}/verifyExistingCustomerPhoneNumber/${phoneNumber}`;
      if (customerIcString !== '') {
        requestUrl += `?customerIc=${customerIcString}`;
      }
      const response = await axios.get<boolean>(requestUrl);
      return response.data;
    } catch (error) {
      console.error('Error verifying phone number:', error);
      return rejectWithValue(error.response?.data || 'Error checking phone number');
    }
  }
);


// slice

export const CustomerSlice = createEntitySlice({
  name: 'customer',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntityByIc.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntityByIcAndBankId.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase((verifyExistingCustomerPhoneNumber.fulfilled), (state, action) => {
        state.loading = false;
        state.updateSuccess=true;
        state.updating=false;
      })
      .addCase(verifyExistingCustomerPhoneNumber.rejected, (state, action) => {
        state.loading = false;
        state.updateSuccess = false;
        state.updating = false;
        state.errorMessage = action.error.message;
      })
      .addMatcher(isFulfilled(getEntities, getAllEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getEntitiesWithFilter, getAllEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })

      .addMatcher(isFulfilled(getUniqueCustomerListByBankIdinQuotation), (state, action) => {
        state.entities = action.payload.data;
      })
      .addMatcher(isFulfilled(getUniqueCustomerListByBranchIdinQuotation), (state, action) => {
        state.entities = action.payload.data;
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(
        isPending(
          getEntities,
          getEntitiesWithFilter,
          getAllEntities,
          getUniqueCustomerListByBankIdinQuotation,
          getUniqueCustomerListByBranchIdinQuotation,
          getEntity,
          getEntityByIc
        ),
        state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.loading = true;
        }
      )
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, verifyExistingCustomerPhoneNumber), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isRejected(
        createEntity,
        updateEntity,
        partialUpdateEntity,
        deleteEntity,
        verifyExistingCustomerPhoneNumber
      ), (state, action) => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      });
  },
});

export const { reset } = CustomerSlice.actions;

// Reducer
export default CustomerSlice.reducer;
