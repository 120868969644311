import React from 'react';
import { DropdownItem } from 'reactstrap';
import { NavDropdown } from './menu-components';
import { locales, languages } from 'app/config/translation';
import TranslateIcon from '@mui/icons-material/Translate';
import { Translate } from 'react-jhipster';
import '../header/header.scss';

export const LocaleMenu = ({ currentLocale, onClick }: { currentLocale: string; onClick: (event: any) => void } ) =>

  Object.keys(languages).length > 1 ? (
    <NavDropdown icon="language" name={
      <div className="locale-icon">
        <TranslateIcon sx={{ fontSize: {xs: 20, md: 22}, width: 20, height: 20, marginBottom: 0.3 , paddingBottom: 0.2}}/>
        <Translate contentKey="global.menu.language" />
      </div>
    }>
      {locales.map(locale => (
        <DropdownItem key={locale} value={locale} onClick={onClick}>
          {languages[locale].name}
        </DropdownItem>
      ))}
    </NavDropdown>
  ) : null;
