import './home.scss';
import React, {useEffect, useState, useRef, useMemo} from 'react';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Storage, Translate, translate,getSortState } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Button } from 'reactstrap';
import { styled, createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonMui from '@mui/material/Button';
import Links from '@mui/material/Link';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from "@mui/material/CardActionArea";
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { enUS, ar } from 'date-fns/locale';
import IconButton from '@mui/material/IconButton';
import EventIcon from '@mui/icons-material/Event';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import UpdateIcon from '@mui/icons-material/Update';
import InfoIcon from '@mui/icons-material/Info';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { visuallyHidden } from '@mui/utils';
import { getEntity as getComexSetting } from 'app/entities/comex-settings/comex-settings.reducer';

import {
  getEntitiesByBankStaff,
  getEntitiesByBranchId,
  getEntitiesByBankId,
  getEntitiesByAuditor,
  getEntitiesFilter,
  getEntitiesByAreaManagerId,
  getEntitiesByCustomerLogin,
  getQuotationSoldCount,
  getQuotationCreatedCount,
  getQuotationPendingTransferCount,
  getQuotationPurchaseOrderCount,
  getQuotationKeepCount,
  getTotalSoldToday,
  getQuotationPurchaseOrderApprovalCount,
  getQuotationKeepApprovalCount,
  getQuotationCancellationApprovalCount,
  getEachTotalSalesToday,
  getUpdateCancellationDecisionFromBursaTodayForAlshmokhAdmin,
  getKeepOrSellOrdersTodayForCustomer,
} from "app/entities/quotation/quotation.reducer";
import {getAllEntities as getBanks, getEntityByBranchId} from 'app/entities/bank/bank.reducer';
import {
  getBranchListByBankId,
  getAllEntities as getBranchs,
  getEntity as getBranch, getBranchForStaffAndBranchManager
} from 'app/entities/branch/branch.reducer';
import { getCommoditiesPriceList, getAllEntities as getCommodities, getUniqueCommoditiesByBankIdInQuotation, getCommoditiesForCustomer, getUniqueCommoditiesByBranchIdInQuotation } from 'app/entities/commodity/commodity.reducer';
import { getAllEntities as getCustomers, getUniqueCustomerListByBankIdinQuotation, getUniqueCustomerListByBranchIdinQuotation } from 'app/entities/customer/customer.reducer';
import { Row, Col, Alert, Input, Label } from 'reactstrap';
import moment from "moment/moment";
import * as momentTimeZone from "moment-timezone";
import 'moment-timezone';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { AUTHORITIES, defaultUser } from 'app/config/constants';
import { Clear } from "@mui/icons-material";
import {
  getUser, getUserByAlshmokhAdmin, getUsersAsAlmoskhAdminAndStaff
} from '../administration/user-management/user-management.reducer';
import { getEntities as getNotificationTemplates } from 'app/entities/notification-template/notification-template.reducer';
import NewLogin from "app/modules/login/login";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle } from '@mui/material';
import { getEntityForEachUserRole } from 'app/entities/currency-index/currency-index.reducer';

import OtpPage from 'app/modules/account/otp/otp';
import { getErrorEntitiesForAdmins, getErrorEntitiesForAreaManager, getErrorEntitiesForAuditor, getErrorEntitiesForBankManager, getErrorEntitiesForBankStaff, getErrorEntitiesForBranchManager, getErrorEntitiesForCustomer } from 'app/entities/bursa-api-order-result/bursa-api-order-result.reducer';

import { ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import Branch from 'app/entities/branch/branch';

import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import {IUser} from "app/shared/model/user.model";

window.onload = function () {
  sessionStorage.removeItem('filterBank');
  sessionStorage.removeItem('filterBranch');
  sessionStorage.removeItem('filterCustomer');
  sessionStorage.removeItem('filterCommodity');
  sessionStorage.removeItem('filterDate');
  sessionStorage.removeItem('filterDateQuo');
  sessionStorage.removeItem('filterStatus');
  sessionStorage.removeItem('filterSearch');
  sessionStorage.removeItem('filterQuoSearch');
}

export const Home = (props: RouteComponentProps<{ id: string }>) => {

  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);
  const myAccount = (account && Object.keys(account).length > 0) ? account : Storage.session.get("account");
  const quotationEntities = useAppSelector(state => state.quotation.entities);
  const bankList = useAppSelector(state => state.bank.entities);
  const specBranch = useAppSelector(state => state.branch.entity);
  const branchForStaffAndManager = useAppSelector(state => state.branch.branchForStaffAndManager);
  const branchList = useAppSelector(state => state.branch.entities);
  const customerList = useAppSelector(state => state.customer.entities);
  const commodityList = useAppSelector(state => state.commodity.entities);
  const commoditiesPriceList = useAppSelector(state => state.commodity.entities);
  const notificationTemplate = useAppSelector(state => state.notificationTemplate.entities);
  const bursaTimeOutNoti = useAppSelector(state => state.notificationTemplate.entity);
  const bursaAPIOrderResult=useAppSelector(state => state.bursaAPIOrderResult.entity);
  const notifTemplateTransferred = notificationTemplate.filter((temp) => temp.type === "poTransferCompleted" && temp.medium === "APP")[0];
  const user = useAppSelector(state => state.userManagement.user);
  const [value, setValue] = React.useState(sessionStorage.getItem("filterDate") || moment(new Date()).format('L'));
  const [valueQuo, setValueQuo] = React.useState(sessionStorage.getItem("filterDateQuo") || moment(new Date()).format('L'));
  const [queryDate, setQueryDate] = React.useState(value || '');
  const [queryDateQuo, setQueryDateQuo] = React.useState(valueQuo || '');
  const [data, setData] = useState(quotationEntities);
  const [searchOnChange, setSearchOnChange] = React.useState(sessionStorage.getItem("filterSearch") || '');
  const [searchTerm, setSearchTerm] = React.useState(searchOnChange);
  const [searchOnChangeQuo, setSearchOnChangeQuo] = React.useState(sessionStorage.getItem("filterQuoSearch") || '');
  const [searchTermQuo, setSearchTermQuo] = React.useState(searchOnChangeQuo);
  const [render, setRender] = React.useState([]);
  const [bankFilter, setBankFilter] = useState(Number(sessionStorage.getItem("filterBank")) || 0);
  const [branchFilter, setBranchFilter] = useState(Number(sessionStorage.getItem("filterBranch")) || 0);
  const [customerFilter, setCustomerFilter] = useState(Number(sessionStorage.getItem("filterCustomer")) || 0);
  const [commodityFilter, setCommodityFilter] = useState(Number(sessionStorage.getItem("filterCommodity")) || 0);
  const [isDone, setIsDone] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [openCalendarQuo, setOpenCalendarQuo] = useState(false);
  const formatTwoDigits = digit => ('0' + digit).slice(-2);
  const tempDate = new Date();
  const currentDate = `${formatTwoDigits(tempDate.getMonth() + 1)}/${formatTwoDigits(tempDate.getDate())}/${tempDate.getFullYear()}`;
  // const totalItems = useAppSelector(state => state.quotation.totalItems);
  const loggedIn = useState(false);
  const [homeReady, setHomeReady] = React.useState(false);
  const notifTemplateSoldApp = notificationTemplate.filter((temp) => temp.type === "poSold" && temp.medium === "APP")[0];
  const notifTemplateSoldEmail = notificationTemplate.filter((temp) => temp.type === "poSold" && temp.medium === "EMAIL")[0];
  const emptyUser = defaultUser;
  const quotationCreatedCount = useAppSelector(state => state.quotation.quotationCreatedCount);
  const quotationSoldCount = useAppSelector(state => state.quotation.quotationSoldCount);
  const quotationPendingTransferCount = useAppSelector(state => state.quotation.quotationPendingTransferCount);
  const quotationPurchaseOrderCount = useAppSelector(state => state.quotation.quotationPurchaseOrderCount);
  const quotationKeepCount = useAppSelector(state => state.quotation.quotationKeepCount);
  const totalSoldToday = useAppSelector(state => state.quotation.totalSoldToday);
  const quotationPOApprovalToday = useAppSelector(state => state.quotation.quotationPOApprovalToday);
  const quotationcancellationApprovalToday = useAppSelector(state => state.quotation.quotationcancellationApprovalToday);
  const quotationKeepApprovalToday = useAppSelector(state => state.quotation.quotationKeepApprovalToday);
  const eachTotalSales = useAppSelector(state => state.quotation.eachTotalSales);
  const keepOrSellOrdersTodayForCustomer = useAppSelector(state => state.quotation.keepOrSellOrdersTodayForCustomer);
  const updateCancellationDecisionFromBursa = useAppSelector(state => state.quotation.updateCancellationDecisionFromBursa);
  const customerSoldCommodities = useAppSelector(state => state.commodity.customerSoldCommodities);
  const [open, setOpen] = useState(false);
  const [openDialogRepurchase, setOpenDialogRepurchase] = useState(false);
  const [purchaseStatus, setPurchaseStatus] = useState('');
  const [maxWidth, setMaxWidth] = useState<DialogProps['maxWidth']>('sm');
  const comexSettingsEntity = useAppSelector(state => state.comexSettings.entity);
  const [operationTime, setOperationTime] = useState(true);
  const [localStart, setLocalStart] = React.useState<any>(new Date('2018-01-01T19:30:00.000Z'));
  const [localEnd, setLocalEnd] = React.useState<any>(new Date('2018-01-01T17:29:00.000Z'));
  const [infoBtnColor, setInfoBtnColor] = useState('#d32f2f');
  const [isFailed, setIsFailed] = useState(false);
  const bank2 = useAppSelector(state => state.bank.entity);
  const banks = useAppSelector(state => state.bank.entities);
  const branches = useAppSelector(state => state.branch.entities);
  const currencyIndexList = useAppSelector(state => state.currencyIndex.entities);
  const currencyIndex= useAppSelector(state => state.currencyIndex.entity);
  // const defaultCurrency = currencyIndexList?.filter(c => c?.id === branch2?.bank?.currencyId || c?.id === banks[0]?.currencyId || c?.id === branches[0]?.bank?.currencyId );
  const verifyOTPUpdateStatus = useAppSelector(state => state.userManagement.updateSuccess);
  const bursaAPIOrderResultList = useAppSelector(state => state.bursaAPIOrderResult.entities);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);

  const verifyOTPstatus = useAppSelector(state => state.userManagement.verifyOTP);
  const otpStatus = verifyOTPstatus ? true : ((sessionStorage.getItem('otpStatus') !== undefined && sessionStorage.getItem('otpStatus') === "1") ? true : false || (Storage.session.get('otpStatus') !== undefined && Storage.session.get('otpStatus') === true) ? true : false || (Storage.local.get('otpStatus') !== undefined && Storage.local.get('otpStatus') === true) ? true : false);

  const [pagination, setPagination] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE, 'id'), props.location.search)
  );
  const [pageNo, setPage] = React.useState(Number(localStorage.getItem("managePOPage")) || 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filter, setFilter] = useState('');
  const poTableRef = useRef(null);

  window.onbeforeunload = function() {
    localStorage.removeItem("managePOPage");
 }
  const areaManagerBranches = branchList.filter(branch => branch.areaManagerId === myAccount?.id).map(branch => branch.id);

  const bankManagerBranches = branchList.filter(branch => branch.branchManagerId === myAccount?.id).map(branch => branch.id);

  const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-input': {
      textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
    },
  });

  useEffect(() => {

    if (verifyOTPstatus && verifyOTPUpdateStatus) {

      sessionStorage.setItem('otpStatus', "1");
      Storage.session.set("otpStatus", true);
      Storage.local.set('otpStatus', true);
    } else if (!verifyOTPstatus && verifyOTPUpdateStatus) {
      const sessionOtp = sessionStorage.getItem('otpStatus') === "1";
      const storageSessionOtp = Storage.session.get('otpStatus') === true;
      const storageLocalOtp = Storage.local.get('otpStatus') === true;
      if (!sessionOtp && !storageSessionOtp && !storageLocalOtp) {
        sessionStorage.setItem('otpStatus', "0");
        Storage.session.set("otpStatus", false);
        Storage.local.set('otpStatus', false);
      }
    }
  }, [verifyOTPstatus, verifyOTPUpdateStatus]);

  // keep approval user
  const accountsUser = useAppSelector(state => state.userManagement.accountsUser);
  useEffect(() => {
    dispatch(getUsersAsAlmoskhAdminAndStaff({}));
  },[dispatch]);

   function setNextStep(item: any) {
     const isLocalCommodity = item?.commodity?.isActive === true && item?.commodity?.ownership === 'Local';
    if (item.cancelOrder !== true) {
      if (item.bankManagerApproval === null && item.bankCustomerApproval === null && item.status === 'Quoted') {
        return `${translate('home.nextStep.buy')}`;
      }
      else if (item.bankManagerApproval === false && (item.bankCustomerApproval === false || item.bankCustomerApproval === true) && item.status === 'Bought') {
        return `${translate('home.nextStep.poPendingApproval')}`;
      }
      else if ((item.cancelOrder === false || item.cancelOrder === null) && item.bankManagerApproval === true && item.status === 'Bought' && isLocalCommodity) {
        return `${translate('home.nextStep.transfer')}`;
      }
      else if ((item.cancelOrder === false || item.cancelOrder === null) && item.bankManagerApproval === true && item.status === 'Bought' && item.bursaAPIBidCertId === null) {
          if (comexSettingsEntity?.serverStatus === true) {
            if(item.bulkProcessRunning === true){
              return `${translate('home.nextStep.purchaseProcessing')}`;
            } else {
              return `${translate('home.nextStep.approved')}`;
            }
          } else {
            return `${translate('home.nextStep.purchaseProcessing')}`;
          }
        }
      else if (item.bursaAPIBidCertId !== null && item.bankCustomerApproval === false && (item.smsLanguage === null || item.smsLanguage === "null") && item.status === 'Bought') {
        return `${translate('home.nextStep.transfer')}`;
      }
      else if (item.smsLanguage !== null && item.smsSent === true && item.status === 'Transferred' && isLocalCommodity) {
        return `${translate('home.nextStep.keepSell')}`;
      }
      else if (item.smsLanguage !== null && item.smsSent === true && item.status === 'Transferred' && item.bursaAPIOtcCertId === null) {
        return `${translate('home.nextStep.transferProcessing')}`;
      }
      else if (item.smsSent === true && item.bursaAPIOtcCertId !== null && item.stbOption === null && item.status === 'Transferred') {
        return `${translate('home.nextStep.keepSell')}`;
      }
      else if (isLocalCommodity && (item.stbOption === 'Sell' || (item.stbOption === 'Keep' && item.comexAdminDecision === 'Sell')) && item.status === 'Sold') {
        return `${translate('home.nextStep.done')}`;
      }
      else if (item.bursaAPIStbCertId === null && (item.stbOption === 'Sell' || (item.stbOption === 'Keep' && item.comexAdminDecision === 'Sell')) && item.status === 'Sold') {
        return `${translate('home.nextStep.sellProcessing')}`;
      }
      else if (item.bursaAPIStbCertId === null && item.stbOption === 'Keep' && item.comexAdminDecision === null && item.status === 'Kept') {
        return `${translate('home.nextStep.pendingApprovalByAdmin')}`;
      }
      else if (item.bursaAPIStbCertId === null && item.stbOption === 'Keep' && item.comexAdminDecision === 'Keep' && item.status === 'Kept') {
        if (isLocalCommodity && item?.keepApproveUser?.login) {
          const isBankStaff = accountsUser.some((usersData: IUser) => {
            return usersData.login === item.keepApproveUser.login &&
              usersData.authorities &&
              usersData.authorities.includes(AUTHORITIES.BANK_STAFF);
          });
          return isBankStaff
            ? translate('home.nextStep.approvedByBankUser')
            : translate('home.nextStep.approvedByAdmin');
        } else {
          return translate('home.nextStep.approvedByAdmin');
        }
      }

      else { return `${translate('home.nextStep.done')}`; }
    }
  }

  function setStatusTranslated(entity: any) {
    if (entity.status === 'Quoted') {
      return `${translate('home.status.quoted')}`;
    } else if (entity.status === 'Bought') {
      return `${translate('home.status.bought')}`;
    } else if (entity.status === 'Transferred') {
      return `${translate('home.status.transferred')}`;
    } else if (entity.status === 'Sold') {
      return `${translate('home.status.sold')}`;
    } else if (entity.status === 'Kept') {
      return `${translate('home.status.kept')}`;
    } else {
      return `${translate('home.status.cancelled')}`;
    }
  }



  const handleToClose = () => {
    setOpen(false);
  };



  const handleToCloseDialogRepurchase = () => {
    setOpenDialogRepurchase(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsFailed(true);
    }, 3000);
  }, []);

  function setInformationButton(entity: any) {
    const nextStep = setNextStep(entity);

    if (isFailed === true && comexSettingsEntity?.serverStatus === true && entity?.bulkProcessRunning === false) {
      // failed information icon color
      if (nextStep?.toLowerCase() === 'approved' || nextStep?.toLowerCase() === 'تمت الموافقة') {
        if(bursaAPIOrderResultList?.find(orderResult => Number(orderResult?.bursaAPIOrder?.quotationId)===entity.id && (orderResult?.bidErrNo ==='115' || orderResult?.otcErrNo ==='115' || orderResult?.stbErrNo ==='115'))!==undefined)
          return (
            <FontAwesomeIcon icon={faInfoCircle} color="#d32f2f" onClick={() => {
              setOpen(true);
              entity.cancelOrder === false ?
                setPurchaseStatus(translate('home.information.cancelFailed') + translate('home.information.orderFailed')) :
                setPurchaseStatus(translate('home.information.orderFailed'))
            }} size="lg" style={{ cursor: 'pointer' }} />);
        else if(bursaAPIOrderResultList?.find(orderResult => Number(orderResult?.bursaAPIOrder?.quotationId)===entity.id && orderResult?.processingCount==='1')!==undefined)
            return (
              <FontAwesomeIcon icon={faInfoCircle} color="#d32f2f" onClick={() => {
                setOpen(true);
                entity.cancelOrder === false ?
                  setPurchaseStatus(translate('home.information.cancelFailed') + translate('home.information.orderProcessing')) :
                  setPurchaseStatus(translate('home.information.orderProcessing'))
              }} size="lg" style={{ cursor: 'pointer' }} />);
        else
          return (
            <FontAwesomeIcon icon={faInfoCircle} color="#d32f2f" onClick={() => {
              setOpen(true);
              entity.cancelOrder === false ?
                setPurchaseStatus(translate('home.information.cancelFailed') + translate('home.information.purchaseFailed')) :
                setPurchaseStatus(translate('home.information.purchaseFailed'))
            }} size="lg" style={{ cursor: 'pointer' }} />);
      } else if (nextStep?.toLowerCase() === 'transfer processing' || nextStep?.toLowerCase() === 'معالجة التحويل') {
        return (
          <FontAwesomeIcon icon={faInfoCircle} color="#d32f2f" onClick={() => {
            setOpen(true);
            entity.cancelOrder === false ?
              setPurchaseStatus(translate('home.information.cancelFailed') + translate('home.information.transferFailed')) :
              setPurchaseStatus(translate('home.information.transferFailed'))
          }} size="lg" style={{ cursor: 'pointer' }} />);
      } else if (nextStep?.toLowerCase() === 'sell processing' || nextStep?.toLowerCase() === 'معالجة البيع') {
        return (
          <FontAwesomeIcon icon={faInfoCircle} color="#d32f2f" onClick={() => {
            setOpen(true);
            entity.cancelOrder === false ?
              setPurchaseStatus(translate('home.information.cancelFailed') + translate('home.information.sellFailed')) :
              setPurchaseStatus(translate('home.information.sellFailed'))
          }} size="lg" style={{ cursor: 'pointer' }} />);
      }
    } else if (comexSettingsEntity?.serverStatus === false) {
      // processing information icon color
      if (nextStep?.toLowerCase() === 'purchase processing' || nextStep?.toLowerCase() === 'معالجة الشراء') {
        return (
          <FontAwesomeIcon icon={faInfoCircle} color="#0275d8" onClick={() => {
            setOpen(true);
            entity.cancelOrder === false ?
              setPurchaseStatus(translate('home.information.purchaseFromCancel')) :
              setPurchaseStatus(translate('home.information.purchaseProcessing'))
          }} size="lg" style={{ cursor: 'pointer' }} />);
      } else if (nextStep?.toLowerCase() === 'transfer processing' || nextStep?.toLowerCase() === 'معالجة التحويل') {
        return (
          <FontAwesomeIcon icon={faInfoCircle} color="#0275d8" onClick={() => {
            setOpen(true);
            entity.cancelOrder === false ?
              setPurchaseStatus(translate('home.information.transferFromCancel')) :
              setPurchaseStatus(translate('home.information.transferProcessing'))
          }} size="lg" style={{ cursor: 'pointer' }} />);
      } else if (nextStep?.toLowerCase() === 'sell processing' || nextStep?.toLowerCase() === 'معالجة البيع') {
        return (
          <FontAwesomeIcon icon={faInfoCircle} color="#0275d8" onClick={() => {
            setOpen(true);
            entity.cancelOrder === false ?
              setPurchaseStatus(translate('home.information.sellFromCancel')) :
              setPurchaseStatus(translate('home.information.sellProcessing'))
          }} size="lg" style={{ cursor: 'pointer' }} />);
      }
    };
  };

  const FRIDAY_PRAYER_START_TIME = '13:15:00';
  const FRIDAY_PRAYER_END_TIME = '13:45:00';
  const currDate = new Date();

  const userLocalStart = moment.tz(FRIDAY_PRAYER_START_TIME, 'HH:mm:ss', 'Asia/Kuala_Lumpur').local().format('hh:mm A');
  const userLocalEnd = moment.tz(FRIDAY_PRAYER_END_TIME, 'HH:mm:ss', 'Asia/Kuala_Lumpur').local().format('hh:mm A');

  const isFriday = currDate.getDay() === 5;

  const isDuringFridayPrayer = (currentTime) => {
    return currentTime >= FRIDAY_PRAYER_START_TIME && currentTime <= FRIDAY_PRAYER_END_TIME;
  };

  const getCurrentTime = () => {
    return moment().tz('Asia/Kuala_Lumpur').format('HH:mm:ss');
  };

  const getMessage = () => {
    const isDuringFridayPrayerTime  = isFriday && isDuringFridayPrayer(getCurrentTime());
    if (isDuringFridayPrayerTime) {
      return (
        <span>
          <Translate
            contentKey="home.fridayPrayerMessageWarning"
            // interpolate={{ startTime: userLocalStart, endTime: userLocalEnd }}
            />
          <span style={{ direction: 'ltr', fontSize: '0.65rem', fontWeight: 'bold' }}>
            &nbsp;( {userLocalStart} - {userLocalEnd} )&nbsp;
          </span>
        </span>
      );
    } else {
      return (
        <span>
          <Translate
            contentKey="home.operationTimeWarning"
            // interpolate={{
            //   startTime: moment(localStart).tz(moment.tz.guess()).format('hh:mm A'),
            //   endTime: moment(localEnd).tz(moment.tz.guess()).format('hh:mm A'),
            // }}
            />
          <span style={{direction: 'ltr', fontSize: '0.65rem', fontWeight: 'bold'}}>
            &nbsp;( {moment(localStart).tz(moment.tz.guess()).format('hh:mm A')} - {moment(localEnd).tz(moment.tz.guess()).format('hh:mm A')} )&nbsp;
          </span>
        </span>
      );
    }
  };


  useEffect(() => {
    if (account?.login !== undefined) {
      const userTimeZone = moment.tz.guess();
      const operationStartMalayTime = moment.utc(comexSettingsEntity?.operationStartTime).tz('Asia/Kuala_Lumpur');
      const operationEndMalayTime = moment.utc(comexSettingsEntity?.operationEndTime).tz('Asia/Kuala_Lumpur');
      const operationStartLocalTime = moment(operationStartMalayTime).tz(userTimeZone);
      const operationEndLocalTime = moment(operationEndMalayTime).tz(userTimeZone);
      setLocalStart(operationStartLocalTime.toDate());
      setLocalEnd(operationEndLocalTime.toDate());

      const currentTime = getCurrentTime();
      const duringFridayPrayer = isDuringFridayPrayer(currentTime);

      if (isFriday && duringFridayPrayer) {
        setOperationTime(false);
        return;
      }

      const currentMalayTime = moment().tz('Asia/Kuala_Lumpur');
      const startTime = moment(currentMalayTime).set({
        hour: operationStartMalayTime.hour(),
        minute: operationStartMalayTime.minute(),
      });

      const endTime = moment(currentMalayTime).set({
        hour: operationEndMalayTime.hour(),
        minute: operationEndMalayTime.minute(),
      });

      if (currentMalayTime.isBetween(startTime, endTime, null, '[]')) {
        setOperationTime(true);
      } else {
        setOperationTime(false);
      }
    }
  }, [comexSettingsEntity]);

  function operationTimeErrorMsg() {
    if (myAccount?.authorities?.includes(AUTHORITIES.BANK_STAFF) && operationTime === false) {
      return (
        <Grid container justifyContent="center">
          <Grid item xs={10} sx={{ textAlign: 'center' }}>
            <Typography
              variant="caption"
              color="error"
              sx={{ fontSize: '0.65rem', wordWrap: 'break-word', maxWidth: '180px', lineHeight: 1.2 }}
            >
              {getMessage()}
            </Typography>
          </Grid>
          {/* Grid item xs={12} sm={12} md={12} className="right-side-component-flex">*/}
          {/*  <Button*/}
          {/*    to="/quotation/new"*/}
          {/*    color="primary"*/}
          {/*    id="jh-create-entity"*/}
          {/*    data-cy="entityCreateButton"*/}
          {/*    disabled*/}
          {/*  >*/}
          {/*    <FontAwesomeIcon icon="plus" />*/}
          {/*    &nbsp;*/}
          {/*    <Translate contentKey="comexApp.quotation.home.createLabel">Create new Quotation</Translate>*/}
          {/*  </Button></Grid> */}
        </Grid>
      )
    }
  }

  const [selectedQuotationOption, setSelectedQuotationOption] = useState('international');
  const [ openModal, setOpenModal ] = useState(false);
  function handleCloseModal() { setOpenModal(false); }
  function handleOpenModal() { setOpenModal(true); }
  const handleQuotationOptionChange = (event) => {
    setSelectedQuotationOption(event.target.value);
  };
  useEffect(() => {
    if (getMessage()) {
      setSelectedQuotationOption('local');
    }
  }, [openModal]);
  function getStylesForQuotationOption(option) {
    const isSelected = selectedQuotationOption === option;
    return {
      backgroundColor: isSelected ? '#D0E8F2' : '#EEEEEE',
      borderColor: isSelected ? '#0147AB' : '#9E9E9E',
      fontWeight: isSelected ? 'bold' : 'normal',
      color: isSelected ? '#0147AB' : 'inherit',
      redTextColor: isSelected ? 'red' : 'inherit',
    };
  }

  const tabName = JSON.parse(window.localStorage.getItem('tab')) || 'po';
  const [toggleStatusState, setToggleStatusState] = React.useState(tabName);
  const toggleStatusTab = (indexTab) => {
    resetFilterOnly();
    setToggleStatusState(indexTab);
    window.localStorage.setItem('tab', JSON.stringify(indexTab));
    setPage(0);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    localStorage.setItem("managePOPage", newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const handleChangeRowsPerPage = event => {
  //   const newRowsPerPage = parseInt(event.target.value, 10);
  //   setRowsPerPage(newRowsPerPage);
  //   setPage(0);
  // };

  const handlePoTable = () => {
      setToggleStatusState('po');
      window.localStorage.setItem('tab', JSON.stringify('po'));
      poTableRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

    const handlePoTablePendingApproval = () => {
        setFilter('Pending Approval');
        setToggleStatusState('po');
        window.localStorage.setItem('tab', JSON.stringify('po'));
        poTableRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        const newStatusCheck = [...new Set([...statusCheck, 'Pending Approval'])];
        setStatusCheck(newStatusCheck);
    }

    const handlePoTablePendingTransfer = () => {
        setFilter('Pending Transfer');
        setToggleStatusState('po');
        window.localStorage.setItem('tab', JSON.stringify('po'));
        poTableRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        const boughtStatusCheck = [...new Set([...statusCheck, 'Bought'])];
        setStatusCheck(boughtStatusCheck);
    }

    const resetFilterOnly = () => {
        setFilter('');
        props.history.push('/home');
    }


    function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      // if (setNextStep(a[0]) === 'Pending for Approval' && setNextStep(b[0]) !== 'Pending for Approval') {
      //   return -1;
      // } else if (setNextStep(b[0]) === 'Pending for Approval' && setNextStep(a[0]) !== 'Pending for Approval') {
      //   return 1;
      // }
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const rows = quotationEntities?.map((obj, i) => ({
    ...obj,
    ecertNo: obj?.bursaAPIBidCertId === null ? '-' : obj?.bursaAPIBidCert?.ecertNo,
    id: obj.id,
    poNumber: obj.purchaseOrder.poNumber,
    customerName: obj.customer.name,
    bankName: obj.bank.name,
  }));
  const headCells = [
    {
      id: 'id',
      numeric: true,
      disablePadding: false,
      label: `${translate('home.table.orderNo')}`,
      disableSorting: false,
    },
    {
      id: 'ecertNo',
      numeric: true,
      disablePadding: false,
      label: `${translate('home.table.ecertNo')}`,
      disableSorting: false,
    },
    {
      id: 'customerName',
      numeric: false,
      disablePadding: false,
      label: `${translate('home.table.customerName')}`,
      disableSorting: false,
    },
    {
      id: 'bankName',
      numeric: false,
      disablePadding: false,
      label: `${translate('home.table.bank')}`,
      disableSorting: false,
    },
    {
      id: 'bankUserName',
      numeric: false,
      disablePadding: false,
      label: `${translate('home.table.bankUser')}`,
      disableSorting: false,
    },
    {
      id: 'amount',
      numeric: true,
      disablePadding: false,
      label: `${translate('home.table.value')}`,
      disableSorting: false,
    },
    {
      id: 'createdDate',
      numeric: false,
      disablePadding: false,
      label: `${translate('home.table.date')}`,
      disableSorting: true,
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: `${translate('home.table.status')}`,
      disableSorting: false,
    },
    {
      id: 'nextStep',
      numeric: false,
      disablePadding: false,
      label: `${translate('home.table.nextStep')}`,
      disableSorting: true,
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: false,
      label: " ",
      disableSorting: true,
    },
  ];

  useEffect(() => {
    if (account?.login !== undefined)
      dispatch(getCommoditiesPriceList({}));
      if(account?.branchId !== null)
        dispatch(getEntityByBranchId(account?.branchId));
    localStorage.setItem('print', 'false');
  }, [account]);

  function EnhancedTableHead(tableHead) {
    const { order, orderBy, onRequestSort } =
      tableHead;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow className="enhanced-table-head">
          {headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              align="left"

              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.disableSorting ? headCell.label :
                <StyledTableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </StyledTableSortLabel>
              }
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleNextStepClick = (entity: any) => {
    const orderId = entity?.id;
    const nextStep = setNextStep(entity);
    const normalizeString = (str) => str?.trim().toUpperCase().normalize('NFC');
    if (nextStep?.toUpperCase() === 'BUY' || nextStep?.toUpperCase() === 'شراء') {
      props.history.push(`/quotation/purchase/${orderId}`);
    } else if (nextStep?.toUpperCase() === 'PENDING FOR APPROVAL' || nextStep?.toUpperCase() === 'أمر الشراء قيد الموافقة') {
      if (myAccount?.authorities?.includes(AUTHORITIES.BRANCH_MANAGER)
        || myAccount?.authorities?.includes(AUTHORITIES.PURCHASE_APPROVAL)) {
        props.history.push(`/quotation/purchase/${orderId}`);
      } else {
        props.history.push(`/quotation/bought/${orderId}`);
      }
    } else if ((nextStep?.toUpperCase() === 'APPROVED' || nextStep?.toUpperCase() === 'تمت الموافقة') ||
      (nextStep?.toUpperCase() === 'PURCHASE PROCESSING' || nextStep?.toUpperCase() === 'معالجة الشراء')) {
      props.history.push(`/quotation/bought/${orderId}`);
    }
    else if (nextStep?.toUpperCase() === 'TRANSFER' || nextStep?.toUpperCase() === 'تحويل ملكية') {

      // old code (in case of something happen)

      // const chosenQuotation = quotationEntities.find(quotation => quotation.id === orderId);
      // dispatch(getUserByLogin(chosenQuotation.customer?.ic)).then(res => {
      //   // old if
      //   // if (res["error"] && res["error"]["message"].includes("404")) {
      //   if (res.payload && !res.payload["data"]) {
      //     dispatch(handleRegister({
      //       login: chosenQuotation.customer?.ic,
      //       password: "customer",
      //       email: chosenQuotation.customer?.email,
      //       firstName: chosenQuotation.customer?.name,
      //       authorities: [AUTHORITIES.CUSTOMER],
      //     }));
      //     toast.success(`Customer with IC ${chosenQuotation.customer?.ic} created!`)
      //     props.history.push(`/quotation/bought/${orderId}`);
      //   } else {
      //     toast.info(`Customer with IC ${chosenQuotation.customer?.ic} already exists!`);
      //   }
      // });

      // new code

      props.history.push(`/quotation/bought/${orderId}`);


    } else if ((nextStep?.toUpperCase() === 'KEEP/SELL' || nextStep?.toUpperCase() === 'احتفاظ / بيع') ||
      (nextStep?.toUpperCase() === 'TRANSFER PROCESSING' || nextStep?.toUpperCase() === 'معالجة التحويل') ||
      (nextStep?.toUpperCase() === 'SELL PROCESSING' || nextStep?.toUpperCase() === 'معالجة البيع') ||
      (nextStep?.toUpperCase() === 'WAITING FOR ORGANIZER’S CONFIRMATION' || normalizeString(nextStep) === normalizeString('قيد تأكيد الجهة المنظمة')) ||
      (nextStep?.toUpperCase() === 'APPROVED BY ADMIN' || nextStep?.toUpperCase() === 'تمت الموافقة عليها من قبل المسؤول') ||
      (nextStep?.toUpperCase() === 'APPROVED BY BANK USER' || nextStep?.toUpperCase() === 'تمت الموافقة من قبل موظف البنك') ||
      (nextStep?.toUpperCase() === 'DONE' || nextStep?.toUpperCase() === 'تم')) {
      props.history.push(`/quotation/bought/${orderId}`);
    }

    else {
      setOpen(true);
      setPurchaseStatus(nextStep);
    }
  };

  useEffect(() => {
    if (account?.login !== undefined) {
      dispatch(getComexSetting(1));

      dispatch(getNotificationTemplates({}));
      if (account?.authorities?.includes(AUTHORITIES.ADMIN) ||
        account?.authorities?.includes(AUTHORITIES.ALSHMOKH_ADMIN) ||
        account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER) ) {

        dispatch(getBanks({}));
        dispatch(getBranchs({}));
        dispatch(getCustomers({}));
        dispatch(getCommodities({}));
        dispatch(getEntityForEachUserRole({
          roleId:account?.id,
          userRole : AUTHORITIES.ADMIN
        }));
      }
      if(account?.authorities?.includes(AUTHORITIES.CUSTOMER)){

        dispatch(getEntityForEachUserRole({
          roleId:account?.id,
          userRole : AUTHORITIES.CUSTOMER
        }));
      }
      if(account?.authorities?.includes(AUTHORITIES.BANK_STAFF) || account?.authorities?.includes(AUTHORITIES.BRANCH_MANAGER)){

        dispatch(getEntityForEachUserRole({
          roleId:account?.id,
          userRole : AUTHORITIES.BANK_STAFF
        }));
      }
      if(account?.authorities?.includes(AUTHORITIES.BANK_MANAGER)){

        dispatch(getEntityForEachUserRole({
          roleId:account?.id,
          userRole : AUTHORITIES.BANK_MANAGER
        }));
      }
      if(account?.authorities?.includes(AUTHORITIES.AREA_MANAGER)){

        dispatch(getEntityForEachUserRole({
          roleId:account?.id,
          userRole : AUTHORITIES.AREA_MANAGER
        }));
      }
      if(account?.authorities?.includes(AUTHORITIES.AUDITOR)){

        dispatch(getEntityForEachUserRole({
          roleId:account?.id,
          userRole : AUTHORITIES.AUDITOR
        }));
      }
    }
  }, [account]);






  const handleDetailClick = (orderId) => {
    props.history.push(`/quotation/${orderId}`);
  };


  const bull = (
    <Box component="span" sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
      •
    </Box>
  );

  let responsiveFont = createTheme();
  responsiveFont = responsiveFontSizes(responsiveFont);

  const SummaryTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
    },
  }));

  // to change colour of the table
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgba(255,255,255,0)",
      color: theme.palette.common.white,
      fontWeight: 'bold',
      letterSpacing: 1,
      fontFamily: 'Roboto, sans-serif',
      textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
    },
  }));

  const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
    '&.MuiTableSortLabel-root': {
      color: 'white',
    },
    '&.MuiTableSortLabel-root:hover': {
      color: 'white',
    },
    '&.Mui-active': {
      color: 'white',
    },
    '& .MuiTableSortLabel-icon': {
      color: 'white !important',
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&.enhanced-table-head': {
      backgroundColor: theme.palette.info.dark,
      color: theme.palette.common.white,
      fontWeight: 'bold',
      letterSpacing: 1,
      boxShadow: "0px 100px 80px rgba(0, 0, 0, 0.07), 0px 36.5016px 29.2013px rgba(0, 0, 0, 0.0482987), 0px 17.7209px 14.1767px rgba(0, 0, 0, 0.0389404), 0px 8.6871px 6.94968px rgba(0, 0, 0, 0.0310596), 0px 3.43489px 2.74791px rgba(0, 0, 0, 0.0217013)",
    },
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
      background: '#FFFFFF'
    },
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
      background: '#EFF7FF'
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const PrimaryTextTypography = styled(Typography)(({ theme }) => ({
    '&.MuiTypography-root': {
      color: '#01579b',
    }
  }));

  const SuccessTextTypography = styled(Typography)(({ theme }) => ({
    '&.MuiTypography-root': {
      color: '#1b5e20',
    }
  }));

  const WarningTextTypography = styled(Typography)(({ theme }) => ({
    '&.MuiTypography-root': {
      color: '#e65100',
    }
  }));

  const ErrorTextTypography = styled(Typography)(({ theme }) => ({
    '&.MuiTypography-root': {
      color: '#c62828',
    }
  }));

  const [statusCheck, setStatusCheck] = useState(JSON.parse(sessionStorage.getItem("filterStatus")) || []);
  // const [filteredQuotation, setFilteredQuotation] = useState([]);

  const handleChange = e => {
      resetFilterOnly();
    if (e.target.checked) {
      setStatusCheck([...statusCheck, e.target.value].sort(function (a, b) {
        return statusType.indexOf(a) - statusType.indexOf(b);
      }));
      setPage(0);
      sessionStorage.setItem("filterStatus", JSON.stringify([...statusCheck, e.target.value].sort(function (a, b) {
        return statusType.indexOf(a) - statusType.indexOf(b);
      })
      ));
    } else {
      setStatusCheck(statusCheck.filter(id => id !== e.target.value));
      setPage(0);
      sessionStorage.setItem("filterStatus", JSON.stringify(statusCheck.filter(id => id !== e.target.value)));
    }
  };

  const getStatusType = () => {
    const allStatus = [
      {
        name: `${translate('home.status.pendingApproval')}`,
        value: 'Pending Approval',
      },
      {
        name: `${translate('home.status.bought')}`,
        value: 'Bought',
      },
      {
        name: `${translate('home.status.transferred')}`,
        value: 'Transferred',
      },
      {
        name: `${translate('home.status.sold')}`,
        value: 'Sold',
      },
      {
        name: `${translate('home.status.kept')}`,
        value: 'Kept',
      }
    ];

    if (!comexSettingsEntity.serverStatus && account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER)) {
      allStatus.push({
        name: `${translate('home.status.processing')}`,
        value: 'Processing',
      });
    }

    return allStatus;
  };

  const statusType = getStatusType();

  useEffect(() => {
    if (myAccount?.login !== undefined && !isDone && myAccount.authorities.includes(AUTHORITIES.BRANCH_MANAGER)) {
      if (myAccount?.authorities.includes(AUTHORITIES.ADMIN))
        dispatch(getUser(myAccount.login));
      else {
        dispatch(getUserByAlshmokhAdmin(myAccount.login));

      }

      if (myAccount?.branchId !== undefined) {
        setIsDone(true);
        dispatch(getBranch(myAccount?.branchId));

        dispatch(getBranchForStaffAndBranchManager(myAccount?.branchId));
      }
    }
  }, [myAccount, user]);
  useEffect(() => {

    if (account?.authorities?.includes(AUTHORITIES.BANK_STAFF)) {
      const summaryRequest = {
        id: account?.login,
        userRole: AUTHORITIES.BANK_STAFF,
      }
      dispatch(getQuotationSoldCount(summaryRequest));
      dispatch(getQuotationCreatedCount(summaryRequest));
      dispatch(getQuotationPendingTransferCount(account?.login));
      dispatch(getQuotationPurchaseOrderCount(summaryRequest));
      dispatch(getQuotationKeepCount(summaryRequest));
      dispatch(getTotalSoldToday(summaryRequest));
      dispatch(getBranch(myAccount?.branchId));
      dispatch(getBranchForStaffAndBranchManager(myAccount?.branchId));
    }
    if (account?.authorities?.includes(AUTHORITIES.BRANCH_MANAGER)) {
      const summaryRequest = {
        id: account?.branchId,
        userRole: AUTHORITIES.BRANCH_MANAGER,
      }
      dispatch(getQuotationSoldCount(summaryRequest));
      dispatch(getQuotationCreatedCount(summaryRequest));
      dispatch(getQuotationPurchaseOrderCount(summaryRequest));
      dispatch(getQuotationKeepCount(summaryRequest));
      dispatch(getTotalSoldToday(summaryRequest));
      dispatch(getQuotationPurchaseOrderApprovalCount(summaryRequest));
      dispatch(getQuotationKeepApprovalCount(summaryRequest));
      dispatch(getQuotationCancellationApprovalCount(summaryRequest));
      dispatch(getEachTotalSalesToday(summaryRequest));
      dispatch(getBranch(myAccount?.branchId));
      dispatch(getBranchForStaffAndBranchManager(myAccount?.branchId));
    }
    if (account?.authorities?.includes(AUTHORITIES.BANK_MANAGER)) {
      const summaryRequest = {
        id: account?.id,
        userRole: AUTHORITIES.BANK_MANAGER,
      }
      dispatch(getQuotationSoldCount(summaryRequest));
      dispatch(getQuotationCreatedCount(summaryRequest));
      dispatch(getQuotationPurchaseOrderCount(summaryRequest));
      dispatch(getQuotationKeepCount(summaryRequest));
      dispatch(getTotalSoldToday(summaryRequest));
      dispatch(getEachTotalSalesToday(summaryRequest));
    }
    if (account?.authorities?.includes(AUTHORITIES.AUDITOR)) {
      const summaryRequest = {
        id: account?.id,
        userRole: AUTHORITIES.AUDITOR,
      }
      dispatch(getQuotationSoldCount(summaryRequest));
      dispatch(getQuotationCreatedCount(summaryRequest));
      dispatch(getQuotationPurchaseOrderCount(summaryRequest));
      dispatch(getQuotationKeepCount(summaryRequest));
      dispatch(getTotalSoldToday(summaryRequest));
      dispatch(getEachTotalSalesToday(summaryRequest));

    }
    if (account?.authorities?.includes(AUTHORITIES.CUSTOMER)) {
      const summaryRequest = {
        id: account?.login,
        userRole: AUTHORITIES.CUSTOMER,
      }
      dispatch(getQuotationSoldCount(summaryRequest));
      dispatch(getQuotationCreatedCount(summaryRequest));
      dispatch(getQuotationPurchaseOrderCount(summaryRequest));
      dispatch(getQuotationKeepCount(summaryRequest));
      dispatch(getTotalSoldToday(summaryRequest));
      dispatch(getKeepOrSellOrdersTodayForCustomer(account?.login));
      dispatch(getCommoditiesForCustomer(account?.login));
    }
    if (account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER)) {
      const summaryRequest = {
        id: account?.id,
        userRole: AUTHORITIES.ALSHMOKH_USER,
      }
      dispatch(getQuotationSoldCount(summaryRequest));
      dispatch(getQuotationCreatedCount(summaryRequest));
      dispatch(getQuotationPurchaseOrderCount(summaryRequest));
      dispatch(getQuotationKeepCount(summaryRequest));
      dispatch(getTotalSoldToday(summaryRequest));
      dispatch(getQuotationPurchaseOrderApprovalCount(summaryRequest));
    }
    if (account?.authorities?.includes(AUTHORITIES.ALSHMOKH_ADMIN)) {
      const summaryRequest = {
        id: account?.id,
        userRole: AUTHORITIES.ALSHMOKH_ADMIN,
      }
      dispatch(getQuotationCreatedCount(summaryRequest));
      dispatch(getQuotationSoldCount(summaryRequest));
      dispatch(getQuotationPurchaseOrderCount(summaryRequest));
      dispatch(getQuotationKeepCount(summaryRequest));
      dispatch(getTotalSoldToday(summaryRequest));
      dispatch(getQuotationKeepApprovalCount(summaryRequest));
      dispatch(getQuotationCancellationApprovalCount(summaryRequest));
      dispatch(getUpdateCancellationDecisionFromBursaTodayForAlshmokhAdmin(account?.id));
    }
    if (account?.authorities?.includes(AUTHORITIES.AREA_MANAGER)) {
      const summaryRequest = {
        id: account?.id,
        userRole: AUTHORITIES.AREA_MANAGER,
      }
      dispatch(getQuotationSoldCount(summaryRequest));
      dispatch(getQuotationCreatedCount(summaryRequest));
      dispatch(getQuotationPurchaseOrderCount(summaryRequest));
      dispatch(getQuotationKeepCount(summaryRequest));
      dispatch(getTotalSoldToday(summaryRequest));
      dispatch(getQuotationPurchaseOrderApprovalCount(summaryRequest));
      dispatch(getEachTotalSalesToday(summaryRequest));
    }
    }, [account]);

  const getMinQuotationDate = () => {
    if (account?.branchId) {
      const branchData = branchList?.find(branch => String(branch?.id) === account?.branchId);
      if (branchData?.bank?.quotationValidityDays != null) {
        const validityDays = branchData.bank.quotationValidityDays;
        const minDate = moment().subtract(validityDays, 'days').add(1, 'days').format('L');
        return minDate;
      }
    }
    return moment(new Date()).format('L');
  };

  const getEntitiesFromProps = () => {
    if (account?.login !== undefined) {

      if (account?.authorities?.includes(AUTHORITIES.BANK_STAFF)){
        dispatch(getErrorEntitiesForBankStaff({
          id:account?.login,
          createdDate:queryDate,
        }));
        dispatch(
          getEntitiesByBankStaff
          ({
            page: 0,
            size: 0,
            sort: "id, desc",
            createdBy: account?.login,
            date: queryDate,
            status: statusCheck,
            search: searchTerm,
            searchQuo: searchTermQuo,
            tab: toggleStatusState,
            quoMinDate: getMinQuotationDate(),
            curDate: currentDate,
          })
        );

      }

      else if (account?.authorities?.includes(AUTHORITIES.BRANCH_MANAGER) ) {
        dispatch(getErrorEntitiesForBranchManager({
          id:account?.branchId,
          createdDate:queryDate,
        }));
        dispatch(
          getEntitiesByBranchId({
            page: 0,
            size: 0,
            sort: "id, desc",
            branchId: account.branchId,
            date: queryDate,
            status: statusCheck,
            search: searchTerm,
            searchQuo: searchTermQuo,
            tab: toggleStatusState,
            curDate: currentDate,
          })
        );

      }
      else if (account?.authorities?.includes(AUTHORITIES.BANK_MANAGER)) {
        //           alert("true");

        dispatch(getErrorEntitiesForBankManager({
          id:account?.id,
          createdDate:queryDate,
        }));
        dispatch(
          getEntitiesByBankId({
            page: 0,
            size: 0,
            sort: "id, desc",
            bankManagerId: account.id,
            date: queryDate,
            status: statusCheck,
            search: searchTerm,
            searchQuo: searchTermQuo,
            tab: toggleStatusState,
            curDate: currentDate,
          })
        );
      }
      else if (account?.authorities?.includes(AUTHORITIES.AREA_MANAGER)) {

        dispatch(getErrorEntitiesForAreaManager({
          id:account?.id,
          createdDate:queryDate,
        }));
        dispatch(
          getEntitiesByAreaManagerId({
            page: 0,
            size: 0,
            sort: "id, desc",
            areaManagerId: account.id,
            date: queryDate,
            status: statusCheck,
            search: searchTerm,
            searchQuo: searchTermQuo,
            tab: toggleStatusState,
            curDate: currentDate,
          })
          // eslint-disable-next-line no-console
        );
      }
      else if (account?.authorities?.includes(AUTHORITIES.AUDITOR)) {

        dispatch(getErrorEntitiesForAuditor({
          id:account?.bankId,
          createdDate:queryDate,
        }));
        dispatch(
          getEntitiesByAuditor({
            page: 0,
            size: 0,
            sort: "id, desc",
            bankId: Number(account?.bankId),
            date: queryDate,
            status: statusCheck,
            search: searchTerm,
            searchQuo: searchTermQuo,
            tab: toggleStatusState,
            curDate: currentDate,
          })
        );
      }
      else if (account?.authorities?.includes(AUTHORITIES.ADMIN) ||
        account?.authorities?.includes(AUTHORITIES.ALSHMOKH_ADMIN) ||
        account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER)) {

        dispatch(getErrorEntitiesForAdmins({
          createdDate:queryDate,
        }));
        dispatch(
          getEntitiesFilter({
            page: 0,
            size: 0,
            sort: "id, desc",
            date: toggleStatusState === 'po' ? queryDate : queryDateQuo,
            status: statusCheck,
            search: searchTerm,
            searchQuo: searchTermQuo,
            tab: toggleStatusState,
            curDate: currentDate,
            bankId: bankFilter,
            branchId: branchFilter,
            customerId: customerFilter,
            commodityId: commodityFilter,
          })
        );
      }
      else if (account?.authorities?.includes(AUTHORITIES.CUSTOMER)) {

        dispatch(getErrorEntitiesForCustomer({
          id:account?.login,
          createdDate:queryDate,
        }));
        dispatch(
          getEntitiesByCustomerLogin({
            page: 0,
            size: 0,
            sort: "id, desc",
            date: queryDate,
            status: statusCheck,
            search: searchTerm,
            searchQuo: searchTermQuo,
            customerLogin: myAccount?.login,
            tab: toggleStatusState,
            curDate: currentDate,
          })
          // eslint-disable-next-line no-console
        );
      }
    }
    // quotationEntities.map(i=>i.cancelOrder===true && dispatch(getEntitiesByQuotation(i.id)));
  }



      const sortEntities= () => {
        getEntitiesFromProps();
      }

      useEffect(() => {
      sortEntities();
    }, [account, pageNo, rowsPerPage, searchTerm, statusCheck, queryDate, toggleStatusState, bankFilter, branchFilter, customerFilter, commodityFilter, searchTermQuo, specBranch, queryDateQuo]);

    useEffect(() => {
      const params = new URLSearchParams(props.location.search);
      const page = params.get('page');
      const sortParam = params.get(SORT);
      if (page && sortParam) {
        const sortSplit = sortParam.split(',');
        setPagination({
          ...pagination,
          activePage: +page,
          sort: sortSplit[0],
          order: sortSplit[1],
        });
      }
    }, [props.location.search]);

    useEffect(() => {

      const pageReload = setInterval(() => {
        if (account?.login !== undefined){
          dispatch(getCommoditiesPriceList({}));
          dispatch(getComexSetting(1));
          dispatch(getNotificationTemplates({}));

          if (account?.authorities?.includes(AUTHORITIES.ADMIN) ||
            account?.authorities?.includes(AUTHORITIES.ALSHMOKH_ADMIN) ||
            account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER) ) {

            dispatch(getBanks({}));
            dispatch(getBranchs({}));
            dispatch(getCustomers({}));
            dispatch(getCommodities({}));
            dispatch(getEntityForEachUserRole({roleId:account?.id, userRole : AUTHORITIES.ADMIN}));
          }

          if(account?.authorities?.includes(AUTHORITIES.CUSTOMER)){
            dispatch(getEntityForEachUserRole({roleId:account?.id, userRole : AUTHORITIES.CUSTOMER}));
          }

          if(account?.authorities?.includes(AUTHORITIES.BANK_STAFF) || account?.authorities?.includes(AUTHORITIES.BRANCH_MANAGER)){
            dispatch(getEntityForEachUserRole({roleId:account?.id, userRole : AUTHORITIES.BANK_STAFF}));
          }

          if(account?.authorities?.includes(AUTHORITIES.BANK_MANAGER)){
            dispatch(getEntityForEachUserRole({roleId:account?.id, userRole : AUTHORITIES.BANK_MANAGER}));
          }

          if(account?.authorities?.includes(AUTHORITIES.AREA_MANAGER)){
            dispatch(getEntityForEachUserRole({roleId:account?.id, userRole : AUTHORITIES.AREA_MANAGER}));
          }

          if(account?.authorities?.includes(AUTHORITIES.AUDITOR)){
            dispatch(getEntityForEachUserRole({roleId:account?.id, userRole : AUTHORITIES.AUDITOR}));
          }
        }

        if(account?.branchId !== null){
          dispatch(getEntityByBranchId(account?.branchId));
        }
        localStorage.setItem('print', 'false');

        if (account?.authorities?.includes(AUTHORITIES.BANK_STAFF)) {
          const summaryRequest = {
            id: account?.login,
            userRole: AUTHORITIES.BANK_STAFF,
          }
          dispatch(getQuotationSoldCount(summaryRequest));
          dispatch(getQuotationCreatedCount(summaryRequest));
          dispatch(getQuotationPendingTransferCount(account?.login));
          dispatch(getQuotationPurchaseOrderCount(summaryRequest));
          dispatch(getQuotationKeepCount(summaryRequest));
          dispatch(getTotalSoldToday(summaryRequest));
        }

        if (account?.authorities?.includes(AUTHORITIES.BRANCH_MANAGER)) {
          const summaryRequest = {
            id: account?.branchId,
            userRole: AUTHORITIES.BRANCH_MANAGER,
          }
          dispatch(getQuotationSoldCount(summaryRequest));
          dispatch(getQuotationCreatedCount(summaryRequest));
          dispatch(getQuotationPurchaseOrderCount(summaryRequest));
          dispatch(getQuotationKeepCount(summaryRequest));
          dispatch(getTotalSoldToday(summaryRequest));
          dispatch(getQuotationPurchaseOrderApprovalCount(summaryRequest));
          dispatch(getQuotationKeepApprovalCount(summaryRequest));
          dispatch(getQuotationCancellationApprovalCount(summaryRequest));
          dispatch(getEachTotalSalesToday(summaryRequest));
        }

        if (account?.authorities?.includes(AUTHORITIES.BANK_MANAGER)) {
          const summaryRequest = {
            id: account?.id,
            userRole: AUTHORITIES.BANK_MANAGER,
          }
          dispatch(getQuotationSoldCount(summaryRequest));
          dispatch(getQuotationCreatedCount(summaryRequest));
          dispatch(getQuotationPurchaseOrderCount(summaryRequest));
          dispatch(getQuotationKeepCount(summaryRequest));
          dispatch(getTotalSoldToday(summaryRequest));
          dispatch(getEachTotalSalesToday(summaryRequest));
        }

        if (account?.authorities?.includes(AUTHORITIES.AUDITOR)) {
          const summaryRequest = {
            id: account?.id,
            userRole: AUTHORITIES.AUDITOR,
          }
          dispatch(getQuotationSoldCount(summaryRequest));
          dispatch(getQuotationCreatedCount(summaryRequest));
          dispatch(getQuotationPurchaseOrderCount(summaryRequest));
          dispatch(getQuotationKeepCount(summaryRequest));
          dispatch(getTotalSoldToday(summaryRequest));
          dispatch(getEachTotalSalesToday(summaryRequest));
        }

        if (account?.authorities?.includes(AUTHORITIES.CUSTOMER)) {
          const summaryRequest = {
            id: account?.login,
            userRole: AUTHORITIES.CUSTOMER,
          }
          dispatch(getQuotationSoldCount(summaryRequest));
          dispatch(getQuotationCreatedCount(summaryRequest));
          dispatch(getQuotationPurchaseOrderCount(summaryRequest));
          dispatch(getQuotationKeepCount(summaryRequest));
          dispatch(getTotalSoldToday(summaryRequest));
          dispatch(getKeepOrSellOrdersTodayForCustomer(account?.login));
          dispatch(getCommoditiesForCustomer(account?.login));
        }

        if (account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER)) {
          const summaryRequest = {
            id: account?.id,
            userRole: AUTHORITIES.ALSHMOKH_USER,
          }
          dispatch(getQuotationSoldCount(summaryRequest));
          dispatch(getQuotationCreatedCount(summaryRequest));
          dispatch(getQuotationPurchaseOrderCount(summaryRequest));
          dispatch(getQuotationKeepCount(summaryRequest));
          dispatch(getTotalSoldToday(summaryRequest));
          dispatch(getQuotationPurchaseOrderApprovalCount(summaryRequest));
        }

        if (account?.authorities?.includes(AUTHORITIES.ALSHMOKH_ADMIN)) {
          const summaryRequest = {
            id: account?.id,
            userRole: AUTHORITIES.ALSHMOKH_ADMIN,
          }
          dispatch(getQuotationCreatedCount(summaryRequest));
          dispatch(getQuotationSoldCount(summaryRequest));
          dispatch(getQuotationPurchaseOrderCount(summaryRequest));
          dispatch(getQuotationKeepCount(summaryRequest));
          dispatch(getTotalSoldToday(summaryRequest));
          dispatch(getQuotationKeepApprovalCount(summaryRequest));
          dispatch(getQuotationCancellationApprovalCount(summaryRequest));
          dispatch(getUpdateCancellationDecisionFromBursaTodayForAlshmokhAdmin(account?.id));
        }

        if (account?.authorities?.includes(AUTHORITIES.AREA_MANAGER)) {
          const summaryRequest = {
            id: account?.id,
            userRole: AUTHORITIES.AREA_MANAGER,
          }
          dispatch(getQuotationSoldCount(summaryRequest));
          dispatch(getQuotationCreatedCount(summaryRequest));
          dispatch(getQuotationPurchaseOrderCount(summaryRequest));
          dispatch(getQuotationKeepCount(summaryRequest));
          dispatch(getTotalSoldToday(summaryRequest));
          dispatch(getQuotationPurchaseOrderApprovalCount(summaryRequest));
          dispatch(getEachTotalSalesToday(summaryRequest));
        }

        getEntitiesFromProps();

      }, 30000);

      return () => clearInterval(pageReload);
    }, [account, pageNo, rowsPerPage, searchTerm, statusCheck,
        queryDate, toggleStatusState, bankFilter, branchFilter,
        customerFilter, commodityFilter, searchTermQuo, specBranch, queryDateQuo]);

  // Fetch bank and branches on component mount
  useEffect(() => {
    const storedBankId = sessionStorage.getItem('filterBank');
    if (storedBankId) {
      const bankId = Number(storedBankId);
      setBankFilter(bankId);
      dispatch(getBranchListByBankId(bankId));
    }else {
      dispatch(getBranchs({}));
      sessionStorage.removeItem('filterBank');
    }

    const storedBranchId = sessionStorage.getItem('filterBranch');
    if (storedBranchId) {
      const branchId = Number(storedBranchId);
      setBranchFilter(branchId);
      dispatch(getUniqueCustomerListByBranchIdinQuotation(branchId));
    } else {
      dispatch(getCustomers({}));
      sessionStorage.removeItem('filterBranch');
    }
  }, [dispatch]);

  // Handle bank selection
  const handleBankChange = (e) => {
      resetFilterOnly();
    const bankId = Number(e.target.value);
    setBankFilter(bankId);
    setPage(0);
    if (bankId !== 0) {
      dispatch(getBranchListByBankId(bankId));
      sessionStorage.setItem("filterBank", bankId.toString());

      setValueQuo(moment(new Date()).format('L'));
      setQueryDateQuo(moment(new Date()).format('L'));
      sessionStorage.setItem("filterDateQuo", moment(new Date()).format('L'));
    } else {
      dispatch(getBranchs({}));
      sessionStorage.removeItem('filterBank');
    }

  };

  // Handle branch selection
  const handleBranchChange = (e) => {
      resetFilterOnly();
    const branchId = Number(e.target.value);
    setBranchFilter(branchId);
    setPage(0);
    if (branchId !== 0) {
      dispatch(getUniqueCustomerListByBranchIdinQuotation(branchId));
      sessionStorage.setItem("filterBranch", branchId.toString());
    } else {
      dispatch(getCustomers({}));
      sessionStorage.removeItem('filterBranch');
    }
  };

  // Handle customer selection
  const handleCustomerChange = (e) => {
      resetFilterOnly();
    const customerId = Number(e.target.value);
    setCustomerFilter(customerId);
    setPage(0);
    if (customerId !== 0) {
      sessionStorage.setItem("filterCustomer", customerId.toString());
    } else {
      sessionStorage.removeItem('filterCustomer');
    }
  };

  const getLocaleDirection = () => {
    return Storage.session.get('locale') === 'ar-ly' ? 'rtl' : 'ltr';
  };

  const getPadding = () => {
    return Storage.session.get('locale') === 'ar-ly' ? 4 : 1;
  };

  if (!isAuthenticated && account?.login !== undefined) {
    const handleLogout = () => {
      window.localStorage.removeItem('tab');
      sessionStorage.removeItem('filterBank');
      sessionStorage.removeItem('filterBranch');
      sessionStorage.removeItem('filterCustomer');
      sessionStorage.removeItem('filterCommodity');
      sessionStorage.removeItem('filterDate');
      sessionStorage.removeItem('filterDateQuo');
      sessionStorage.removeItem('filterStatus');
      sessionStorage.removeItem('filterSearch');
      sessionStorage.removeItem('filterQuoSearch');
      sessionStorage.removeItem('otpStatus');
      Storage.local.remove('otpStatus');
      Storage.session.remove('otpStatus');
      sessionStorage.removeItem('jhi-authenticationToken');
      Storage.local.remove('jhi-authenticationToken');
      Storage.session.remove('jhi-authenticationToken');
    }
    return <Redirect to="/logout" />;
  }

  const isLocalEnabled = useMemo(() => myAccount?.isLocalTransactionEnabled ?? false, [myAccount]);
  const isInternationalEnabled = useMemo(() => myAccount?.isInternationalTransactionEnabled ?? false, [myAccount]);
  const bothDisabled = !isLocalEnabled && !isInternationalEnabled;
  useEffect(() => {
    if (!bothDisabled) {
      if (!isLocalEnabled && selectedQuotationOption === 'local') {
        setSelectedQuotationOption('international');
      } else if (!isInternationalEnabled && selectedQuotationOption === 'international') {
        setSelectedQuotationOption('local');
      }
    } else {
      setSelectedQuotationOption('');
    }
  }, [isLocalEnabled, isInternationalEnabled, selectedQuotationOption]);

  const renderPoFilters = () => (
    <Grid container spacing={2} className="filter-row">
      <Grid item xs={12} sm={3} md={3}>
        <Box className="input-styled">
          <LocalizationProvider locale={Storage.session.get('locale') === 'en' ? enUS : ar} dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              toolbarTitle={translate('entity.action.selectDate')}
              okText={translate('entity.action.ok')}
              cancelText={translate('entity.action.cancel')}
              disableFuture
              mask='____/__/__'
              inputFormat="yyyy/MM/dd"
              views={['year', 'month', 'day']}
              value={value}
              onChange={newValue => {
                resetFilterOnly();
                setValue(newValue);
                setQueryDate(moment(newValue).format('L'));
                setPage(0);
                sessionStorage.setItem("filterDate", moment(newValue).format('L'));
              }}
              open={openCalendar}
              onOpen={() => setOpenCalendar(true)}
              onClose={() => setOpenCalendar(false)}
              renderInput={(params) => {
                return (
                  <StyledTextField
                    size="small"
                    fullWidth
                    {...params}
                    onClick={(e) => setOpenCalendar(true)}
                    InputProps={{
                      placeholder: "yyyy/mm/dd",
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end" onClick={(e) => setOpenCalendar(true)}>
                            <EventIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                );
              }}
            />
          </LocalizationProvider>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Box className="status-filter-box">
          <FormGroup sx={{ flexDirection: { md: 'row', sm: 'column', xs: 'column' }, alignItems: 'flex-start' }}>
            <FormLabel className="form-label-status">
              <Translate contentKey="home.status.title">Status:</Translate>
            </FormLabel>
            {statusType.map((status, index) => (
              <FormControlLabel
                key={index}
                control={<Checkbox onChange={handleChange} checked={(statusCheck.indexOf(status.value) > -1) === true} />}
                label={status.name}
                value={status.value}
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: { md: 20, sm: 18, xs: 16 } },
                  '& .MuiTypography-root': { fontSize: { md: 16, sm: 14, xs: 14 } },
                  mx: 1
                }}
              />
            ))}
          </FormGroup>
        </Box>
      </Grid>
      <Grid item xs={12} sm={3} md={3}>
        <Box className="input-styled">
          <TextField className={"search-box"}
                     size="small"
                     fullWidth
                     placeholder={translate('home.search')}
                     value={searchOnChange}
                     InputProps={{
                       startAdornment: (
                         <InputAdornment position="start">
                           <Search />
                         </InputAdornment>
                       ),
                       endAdornment: (
                         <InputAdornment position="end" onClick={(e) => { setSearchTerm(''); setSearchOnChange(''); setPage(0); sessionStorage.removeItem('filterSearch'); }}>
                           <Clear style={{ fontSize: 12 }} />
                         </InputAdornment>
                       ),
                     }}
                     onChange={e => { resetFilterOnly(); setSearchOnChange(e.target.value); sessionStorage.setItem("filterSearch", e.target.value); }}
                     onKeyPress={e => { if (e.key === 'Enter') { setSearchTerm(searchOnChange); setPage(0); } }}
          />
        </Box>
      </Grid>
    </Grid>
  );

  const renderQuoFilters = () => (
    <Grid container spacing={2} className="filter-row">
      {(myAccount?.authorities?.includes(AUTHORITIES.ADMIN) ||
        myAccount?.authorities?.includes(AUTHORITIES.ALSHMOKH_ADMIN) ||
        myAccount?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER)) && (
      <Grid item xs={12} sm={3} md={3}>
        <Box className="input-styled">
          <LocalizationProvider locale={Storage.session.get('locale') === 'en' ? enUS : ar} dateAdapter={AdapterDateFns}>
            {(() => {
              const filterBankId = sessionStorage.getItem('filterBank');
              const selectedBank = filterBankId ? bankList.find(bank => bank.id === Number(filterBankId)) : null;
              const minDate = selectedBank && selectedBank.quotationValidityDays != null
                ? moment().subtract(selectedBank.quotationValidityDays, 'days').toDate()
                : null;

              return (
                <MobileDatePicker
                  toolbarTitle={translate('entity.action.selectDate')}
                  okText={translate('entity.action.ok')}
                  cancelText={translate('entity.action.cancel')}
                  disableFuture
                  minDate={minDate}
                  mask='____/__/__'
                  inputFormat="yyyy/MM/dd"
                  views={['year', 'month', 'day']}
                  value={valueQuo}
                  onChange={newValue => {
                    resetFilterOnly();
                    const formattedDate = newValue ? moment(newValue).format('L') : '';
                    setValueQuo(formattedDate);
                    setQueryDateQuo(moment(newValue).format('L'));
                    setPage(0);
                    sessionStorage.setItem("filterDateQuo", moment(newValue).format('L'));
                  }}
                  open={openCalendarQuo}
                  onOpen={() => setOpenCalendarQuo(true)}
                  onClose={() => setOpenCalendarQuo(false)}
                  renderInput={(params) => (
                    <StyledTextField
                      size="small"
                      fullWidth
                      {...params}
                      onClick={() => setOpenCalendarQuo(true)}
                      InputProps={{
                        placeholder: "yyyy/mm/dd",
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton edge="end" onClick={() => setOpenCalendarQuo(true)}>
                              <EventIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              );
            })()}
          </LocalizationProvider>
        </Box>
      </Grid>
      )}
      <Grid item xs={12} sm={6} md={6} className="left-side-component-flex">
        <Box className="input-styled">
          <TextField
            size="small"
            fullWidth
            placeholder={translate('home.search')}
            value={searchOnChangeQuo}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" onClick={(e) => { setSearchOnChangeQuo(''); setSearchTermQuo(''); setPage(0); sessionStorage.removeItem('filterQuoSearch'); }}>
                  <Clear style={{ fontSize: 12 }} />
                </InputAdornment>
              ),
            }}
            onChange={e => { resetFilterOnly(); setSearchOnChangeQuo(e.target.value); sessionStorage.setItem("filterQuoSearch", e.target.value); }}
            onKeyPress={e => { if (e.key === 'Enter') { setSearchTermQuo(searchOnChangeQuo); setPage(0); } }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={6} className="right-side-component-flex">
        <Box>
          {(myAccount?.authorities?.includes(AUTHORITIES.BANK_STAFF)) &&
            <Button color="primary" onClick={handleOpenModal}>
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="comexApp.quotation.home.createLabel">Create new Quotation</Translate>
            </Button>}
          {/* {operationTimeErrorMsg()} */}
        </Box>
      </Grid>
    </Grid>
  );

  const renderQuotationOptions = () => {
    return (
      <FormControl component="fieldset" fullWidth>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          value={selectedQuotationOption}
          onChange={handleQuotationOptionChange}
          name="radio-buttons-group"
        >
          <Grid container spacing={1}>
            <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box mb={1} sx={{
                p: 1,
                border: '2px solid',
                borderRadius: '8px',
                textAlign: 'center',
                backgroundColor: getStylesForQuotationOption('local').backgroundColor,
                borderColor: getStylesForQuotationOption('local').borderColor,
                width: {
                  sm: '100px',
                  md: '130px',
                }}}>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{
                    fontWeight: getStylesForQuotationOption('local').fontWeight,
                    color: getStylesForQuotationOption('local').color
                  }}><span style={{display: 'block'}}>Local Commodity</span></Typography>
              </Box>
              <FormControlLabel
                value="local"
                control={<Radio />}
                label={translate('home.local')}
                disabled={!isLocalEnabled || bothDisabled }
              />
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box mb={1} sx={{
                p: 1,
                border: '2px solid',
                borderRadius: '8px',
                textAlign: 'center',
                backgroundColor: getStylesForQuotationOption('international').backgroundColor,
                borderColor: getStylesForQuotationOption('international').borderColor,
                width: {
                  sm: '100px',
                  md: '130px',
                }}}>
                <Typography variant="body2" component="div">
                        <span
                          style={{
                            fontWeight: getStylesForQuotationOption('international').fontWeight,
                            color: getStylesForQuotationOption('international').color,
                            display: 'block'
                          }}>BURSA</span>
                  <span
                    style={{
                      fontWeight: getStylesForQuotationOption('international').fontWeight,
                      color: getStylesForQuotationOption('international').redTextColor,
                      display: 'block'
                    }}>MALAYSIA</span>
                </Typography>
              </Box>
              <FormControlLabel
                value="international"
                control={<Radio />}
                label={translate('home.international')}
                disabled={!isInternationalEnabled || bothDisabled || myAccount?.authorities?.includes(AUTHORITIES.BANK_STAFF) && operationTime === false}
              />
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                {operationTimeErrorMsg()}
              </Grid>
            </Grid>
          </Grid>
        </RadioGroup>
        {bothDisabled && (
          <Typography variant="body2" color="error" sx={{ textAlign: 'center', mt: 2 }}>
            {translate('home.transactionTypeNotSet')}
          </Typography>
        )}
      </FormControl>
    );
  };




  if (myAccount?.login === undefined) {
    return (<NewLogin></NewLogin>)
  } else if (isAuthenticated && myAccount?.login !== undefined && myAccount?.otp === "1" && !otpStatus) {
    return (<div className="app-container" >
      <div style={{ padding: "20px" }}>
        <OtpPage /></div></div>);
  } else {
    return (
      <>


        <Container>
          <div>
            <div className="my-4">
              <Typography variant="h5" component="div" hidden={(myAccount?.authorities?.includes(AUTHORITIES.ADMIN))}>
                {translate('home.greetings.message')} {myAccount?.firstName} {myAccount?.lastName}!
              </Typography>
              <div hidden={!(myAccount?.authorities?.includes(AUTHORITIES.BANK_STAFF) || myAccount?.authorities?.includes(AUTHORITIES.BRANCH_MANAGER))}>
                {translate('home.branch')}: {branchForStaffAndManager?.name}
              </div>
            </div>

            {(myAccount?.authorities?.includes(AUTHORITIES.BANK_STAFF)) &&
              <Grid container spacing={2} className="chart-space">
                <Grid item xs={12} sm={4} md={12} lg={2.5}>
                  <Card className="action-paper" onClick={handlePoTablePendingTransfer}>
                    <CardActionArea onClick={() => props.history.push(`/home/#poTable`)} >
                      <CardContent sx={{ backgroundColor: '#EBF2F7' }}>
                        <Grid container spacing={2}>
                          <Grid item xs className="left-side-component-flex">
                            <PrimaryTextTypography variant="h6">
                              <strong>{quotationPendingTransferCount > 0 ? quotationPendingTransferCount : 0}</strong>
                            </PrimaryTextTypography>
                          </Grid>
                          <Grid item xs className="right-side-component-flex">
                            <ImportExportIcon className="icon-card" sx={{ color: '#01579b' }} />
                          </Grid>
                        </Grid>
                        <PrimaryTextTypography variant="body2">
                          {translate('home.topInfo.pendingTransfer.title')}&nbsp;
                          <Tooltip title={`${translate('home.topInfo.pendingTransfer.tooltip')}`}>
                            <InfoOutlinedIcon sx={{ fontSize: 14, cursor: 'pointer' }} />
                          </Tooltip>
                        </PrimaryTextTypography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={5}>
                  <Paper className="summary-paper">
                    <Typography variant="h6" component="div">{translate('home.topInfo.commodityPrice')}</Typography>
                    <TableContainer sx={{ maxHeight: 180, marginTop: '20px' }}>
                      <Table size="small">
                        <TableBody>
                          {bank2?.commodities?.map((commodityPrice: { id: number, name: string, price: number }) => (
                            <TableRow key={commodityPrice?.id}>
                              <SummaryTableCell style={{ borderBottom: "none" }}>{commodityPrice?.name}</SummaryTableCell>
                              <SummaryTableCell align="right" style={{ borderBottom: "none" }}>{commodityPrice?.price?.toLocaleString()}</SummaryTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4.5}>
                  <Paper className="summary-paper">
                    <Typography variant="h6" component="div">{translate('home.topInfo.todaysSummary.title')}</Typography>
                    <div style={{ textAlign: 'center', marginTop: '12px' }}>
                      <Typography variant="h6" component="div">
                        <strong>{totalSoldToday > 0 ? totalSoldToday.toLocaleString() : 0}</strong>
                      </Typography>
                      <Typography variant="body2" component="div">
                        <Translate contentKey="home.topInfo.todaysSummary.totalSales.title"
                          interpolate={{ currency: currencyIndex?.name }} />&nbsp;
                        <Tooltip title={`${translate('home.topInfo.todaysSummary.totalSales.tooltip')}`}>
                          <InfoOutlinedIcon sx={{ fontSize: 14, cursor: 'pointer' }} />
                        </Tooltip>
                      </Typography>
                    </div>
                    <Divider className="mt-2" variant="middle" style={{ color: '#ededed' }} />
                    <Grid container>
                      <Grid item xs className="mx-4 my-2">
                        <Typography variant="h6" component="div">
                          <strong>{quotationCreatedCount > 0 ? quotationCreatedCount : 0}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {translate('home.topInfo.todaysSummary.quotations')}
                        </Typography>
                      </Grid>
                      <div className="vertical-line" />
                      <Grid item xs className="mx-4 my-2">
                        <div>
                          <Typography variant="h6" component="div">
                            <strong>{quotationPurchaseOrderCount > 0 ? quotationPurchaseOrderCount : 0}</strong>
                          </Typography>
                          <Typography variant="body2" component="div">
                            {translate('home.topInfo.todaysSummary.po')}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Divider variant="middle" style={{ color: '#ededed' }} />
                    <Grid container>
                      <Grid item xs className="mx-4 my-2">
                        <Typography variant="h6" component="div">
                          <strong>{quotationSoldCount > 0 ? quotationSoldCount : 0}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {translate('home.topInfo.todaysSummary.soldOrders')}
                        </Typography>
                      </Grid>
                      <div className="vertical-line" />
                      <Grid item xs className="mx-4 my-2">
                        <Typography variant="h6" component="div">
                          <strong>{quotationKeepCount > 0 ? quotationKeepCount : 0}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {translate('home.topInfo.todaysSummary.keptOrders')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>}

            {(myAccount?.authorities?.includes(AUTHORITIES.BRANCH_MANAGER)) &&
              <Grid container spacing={2} className="chart-space">
                <Grid item xs={12} sm={12} md={12} lg={2.5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} lg={12}>
                      <Card className="action-paper" onClick={handlePoTablePendingApproval}>
                        <CardActionArea onClick={() => props.history.push(`/home/#poTable`)} >
                          <CardContent sx={{ backgroundColor: '#EBF2F7' }}>
                            <Grid container spacing={2}>
                              <Grid item xs className="left-side-component-flex">
                                <PrimaryTextTypography variant="h6">
                                  <strong>{quotationPOApprovalToday > 0 ? quotationPOApprovalToday : 0}</strong>
                                </PrimaryTextTypography>
                              </Grid>
                              <Grid item xs className="right-side-component-flex">
                                <PendingActionsIcon className="icon-card" sx={{ color: '#01579b' }} />
                              </Grid>
                            </Grid>
                            <PrimaryTextTypography variant="body2">
                              {translate('home.topInfo.poApproval.title')}&nbsp;
                              <Tooltip title={`${translate('home.topInfo.poApproval.tooltip')}`}>
                                <InfoOutlinedIcon sx={{ fontSize: 14, cursor: 'pointer' }} />
                              </Tooltip>
                            </PrimaryTextTypography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={12}>
                      <Card className="action-paper">
                        <CardActionArea onClick={() => props.history.push(`/cancelOrder`)} >
                          <CardContent sx={{ backgroundColor: '#FFEBF2' }}>
                            <Grid container spacing={2}>
                              <Grid item xs className="left-side-component-flex">
                                <ErrorTextTypography variant="h6">
                                  <strong>{quotationcancellationApprovalToday > 0 ? quotationcancellationApprovalToday : 0}</strong>
                                </ErrorTextTypography>
                              </Grid>
                              <Grid item xs className="right-side-component-flex">
                                <PendingActionsIcon className="icon-card" sx={{ color: '#c62828' }} />
                              </Grid>
                            </Grid>
                            <ErrorTextTypography variant="body2">
                              {translate('home.topInfo.cancellationApproval.title')}&nbsp;
                              <Tooltip title={`${translate('home.topInfo.cancellationApproval.tooltip')}`}>
                                <InfoOutlinedIcon sx={{ fontSize: 14, cursor: 'pointer' }} />
                              </Tooltip>
                            </ErrorTextTypography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={5}>
                  <Paper className="summary-paper">
                    <Typography variant="h6" component="div"><Translate contentKey="home.topInfo.bankStaffSales"
                      interpolate={{ currency: currencyIndex?.name }} /></Typography>
                    <TableContainer sx={{ maxHeight: 140, marginTop: '20px' }}>
                      <Table size="small">
                        <TableBody>
                          {eachTotalSales?.map(eachBankStaffTotalSales => (
                            <TableRow key={eachBankStaffTotalSales.id}>
                              <SummaryTableCell style={{ borderBottom: "none" }} >{eachBankStaffTotalSales.name}</SummaryTableCell>
                              <SummaryTableCell align="right" style={{ borderBottom: "none" }} >{eachBankStaffTotalSales?.totalSales?.toLocaleString()}</SummaryTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <ButtonMui onClick={() => props.history.push(`reports`)} className="mt-4" sx={{ textTransform: 'none' }}>{translate('home.topInfo.viewDetails')}</ButtonMui>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4.5}>
                  <Paper className="summary-paper">
                    <Typography variant="h6" component="div">{translate('home.topInfo.todaysSummary.title')}</Typography>
                    <div style={{ textAlign: 'center', marginTop: '12px' }}>
                      <Typography variant="h6" component="div">
                        <strong>{totalSoldToday > 0 ? totalSoldToday.toLocaleString() : 0}</strong>
                      </Typography>
                      <Typography variant="body2" component="div">
                        <Translate contentKey="home.topInfo.todaysSummary.totalSales.title"
                          interpolate={{ currency: currencyIndex?.name }} />&nbsp;
                        <Tooltip title={`${translate('home.topInfo.todaysSummary.totalSales.tooltip')}`}>
                          <InfoOutlinedIcon sx={{ fontSize: 14, cursor: 'pointer' }} />
                        </Tooltip>
                      </Typography>
                    </div>
                    <Divider className="mt-2" variant="middle" style={{ color: '#ededed' }} />
                    <Grid container>
                      <Grid item xs className="mx-4 my-2">
                        <Typography variant="h6" component="div">
                          <strong>{quotationCreatedCount > 0 ? quotationCreatedCount : 0}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {translate('home.topInfo.todaysSummary.quotations')}
                        </Typography>
                      </Grid>
                      <div className="vertical-line" />
                      <Grid item xs className="mx-4 my-2">
                        <div>
                          <Typography variant="h6" component="div">
                            <strong>{quotationPurchaseOrderCount > 0 ? quotationPurchaseOrderCount : 0}</strong>
                          </Typography>
                          <Typography variant="body2" component="div">
                            {translate('home.topInfo.todaysSummary.po')}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Divider variant="middle" style={{ color: '#ededed' }} />
                    <Grid container>
                      <Grid item xs className="mx-4 my-2">
                        <Typography variant="h6" component="div">
                          <strong>{quotationSoldCount > 0 ? quotationSoldCount : 0}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {translate('home.topInfo.todaysSummary.soldOrders')}
                        </Typography>
                      </Grid>
                      <div className="vertical-line" />
                      <Grid item xs className="mx-4 my-2">
                        <Typography variant="h6" component="div">
                          <strong>{quotationKeepCount > 0 ? quotationKeepCount : 0}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {translate('home.topInfo.todaysSummary.keptOrders')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>}

            {(myAccount?.authorities?.includes(AUTHORITIES.AREA_MANAGER)) &&
              <Grid container spacing={2} className="chart-space">
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Paper className="summary-paper">
                    <Typography variant="h6" component="div"><Translate contentKey="home.topInfo.branchesSales"
                      interpolate={{ currency: currencyIndex?.name }} /></Typography>
                    <TableContainer sx={{ maxHeight: 140, marginTop: '20px' }}>
                      <Table size="small">

                      <TableBody>
                        {eachTotalSales?.map(eachBranchTotalSales => (
                          areaManagerBranches.includes(eachBranchTotalSales.id) && (
                            <TableRow key={eachBranchTotalSales.id}>
                              <SummaryTableCell style={{ borderBottom: "none" }}>{eachBranchTotalSales.name}</SummaryTableCell>
                              <SummaryTableCell align="right" style={{ borderBottom: "none" }}>{eachBranchTotalSales?.totalSales?.toLocaleString()}</SummaryTableCell>
                            </TableRow>
                          )
                        ))}
                      </TableBody>


                      </Table>
                    </TableContainer>
                    <ButtonMui onClick={() => props.history.push(`reports`)} className="mt-4" sx={{ textTransform: 'none' }}>{translate('home.topInfo.viewDetails')}</ButtonMui>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Paper className="summary-paper">
                    <Typography variant="h6" component="div">{translate('home.topInfo.todaysSummary.title')}</Typography>
                    <div style={{ textAlign: 'center', marginTop: '12px' }}>
                      <Typography variant="h6" component="div">
                        <strong>{totalSoldToday > 0 ? totalSoldToday.toLocaleString() : 0}</strong>
                      </Typography>
                      <Typography variant="body2" component="div">
                        <Translate contentKey="home.topInfo.todaysSummary.totalSales.title"
                          interpolate={{ currency: currencyIndex?.name }} />&nbsp;
                        <Tooltip title={`${translate('home.topInfo.todaysSummary.totalSales.tooltip')}`}>
                          <InfoOutlinedIcon sx={{ fontSize: 14, cursor: 'pointer' }} />
                        </Tooltip>
                      </Typography>
                    </div>
                    <Divider className="mt-2" variant="middle" style={{ color: '#ededed' }} />
                    <Grid container>
                      <Grid item xs className="mx-4 my-2">
                        <Typography variant="h6" component="div">
                          <strong>{quotationCreatedCount > 0 ? quotationCreatedCount.toLocaleString() : 0}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {translate('home.topInfo.todaysSummary.quotations')}
                        </Typography>
                      </Grid>
                      <div className="vertical-line" />
                      <Grid item xs className="mx-4 my-2">
                        <div>
                          <Typography variant="h6" component="div">
                            <strong>{quotationPurchaseOrderCount > 0 ? quotationPurchaseOrderCount : 0}</strong>
                          </Typography>
                          <Typography variant="body2" component="div">
                            {translate('home.topInfo.todaysSummary.po')}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Divider variant="middle" style={{ color: '#ededed' }} />
                    <Grid container>
                      <Grid item xs className="mx-4 my-2">
                        <Typography variant="h6" component="div">
                          <strong>{quotationSoldCount > 0 ? quotationSoldCount : 0}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {translate('home.topInfo.todaysSummary.soldOrders')}
                        </Typography>
                      </Grid>
                      <div className="vertical-line" />
                      <Grid item xs className="mx-4 my-2">
                        <Typography variant="h6" component="div">
                          <strong>{quotationKeepCount > 0 ? quotationKeepCount : 0}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {translate('home.topInfo.todaysSummary.keptOrders')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>}

            {(myAccount?.authorities?.includes(AUTHORITIES.BANK_MANAGER) || myAccount?.authorities?.includes(AUTHORITIES.AUDITOR)) &&
              <Grid container spacing={2} className="chart-space" sx={{ justifyContent: 'center' }}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Paper className="summary-paper">
                    <Typography variant="h6" component="div"><Translate contentKey="home.topInfo.branchesSales"
                      interpolate={{ currency: currencyIndex?.name }} /></Typography>
                    <TableContainer sx={{ maxHeight: 140, marginTop: '20px' }}>
                      <Table size="small">
                        <TableBody>
                          {eachTotalSales?.map(eachBranchTotalSales => (
                            <TableRow key={eachBranchTotalSales.id}>
                              <SummaryTableCell style={{ borderBottom: "none" }} >{eachBranchTotalSales.name}</SummaryTableCell>
                              <SummaryTableCell align="right" style={{ borderBottom: "none" }} >{eachBranchTotalSales?.totalSales?.toLocaleString()}</SummaryTableCell>
                            </TableRow>
                          ))}
                        </TableBody>

                      </Table>
                    </TableContainer>
                    <ButtonMui onClick={() => props.history.push(`reports`)} className="mt-4" sx={{ textTransform: 'none' }}>{translate('home.topInfo.viewDetails')}</ButtonMui>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={5}>
                  <Paper className="summary-paper">
                    <Typography variant="h6" component="div">{translate('home.topInfo.todaysSummary.title')}</Typography>
                    <div style={{ textAlign: 'center', marginTop: '12px' }}>
                      <Typography variant="h6" component="div">
                        <strong>{totalSoldToday > 0 ? totalSoldToday.toLocaleString() : 0}</strong>
                      </Typography>
                      <Typography variant="body2" component="div">
                        <Translate contentKey="home.topInfo.todaysSummary.totalSales.title"
                          interpolate={{ currency: currencyIndex?.name }} />&nbsp;
                        <Tooltip title={`${translate('home.topInfo.todaysSummary.totalSales.tooltip')}`}>
                          <InfoOutlinedIcon sx={{ fontSize: 14, cursor: 'pointer' }} />
                        </Tooltip>
                      </Typography>
                    </div>
                    <Divider className="mt-2" variant="middle" style={{ color: '#ededed' }} />
                    <Grid container>
                      <Grid item xs className="mx-4 my-2">
                        <Typography variant="h6" component="div">
                          <strong>{quotationCreatedCount > 0 ? quotationCreatedCount : 0}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {translate('home.topInfo.todaysSummary.quotations')}
                        </Typography>
                      </Grid>
                      <div className="vertical-line" />
                      <Grid item xs className="mx-4 my-2">
                        <div>
                          <Typography variant="h6" component="div">
                            <strong>{quotationPurchaseOrderCount > 0 ? quotationPurchaseOrderCount : 0}</strong>
                          </Typography>
                          <Typography variant="body2" component="div">
                            {translate('home.topInfo.todaysSummary.po')}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Divider variant="middle" style={{ color: '#ededed' }} />
                    <Grid container>
                      <Grid item xs className="mx-4 my-2">
                        <Typography variant="h6" component="div">
                          <strong>{quotationSoldCount > 0 ? quotationSoldCount : 0}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {translate('home.topInfo.todaysSummary.soldOrders')}
                        </Typography>
                      </Grid>
                      <div className="vertical-line" />
                      <Grid item xs className="mx-4 my-2">
                        <Typography variant="h6" component="div">
                          <strong>{quotationKeepCount > 0 ? quotationKeepCount : 0}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {translate('home.topInfo.todaysSummary.keptOrders')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>}

            {(myAccount?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER)) &&
              <Grid container spacing={2} className="chart-space" sx={{ justifyContent: 'center' }}>
                <Grid item xs={12} sm={4} md={12} lg={2.5}>
                  <Card className="action-paper" onClick={handlePoTablePendingApproval}>
                    <CardActionArea onClick={() => props.history.push(`/home/#poTable`)} >
                      <CardContent sx={{ backgroundColor: '#EBF2F7' }}>
                        <Grid container spacing={2}>
                          <Grid item xs className="left-side-component-flex">
                            <PrimaryTextTypography variant="h6">
                              <strong>{quotationPOApprovalToday > 0 ? quotationPOApprovalToday : 0}</strong>
                            </PrimaryTextTypography>
                          </Grid>
                          <Grid item xs className="right-side-component-flex">
                            <PendingActionsIcon className="icon-card" sx={{ color: '#01579b' }} />
                          </Grid>
                        </Grid>
                        <PrimaryTextTypography variant="body2">
                          {translate('home.topInfo.poApproval.title')}&nbsp;
                          <Tooltip title={`${translate('home.topInfo.poApproval.tooltip')}`}>
                            <InfoOutlinedIcon sx={{ fontSize: 14, cursor: 'pointer' }} />
                          </Tooltip>
                        </PrimaryTextTypography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={5}>
                  <Paper className="summary-paper">
                    <Typography variant="h6" component="div">{translate('home.topInfo.todaysSummary.title')}</Typography>
                    <div style={{ textAlign: 'center', marginTop: '12px' }}>
                      <Typography variant="h6" component="div">
                        <strong>{totalSoldToday > 0 ? totalSoldToday.toLocaleString() : 0}</strong>
                      </Typography>
                      <Typography variant="body2" component="div">
                        <Translate contentKey="home.topInfo.todaysSummary.totalSales.title"
                          interpolate={{ currency: 'KWD' }} />&nbsp;
                        <Tooltip title={`${translate('home.topInfo.todaysSummary.totalSales.tooltip')}`}>
                          <InfoOutlinedIcon sx={{ fontSize: 14, cursor: 'pointer' }} />
                        </Tooltip>
                      </Typography>
                    </div>
                    <Divider className="mt-2" variant="middle" style={{ color: '#ededed' }} />
                    <Grid container>
                      <Grid item xs className="mx-4 my-2">
                        <Typography variant="h6" component="div">
                          <strong>{quotationCreatedCount > 0 ? quotationCreatedCount : 0}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {translate('home.topInfo.todaysSummary.quotations')}
                        </Typography>
                      </Grid>
                      <div className="vertical-line" />
                      <Grid item xs className="mx-4 my-2">
                        <div>
                          <Typography variant="h6" component="div">
                            <strong>{quotationPurchaseOrderCount > 0 ? quotationPurchaseOrderCount : 0}</strong>
                          </Typography>
                          <Typography variant="body2" component="div">
                            {translate('home.topInfo.todaysSummary.po')}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Divider variant="middle" style={{ color: '#ededed' }} />
                    <Grid container>
                      <Grid item xs className="mx-4 my-2">
                        <Typography variant="h6" component="div">
                          <strong>{quotationSoldCount > 0 ? quotationSoldCount : 0}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {translate('home.topInfo.todaysSummary.soldOrders')}
                        </Typography>
                      </Grid>
                      <div className="vertical-line" />
                      <Grid item xs className="mx-4 my-2">
                        <Typography variant="h6" component="div">
                          <strong>{quotationKeepCount > 0 ? quotationKeepCount : 0}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {translate('home.topInfo.todaysSummary.keptOrders')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>}

            {(myAccount?.authorities?.includes(AUTHORITIES.ALSHMOKH_ADMIN)) &&
              <Grid container spacing={2} className="chart-space" sx={{ justifyContent: 'center' }}>
                <Grid item xs={12} sm={12} md={12} lg={2.5} >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={4} lg={12} style={{ display: 'none' }}>
                      <Card className="action-paper">
                        <CardActionArea onClick={() => props.history.push('/cancelOrder')} >
                          <CardContent sx={{ backgroundColor: '#FFEBF2' }}>
                            <Grid container spacing={2}>
                              <Grid item xs className="left-side-component-flex">
                                <ErrorTextTypography variant="h6">
                                  <strong>{quotationcancellationApprovalToday > 0 ? quotationcancellationApprovalToday : 0}</strong>
                                </ErrorTextTypography>
                              </Grid>
                              <Grid item xs className="right-side-component-flex">
                                <PendingActionsIcon className="icon-card" sx={{ color: '#c62828' }} />
                              </Grid>
                            </Grid>
                            <ErrorTextTypography variant="body2">
                              {translate('home.topInfo.cancellationApproval.title')}&nbsp;
                              <Tooltip title={`${translate('home.topInfo.cancellationApproval.tooltip')}`}>
                                <InfoOutlinedIcon sx={{ fontSize: 14, cursor: 'pointer' }} />
                              </Tooltip>
                            </ErrorTextTypography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={12} style={{ display: 'none' }}>
                      <Card className="action-paper">
                        <CardActionArea onClick={() => props.history.push('/cancelOrder')} >
                          <CardContent sx={{ backgroundColor: '#EBF2F7' }}>
                            <Grid container spacing={2}>
                              <Grid item xs className="left-side-component-flex">
                                <PrimaryTextTypography variant="h6">
                                  <strong>{updateCancellationDecisionFromBursa > 0 ? updateCancellationDecisionFromBursa : 0}</strong>
                                </PrimaryTextTypography>
                              </Grid>
                              <Grid item xs className="right-side-component-flex">
                                <UpdateIcon className="icon-card" sx={{ color: '#01579b' }} />
                              </Grid>
                            </Grid>
                            <PrimaryTextTypography variant="body2">
                              {translate('home.topInfo.bursaDecision.title')}&nbsp;
                              <Tooltip title={`${translate('home.topInfo.bursaDecision.tooltip')}`}>
                                <InfoOutlinedIcon sx={{ fontSize: 14, cursor: 'pointer' }} />
                              </Tooltip>
                            </PrimaryTextTypography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={12}>
                      <Card className="action-paper" onClick={handlePoTable}>
                        <CardActionArea onClick={() => props.history.push(`/home/#poTable`)} >
                          <CardContent sx={{ backgroundColor: '#F1F9F1' }}>
                            <Grid container spacing={2}>
                              <Grid item xs className="left-side-component-flex">
                                <SuccessTextTypography variant="h6">
                                  <strong>{quotationKeepApprovalToday > 0 ? quotationKeepApprovalToday : 0}</strong>
                                </SuccessTextTypography>
                              </Grid>
                              <Grid item xs className="right-side-component-flex">
                                <PendingActionsIcon className="icon-card" sx={{ color: '#1b5e20' }} />
                              </Grid>
                            </Grid>
                            <SuccessTextTypography variant="body2">
                              {translate('home.topInfo.keepRequestApproval.title')}&nbsp;
                              <Tooltip title={`${translate('home.topInfo.keepRequestApproval.tooltip')}`}>
                                <InfoOutlinedIcon sx={{ fontSize: 14, cursor: 'pointer' }} />
                              </Tooltip>
                            </SuccessTextTypography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={5}>
                  <Paper className="summary-paper">
                    <Typography variant="h6" component="div">{translate('home.topInfo.todaysSummary.title')}</Typography>
                    <div style={{ textAlign: 'center', marginTop: '12px' }}>
                      <Typography variant="h6" component="div">
                        <strong>{totalSoldToday > 0 ? totalSoldToday.toLocaleString() : 0}</strong>
                      </Typography>
                      <Typography variant="body2" component="div">
                        <Translate contentKey="home.topInfo.todaysSummary.totalSales.title"
                          interpolate={{ currency: 'KWD' }} />&nbsp;
                        <Tooltip title={`${translate('home.topInfo.todaysSummary.totalSales.tooltip')}`}>
                          <InfoOutlinedIcon sx={{ fontSize: 14, cursor: 'pointer' }} />
                        </Tooltip>
                      </Typography>
                    </div>
                    <Divider className="mt-2" variant="middle" style={{ color: '#ededed' }} />
                    <Grid container>
                      <Grid item xs className="mx-4 my-2">
                        <Typography variant="h6" component="div">
                          <strong>{quotationCreatedCount > 0 ? quotationCreatedCount : 0}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {translate('home.topInfo.todaysSummary.quotations')}
                        </Typography>
                      </Grid>
                      <div className="vertical-line" />
                      <Grid item xs className="mx-4 my-2">
                        <div>
                          <Typography variant="h6" component="div">
                            <strong>{quotationPurchaseOrderCount > 0 ? quotationPurchaseOrderCount : 0}</strong>
                          </Typography>
                          <Typography variant="body2" component="div">
                            {translate('home.topInfo.todaysSummary.po')}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Divider variant="middle" style={{ color: '#ededed' }} />
                    <Grid container>
                      <Grid item xs className="mx-4 my-2">
                        <Typography variant="h6" component="div">
                          <strong>{quotationSoldCount > 0 ? quotationSoldCount : 0}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {translate('home.topInfo.todaysSummary.soldOrders')}
                        </Typography>
                      </Grid>
                      <div className="vertical-line" />
                      <Grid item xs className="mx-4 my-2">
                        <Typography variant="h6" component="div">
                          <strong>{quotationKeepCount > 0 ? quotationKeepCount : 0}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {translate('home.topInfo.todaysSummary.keptOrders')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>}

            {(myAccount?.authorities?.includes(AUTHORITIES.CUSTOMER)) &&
              <Grid container spacing={2} className="chart-space">
                <Grid item xs={12} sm={4} md={12} lg={2.5}>
                  <Card className="action-paper" onClick={handlePoTable}>
                    <CardActionArea onClick={() => props.history.push(`/home/#poTable`)} >
                      <CardContent sx={{ backgroundColor: '#F1F9F1' }}>
                        <Grid container spacing={2}>
                          <Grid item xs className="left-side-component-flex">
                            <SuccessTextTypography variant="h6">
                              <strong>{keepOrSellOrdersTodayForCustomer > 0 ? keepOrSellOrdersTodayForCustomer : 0}</strong>
                            </SuccessTextTypography>
                          </Grid>
                          <Grid item xs className="right-side-component-flex">
                            <ImportExportIcon className="icon-card" sx={{ color: '#1b5e20' }} />
                          </Grid>
                        </Grid>
                        <SuccessTextTypography variant="body2">
                          {translate('home.topInfo.keepOrSell.title')}&nbsp;
                          <Tooltip title={`${translate('home.topInfo.keepOrSell.tooltip')}`}>
                            <InfoOutlinedIcon sx={{ fontSize: 14, cursor: 'pointer' }} />
                          </Tooltip>
                        </SuccessTextTypography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={5}>
                  <Paper className="summary-paper">
                    <Typography variant="h6" component="div">{translate('home.topInfo.myCommodity')}</Typography>
                    <TableContainer sx={{ maxHeight: 180, marginTop: '20px' }}>
                      <Table size="small">
                        <TableBody>
                          {customerSoldCommodities?.map(customerSoldEachCommodity => (
                            <TableRow key={customerSoldEachCommodity.id}>
                              <SummaryTableCell style={{ borderBottom: "none" }} >{customerSoldEachCommodity.name}</SummaryTableCell>
                              <SummaryTableCell align="right" style={{ borderBottom: "none" }} >{customerSoldEachCommodity.amount.toLocaleString()}</SummaryTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4.5}>
                  <Paper className="summary-paper">
                    <Typography variant="h6" component="div">{translate('home.topInfo.todaysSummary.title')}</Typography>
                    <div style={{ textAlign: 'center', marginTop: '12px' }}>
                      <Typography variant="h6" component="div">
                        <strong>{totalSoldToday > 0 ? totalSoldToday.toLocaleString() : 0}</strong>
                      </Typography>
                      <Typography variant="body2" component="div">
                        <Translate contentKey="home.topInfo.todaysSummary.totalSales.title"
                          interpolate={{ currency: 'KWD' }} />&nbsp;
                        <Tooltip title={`${translate('home.topInfo.todaysSummary.totalSales.tooltip')}`}>
                          <InfoOutlinedIcon sx={{ fontSize: 14, cursor: 'pointer' }} />
                        </Tooltip>
                      </Typography>
                    </div>
                    <Divider className="mt-2" variant="middle" style={{ color: '#ededed' }} />
                    <Grid container>
                      <Grid item xs className="mx-4 my-2">
                        <Typography variant="h6" component="div">
                          <strong>{quotationCreatedCount > 0 ? quotationCreatedCount : 0}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {translate('home.topInfo.todaysSummary.quotations')}
                        </Typography>
                      </Grid>
                      <div className="vertical-line" />
                      <Grid item xs className="mx-4 my-2">
                        <div>
                          <Typography variant="h6" component="div">
                            <strong>{quotationPurchaseOrderCount > 0 ? quotationPurchaseOrderCount : 0}</strong>
                          </Typography>
                          <Typography variant="body2" component="div">
                            {translate('home.topInfo.todaysSummary.po')}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Divider variant="middle" style={{ color: '#ededed' }} />
                    <Grid container>
                      <Grid item xs className="mx-4 my-2">
                        <Typography variant="h6" component="div">
                          <strong>{quotationSoldCount > 0 ? quotationSoldCount : 0}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {translate('home.topInfo.todaysSummary.soldOrders')}
                        </Typography>
                      </Grid>
                      <div className="vertical-line" />
                      <Grid item xs className="mx-4 my-2">
                        <Typography variant="h6" component="div">
                          <strong>{quotationKeepCount > 0 ? quotationKeepCount : 0}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                          {translate('home.topInfo.todaysSummary.keptOrders')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>}

            <Grid container spacing={2} className="heading-space">
              <Grid item xs={12} sm={6} md={6} className="left-side-component-flex">
                <ThemeProvider theme={responsiveFont}>
                  {toggleStatusState === 'quotation' ? (
                    <Typography variant="h4" component="div">
                      {translate('home.table-title-quotation-tab')}
                    </Typography>
                  ) : (
                    <Typography variant="h4" component="div">
                      {translate('home.table-title-po-tab')}
                    </Typography>
                  )}
                </ThemeProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={6} className="right-side-component-flex">
                <div className="switch-status" onChange={toggleStatusTab}>
                  <div
                    className={toggleStatusState === 'po' ? "switch-item-active" : "switch-item"}
                    onClick={() => toggleStatusTab('po')}
                    onChange={toggleStatusTab}
                  >
                    <Translate contentKey="home.poTab" />
                  </div>
                  <div
                    className={toggleStatusState === 'quotation' ? "switch-item-active" : "switch-item"}
                    onClick={() => toggleStatusTab('quotation')}
                    onChange={toggleStatusTab}
                  >
                    <Translate contentKey="home.quotationsTab" />
                  </div>
                </div>
              </Grid>
            </Grid>

            {(myAccount?.authorities?.includes(AUTHORITIES.ADMIN) || myAccount?.authorities?.includes(AUTHORITIES.ALSHMOKH_ADMIN) || myAccount?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER)) &&
              <Grid container spacing={2} className="filter-row">
                <Grid item xs={12} sm={6} md={3}>
                  <Row className="select-row">
                    <Col>
                      <Label style={{ fontFamily: 'Roboto, sans-serif' }}>
                        <Translate contentKey='home.bank' />
                      </Label>
                    </Col>
                    <Col>
                      <Input className="options-home" type='select' width={"80%"} value={bankFilter || ''} onChange={handleBankChange} >
                        <option value={0} label={translate('home.dropdown.allDropdown')}></option>
                        {bankList?.map((bank, index) => (
                          <option value={bank?.id} key={index}>
                            {bank?.name}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </Row>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Row className="select-row">
                    <Col>
                      <Label style={{ fontFamily: 'Roboto, sans-serif' }}>
                        <Translate contentKey='home.branch' />
                      </Label>
                    </Col>
                    <Col>
                      <Input className="options-home" type='select' width={"80%"} value={branchFilter || ''} onChange={handleBranchChange}>
                        <option value={0} label={translate('home.dropdown.allDropdown')}></option>
                        {branchList?.map((branch, index) => (
                          <option value={branch?.id} key={index}>
                            {branch?.name}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </Row>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Row className="select-row">
                    <Col>
                      <Label style={{ fontFamily: 'Roboto, sans-serif' }}>
                        <Translate contentKey='home.customer' />
                      </Label>
                    </Col>
                    <Col>
                      <Input className={"options-home"} type='select' width={"80%"} value={customerFilter || ''} onChange={handleCustomerChange}>
                        <option value={0} label={translate('home.dropdown.allDropdown')}></option>
                        {customerList?.map((customer, index) => (
                          <option value={customer?.id} key={index}>
                            {customer?.name}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </Row>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Row className="select-row">
                    <Col>
                      <Label style={{ fontFamily: 'Roboto, sans-serif' }}>
                        <Translate contentKey='home.commodity' />
                      </Label>
                    </Col>
                    <Col>
                      <Input className={"options-home"} type='select' width={"80%"} value={commodityFilter || ''} onChange={e => {
                          resetFilterOnly();
                          setCommodityFilter(Number(e.target.value));
                        setPage(0);
                        if (Number(e.target.value) !== 0) {
                          sessionStorage.setItem("filterCommodity", e.target.value);
                        } else {
                          sessionStorage.removeItem("filterCommodity");
                        }
                      }}>
                        <option value={0} label={translate('home.dropdown.allDropdown')}></option>
                        {commodityList?.map((commodity, index) => (
                          <option value={commodity?.id} key={index}>
                            {commodity?.name}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </Row>
                </Grid>
              </Grid>}
            {toggleStatusState === 'po' ?  renderPoFilters() : renderQuoFilters()}

            <Paper className="paper-table mb-4" id="poTable" ref={poTableRef}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                      {quotationEntities && stableSort(rows, getComparator(order, orderBy))
                     .slice(pageNo * rowsPerPage, pageNo * rowsPerPage + rowsPerPage)
                      .filter(entity => {
                          if (filter === 'Pending Approval') {
                              return setNextStep(entity) === translate('home.nextStep.poPendingApproval');
                          } else if (filter === 'Pending Transfer') {
                              return entity.status === 'Bought';
                          }
                          return true;
                      })
                     .map((entity, i) => (
                          <StyledTableRow id={entity.id} key={`entity-${i}`}>
                            <StyledTableCell component="th" scope="row">
                              {entity?.id}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {entity?.bursaAPIBidCertId === null ? '-' : entity?.bursaAPIBidCert?.ecertNo}
                              {/* {entity?.purchaseOrder?.poNumber !== undefined ? entity?.purchaseOrder?.poNumber : '-' } */}
                            </StyledTableCell>
                            <StyledTableCell>{entity?.customer?.name}</StyledTableCell>
                            <StyledTableCell>{entity?.bank?.name}</StyledTableCell>
                            <StyledTableCell>{entity?.createdUser?.firstName} {entity?.createdUser?.lastName}</StyledTableCell>
                            <StyledTableCell>{entity?.amount?.toLocaleString()} {entity?.buyCurrency}</StyledTableCell>
                            <StyledTableCell>{moment(entity?.createdDate).format('yyyy/MM/DD')}</StyledTableCell>
                            <StyledTableCell>{setStatusTranslated(entity)}</StyledTableCell>
                            <StyledTableCell>
                              <Links
                                underline="hover"
                                onClick={() => {
                                  handleNextStepClick(entity);
                                }}
                                sx={{ fontWeight: 'light', textTransform: 'uppercase' }}
                              >
                                {setNextStep(entity)}
                              </Links>
                              &nbsp;
                              {setInformationButton(entity)}

                            </StyledTableCell>
                            <StyledTableCell>
                              <Button className={"btn-detail"}
                                color="primary"
                                onClick={() => {
                                  handleNextStepClick(entity);
                                }}
                              >
                                <Translate contentKey="home.table.detailBtn">Detail</Translate>
                              </Button>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>


              <TablePagination
                className={Storage.session.get('locale') === 'en' ? "table-pagination" : "table-pagination-arabic"}
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                page={rowsPerPage >= rows?.length ? 0 : pageNo}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={translate('home.table.rowsPerPage')}
              />
            </Paper>
            <Grid item xs={12} className="right-side-component-flex mb-3">
              <Links style={{ fontFamily: 'Roboto, sans-serif' }} onClick={() => props.history.push('/cancelOrder')} underline="hover">
                <Translate contentKey="home.cancelOrderLink" />
              </Links>
            </Grid>
          </div>
        </Container>

        <Dialog open={open} onClose={handleToClose} maxWidth={maxWidth}>
          <DialogTitle></DialogTitle>
          <DialogContent>
            <DialogContentText style={{ color: "black" }} className='mb-3'>{purchaseStatus}</DialogContentText>
          </DialogContent>
          <DialogActions className="mx-3 mb-3">
            <Button color="primary" onClick={handleToClose} autoFocus>
              {translate('comexApp.quotation.okButton')}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openModal} onClose={handleCloseModal}>
          <Box sx={{p: 2, pt: 1, width: { xs: 250, sm: 350, md: 390}}}>

            <Grid container spacing={2}>
              <Grid item xs={1}>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleCloseModal}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={11}>
                <DialogTitle sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem'}, padding: 0, paddingBottom: 2, fontWeight: 'bold' }}> {translate('home.chooseCommodity')}</DialogTitle>
              </Grid>
            </Grid>

            {renderQuotationOptions()}

            <Grid container spacing={2} sx={{ mt: 1, direction: getLocaleDirection() }}>
              <Grid item xs={2} sm={2} md={4}>
              </Grid>
              <Grid item xs={5} sm={5} md={4} sx={{ pr: getPadding() }}>
                <Button variant="outlined" onClick={handleCloseModal} fullWidth>
                  <Translate contentKey="comexApp.quotation.cancelBtnLabel">Cancel</Translate>
                </Button>
              </Grid>
              <Grid item xs={5} sm={5} md={4} sx={{ pl: getPadding() }}>
                <Link
                  to={{pathname: "/quotation/new", state: { selectedQuotationOption }}}
                  className="btn btn-primary jh-create-entity"
                  id="jh-create-entity"
                  data-cy="entityCreateButton"
                >
                  <Translate contentKey="comexApp.quotation.home.create">Create</Translate>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Dialog>

      </>
    );
  }


};

export default Home;
