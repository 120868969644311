import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IBank, defaultValue } from 'app/shared/model/bank.model';

const initialState: EntityState<IBank> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/banks';

// Actions

export const uploadImage = createAsyncThunk('bank/upload_profile_image', async (data: FormData) => {
  const requestUrl = `${apiUrl}/profile/image/upload`;
  return axios.post(requestUrl, data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
})

export const getEntities = createAsyncThunk('bank/fetch_entity_list', async ({ page, size, sort, search }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&search=${search}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IBank[]>(requestUrl);
});

export const getAllEntities = createAsyncThunk('bank/fetch_entity_all_list', async (entity: IQueryParams) => {
  const requestUrl = `${apiUrl}/all-lists`;
  return axios.get<IBank[]>(requestUrl);
});

export const getEntitiesWithoutPending = createAsyncThunk('bank/fetch_entity_list_without_pending', async ({ page, size, sort, search }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&search=${search}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IBank[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'bank/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IBank>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntityWithoutPending = createAsyncThunk(
  'bank/fetch_entity_without_pending',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IBank>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntityByBranchId = createAsyncThunk(
  'bank/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/getEntityByBranchId/${id}`;
    return axios.get<IBank>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);
export const getEntityByUserId = createAsyncThunk(
  'bank/fetch_entity_by_user_id',
  async (user_id: string | number) => {
    const requestUrl = `${apiUrl}/user-id=${user_id}`;
    return axios.get<IBank>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'bank/create_entity',
  async (entity: IBank, thunkAPI) => {
    const result = await axios.post<IBank>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'bank/update_entity',
  async (entity: IBank, thunkAPI) => {
    const result = await axios.put<IBank>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'bank/partial_update_entity',
  async (entity: IBank, thunkAPI) => {
    const result = await axios.patch<IBank>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'bank/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IBank>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const BankSlice = createEntitySlice({
  name: 'bank',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntityWithoutPending.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntityByUserId.fulfilled, (state, action) => {
        state.loading = false;
        state.entities.push(action.payload.data);
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntityByBranchId), (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })

      .addMatcher(isFulfilled(getEntities, getAllEntities, getEntitiesWithoutPending), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })

      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, getEntityByBranchId, getEntityByUserId, getAllEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(getEntitiesWithoutPending, getEntityWithoutPending), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
        state.entity = null;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = BankSlice.actions;

// Reducer
export default BankSlice.reducer;
