import React from 'react';

import '../linkcoloractive.scss';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

export const NavDropdown = props => (
  <UncontrolledDropdown nav inNavbar id={props.id} data-cy={props['data-cy']} style={{ height: '30px' }}>
    <DropdownToggle nav data-toggle="dropdown" className="linkcolor">
      <span>{props.name}</span>
    </DropdownToggle>
    <DropdownMenu end style={props.style}>
      {props.children}
    </DropdownMenu>
  </UncontrolledDropdown>
);
