import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';
import { Translate, translate, getSortState, JhiPagination, JhiItemCount, Storage } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveAs } from 'file-saver';
import './customer.scss';

import { getEntities, getEntitiesWithFilter, resetPassword  } from './customer.reducer';
import { ICustomer } from 'app/shared/model/customer.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { styled, createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";
import {Clear} from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CircleIcon from '@mui/icons-material/Circle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExcelJS from 'exceljs';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import WarningIcon from '@mui/icons-material/Warning';

import { getEntities as getBankEntities } from "app/entities/bank/bank.reducer";
import { getEntities as getBranchEntities } from "app/entities/branch/branch.reducer";

export const Customer = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();
  const userAuthorities = useAppSelector(state => state.authentication.account.authorities);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE, 'id'), props.location.search)
  );

  const customerList = useAppSelector(state => state.customer.entities);
  const bankList = useAppSelector(state => state.bank.entities);
  const branchList = useAppSelector(state => state.branch.entities);
  const loading = useAppSelector(state => state.customer.loading);
  const totalItems = useAppSelector(state => state.customer.totalItems);

  const [searchOnChange, setSearchOnChange] = useState('');
  const [searchItem, setSearchItem] = useState('');
  const [pageNo, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [bankFilter, setBankFilter] = useState(0);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const [iconColor, setIconColor] = useState<'primary' | 'error' | 'success'>('primary');

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleResetPasswordClick = (customerIc: string, customerTel: string, bankId: number | null) => {
    dispatch(resetPassword({ customerIc, bankId }))
      .then(action => {
        if (action.meta.requestStatus === 'fulfilled' && isPayloadWithStatus(action.payload)) {
          if (action.payload.status === 200) {
            const locale = Storage.session.get('locale');
            const formattedCustomerTel = locale !== 'en' ? `\u202A+${customerTel}\u202C` : `+${customerTel}`;
            setDialogMessage(translate('comexApp.customer.resetPasswordSuccess', { customerTel: formattedCustomerTel }));
            setDialogTitle(translate('comexApp.customer.resetPasswordSuccessTitle'));
            setIconColor('success');
          } else {
            let errorMessage = translate('comexApp.customer.resetPasswordFailed');
            if (action.payload.message) {
              errorMessage = action.payload.message;
            }
            setDialogMessage(errorMessage);
            setDialogTitle(translate('comexApp.customer.resetPasswordFailedTitle'));
            setIconColor('error');
            throw new Error(errorMessage);
          }
        } else {
          throw new Error(translate('comexApp.customer.resetPasswordFailed'));
        }
      })
      .catch(error => {
        let errorMessage = translate('comexApp.customer.resetPasswordFailed');
        if (error.message) {
          errorMessage = error.message;
        } else if (error.response && error.response.data && error.response.data.detail) {
          errorMessage = error.response.data.detail;
        }
        setDialogMessage(errorMessage);
        setDialogTitle(translate('comexApp.customer.resetPasswordFailedTitle'));
        setIconColor('error');
      })
      .then(() => {
        setOpenDialog(true);
        setTimeout(() => {
          setOpenDialog(false);
        }, 3000);
      });
  };

  function isPayloadWithStatus(payload: any): payload is { status: number; message?: string } {
    return payload && typeof payload.status === 'number';
  }

  const getAllEntities = () => {
    if (bankFilter !== 0) {
      dispatch(
        getEntitiesWithFilter({
          page: 0,
          size: 0,
          sort: 'id,asc',
          search: searchItem,
          bankId: bankFilter,
        })
      );
    } else {
      dispatch(
        getEntities({
          page: 0,
          size: 0,
          sort: 'id,asc',
          search: searchItem,
        })
     );
    }
  };

  const sortEntities = () => {
    getAllEntities();
    // const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    // if (props.location.search !== endURL) {
    //   props.history.push(`${props.location.pathname}${endURL}`);
    // }
  };

  useEffect(() => {
    sortEntities();
    dispatch(getBankEntities({}));
    dispatch(getBranchEntities({}));
  }, [pageNo, rowsPerPage, searchItem, bankFilter]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const { match } = props;
  let responsiveFont = createTheme();
  responsiveFont = responsiveFontSizes(responsiveFont);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgba(255,255,255,0)",
      color: theme.palette.common.white,
      fontWeight: 'bold',
      letterSpacing: 1,
      textAlign:Storage.session.get('locale') === 'en' ?'left':'right',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign:Storage.session.get('locale') === 'en' ?'left':'right',
    },
  }));

  const StyledTableCellCenter = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgba(255,255,255,0)",
      color: theme.palette.common.white,
      fontWeight: 'bold',
      letterSpacing: 1,
      textAlign:'center',
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
    '&.MuiTableSortLabel-root': {
      color: 'white',
    },
    '&.MuiTableSortLabel-root:hover': {
      color: 'white',
    },
    '&.Mui-active': {
      color: 'white',
    },
    '& .MuiTableSortLabel-icon': {
      color: 'white !important',
    },
  }));

  const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
    '&.MuiList-root': {
      padding: 0,
      backgroundColor: 'red',
    },
  }));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const customerNewList = [];

  function getBankName(entity: any) {
    const filterBank = bankList?.filter(bank => bank?.id === entity?.bankId);
    return filterBank[0]?.name;
  }

  function getBranchName(entity: any) {
    const filterBranch = branchList?.filter(branch => branch?.bank?.id === entity?.bankId);
    return filterBranch[0]?.name;
  }

  const rows = customerList?.map((obj, i)=>({
    ...obj,
    bankName: obj?.bankId ? getBankName(obj) : '-',
    branchName: obj?.bankId ? getBranchName(obj) : '-',
  }));

  customerNewList.push(rows);


  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      label: `${translate('comexApp.customer.id')}`,
      hidden: true,
      disableSorting: true,
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: `${translate('comexApp.customer.name')}`,
      disableSorting: false,
    },
    {
      id: 'ic',
      numeric: false,
      disablePadding: false,
      label: `${translate('comexApp.customer.ic')}`,
      disableSorting: false,
    },
    {
      id: 'nationality',
      numeric: false,
      disablePadding: false,
      label: `${translate('comexApp.customer.nationality')}`,
      disableSorting: false,
    },
    {
      id: 'tel',
      numeric: false,
      disablePadding: false,
      label: `${translate('comexApp.customer.tel')}`,
      disableSorting: false,
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: `${translate('comexApp.customer.email')}`,
      disableSorting: false,
    },
    {
      id: 'bank',
      numeric: false,
      disablePadding: false,
      label: `${translate('userManagement.bank')}`,
      disableSorting: false,
    },
  ];

  function EnhancedTableHead(tableHead) {
    const { order, orderBy, onRequestSort } =
      tableHead;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow className='enhanced-table-customer' sx={{ fontWeight: '500' }}>
          {headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              align="left"
              hidden={headCell.hidden}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.disableSorting? headCell.label:
                <StyledTableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </StyledTableSortLabel>
              }
            </StyledTableCell>
          ))}
          <StyledTableCellCenter>
            {translate('userManagement.action')}
          </StyledTableCellCenter>
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // setAnchorEl(event.currentTarget);
  }

  const handlePrint = () => {
    const authToken = Storage.session.get('jhi-authenticationToken');
    Storage.local.set('jhi-authenticationToken', authToken);
    localStorage.setItem('isCustomerPrint','true');
  };

  useEffect(() => {
    localStorage.setItem('isCustomerPrint','false');
  }, []);

  function addRow(ws, data, section) {
    const borderStyles = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    const row = ws.addRow(data);
    // console.log('addRow', section, data);
    row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      if (section?.border) {
        cell.border = borderStyles;
      }
      if (section?.money && typeof cell.value === 'number') {
        cell.alignment = { horizontal: 'right', vertical: 'middle' };
        cell.numFmt = '$#,##0.00;[Red]-$#,##0.00';
      }
      if (section?.alignment) {
        cell.alignment = section.alignment;
      } else {
        cell.alignment = { vertical: 'middle' };
      }
      if (section?.font) {
        cell.font = section.font;
      }
      if (section?.fill) {
        cell.fill = section.fill;
      }
    });
    if (section?.height > 0) {
      row.height = section.height;
    }
    return row;
  }

  const getDataToExportExcel = () => {
    const updatedCustomers = customerList.map(q => ({
      ...q,
      name: q?.name,
      ic: q?.ic,
      nationality: q?.nationality,
      tel: q?.tel,
      email: q?.email,
      bank: q?.bankId ? getBankName(q) : '-',
      branch: q?.bankId ? getBranchName(q) : '-',
    }));
    const dataToExport = updatedCustomers?.map( ({name, ic, nationality, tel, email, bank, branch}) => ({
      name,
      ic,
      nationality,
      tel,
      email,
      bank,
      branch
    }))
    return dataToExport;
  };

  const getDataToExportExcelArabic = () => {
    const updatedCustomers = customerList.map(q => ({
      ...q,
      name: q?.name,
      ic: q?.ic,
      nationality: q?.nationality,
      tel: q?.tel,
      email: q?.email,
      bank: q?.bankId ? getBankName(q) : '-',
      branch: q?.bankId ? getBranchName(q) : '-',
    }));
    const dataToExport = updatedCustomers?.map( ({name, ic, nationality, tel, email, bank, branch}) => ({
      name,
      ic,
      nationality,
      tel,
      email,
      bank,
      branch
    }))
    return dataToExport;
  };

  async function handleExportExcel() {
    const fileName = "customers";
    const data = getDataToExportExcel();
    const dataArabic = getDataToExportExcelArabic();
    const widths = [{width: 30}, {width: 20}, {width: 20}, {width: 20}, {width: 20}, {width: 20}];
    if(data.length > 0){
      if(Storage.session.get('locale') === 'en') {
        const wb = new ExcelJS.Workbook();
        const ws = wb.addWorksheet('My Sheet');

        const headerTitle = ["Name",
          "Ic",
          "Nationality",
          "Tel",
          "Email",
          "Bank",
          "Branch"];
        const columns = headerTitle.length;

        const headerCustom = {
          border: true,
          money: false,
          height: 30,
          font: { size: 15, bold: false, color: { argb: 'FFFFFF' } },
          alignment: { horizontal: 'center', vertical: 'middle' },
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: '01579B',
            },
          },
        };
        const dataCustom = {
          border: true,
          money: false,
          height: 0,
          font: { size: 12, bold: false, color: { argb: '000000' } },
          alignment: { horizontal: 'left', vertical: 'left' },
          fill: null,
        };
        const footerCustom = {
          border: true,
          money: false,
          height: 30,
          font: { size: 15, bold: true, color: { argb: 'FFFFFF' } },
          alignment: null,
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: '0000FF',
            },
          },
        };
        if (widths && widths.length > 0) {
          ws.columns = widths;
        }

        addRow(ws, headerTitle, headerCustom);
        data.forEach(( rowData ) => {
          addRow(ws, Object.values(rowData), dataCustom);
        })

        const buf = await wb.xlsx.writeBuffer();
        saveAs(new Blob([buf]), `${fileName}.xlsx`);

      } else {

        const wb = new ExcelJS.Workbook();
        const ws = wb.addWorksheet('My Sheet');

        const headerTitle2 = ["اسم","الرقم المدني/الوطني","الجنسية","رقم الهاتف","البريد الالكتروني","البنك","الفرع"];
        const columns = headerTitle2.length;

        const headerCustom = {
          border: true,
          money: false,
          height: 30,
          font: { size: 15, bold: false, color: { argb: 'FFFFFF' } },
          alignment: { horizontal: 'center', vertical: 'middle' },
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: '01579B',
            },
          },
        };
        const dataCustom = {
          border: true,
          money: false,
          height: 0,
          font: { size: 12, bold: false, color: { argb: '000000' } },
          alignment: { horizontal: 'right', vertical: 'right' },
          fill: null,
        };
        const footerCustom = {
          border: true,
          money: false,
          height: 30,
          font: { size: 15, bold: true, color: { argb: 'FFFFFF' } },
          alignment: { horizontal: 'right', vertical: 'right' },
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: '0000FF',
            },
          },
        };
        if (widths && widths.length > 0) {
          ws.columns = widths;
        }
        addRow(ws, headerTitle2, headerCustom);
        dataArabic.forEach(( rowData ) => {
          addRow(ws, Object.values(rowData), dataCustom);
        })

        const buf = await wb.xlsx.writeBuffer();
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
      }
    }
  };

  return (
    <Container>
      <div className="mt-4">
        <ThemeProvider theme={responsiveFont}>
          <Typography variant="h4" component="div" data-cy="CustomerHeading" className="mb-4">
            {translate('comexApp.customer.home.title')}
          </Typography>
        </ThemeProvider>
        <div style={{ flexDirection: 'row', margin: '30px 0px' }}>
          <Grid container spacing={2}>
            <Grid item md={3} sm={3} xs={12} sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <TextField size='small' value={searchOnChange} placeholder={translate('home.search')} fullWidth
                // sx={{ height: '80%' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search/>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" onClick={(e) => {
                      setSearchItem('');
                      setSearchOnChange('');
                      setPage(0);
                    }}>
                      <Clear style={{fontSize: 12}}/>
                    </InputAdornment>
                  ),
                }}
                onChange={e => {
                  setSearchOnChange(e.target.value);
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    setSearchItem(searchOnChange);
                    setPage(0);
                  }
                }}
              />
            </Grid>
            <Grid item md={3} sm={3} xs={12}>
              <Row className="select-row">
                <Col>
                  <Label style={{ fontFamily: 'Roboto, sans-serif' }}>
                    <Translate contentKey='home.bank' />
                  </Label>
                </Col>
                <Col>
                  <Input className="options-home" type='select' width={"80%"}
                  value={bankFilter || ''}
                  onChange={e => {
                    setBankFilter(Number(e.target.value));
                    setPage(0);
                  }}>
                    <option value={0} label={translate('home.dropdown.allDropdown')}></option>
                    {bankList?.map((bank, index) => (
                      <option value={bank?.id} key={index}>
                        {bank?.name}
                      </option>
                    ))}
                  </Input>
                </Col>
              </Row>
            </Grid>
            <Grid item md={6} sm={6} xs={12} sx={{ display: 'flex', justifyContent: {sm: 'flex-end'}, alignItems: 'flex-end' }}>
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggle}
                variant="contained"
                onClick={handleButtonClick}
              >
                <DropdownToggle caret color="primary"><FontAwesomeIcon icon="print" /> <Translate contentKey="global.menu.report.exportBtn"/></DropdownToggle>
                <DropdownMenu>
                  <MenuItem data-export-type="excel">
                    <div>
                      <DropdownItem onClick={handleExportExcel}>
                        <Translate contentKey="global.menu.report.excelBtn" />
                      </DropdownItem >
                    </div>
                  </MenuItem>
                  <MenuItem data-export-type="pdf">
                    <div>
                      <Link
                        to={{
                          pathname: "/customer-print",
                        }}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <DropdownItem
                        onClick={handlePrint}
                        >
                          <Translate contentKey="global.menu.report.printBtn" />
                        </DropdownItem >
                      </Link>
                    </div>
                  </MenuItem>
                </DropdownMenu>
              </Dropdown>
            </Grid>
          </Grid>
        </div>
        <Paper className="paper-table my-4">
          <TableContainer sx={{maxHeight: 440}}>
            {customerList && customerList.length > 0 ? (
              <Table sx={{minWidth: 650}}>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {customerList && stableSort(rows, getComparator(order, orderBy))
                    .slice(pageNo * rowsPerPage, pageNo * rowsPerPage + rowsPerPage)
                    .map((customer, i) => (
                      <StyledTableRow id={customer.id} key={`user-${i}`}>
                        <StyledTableCell hidden>{customer.id}</StyledTableCell>
                        <StyledTableCell>{customer.name}</StyledTableCell>
                        <StyledTableCell>{customer.ic}</StyledTableCell>
                        <StyledTableCell>{customer.nationality}</StyledTableCell>
                        <StyledTableCell>{customer.tel}</StyledTableCell>
                        <StyledTableCell>{customer.email}</StyledTableCell>
                        <StyledTableCell>{customer.bankName}</StyledTableCell>
                        <StyledTableCell>
                          <div className="btn-group flex-btn-group-container" style={{display: 'flex', justifyContent: 'center'}}>
                            <Tooltip title={translate('userManagement.viewIcon')}>
                              <span>
                                <IconButton
                                  className="px-3"
                                  aria-label="view"
                                  size="small"
                                  onClick={() => {
                                    props.history.push(`${match.url}/${customer.id}`);
                                  }}
                                  >
                                  <VisibilityIcon fontSize="small"/>
                                </IconButton>
                              </span>
                            </Tooltip>
                            <Tooltip title={translate('userManagement.editIcon')} hidden>
                              <span>
                                <IconButton
                                  className="px-3"
                                  aria-label="edit"
                                  size="small"
                                  onClick={() => {
                                    props.history.push(`${match.url}/${customer.id}/edit`);
                                  }}
                                >
                                  <EditIcon fontSize="small"/>
                                </IconButton>
                              </span>
                            </Tooltip>
                            <Tooltip title={translate('userManagement.deleteIcon')} hidden>
                              <span>
                                <IconButton
                                  className="px-3"
                                  aria-label="delete"
                                  size="small"
                                  color="error"
                                  onClick={() => {
                                    props.history.push(`${match.url}/${customer.id}/delete`);
                                  }}
                                >
                                  <DeleteIcon fontSize="small"/>
                                </IconButton>
                              </span>
                            </Tooltip>
                            {/* <Tooltip title={translate('userManagement.resetPassword')}>
                                <span>
                                  <Button
                                    className="btn-reset-password"
                                    color="primary"
                                    onClick={() => handleResetPasswordClick(customer.ic, customer.tel, customer.bankId)}
                                  >
                                    <Translate contentKey="comexApp.customer.resetPasswordBtn">Reset Password</Translate>
                                  </Button>
                                </span>
                            </Tooltip>
                            */}
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            ) : (
              !loading && (
                <div className="alert alert-warning">
                  <Translate contentKey="comexApp.customer.home.notFound">No Customers found</Translate>
                </div>
              )
            )}
          </TableContainer>
          <TablePagination
            className={Storage.session.get('locale') === 'en' ? "table-pagination" : "table-pagination-arabic"}
            rowsPerPageOptions={[5, 20, 50]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={rowsPerPage >= rows.length ? 0 : pageNo}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={translate('home.table.rowsPerPage')}
          />
        </Paper>
      </div>
      {customerList?.length > 0 && localStorage.setItem("customerPrint",JSON.stringify(customerNewList[0]))}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          <Stack direction="row" alignItems="center" spacing={1}>
            <WarningIcon color={iconColor} />
            <Typography variant="h6">{dialogTitle}</Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="subtitle1" component="span" style={{ fontWeight: 'bold' }}>
              {dialogMessage}
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default Customer;