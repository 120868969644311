import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import {RouteComponentProps} from "react-router-dom";
import {getEntity} from "app/entities/quotation/quotation.reducer";
import {useAppDispatch, useAppSelector} from "app/config/store";
import { Storage } from "react-jhipster";
import {useReactToPrint} from "react-to-print";
import {Translate} from "react-jhipster";
import {Button} from "reactstrap";

export const PurchasePrintTemplate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const componentRef = useRef();
  const quotationEntity = useAppSelector(state => state.quotation.entity);
  const [descriptionInArabic, setDescriptionInArabic] = useState('');

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, [props.match.params.id]);

  useEffect(() => {
    if(Storage.session.get('locale') === 'ar-ly'){
      const data = {
        q: quotationEntity?.description,
        source: 'en',
        target: 'ar'
      }
      axios.post('https://translate.argosopentech.com/translate', data)
        .then( (response) => {
          setDescriptionInArabic(response.data.translatedText);
        } )
    }
  }, [quotationEntity?.description]);

  useEffect(() => {
    // const input = document.getElementById('divToPrint');
    // html2canvas(input)
    //   .then((canvas) => {
    //     const imgWidth = 210;
    //     const imgHeight = canvas.height * imgWidth / canvas.width;
    //     const imgData = canvas.toDataURL('img/png');
    //     const pdf = new jsPDF('p', 'mm', 'a4');
    //     pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
    //     // pdf.output('dataurlnewwindow');
    //     if (quotationEntity?.id) {
    //       pdf.save("quotation-print.pdf");
    //     }
    //   })
    // ;

    const input = document.getElementById('divToPrint').innerHTML;
    const originalContents = document.body.innerHTML;

    if (quotationEntity?.id) {
      document.body.innerHTML = input;

      window.print();

      document.body.innerHTML = originalContents;
    }
  },[quotationEntity?.id]);

  // const printPage = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  return (
    <div className="App container mt-5" style={{height: '1100px'}}>
      <div id="divToPrint" ref={componentRef}>



        <div id="headerPrint" style={{margin: '24px 48px 24px 48px'}}>
          <Grid container spacing={2} style={{alignItems: 'center'}}>
            <Grid item xs={6} className="left-side-component-flex">
              <Typography variant='h4' component='div'>Quotation</Typography>
            </Grid>
            <Grid item xs={6} className="right-side-component-flex">
              <img src="../content/images/img.png"  width={'120px'}/>
            </Grid>
          </Grid>
        </div>

        <Divider sx={{ borderBottomWidth: 3, marginLeft: '48px', marginRight: '48px', color: '#01579b' }} />

        <div id="informationPrint" style={{margin: '24px 48px 24px 48px'}}>
          <Grid container spacing={2}>
            <Grid item xs={8} style={{ lineHeight: 1 }}>
              <Typography variant='subtitle1' ><strong>Bank</strong></Typography>
              <Typography variant='subtitle1'>{quotationEntity?.bank?.name || ''}</Typography>
              <Typography variant='subtitle1'>{quotationEntity?.branch?.name || ''}</Typography>
              <Typography variant='subtitle1'>{quotationEntity?.createdBy || ''}</Typography>
            </Grid>
            <Grid item xs={4} style={{ lineHeight: 1 }}>
              <Typography variant='subtitle1'><strong>Customer</strong></Typography>
              <Typography variant='subtitle1'>{quotationEntity?.customer?.name || ''}</Typography>
              <Typography variant='subtitle1'>{quotationEntity?.customer?.nationality || ''} </Typography>
              <Typography variant='subtitle1'>{quotationEntity?.customer?.ic || ''}</Typography>
              <Typography variant='subtitle1'>{quotationEntity?.customer?.tel || ''}</Typography>
              <Typography variant='subtitle1'>{quotationEntity?.customer?.email || ''}</Typography>
            </Grid>
          </Grid>
        </div>

        <div id='quotationTablePrint' style={{margin: '48px'}}>
          <div style={{ lineHeight: 1, marginTop: '24px', marginBottom: '12px' }}>
            <Typography variant='subtitle1'><strong>Information</strong></Typography>
            <Typography variant='subtitle1'>Quotation No : {quotationEntity?.id || ''}</Typography>
            <Typography variant='subtitle1'>Date : {quotationEntity?.createdDate || ''}</Typography>
          </div>

          <TableContainer>
            <Divider sx={{ borderBottomWidth: 3, color: '#01579b'}} />
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>Description</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Price</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Quantity</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{Storage.session.get('locale') === 'en' ? quotationEntity?.description : descriptionInArabic}</TableCell>
                  <TableCell>{quotationEntity?.commodityUnitPrice || ''} MYR</TableCell>
                  <TableCell>{quotationEntity?.commodityQuantity || ''}</TableCell>
                  <TableCell>{quotationEntity?.amount || ''} {quotationEntity?.buyCurrency || ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={2} />
                  <TableCell rowSpan={2} />
                  <TableCell><strong>Fee</strong></TableCell>
                  <TableCell>{quotationEntity?.fee} {quotationEntity?.buyCurrency || ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Total Amount</strong></TableCell>
                  <TableCell>{quotationEntity?.totalAmount || ''} {quotationEntity?.buyCurrency || ''}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div style={{marginBottom: '96px', marginTop: '96px'}}>
          <Typography variant='subtitle1' style={{margin: '48px'}}>Customer Signature: ______________</Typography>
        </div>

        <Divider sx={{ borderBottomWidth: 3, marginLeft: '48px', marginRight: '48px', color: '#01579b' }} />

        <div id="informationPrint" style={{margin: '24px 48px 24px 48px'}}>
          <Grid container>
            <Grid item xs={4} style={{ lineHeight: 1, display: 'inline-flex', justifyContent: 'center' }}>
              <div>
                {/* <Typography variant='body1' ><strong>Bank</strong></Typography> */}
              </div>
            </Grid>
            <Grid item xs={4} style={{ lineHeight: 1, display: 'inline-flex', justifyContent: 'center' }}>
              <div>
                {/* <Typography variant='body1' ><strong>Alsmokh</strong></Typography> */}
              </div>
            </Grid>
            <Grid item xs={4} style={{ lineHeight: 1, display: 'inline-flex', justifyContent: 'center' }}>
              <div>
                {/* <Typography variant='body1' ><strong>Created By</strong></Typography> */}
              </div>
            </Grid>
          </Grid>
        </div>

      </div>
    </div>
  )
}

export default PurchasePrintTemplate;
