import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './commission-log.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const CommissionLogDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const commissionLogEntity = useAppSelector(state => state.commissionLog.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="commissionLogDetailsHeading">
          <Translate contentKey="comexApp.commissionLog.detail.title">CommissionLog</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{commissionLogEntity.id}</dd>
          <dt>
            <span id="commission">
              <Translate contentKey="comexApp.commissionLog.commission">Commission</Translate>
            </span>
          </dt>
          <dd>{commissionLogEntity.commission ? 'true' : 'false'}</dd>
          <dt>
            <span id="commissionAmount">
              <Translate contentKey="comexApp.commissionLog.commissionAmount">Commission Amount</Translate>
            </span>
          </dt>
          <dd>{commissionLogEntity.commissionAmount}</dd>
          <dt>
            <span id="commissionForEachAmount">
              <Translate contentKey="comexApp.commissionLog.commissionForEachAmount">Commission For Each Amount</Translate>
            </span>
          </dt>
          <dd>{commissionLogEntity.commissionForEachAmount}</dd>
          <dt>
            <Translate contentKey="comexApp.commissionLog.bank">Bank</Translate>
          </dt>
          <dd>{commissionLogEntity.bank ? commissionLogEntity.bank.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/commission-log" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/commission-log/${commissionLogEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default CommissionLogDetail;
