import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './quotation.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const QuotationDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);


  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);


  const quotationEntity = useAppSelector(state => state.quotation.entity);
  return (account?.authorities?.includes(AUTHORITIES.ADMIN) || account?.authorities?.includes(AUTHORITIES.AUDITOR) || quotationEntity.createdBy === account?.login) && (
    <Row>
      <Col md="8">
        <h2 data-cy="quotationDetailsHeading">
          <Translate contentKey="comexApp.quotation.detail.title">Quotation</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{quotationEntity.id}</dd>
          <dt>
            <span id="amount">
              <Translate contentKey="comexApp.quotation.amount">Amount</Translate>
            </span>
          </dt>
          <dd>{quotationEntity.amount?.toLocaleString()}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="comexApp.quotation.description">Description</Translate>
            </span>
          </dt>
          <dd>{quotationEntity.description}</dd>
          <dt>
            <span id="commodityUnitPrice">
              <Translate contentKey="comexApp.quotation.commodityUnitPrice">Commodity Unit Price</Translate>
            </span>
          </dt>
          <dd>{quotationEntity.commodityUnitPrice?.toLocaleString()}</dd>
          <dt>
            <span id="commodityQuantity">
              <Translate contentKey="comexApp.quotation.commodityQuantity">Commodity Quantity</Translate>
            </span>
          </dt>
          <dd>{quotationEntity.commodityQuantity?.toLocaleString()}</dd>
          <dt>
            <span id="currencyRate">
              <Translate contentKey="comexApp.quotation.currencyRate">Currency Rate</Translate>
            </span>
          </dt>
          <dd>{quotationEntity.currencyRate?.toLocaleString()}</dd>
          <dt>
            <span id="buyCurrency">
              <Translate contentKey="comexApp.quotation.buyCurrency">Buy Currency</Translate>
            </span>
          </dt>
          <dd>{quotationEntity.buyCurrency}</dd>
          <dt>
            <span id="bankManagerApproval">
              <Translate contentKey="comexApp.quotation.bankManagerApproval">Bank Manager Approval</Translate>
            </span>
          </dt>
          <dd>{quotationEntity.bankManagerApproval ? 'true' : 'false'}</dd>
          <dt>
            <span id="bankCustomerApproval">
              <Translate contentKey="comexApp.quotation.bankCustomerApproval">Bank Customer Approval</Translate>
            </span>
          </dt>
          <dd>{quotationEntity.bankCustomerApproval ? 'true' : 'false'}</dd>
          <dt>
            <span id="smsLanguage">
              <Translate contentKey="comexApp.quotation.smsLanguage">Sms Language</Translate>
            </span>
          </dt>
          <dd>{quotationEntity.smsLanguage}</dd>
          <dt>
            <span id="smsSent">
              <Translate contentKey="comexApp.quotation.smsSent">Sms Sent</Translate>
            </span>
          </dt>
          <dd>{quotationEntity.smsSent ? 'true' : 'false'}</dd>
          {account.authorities.filter((item: string) => [AUTHORITIES.ADMIN, AUTHORITIES.AUDITOR].includes(item)).length !== 0 && <dt>
            <span id="bursaAPIBidCertId">
              <Translate contentKey="comexApp.quotation.bursaAPIBidCertId">Bursa API Bid Cert Id</Translate>
            </span>
          </dt>}
          {account.authorities.filter((item: string) => [AUTHORITIES.ADMIN, AUTHORITIES.AUDITOR].includes(item)).length !== 0 && <dd>{quotationEntity.bursaAPIBidCertId}</dd>}
          {account.authorities.filter((item: string) => [AUTHORITIES.ADMIN, AUTHORITIES.AUDITOR].includes(item)).length !== 0 && <dt>
            <span id="bursaAPIOtcCertId">
              <Translate contentKey="comexApp.quotation.bursaAPIOtcCertId">Bursa API Otc Cert Id</Translate>
            </span>
          </dt>}
          {account.authorities.filter((item: string) => [AUTHORITIES.ADMIN, AUTHORITIES.AUDITOR].includes(item)).length !== 0 && <dd>{quotationEntity.bursaAPIOtcCertId}</dd>}
          {account.authorities.filter((item: string) => [AUTHORITIES.ADMIN, AUTHORITIES.AUDITOR].includes(item)).length !== 0 && <dt>
            <span id="bursaAPIStbCertId">
              <Translate contentKey="comexApp.quotation.bursaAPIStbCertId">Bursa API Stb Cert Id</Translate>
            </span>
          </dt>}
          {account.authorities.filter((item: string) => [AUTHORITIES.ADMIN, AUTHORITIES.AUDITOR].includes(item)).length !== 0 && <dd>{quotationEntity.bursaAPIStbCertId}</dd>}
          {account.authorities.filter((item: string) => [AUTHORITIES.ADMIN, AUTHORITIES.AUDITOR].includes(item)).length !== 0 && <dt>
            <span id="stbOption">
              <Translate contentKey="comexApp.quotation.stbOption">Stb Option</Translate>
            </span>
          </dt>}
          {account.authorities.filter((item: string) => [AUTHORITIES.ADMIN, AUTHORITIES.AUDITOR].includes(item)).length !== 0 && <dd>{quotationEntity.stbOption}</dd>}
          {account.authorities.filter((item: string) => [AUTHORITIES.ADMIN, AUTHORITIES.AUDITOR].includes(item)).length !== 0 && <dt>
            <span id="comexAdminDecision">
              <Translate contentKey="comexApp.quotation.comexAdminDecision">Comex Admin Decision</Translate>
            </span>
          </dt>}
          {account.authorities.filter((item: string) => [AUTHORITIES.ADMIN, AUTHORITIES.AUDITOR].includes(item)).length !== 0 && <dd>{quotationEntity.comexAdminDecision}</dd>}
          <dt>
            <span id="status">
              <Translate contentKey="comexApp.quotation.status">Status</Translate>
            </span>
          </dt>
          <dd>{quotationEntity.status}</dd>
          <dt>
            <Translate contentKey="comexApp.quotation.customer">Customer</Translate>
          </dt>
          <dd>{quotationEntity.customer ? quotationEntity.customer.name : ''}</dd>
          <dt>
            <Translate contentKey="comexApp.quotation.commodity">Commodity</Translate>
          </dt>
          <dd>{quotationEntity.commodity ? quotationEntity.commodity.name : ''}</dd>
          <dt>
            <Translate contentKey="comexApp.quotation.branch">Branch</Translate>
          </dt>
          <dd>{quotationEntity.branch ? quotationEntity.branch.name : ''}</dd>
          <dt>
            <Translate contentKey="comexApp.quotation.purchaseOrder">Purchase Order</Translate>
          </dt>
          <dd>{quotationEntity.purchaseOrder ? quotationEntity.purchaseOrder.poNumber : ''}</dd>
          <dt>
            <Translate contentKey="comexApp.quotation.currencyIndex">Currency Index</Translate>
          </dt>
          <dd>{quotationEntity.currencyIndex ? quotationEntity.currencyIndex.name : ''}</dd>
        </dl>
        <Button tag={Link} to="/quotation" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        {account.authorities.filter((item: string) => [AUTHORITIES.BANK_MANAGER, AUTHORITIES.BRANCH_MANAGER, AUTHORITIES.BANK_STAFF].includes(item)).length !== 0 && <Button tag={Link} to={`/quotation/${quotationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>}
      </Col>
    </Row>
  );
};

export default QuotationDetail;
