export interface IIban {
  id?: number;
  ibanNumber: string;
  customerId: number;
  quotationId: number;
  ibanBankName: string;
  ibanBranchName: string;
}

export const defaultValue: Readonly<IIban> = {
  id: 0,
  ibanNumber: '',
  customerId: 0,
  quotationId: 0,
  ibanBankName: '',
  ibanBranchName: ''
}
