import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { Translate, translate } from 'react-jhipster';
import { NavDropdown } from './menu-components';
import { useAppSelector } from 'app/config/store';
import { AUTHORITIES } from 'app/config/constants';
import '../header/header.scss';

export interface IEntityProps {
  account: any;
}

export const EntitiesMenu = (props: IEntityProps) => {
  const { account } = props;
  return (
    <NavDropdown
      icon="th-list"
      name={<div className="entities-menu">{translate('global.menu.entities.main')}</div>}
      id="entity-menu"
      data-cy="entity"
      style={{ maxHeight: '80vh', overflow: 'auto' }}
    >
      <>{/* to avoid warnings when empty */}</>
      {account?.authorities?.filter((item: string) =>
        [
          AUTHORITIES.ADMIN,
          AUTHORITIES.BANK_STAFF,
          AUTHORITIES.BANK_MANAGER,
          AUTHORITIES.BRANCH_MANAGER,
          AUTHORITIES.AUDITOR,
          AUTHORITIES.ALSHMOKH_ADMIN,
        ].includes(item)
      ).length !== 0 && (
        <MenuItem icon="asterisk" to="/commodity">
          <Translate contentKey="global.menu.entities.commodity" />
        </MenuItem>
      )}
      {account?.authorities?.filter((item: string) =>
        [
          AUTHORITIES.ADMIN,
          AUTHORITIES.BANK_STAFF,
          AUTHORITIES.BANK_MANAGER,
          AUTHORITIES.BRANCH_MANAGER,
          AUTHORITIES.AUDITOR,
          AUTHORITIES.ALSHMOKH_ADMIN,
        ].includes(item)
      ).length !== 0 && (
        <MenuItem icon="asterisk" to="/customer">
          <Translate contentKey="global.menu.entities.customer" />
        </MenuItem>
      )}
      {account?.authorities?.filter((item: string) =>
        [AUTHORITIES.ADMIN, AUTHORITIES.BANK_STAFF, AUTHORITIES.AUDITOR, AUTHORITIES.ALSHMOKH_ADMIN].includes(item)
      ).length !== 0 && (
        <MenuItem icon="asterisk" to="/quotation">
          <Translate contentKey="global.menu.entities.quotation" />
        </MenuItem>
      )}
      {account?.authorities?.filter((item: string) => [AUTHORITIES.ADMIN, AUTHORITIES.ALSHMOKH_ADMIN].includes(item)).length !== 0 && (
        <MenuItem icon="asterisk" to="/bank">
          <Translate contentKey="global.menu.entities.bank" />
        </MenuItem>
      )}
      {account?.authorities?.filter((item: string) =>
        [AUTHORITIES.ADMIN, AUTHORITIES.BRANCH_MANAGER, AUTHORITIES.BANK_MANAGER, AUTHORITIES.AUDITOR, AUTHORITIES.ALSHMOKH_ADMIN].includes(
          item
        )
      ).length !== 0 && (
        <MenuItem icon="asterisk" to="/branch">
          <Translate contentKey="global.menu.entities.branch" />
        </MenuItem>
      )}
      {account?.authorities?.filter((item: string) =>
        [AUTHORITIES.ADMIN, AUTHORITIES.BANK_STAFF, AUTHORITIES.AUDITOR, AUTHORITIES.ALSHMOKH_ADMIN].includes(item)
      ).length !== 0 && (
        <MenuItem icon="asterisk" to="/currency-rates">
          <Translate contentKey="global.menu.entities.currencyRates" />
        </MenuItem>
      )}
      {account?.authorities?.filter((item: string) =>
        [AUTHORITIES.ADMIN, AUTHORITIES.BANK_STAFF, AUTHORITIES.ALSHMOKH_ADMIN].includes(item)
      ).length !== 0 && (
        <MenuItem icon="asterisk" to="/comex-settings">
          <Translate contentKey="global.menu.entities.comexSettings" />
        </MenuItem>
      )}
      {account?.authorities?.filter((item: string) => [AUTHORITIES.ADMIN, AUTHORITIES.ALSHMOKH_ADMIN].includes(item)).length !== 0 && (
        <MenuItem icon="asterisk" to="/notification-template">
          <Translate contentKey="global.menu.entities.notificationTemplate" />
        </MenuItem>
      )}
      {account?.authorities?.filter((item: string) => [AUTHORITIES.ADMIN, AUTHORITIES.ALSHMOKH_ADMIN].includes(item)).length !== 0 && (
        <MenuItem icon="asterisk" to="/notification-record">
          <Translate contentKey="global.menu.entities.notificationRecord" />
        </MenuItem>
      )}
      {account?.authorities?.filter((item: string) => [AUTHORITIES.ADMIN, AUTHORITIES.ALSHMOKH_ADMIN].includes(item)).length !== 0 && (
        <MenuItem icon="asterisk" to="/bursa-api-bid-cert">
          <Translate contentKey="global.menu.entities.bursaApiBidCert" />
        </MenuItem>
      )}
      {account?.authorities?.filter((item: string) => [AUTHORITIES.ADMIN, AUTHORITIES.ALSHMOKH_ADMIN].includes(item)).length !== 0 && (
        <MenuItem icon="asterisk" to="/bursa-api-otc-cert">
          <Translate contentKey="global.menu.entities.bursaApiOtcCert" />
        </MenuItem>
      )}
      {account?.authorities?.filter((item: string) => [AUTHORITIES.ADMIN, AUTHORITIES.ALSHMOKH_ADMIN].includes(item)).length !== 0 && (
        <MenuItem icon="asterisk" to="/bursa-api-stb-cert">
          <Translate contentKey="global.menu.entities.bursaApiStbCert" />
        </MenuItem>
      )}
      {account?.authorities?.filter((item: string) => [AUTHORITIES.ADMIN, AUTHORITIES.ALSHMOKH_ADMIN].includes(item)).length !== 0 && (
        <MenuItem icon="asterisk" to="/purchase-cancellation">
          <Translate contentKey="global.menu.entities.purchaseCancellation" />
        </MenuItem>
      )}
      {account?.authorities?.filter((item: string) => [AUTHORITIES.ADMIN, AUTHORITIES.ALSHMOKH_ADMIN].includes(item)).length !== 0 && (
        <MenuItem icon="asterisk" to="/bursa-api-order">
          <Translate contentKey="global.menu.entities.bursaApiOrder" />
        </MenuItem>
      )}
      {account?.authorities?.filter((item: string) => [AUTHORITIES.ADMIN, AUTHORITIES.ALSHMOKH_ADMIN].includes(item)).length !== 0 && (
        <MenuItem icon="asterisk" to="/bursa-api-order-result">
          <Translate contentKey="global.menu.entities.bursaApiOrderResult" />
        </MenuItem>
      )}
      {account?.authorities?.filter((item: string) => [AUTHORITIES.ADMIN, AUTHORITIES.ALSHMOKH_ADMIN].includes(item)).length !== 0 && (
        <MenuItem icon="asterisk" to="/bursa-api-end-points">
          <Translate contentKey="global.menu.entities.bursaApiEndPoints" />
        </MenuItem>
      )}
      {account?.authorities?.filter((item: string) =>
        [
          AUTHORITIES.ADMIN,
          AUTHORITIES.BANK_STAFF,
          AUTHORITIES.BANK_MANAGER,
          AUTHORITIES.BRANCH_MANAGER,
          AUTHORITIES.AUDITOR,
          AUTHORITIES.ALSHMOKH_ADMIN,
        ].includes(item)
      ).length !== 0 && (
        <MenuItem icon="asterisk" to="/currency-index">
          <Translate contentKey="global.menu.entities.currencyIndex" />
        </MenuItem>
      )}
      {account?.authorities?.filter((item: string) => [AUTHORITIES.ADMIN, AUTHORITIES.AUDITOR, AUTHORITIES.ALSHMOKH_ADMIN].includes(item))
        .length !== 0 && (
        <MenuItem icon="asterisk" to="/currency-pairs-log">
          <Translate contentKey="global.menu.entities.currencyPairsLog" />
        </MenuItem>
      )}
      {account?.authorities?.filter((item: string) =>
        [AUTHORITIES.ADMIN, AUTHORITIES.BANK_STAFF, AUTHORITIES.ALSHMOKH_ADMIN].includes(item)
      ).length !== 0 && (
        <MenuItem icon="asterisk" to="/comex-bank-fee">
          <Translate contentKey="global.menu.entities.comexBankFee" />
        </MenuItem>
      )}
      {account?.authorities?.filter((item: string) => [AUTHORITIES.ADMIN, AUTHORITIES.AUDITOR, AUTHORITIES.ALSHMOKH_ADMIN].includes(item))
        .length !== 0 && (
        <MenuItem icon="asterisk" to="/comex-bank-fee-log">
          <Translate contentKey="global.menu.entities.comexBankFeeLog" />
        </MenuItem>
      )}
      {account?.authorities?.filter((item: string) => [AUTHORITIES.ADMIN, AUTHORITIES.AUDITOR, AUTHORITIES.ALSHMOKH_ADMIN].includes(item))
        .length !== 0 && (
        <MenuItem icon="asterisk" to="/commodity-log">
          <Translate contentKey="global.menu.entities.commodityLog" />
        </MenuItem>
      )}
      {account?.authorities?.filter((item: string) =>
        [AUTHORITIES.ADMIN, AUTHORITIES.BANK_STAFF, AUTHORITIES.AUDITOR, AUTHORITIES.ALSHMOKH_ADMIN].includes(item)
      ).length !== 0 && (
        <MenuItem icon="asterisk" to="/purchase-order">
          <Translate contentKey="global.menu.entities.purchaseOrder" />
        </MenuItem>
      )}
      <MenuItem icon="asterisk" to="/notify-cancellation-emails">
        <Translate contentKey="global.menu.entities.notifyCancellationEmails" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/auth-token">
        <Translate contentKey="global.menu.entities.authToken" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/banklog">
        <Translate contentKey="global.menu.entities.banklog" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/commission-log">
        <Translate contentKey="global.menu.entities.commissionLog" />
      </MenuItem>

      <MenuItem icon="asterisk" to="/bulk-transaction">
        <Translate contentKey="global.menu.entities.bulkTransaction" />
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </NavDropdown>
  );
};
