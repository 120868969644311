import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ComexSettings from './comex-settings';
import ComexSettingsDetail from './comex-settings-detail';
import ComexSettingsUpdate from './comex-settings-update';
import ComexSettingsDeleteDialog from './comex-settings-delete-dialog';
import PrivateRoute from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

const Routes = ({ match }) => (
  <>
    <Switch>
      <PrivateRoute exact path={`${match.url}/new`} component={ComexSettingsUpdate} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <PrivateRoute exact path={`${match.url}/:id/edit`} component={ComexSettingsUpdate} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ComexSettingsDetail} />
      <ErrorBoundaryRoute path={match.url} component={ComexSettings} />
    </Switch>
    <PrivateRoute exact path={`${match.url}/:id/delete`} component={ComexSettingsDeleteDialog} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
  </>
);

export default Routes;
