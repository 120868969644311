import { ICurrencyIndex } from 'app/shared/model/currency-index.model';

export interface ICurrencyRates {
  id?: number;
  exchangeRate?: number;
  buyFrom?: ICurrencyIndex;
  sellTo?: ICurrencyIndex;
  createdBy?: string;
  createdDate?: any;
  lastModifiedBy?: any;
  lastModifiedDate?: any;
}

export const defaultValue: Readonly<ICurrencyRates> = {};
