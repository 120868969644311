import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Quotation from './quotation';
import QuotationDetail from './quotation-detail';
import QuotationUpdate from './quotation-update';
import QuotationPurchase from './quotation-purchase';
import QuotationBought from './quotation-bought';
import QuotationDeleteDialog from './quotation-delete-dialog';
import PurchaseCertificateTemplate from './purchase-certificate-template';
import SellCertificateTemplate from './sell-certificate-template';
import CancellationCertificateTemplate from './cancellation-certificate-template';
import TransferCertificateTemplate from './transfer-certificate-template';
import ApproveQuotationByBranchManager from './quotation-approve-branchmanager';
import AmendQuotationByBranchManager from './quotation-amend-branchmanager';
import PrivateRoute from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import {PurchasePrintTemplate} from "app/entities/quotation/purchase-print-template";
import BursaPurchaseCertificateTemplate from "app/entities/quotation/bursa_purchase_certificate_template";
import BursaSellCertificateTemplate from "app/entities/quotation/bursa_sell_certificate_template";
import BursaTransferCertificateTemplate from "app/entities/quotation/bursa_transfer_certificate_template";
import TransactionFormsPurchase from "app/entities/quotation/transaction-forms-purchase";
import TransactionFormsTransfer from "app/entities/quotation/transaction-forms-transfer";
import TransactionFormsSell from "app/entities/quotation/transaction-forms-sell";


const Routes = ({ match }) => {
  return (
    <>
      <Switch>
        <PrivateRoute
          exact path={`${match.url}/new`}
          component={QuotationUpdate}
          hasAnyAuthorities={[
            AUTHORITIES.BANK_STAFF
          ]}
        />
        <ErrorBoundaryRoute exact path={`${match.url}/purchase/:id`} component={QuotationPurchase} />
        <PrivateRoute exact path={`${match.url}/bought/:id`} hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.BANK_STAFF,
          AUTHORITIES.AUDITOR,
          AUTHORITIES.BANK_MANAGER,
          AUTHORITIES.AREA_MANAGER,
          AUTHORITIES.BRANCH_MANAGER,
          AUTHORITIES.CUSTOMER,
          AUTHORITIES.ALSHMOKH_ADMIN,
          AUTHORITIES.ALSHMOKH_USER,
          AUTHORITIES.PURCHASE_APPROVAL,
          AUTHORITIES.SELL_APPROVAL,
        ]} component={QuotationBought} />
        <ErrorBoundaryRoute exact path={`${match.url}/approve-by-branch-manager/:id`} component={ApproveQuotationByBranchManager} />
        <ErrorBoundaryRoute exact path={`${match.url}/amend-by-branch-manager/:id`} component={AmendQuotationByBranchManager} />
        <PrivateRoute
          exact path={`${match.url}/:id/edit`}
          component={QuotationUpdate}
          hasAnyAuthorities={[
            AUTHORITIES.BANK_STAFF,
            AUTHORITIES.AUDITOR,
            AUTHORITIES.BANK_MANAGER,
            AUTHORITIES.BRANCH_MANAGER
          ]}
        />

        <ErrorBoundaryRoute exact path={`${match.url}/purchase-print/:id`} component={PurchasePrintTemplate}/>

        <ErrorBoundaryRoute
                exact path={`${match.url}/purchase-certificate/:id`}
                component={PurchaseCertificateTemplate}/>
        <ErrorBoundaryRoute
                exact path={`${match.url}/sell-certificate/:id`}
                component={SellCertificateTemplate}/>
        <ErrorBoundaryRoute
                        exact path={`${match.url}/cancellation-certificate/:id`}
                        component={CancellationCertificateTemplate}/>
        <ErrorBoundaryRoute
          exact path={`${match.url}/bursa-purchase-certificate/:id`}
          component={BursaPurchaseCertificateTemplate}/>
        <ErrorBoundaryRoute
          exact path={`${match.url}/bursa-sell-certificate/:id`}
          component={BursaSellCertificateTemplate}/>
        <ErrorBoundaryRoute
          exact path={`${match.url}/bursa-transfer-certificate/:id`}
          component={BursaTransferCertificateTemplate}/>
        <ErrorBoundaryRoute
                        exact path={`${match.url}/transfer-certificate/:id`}
                        component={TransferCertificateTemplate}/>
        <ErrorBoundaryRoute
          exact path={`${match.url}/transaction-forms-purchase/:id`}
          component={TransactionFormsPurchase}/>
        <ErrorBoundaryRoute
          exact path={`${match.url}/transaction-forms-transfer/:id`}
          component={TransactionFormsTransfer}/>
        <ErrorBoundaryRoute
          exact path={`${match.url}/transaction-forms-sell/:id`}
          component={TransactionFormsSell}/>
        <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={QuotationDetail} />
        <ErrorBoundaryRoute path={match.url} component={Quotation} />

      </Switch>
      <PrivateRoute
        exact path={`${match.url}/:id/delete`}
        component={QuotationDeleteDialog}
        hasAnyAuthorities={[
          AUTHORITIES.BANK_STAFF,
          AUTHORITIES.AUDITOR,
          AUTHORITIES.BANK_MANAGER,
          AUTHORITIES.BRANCH_MANAGER
        ]}
      />
    </>
  );
}


export default Routes;
