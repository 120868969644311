import { IBank } from './bank.model';

export interface ICustomer {
  id?: number;
  name?: string;
  ic?: string;
  nationality?: string;
  tel?: string;
  email?: string;
  bank?: IBank;
  bankId?:number;
}

export interface ICustomerQueryParam {
  ic?: string;
  bId?: number | string;
}

export const defaultValue: Readonly<ICustomer> = {};
