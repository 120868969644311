import axios from 'axios';
import { createAsyncThunk, createSlice, isPending, isRejected } from '@reduxjs/toolkit';

import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';

const initialState = {
  loading: false,
  resetPasswordSuccess: false,
  resetPasswordFailure: false,
  successMessage: null,
  statusNumber: 0,
  isUpdated: false,
};

export type PasswordResetState = Readonly<typeof initialState>;

const apiUrl = 'api/account/reset-password';
// Actions

export const handlePasswordResetInit = createAsyncThunk(
  'passwordReset/reset_password_init',
  // If the content-type isn't set that way, axios will try to encode the body and thus modify the data sent to the server.
  async (mail: string) => axios.post(`${apiUrl}/init`, mail, { headers: { ['Content-Type']: 'text/plain' } }),
  { serializeError: serializeAxiosError }
);

export const handlePasswordResetFinish = createAsyncThunk(
  'passwordReset/reset_password_finish',
  async (data: { key: string; newPassword: string }) => {
    const result = await axios.post(`${apiUrl}/finish`, data);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const handlePasswordResetByPhoneNo = createAsyncThunk(
  'passwordReset/reset_password_by_phone_number',
  async (phoneNumber: string) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios.post(`${apiUrl}/byPhoneNumber?phoneNumber=${encodeURIComponent(phoneNumber)}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  { serializeError: serializeAxiosError }
);

export const PasswordResetSlice = createSlice({
  name: 'passwordReset',
  initialState: initialState as PasswordResetState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(handlePasswordResetInit.fulfilled, () => ({
        ...initialState,
        loading: false,
        resetPasswordSuccess: true,
        // successMessage: 'reset.request.messages.success',
      }))
      .addCase(handlePasswordResetFinish.fulfilled, (state, action) => {
        state.loading = false;
        state.resetPasswordSuccess= true;
        state.successMessage = 'reset.finish.messages.success';
        state.statusNumber = action.payload.status;
        state.isUpdated = true;
      })
      .addCase(handlePasswordResetByPhoneNo.fulfilled, (state, action) => {
        state.loading = false;
        state.resetPasswordSuccess= true;
        state.successMessage = 'reset.finish.messages.success';
        state.isUpdated = true;
      })
      .addMatcher(isPending(handlePasswordResetInit, handlePasswordResetFinish, handlePasswordResetByPhoneNo), state => {
        state.loading = true;
      })
      .addMatcher(isRejected(handlePasswordResetInit, handlePasswordResetFinish, handlePasswordResetByPhoneNo), () => ({
        ...initialState,
        loading: false,
        resetPasswordFailure: true,
      }));
  },
});

export const { reset } = PasswordResetSlice.actions;

// Reducer
export default PasswordResetSlice.reducer;
