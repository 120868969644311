import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import BursaAPIOrder from './bursa-api-order';
import BursaAPIOrderDetail from './bursa-api-order-detail';
import BursaAPIOrderUpdate from './bursa-api-order-update';
import BursaAPIOrderDeleteDialog from './bursa-api-order-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={BursaAPIOrderUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={BursaAPIOrderUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={BursaAPIOrderDetail} />
      <ErrorBoundaryRoute path={match.url} component={BursaAPIOrder} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={BursaAPIOrderDeleteDialog} />
  </>
);

export default Routes;
