import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './notification-record.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const NotificationRecordDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const notificationRecordEntity = useAppSelector(state => state.notificationRecord.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="notificationRecordDetailsHeading">
          <Translate contentKey="comexApp.notificationRecord.detail.title">NotificationRecord</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{notificationRecordEntity.id}</dd>
          <dt>
            <span id="message">
              <Translate contentKey="comexApp.notificationRecord.message">Message</Translate>
            </span>
          </dt>
          <dd>{notificationRecordEntity.message}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="comexApp.notificationRecord.status">Status</Translate>
            </span>
          </dt>
          <dd>{notificationRecordEntity.status}</dd>
          <dt>
            <span id="customerID">
              <Translate contentKey="comexApp.notificationRecord.customerID">Customer ID</Translate>
            </span>
          </dt>
          <dd>{notificationRecordEntity.customerID}</dd>
          <dt>
            <span id="userID">
              <Translate contentKey="comexApp.notificationRecord.userID">User ID</Translate>
            </span>
          </dt>
          <dd>{notificationRecordEntity.userID}</dd>
          <dt>
            <span id="quotationId">
              <Translate contentKey="comexApp.notificationRecord.quotationId">Quotation Id</Translate>
            </span>
          </dt>
          <dd>{notificationRecordEntity.quotationId}</dd>
          <dt>
            <Translate contentKey="comexApp.notificationRecord.notificationTemplate">Notification Template</Translate>
          </dt>
          <dd>{notificationRecordEntity.notificationTemplate ? notificationRecordEntity.notificationTemplate.type : ''}</dd>
        </dl>
        <Button tag={Link} to="/notification-record" replace data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/notification-record/${notificationRecordEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default NotificationRecordDetail;
