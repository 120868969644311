import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity, updateEntity, createEntity, reset } from './bursa-api-order.reducer';
import { IBursaAPIOrder } from 'app/shared/model/bursa-api-order.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const BursaAPIOrderUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const bursaAPIOrderEntity = useAppSelector(state => state.bursaAPIOrder.entity);
  const loading = useAppSelector(state => state.bursaAPIOrder.loading);
  const updating = useAppSelector(state => state.bursaAPIOrder.updating);
  const updateSuccess = useAppSelector(state => state.bursaAPIOrder.updateSuccess);
  const handleClose = () => {
    props.history.push('/bursa-api-order' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...bursaAPIOrderEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...bursaAPIOrderEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="comexApp.bursaAPIOrder.home.createOrEditLabel" data-cy="BursaAPIOrderCreateUpdateHeading">
            <Translate contentKey="comexApp.bursaAPIOrder.home.createOrEditLabel">Create or edit a BursaAPIOrder</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="bursa-api-order-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('comexApp.bursaAPIOrder.endpointURL')}
                id="bursa-api-order-endpointURL"
                name="endpointURL"
                data-cy="endpointURL"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrder.memberShortName')}
                id="bursa-api-order-memberShortName"
                name="memberShortName"
                data-cy="memberShortName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 8, message: translate('entity.validation.maxlength', { max: 8 }) },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrder.uuid')}
                id="bursa-api-order-uuid"
                name="uuid"
                data-cy="uuid"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrder.serialNumber')}
                id="bursa-api-order-serialNumber"
                name="serialNumber"
                data-cy="serialNumber"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrder.bidOption')}
                id="bursa-api-order-bidOption"
                name="bidOption"
                data-cy="bidOption"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrder.stbOption')}
                id="bursa-api-order-stbOption"
                name="stbOption"
                data-cy="stbOption"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrder.otcOption')}
                id="bursa-api-order-otcOption"
                name="otcOption"
                data-cy="otcOption"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrder.productCode')}
                id="bursa-api-order-productCode"
                name="productCode"
                data-cy="productCode"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrder.purchaseType')}
                id="bursa-api-order-purchaseType"
                name="purchaseType"
                data-cy="purchaseType"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrder.clientName')}
                id="bursa-api-order-clientName"
                name="clientName"
                data-cy="clientName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrder.currency')}
                id="bursa-api-order-currency"
                name="currency"
                data-cy="currency"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrder.bidValue')}
                id="bursa-api-order-bidValue"
                name="bidValue"
                data-cy="bidValue"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrder.valueDate')}
                id="bursa-api-order-valueDate"
                name="valueDate"
                data-cy="valueDate"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrder.tenor')}
                id="bursa-api-order-tenor"
                name="tenor"
                data-cy="tenor"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrder.otcCounterParty')}
                id="bursa-api-order-otcCounterParty"
                name="otcCounterParty"
                data-cy="otcCounterParty"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrder.otcMurabaha')}
                id="bursa-api-order-otcMurabaha"
                name="otcMurabaha"
                data-cy="otcMurabaha"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrder.otcMurabahaValue')}
                id="bursa-api-order-otcMurabahaValue"
                name="otcMurabahaValue"
                data-cy="otcMurabahaValue"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrder.ecertNo')}
                id="bursa-api-order-ecertNo"
                name="ecertNo"
                data-cy="ecertNo"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrder.errorCode')}
                id="bursa-api-order-errorCode"
                name="errorCode"
                data-cy="errorCode"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrder.errorMsg')}
                id="bursa-api-order-errorMsg"
                name="errorMsg"
                data-cy="errorMsg"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrder.statusCode')}
                id="bursa-api-order-statusCode"
                name="statusCode"
                data-cy="statusCode"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrder.statusMessage')}
                id="bursa-api-order-statusMessage"
                name="statusMessage"
                data-cy="statusMessage"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrder.quotationId')}
                id="bursa-api-order-quotationId"
                name="quotationId"
                data-cy="quotationId"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/bursa-api-order" replace>
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default BursaAPIOrderUpdate;
