import { ICustomer } from 'app/shared/model/customer.model';
import { ICommodity } from 'app/shared/model/commodity.model';
import { IBranch } from 'app/shared/model/branch.model';
import { IPurchaseOrder } from 'app/shared/model/purchase-order.model';
import { ICurrencyIndex } from 'app/shared/model/currency-index.model';
import { IBank } from "app/shared/model/bank.model";
import { IBursaAPIBidCert } from "app/shared/model/bursa-api-bid-cert.model";
import { IBursaAPIOtcCert } from "app/shared/model/bursa-api-otc-cert.model";
import { IBursaAPIStbCert } from "app/shared/model/bursa-api-stb-cert.model";
import { IUser } from './user.model';

export interface IQuotation {
  createdDate?: string;
  formattedCreatedDate?: string;
  lastModifiedDate?: string;
  id?: number;
  amount?: number | 0;
  description?: string | null;
  commodityUnitPrice?: number;
  commodityQuantity?: number;
  fee?: number | 0;
  commission?: number | 0;
  transactionFee?: number | 0;
  totalAmount?: number | 0;
  currencyRate?: number;
  buyCurrency?: string;
  bankManagerApproval?: boolean | null;
  bankCustomerApproval?: boolean | null;
  smsLanguage?: string | null;
  smsSent?: boolean | null;
  bursaAPIBidCertId?: number | null;
  bursaAPIOtcCertId?: number | null;
  bursaAPIStbCertId?: number | null;
  bursaAPIBidCert?: IBursaAPIBidCert;
  bursaAPIOtcCert?: IBursaAPIOtcCert;
  bursaAPIStbCert?: IBursaAPIStbCert;
  stbOption?: string | null;
  comexAdminDecision?: string | null;
  status?: string;
  customer?: ICustomer;
  commodity?: ICommodity;
  commodityId?: number;
  branch?: IBranch;
  bank?:IBank;
  purchaseOrderId?: number;
  purchaseOrder?: IPurchaseOrder | null;
  currencyIndex?: ICurrencyIndex;
  currencyIndexId?: number;
  cancelOrder?: boolean | null;
  nextStep?: string;
  createdBy?: string;
  approvedBy?: number | null;
  approvedDate?: string | null;
  day?:string;
  totalValueSold?: number;
  customerId?: number;
  date?: string;
  totalPo?: number;
  total?: number;
  branchName?:string;
  approver?: IUser | null;
  createdUser?: IUser | null;
  approverName?: string | null;
  comexCreated?:boolean;
  feeCurrencyIndex?: string;
  feeInQuotationCurrency?: number;
  bulkProcessRunning?:boolean;
}

export interface IQuotationCustomer {
  id?: string | number;
  fromDateReq?:string | Date;
  toDateReq?:string | Date;
}

export const defaultValue: Readonly<IQuotation> = {
  bankManagerApproval: false,
  bankCustomerApproval: false,
  smsSent: false,
};

export interface IValueSold {
  day:string,
  totalAmount:number,
}

export interface IPerformance {
  total?: number;
  date?: string;
}

