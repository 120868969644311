import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './bursa-api-bid-cert.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const BursaAPIBidCertDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const bursaAPIBidCertEntity = useAppSelector(state => state.bursaAPIBidCert.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="bursaAPIBidCertDetailsHeading">
          <Translate contentKey="comexApp.bursaAPIBidCert.detail.title">BursaAPIBidCert</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{bursaAPIBidCertEntity.id}</dd>
          <dt>
            <span id="endpointURL">
              <Translate contentKey="comexApp.bursaAPIBidCert.endpointURL">Endpoint URL</Translate>
            </span>
          </dt>
          <dd>{bursaAPIBidCertEntity.endpointURL}</dd>
          <dt>
            <span id="ecertNo">
              <Translate contentKey="comexApp.bursaAPIBidCert.ecertNo">Ecert No</Translate>
            </span>
          </dt>
          <dd>{bursaAPIBidCertEntity.ecertNo}</dd>
          <dt>
            <span id="memberShortName">
              <Translate contentKey="comexApp.bursaAPIBidCert.memberShortName">Member Short Name</Translate>
            </span>
          </dt>
          <dd>{bursaAPIBidCertEntity.memberShortName}</dd>
          <dt>
            <span id="successYN">
              <Translate contentKey="comexApp.bursaAPIBidCert.successYN">Success YN</Translate>
            </span>
          </dt>
          <dd>{bursaAPIBidCertEntity.successYN}</dd>
          <dt>
            <span id="msg">
              <Translate contentKey="comexApp.bursaAPIBidCert.msg">Msg</Translate>
            </span>
          </dt>
          <dd>{bursaAPIBidCertEntity.msg}</dd>
          <dt>
            <span id="buyer">
              <Translate contentKey="comexApp.bursaAPIBidCert.buyer">Buyer</Translate>
            </span>
          </dt>
          <dd>{bursaAPIBidCertEntity.buyer}</dd>
          <dt>
            <span id="owner">
              <Translate contentKey="comexApp.bursaAPIBidCert.owner">Owner</Translate>
            </span>
          </dt>
          <dd>{bursaAPIBidCertEntity.owner}</dd>
          <dt>
            <span id="bidNo">
              <Translate contentKey="comexApp.bursaAPIBidCert.bidNo">Bid No</Translate>
            </span>
          </dt>
          <dd>{bursaAPIBidCertEntity.bidNo}</dd>
          <dt>
            <span id="totalValue">
              <Translate contentKey="comexApp.bursaAPIBidCert.totalValue">Total Value</Translate>
            </span>
          </dt>
          <dd>{bursaAPIBidCertEntity.totalValue}</dd>
          <dt>
            <span id="currency">
              <Translate contentKey="comexApp.bursaAPIBidCert.currency">Currency</Translate>
            </span>
          </dt>
          <dd>{bursaAPIBidCertEntity.currency}</dd>
          <dt>
            <span id="price">
              <Translate contentKey="comexApp.bursaAPIBidCert.price">Price</Translate>
            </span>
          </dt>
          <dd>{bursaAPIBidCertEntity.price}</dd>
          <dt>
            <span id="priceMYR">
              <Translate contentKey="comexApp.bursaAPIBidCert.priceMYR">Price MYR</Translate>
            </span>
          </dt>
          <dd>{bursaAPIBidCertEntity.priceMYR}</dd>
          <dt>
            <span id="purchaseTimeDate">
              <Translate contentKey="comexApp.bursaAPIBidCert.purchaseTimeDate">Purchase Time Date</Translate>
            </span>
          </dt>
          <dd>{bursaAPIBidCertEntity.purchaseTimeDate}</dd>
          <dt>
            <span id="valueDate">
              <Translate contentKey="comexApp.bursaAPIBidCert.valueDate">Value Date</Translate>
            </span>
          </dt>
          <dd>{bursaAPIBidCertEntity.valueDate}</dd>
          <dt>
            <span id="pName">
              <Translate contentKey="comexApp.bursaAPIBidCert.pName">P Name</Translate>
            </span>
          </dt>
          <dd>{bursaAPIBidCertEntity.pName}</dd>
          <dt>
            <span id="pVolume">
              <Translate contentKey="comexApp.bursaAPIBidCert.pVolume">P Volume</Translate>
            </span>
          </dt>
          <dd>{bursaAPIBidCertEntity.pVolume}</dd>
          <dt>
            <span id="supplier">
              <Translate contentKey="comexApp.bursaAPIBidCert.supplier">Supplier</Translate>
            </span>
          </dt>
          <dd>{bursaAPIBidCertEntity.supplier}</dd>
          <dt>
            <span id="volume">
              <Translate contentKey="comexApp.bursaAPIBidCert.volume">Volume</Translate>
            </span>
          </dt>
          <dd>{bursaAPIBidCertEntity.volume}</dd>
        </dl>
        <Button tag={Link} to="/bursa-api-bid-cert" replace data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/bursa-api-bid-cert/${bursaAPIBidCertEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default BursaAPIBidCertDetail;
