import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './bursa-api-order.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const BursaAPIOrderDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const bursaAPIOrderEntity = useAppSelector(state => state.bursaAPIOrder.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="bursaAPIOrderDetailsHeading">
          <Translate contentKey="comexApp.bursaAPIOrder.detail.title">BursaAPIOrder</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderEntity.id}</dd>
          <dt>
            <span id="endpointURL">
              <Translate contentKey="comexApp.bursaAPIOrder.endpointURL">Endpoint URL</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderEntity.endpointURL}</dd>
          <dt>
            <span id="memberShortName">
              <Translate contentKey="comexApp.bursaAPIOrder.memberShortName">Member Short Name</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderEntity.memberShortName}</dd>
          <dt>
            <span id="uuid">
              <Translate contentKey="comexApp.bursaAPIOrder.uuid">Uuid</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderEntity.uuid}</dd>
          <dt>
            <span id="serialNumber">
              <Translate contentKey="comexApp.bursaAPIOrder.serialNumber">Serial Number</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderEntity.serialNumber}</dd>
          <dt>
            <span id="bidOption">
              <Translate contentKey="comexApp.bursaAPIOrder.bidOption">Bid Option</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderEntity.bidOption}</dd>
          <dt>
            <span id="stbOption">
              <Translate contentKey="comexApp.bursaAPIOrder.stbOption">Stb Option</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderEntity.stbOption}</dd>
          <dt>
            <span id="otcOption">
              <Translate contentKey="comexApp.bursaAPIOrder.otcOption">Otc Option</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderEntity.otcOption}</dd>
          <dt>
            <span id="productCode">
              <Translate contentKey="comexApp.bursaAPIOrder.productCode">Product Code</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderEntity.productCode}</dd>
          <dt>
            <span id="purchaseType">
              <Translate contentKey="comexApp.bursaAPIOrder.purchaseType">Purchase Type</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderEntity.purchaseType}</dd>
          <dt>
            <span id="clientName">
              <Translate contentKey="comexApp.bursaAPIOrder.clientName">Client Name</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderEntity.clientName}</dd>
          <dt>
            <span id="currency">
              <Translate contentKey="comexApp.bursaAPIOrder.currency">Currency</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderEntity.currency}</dd>
          <dt>
            <span id="bidValue">
              <Translate contentKey="comexApp.bursaAPIOrder.bidValue">Bid Value</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderEntity.bidValue}</dd>
          <dt>
            <span id="valueDate">
              <Translate contentKey="comexApp.bursaAPIOrder.valueDate">Value Date</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderEntity.valueDate}</dd>
          <dt>
            <span id="tenor">
              <Translate contentKey="comexApp.bursaAPIOrder.tenor">Tenor</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderEntity.tenor}</dd>
          <dt>
            <span id="otcCounterParty">
              <Translate contentKey="comexApp.bursaAPIOrder.otcCounterParty">Otc Counter Party</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderEntity.otcCounterParty}</dd>
          <dt>
            <span id="otcMurabaha">
              <Translate contentKey="comexApp.bursaAPIOrder.otcMurabaha">Otc Murabaha</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderEntity.otcMurabaha}</dd>
          <dt>
            <span id="otcMurabahaValue">
              <Translate contentKey="comexApp.bursaAPIOrder.otcMurabahaValue">Otc Murabaha Value</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderEntity.otcMurabahaValue}</dd>
          <dt>
            <span id="ecertNo">
              <Translate contentKey="comexApp.bursaAPIOrder.ecertNo">Ecert No</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderEntity.ecertNo}</dd>
          <dt>
            <span id="errorCode">
              <Translate contentKey="comexApp.bursaAPIOrder.errorCode">Error Code</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderEntity.errorCode}</dd>
          <dt>
            <span id="errorMsg">
              <Translate contentKey="comexApp.bursaAPIOrder.errorMsg">Error Msg</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderEntity.errorMsg}</dd>
          <dt>
            <span id="statusCode">
              <Translate contentKey="comexApp.bursaAPIOrder.statusCode">Status Code</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderEntity.statusCode}</dd>
          <dt>
            <span id="statusMessage">
              <Translate contentKey="comexApp.bursaAPIOrder.statusMessage">Status Message</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderEntity.statusMessage}</dd>
          <dt>
            <span id="quotationId">
              <Translate contentKey="comexApp.bursaAPIOrder.quotationId">Quotation Id</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderEntity.quotationId}</dd>
        </dl>
        <Button tag={Link} to="/bursa-api-order" replace data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/bursa-api-order/${bursaAPIOrderEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default BursaAPIOrderDetail;
