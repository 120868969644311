export interface IBursaAPIEndPoints {
  id?: number;
  order?: string;
  orderResult?: string;
  bidCert?: string;
  otcCert?: string;
  stbCert?: string;
  authCode?:string;
  authToken?:string;
  refreshToken?:string;
}

export const defaultValue: Readonly<IBursaAPIEndPoints> = {};
