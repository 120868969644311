import React, { useEffect, useLayoutEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { logout } from 'app/shared/reducers/authentication';
import Container from '@mui/material/Container';
import { Storage } from "react-jhipster";
import { setLocale } from "app/shared/reducers/locale";
import { isRTL } from 'app/config/translation';
import { Redirect } from "react-router-dom";


export const Logout = () => {
  const logoutUrl = useAppSelector(state => state.authentication.logoutUrl);
  const [redirect, setRedirect] = useState(false);
  const dispatch = useAppDispatch();
  useLayoutEffect(() => {
    dispatch(logout());
    setRedirect(true);
  });

  function redirectTo() {
    return <Redirect to="/login" />;
  }

 if (redirect) return redirectTo();
  return (
      <Container>
      <p>Log out successfully!</p>
      </Container>
  );
};

export default Logout;
