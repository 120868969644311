import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ICommodity, defaultValue, ICommodityRequest, ITopCommodities, ICustomerCommoditiesSold } from 'app/shared/model/commodity.model';

const initialState: EntityState<ICommodity> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  topCommodities:[] ,
  commoditiesPriceList:[],
  customerSoldCommodities:[],
};

const apiUrl = 'api/commodities';


// Actions

export const getEntities = createAsyncThunk('commodity/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<ICommodity[]>(requestUrl);
});

export const getAllEntities = createAsyncThunk('commodity/fetch_entity_all_list', async (entity: IQueryParams) => {
  const requestUrl = `${apiUrl}/all-lists`;
  return axios.get<ICommodity[]>(requestUrl);
});


export const getCommoditiesByBankId = createAsyncThunk('commodity/fetch_entity_list_by_bankId', async (bankId:number) => {
  const requestUrl = `${apiUrl}/getCommoditiesByBankId/${bankId}`;
  return axios.get<ICommodity[]>(requestUrl);
});

export const getCommoditiesPriceList = createAsyncThunk('commodity/fetch_entity_pricelist_', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<ICommodity[]>(requestUrl);
});
export const getEntity = createAsyncThunk(
  'commodity/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ICommodity>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getTopCommodities = createAsyncThunk(
  'commodity/fetch_top_commodities',
  async ({bankId,branchId,bankUserId,fromDate,toDate} : ICommodityRequest) => {
    const requestUrl = `${apiUrl}/getTopCommodities${`?bankId=${bankId}&branchId=${branchId}&bankUserId=${bankUserId}&fromDate=${fromDate}&toDate=${toDate}`}`;
    return axios.get<ITopCommodities[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);
export const getUniqueCommoditiesByBankIdInQuotation = createAsyncThunk(
  'commodity/fetch_unique_entities_by_bank_id_in_quotation',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/getUniqueCommoditiesByBankIdInQuotation/${id}`;
    return axios.get<ICommodity[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);


export const getUniqueCommoditiesByBranchIdInQuotation = createAsyncThunk(
  'commodity/fetch_unique_entities_by_bank_id_in_quotation',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/getUniqueCommoditiesByBranchIdInQuotation/${id}`;
    return axios.get<ICommodity[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);



export const getCommoditiesForCustomer = createAsyncThunk(
  'commodity/fetch_entities_by_customer_id',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/getCommoditiesForCustomer/${id}`;
    return axios.get<ICustomerCommoditiesSold[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);


export const createEntity = createAsyncThunk(
  'commodity/create_entity',
  async (entity: ICommodity, thunkAPI) => {
    const result = await axios.post<ICommodity>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'commodity/update_entity',
  async (entity: ICommodity, thunkAPI) => {
    const result = await axios.put<ICommodity>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'commodity/partial_update_entity',
  async (entity: ICommodity, thunkAPI) => {
    const result = await axios.patch<ICommodity>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'commodity/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<ICommodity>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export type CommodityState = Readonly<typeof initialState>;
// slice

export const CommoditySlice = createEntitySlice({
  name: 'commodity',
  initialState: initialState as CommodityState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getAllEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getCommoditiesPriceList), (state, action) => {
        return {
          ...state,
          loading: false,
          commoditiesPriceList: action.payload.data,
        };
      })
      .addMatcher(isFulfilled(getCommoditiesByBankId), (state, action) => {
        state.loading=false;
        state.entities = action.payload.data;
      })

      .addMatcher(isFulfilled(getCommoditiesForCustomer), (state, action) => {
        state.customerSoldCommodities = action.payload.data;
      })
      .addMatcher(isFulfilled(getUniqueCommoditiesByBankIdInQuotation), (state, action) => {
        state.entities = action.payload.data;
      })
      .addMatcher(isFulfilled(getTopCommodities), (state, action) => {
        state.topCommodities=null;
        state.topCommodities = action.payload.data;
      })
      .addMatcher(isFulfilled(getUniqueCommoditiesByBranchIdInQuotation), (state, action) => {
        state.entities = action.payload.data;
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getAllEntities, getCommoditiesForCustomer, getCommoditiesPriceList, getUniqueCommoditiesByBankIdInQuotation, getTopCommodities, getUniqueCommoditiesByBranchIdInQuotation, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = CommoditySlice.actions;

// Reducer
export default CommoditySlice.reducer;
