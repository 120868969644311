import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './comex-bank-fee.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const ComexBankFeeDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const userAuthorities = useAppSelector(state => state.authentication.account.authorities);

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const comexBankFeeEntity = useAppSelector(state => state.comexBankFee.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="comexBankFeeDetailsHeading">
          <Translate contentKey="comexApp.comexBankFee.detail.title">ComexBankFee</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{comexBankFeeEntity.id}</dd>
          <dt>
            <span id="fromTo">
              <Translate contentKey="comexApp.comexBankFee.fromTo">From To</Translate>
            </span>
          </dt>
          <dd>{comexBankFeeEntity.fromTo?.toLocaleString()}</dd>
          <dt>
            <span id="feeType">
              <Translate contentKey="comexApp.comexBankFee.feeType">Fee Type</Translate>
            </span>
          </dt>
          <dd>{comexBankFeeEntity.feeType}</dd>
          <dt>
            <span id="amount">
              <Translate contentKey="comexApp.comexBankFee.amount">Amount</Translate>
            </span>
          </dt>
          <dd>{comexBankFeeEntity.amount?.toLocaleString()}</dd>
          <dt>
            <Translate contentKey="comexApp.comexBankFee.bank">Bank</Translate>
          </dt>
          <dd>{comexBankFeeEntity.bank ? comexBankFeeEntity.bank.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/comex-bank-fee" replace data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        {userAuthorities.includes(AUTHORITIES.ADMIN) && <Button tag={Link} to={`/comex-bank-fee/${comexBankFeeEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>}
      </Col>
    </Row>
  );
};

export default ComexBankFeeDetail;
