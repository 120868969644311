import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { IUser, defaultValue } from 'app/shared/model/user.model';
import { IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import {IComexBankFee} from "app/shared/model/comex-bank-fee.model";
import {cleanEntity} from "app/shared/util/entity-utils";
import {getEntities} from "app/entities/comex-bank-fee/comex-bank-fee.reducer";
import { AUTHORITIES } from 'app/config/constants';
import {checkCredentials} from "app/entities/bursa-api-end-points/bursa-api-end-points.reducer";
import {IUserRoleAccess} from "app/shared/model/user-role-access.model";

const initialState = {
  loading: false,
  errorMessage: null,
  users: [] as Array<IUser>,
  accountsByBranchId: [] as ReadonlyArray<IUser>,
  authorities: [] as any[],
  user: defaultValue,
  branchManager: defaultValue,
  branchManagers:[],
  bankStaff:defaultValue,
  alshmokhAdmin: defaultValue,
  allAlshmokhAdmin: [],
  allAlshmokhStaff: [],
  bankManager: defaultValue,
  alshmokhStaff:defaultValue,
  customer:defaultValue,
  updating: false,
  updateSuccess: false,
  totalItems: 0,
  areaManager:defaultValue,
  verifyExistingEmailUser:defaultValue,
  verifyExistingPhoneUser:defaultValue,
  verifyExistingLoginUsername:defaultValue,
  verifyOTP:false,
  accountsUser: [] as ReadonlyArray<IUser>,
  emailExists:false,
  phoneExists:false,
  userRoleAccessList: [] as Array<IUserRoleAccess>
};

const apiUrl = 'api/users';
const allRolesApiUrl = 'api/users/allRoles';
const adminUrl = 'api/admin/users';
const alsmokhAdminAndStaffUrl = 'api/all/users';
const userUrlForLangKey="api/users/langKey";
const branchApiUrl = 'api/users/branch';
const adminUrlForLangKey="api/admin/users/langKey";
const adminUrlForSubAdmin="api/admin/users/authoritiesSubAdmin";
const alshmokhAdminUrlForSubAdmin="api/alshmokhAdmin/users/authoritiesSubAdmin";
const alshmokhAdminUrl = 'api/alshmokhAdmin/users';
const alshmokhStaffUrl = 'api/alshmokhStaff/users';
const alshmokhAdminUrlForSubAdminUserRoleAccess="api/usersRoleAccess";
// Async Actions

export const getUsers = createAsyncThunk('userManagement/fetch_users', async ({ page, size, sort, search }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&search=${search}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IUser[]>(requestUrl);
});

export const getAllBankStaffFromBranch = createAsyncThunk('userManagement/fetch_bank_staff_by_branch', async (branchId: number) => {
  const requestUrl = `api/users/bankStaff/${branchId}`;
  return axios.get<IUser[]>(requestUrl);
})

export const getAllUsersAsAlshmokhAdmin = createAsyncThunk('userManagement/fetch_users', async ({ page, size, sort, search }: IQueryParams) => {
  const requestUrl = `${alshmokhAdminUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&search=${search}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IUser[]>(requestUrl);
});

export const getAllUsersAsAlshmokhStaff = createAsyncThunk('userManagement/fetch_users', async ({ page, size, sort, search }: IQueryParams) => {
  const requestUrl = `${alshmokhStaffUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&search=${search}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IUser[]>(requestUrl);
});

export const getUserListByBranchId = createAsyncThunk('userManagement/fetch_users/branch', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${branchApiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return axios.get<IUser[]>(requestUrl);
});

export const getBankStaffListByBranchList = createAsyncThunk('userManagement/fetch_user_by_branch_list', async (branchList: string[]) => {
  const requestUrl = `api/users/bankStaff?branch_list=${branchList}`;
  return axios.get<IUser[]>(requestUrl);
})

export const getBranchManagerUser = createAsyncThunk('userManagement/fetch_user/branchManager', async (branchId: number) => {
  const requestUrl = `api/account/branchManager/${branchId}`;
  return axios.get<IUser[]>(requestUrl);
});

export const getAlshmokhAdminAcc = createAsyncThunk('userManagement/fetch_user/alshmokhAdmin', async () => {
  const requestUrl = `api/account/AlshmokhAdmin/`;
  return axios.get<IUser>(requestUrl);
});

export const getAllAlshmokhAdminUsers = createAsyncThunk('userManagement/fetch_user/allAlshmokhAdmin', async () => {
  const requestUrl = `api/account/allAlshmokhAdmin/`;
  return axios.get<IUser[]>(requestUrl);
});

export const getAllAlshmokhStaffUsers = createAsyncThunk('userManagement/fetch_user/allAlshmokhStaff', async () => {
  const requestUrl = `api/account/allAlshmokhStaff/`;
  return axios.get<IUser[]>(requestUrl);
});

export const getAccountsByBranchId = createAsyncThunk('userManagement/fetch_accounts/branch', async (branchId:number) => {
  const requestUrl = `api/accounts/branch/${branchId}`;
  return axios.get<IUser[]>(requestUrl);
});
// export const getUserListByBranchId = createAsyncThunk(
//   'userManagement/fetch_users',
//   async ({ page, size, sort, branchId }: IQueryParams) => {
//   const requestUrl = `${branchApiUrl}${branchId}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}`;
//   return axios.get<IUser[]>(requestUrl);
// });

// export const getUserListByBranchId = createAsyncThunk(
//   'branch/fetch_user_list_by_branch_id',
//   async (branchId: number | string) => {
//     const requestUrl = `${apiUrl}/getUserListByBranchId/${branchId}`;
//     return axios.get<IUser[]>(requestUrl);
//   },
//   { serializeError: serializeAxiosError }
// );
export const getVerifyExistingEmailUser = createAsyncThunk("accounts/fetch_user_for_verifying_email", (email: string) => {
  return axios.get<IUser>(`api/verifyExistingEmailUser/${email}`)
});

export const getVerifyExistingPhoneUser = createAsyncThunk("accounts/fetch_user_for_verifying_phone_number", (phone: string) => {
  return axios.get<IUser>(`api/verifyExistingPhoneUser/${phone}`)
});

export const getVerifyExistingLoginUsername = createAsyncThunk("accounts/fetch_user_for_verifying_name", (login: string) => {
  return axios.get<IUser>(`api/verifyExistingLoginUsername/${login}`)
});

export const getBranchManagerByUserId = createAsyncThunk("accounts/fetch_branch_manager_by_userid", (id: number) => {
  return axios.get<IUser>(`api/getBranchManagerByUserId/${id}`)
});
export const getUserByLogin = createAsyncThunk("accounts/fetch_user_by_login", (login: string) => {
  return axios.get<IUser>(`api/account/${login}`)
});

export const getUsersAsAdmin = createAsyncThunk('userManagement/fetch_users_as_admin', async ({ page, size, sort, search }: IQueryParams) => {
  const requestUrl = `${adminUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&search=${search}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IUser[]>(requestUrl);
});

export const getUsersAsAlmoskhAdminAndStaff = createAsyncThunk('userManagement/fetch_users_as_alsmokh_admin_user', async ({ page, size, sort, search }: IQueryParams) => {
  const requestUrl = `${alsmokhAdminAndStaffUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&search=${search}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IUser[]>(requestUrl);
});

export const getRoles = createAsyncThunk('userManagement/fetch_roles', async () => {
  return axios.get<any[]>(`api/authorities`);
});

export const verifyOTP = createAsyncThunk("accounts/verify_otp", (otp: string) => {
  return axios.post<boolean>(`api/verifyOTP/${otp}`)
});

export const generateOTPByLogin = createAsyncThunk("accounts/fetch_otp_by_login", (login: string) => {
  return axios.get<IUser>(`api/otp/${login}`)
});

export const getUser = createAsyncThunk(
  'userManagement/fetch_user',
  async (id: string) => {
    const requestUrl = `${adminUrl}/${id}`;
    return axios.get<IUser>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getUserByIdAsAdmin = createAsyncThunk(
  'userManagement/fetch_user_by_id',
  async (id: number) => {
    const requestUrl = `api/admin/getUserById/${id}`;
    return axios.get<IUser>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getUserByIdAsOtherRoles = createAsyncThunk(
  'userManagement/fetch_user_by_id',
  async (id: number) => {
    const requestUrl = `api/getUserById/${id}`;
    return axios.get<IUser>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getUserByAlshmokhAdmin = createAsyncThunk(
  'userManagement/fetch_user',
  async (id: string) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IUser>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getUserByAlshmokhStaff = createAsyncThunk(
  'userManagement/fetch_user',
  async (id: string) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IUser>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createUserByAlshmokhAdmin = createAsyncThunk(
  'userManagement/create_user_by_alshmokh_admin',
  async (user: IUser, thunkAPI) => {
    const result = await axios.post<IUser>(apiUrl, user);
    thunkAPI.dispatch(getUsers({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateUser = createAsyncThunk(
  'userManagement/update_user',
  async (user: IUser, thunkAPI) => {
    const result = await axios.put<IUser>(adminUrl, user);
    thunkAPI.dispatch(getUsersAsAdmin({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateUserByAlshmokhAdmin = createAsyncThunk(
  'userManagement/update_user_by_alshmokh_admin',
  async (user: IUser, thunkAPI) => {
    const result = await axios.put<IUser>(alshmokhAdminUrl, user);
    thunkAPI.dispatch(getAllUsersAsAlshmokhAdmin({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateUserByAlshmokhStaff = createAsyncThunk(
  'userManagement/update_user_by_alshmokh_staff',
  async (user: IUser, thunkAPI) => {
    const result = await axios.put<IUser>(alshmokhStaffUrl, user);
    thunkAPI.dispatch(getAllUsersAsAlshmokhStaff({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateUserByOtherRoles = createAsyncThunk(
  'userManagement/update_user_by_alshmokh_admin',
  async (data: { login: string; email: string; password: string; langKey?: string; firstName?: string; authorities?: string[]; }, thunkAPI) => {
    const result = await axios.put<IUser>(apiUrl, data);
    thunkAPI.dispatch(getUsers({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);
export const deleteUserByAlshmokhAdmin = createAsyncThunk(
  'userManagement/delete_user_by_alshmokh_admin',
  async (id: string, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IUser>(requestUrl);
    thunkAPI.dispatch(getAllUsersAsAlshmokhAdmin({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteUserByAlshmokhStaff = createAsyncThunk(
  'userManagement/delete_user_by_alshmokh_staff',
  async (id: string, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IUser>(requestUrl);
    thunkAPI.dispatch(getAllUsersAsAlshmokhStaff({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const createUser = createAsyncThunk(
  'userManagement/create_user',
  async (user: IUser, thunkAPI) => {
    const result = await axios.post<IUser>(adminUrl, user);
    thunkAPI.dispatch(getUsersAsAdmin({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateAuthoritiesSubAdmin = createAsyncThunk(
  'userManagement/update_authorities_subAdmin',
  async (user: IUser) => {
    const result = await axios.put<IUser>(adminUrlForSubAdmin, user);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateAuthoritiesSubAdminAsAlshmokhAdmin = createAsyncThunk(
  'userManagement/update_authorities_subAdmin_As_AlshmokhAdmin',
  async (user: IUser) => {
    const result = await axios.put<IUser>(alshmokhAdminUrlForSubAdmin, user);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateUserRoleAccess = createAsyncThunk(
  'userManagement/update_user_role_access',
  async (payload: { userId: number; userRoleAccessListData: IUserRoleAccess[] }) => {
    const { userId, userRoleAccessListData } = payload;
    const url = `${alshmokhAdminUrlForSubAdminUserRoleAccess}?userId=${userId}`;
    const response = await axios.put<IUserRoleAccess[]>(url, userRoleAccessListData);
    return response.data;
  },
  { serializeError: serializeAxiosError }
);

export const getUserRoleAccessByUserId = createAsyncThunk(
  'userManagement/fetch_user_role_access_by_user_id',
  async (userId: number) => {
    const url = `${alshmokhAdminUrlForSubAdminUserRoleAccess}/${userId}`;
    const response = await axios.get<IUserRoleAccess[]>(url);
    return response.data;
  },
  { serializeError: serializeAxiosError }
);


export const updateUserLanguage = createAsyncThunk(
  'userManagement/update_user_language',
  async (user: IUser) => {
    const result = await axios.put<IUser>(adminUrlForLangKey, user);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateUserLanguageForAllRoles = createAsyncThunk(
  'userManagement/update_user_language_for_all_roles',
  async (user: IUser) => {
    const result = await axios.put<IUser>(userUrlForLangKey, user);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteUser = createAsyncThunk(
  'userManagement/delete_user',
  async (id: string, thunkAPI) => {
    const requestUrl = `${adminUrl}/${id}`;
    const result = await axios.delete<IUser>(requestUrl);
    thunkAPI.dispatch(getUsersAsAdmin({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);


export const checkEmailExistsExceptUser = createAsyncThunk(
    'userManagement/checkEmailExistsExceptUser',
    async ({ userId, email }: { userId: number; email: string }) => {
        const requestUrl = `/api/users/checkEmailExistsExceptUser/?userId=${userId}&email=${encodeURIComponent(email)}`;
        const response = await axios.get<boolean>(requestUrl);
        return response.data;
    },
    { serializeError: serializeAxiosError }
);

export const checkPhoneExistsExceptUser = createAsyncThunk(
  'userManagement/checkPhoneExistsExceptUser',
  async ({ userId, phone }: { userId: number; phone: string }) => {
    const requestUrl = `/api/users/checkPhoneExistsExceptUser/?userId=${userId}&phone=${phone}`;
    const response = await axios.get<boolean>(requestUrl);
    return response.data;
  },
  { serializeError: serializeAxiosError }
);


export type UserManagementState = Readonly<typeof initialState>;

export const UserManagementSlice = createSlice({
  name: 'userManagement',
  initialState: initialState as UserManagementState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getRoles.fulfilled, (state, action) => {
        state.authorities = action.payload.data;
      })
      .addCase((getUser.fulfilled, getUserByAlshmokhAdmin.fulfilled), (state, action) => {
        state.loading = false;
        state.user = action.payload.data;
        if(action.payload.data?.authorities?.includes(AUTHORITIES.AREA_MANAGER))
          state.areaManager = action.payload.data;
        if(action.payload.data?.authorities?.includes(AUTHORITIES.ALSHMOKH_ADMIN))
          state.alshmokhAdmin=action.payload.data;
        if(action.payload.data?.authorities?.includes(AUTHORITIES.BANK_STAFF))
          state.bankStaff=action.payload.data;
        if(action.payload.data?.authorities?.includes(AUTHORITIES.BRANCH_MANAGER))
          state.branchManager=action.payload.data;
        if(action.payload.data?.authorities?.includes(AUTHORITIES.BANK_MANAGER))
          state.bankManager=action.payload.data;
        if(action.payload.data?.authorities?.includes(AUTHORITIES.CUSTOMER))
          state.customer=action.payload.data;
        if(action.payload.data?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER))
          state.alshmokhStaff=action.payload.data;
      })

      .addCase((getUserByIdAsAdmin.fulfilled, getUserByIdAsOtherRoles.fulfilled), (state, action) => {
        state.loading = false;
        state.user=action.payload.data;
        if(action.payload.data?.authorities?.includes(AUTHORITIES.AREA_MANAGER))
          state.areaManager = action.payload.data;
        if(action.payload.data?.authorities?.includes(AUTHORITIES.ALSHMOKH_ADMIN))
          state.alshmokhAdmin=action.payload.data;
        if(action.payload.data?.authorities?.includes(AUTHORITIES.BANK_STAFF))
          state.bankStaff=action.payload.data;
        if(action.payload.data?.authorities?.includes(AUTHORITIES.BRANCH_MANAGER))
          state.branchManager=action.payload.data;
        if(action.payload.data?.authorities?.includes(AUTHORITIES.BANK_MANAGER))
          state.bankManager=action.payload.data;
        if(action.payload.data?.authorities?.includes(AUTHORITIES.CUSTOMER))
          state.customer=action.payload.data;
        if(action.payload.data?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER))
          state.alshmokhStaff=action.payload.data;
      })
      .addCase((getVerifyExistingEmailUser.fulfilled), (state, action) => {
        state.loading = false;
        state.verifyExistingEmailUser = action.payload.data;
      })
      .addCase((getVerifyExistingPhoneUser.fulfilled), (state, action) => {
        state.loading = false;
        state.verifyExistingPhoneUser = action.payload.data;
      })
      .addCase((getVerifyExistingLoginUsername.fulfilled), (state, action) => {
        state.loading = false;
        state.verifyExistingLoginUsername = action.payload.data;
      })
      .addCase((getBranchManagerByUserId.fulfilled), (state, action) => {
        state.loading = false;
        state.branchManager = action.payload.data;
      })

      .addCase((getUserByLogin.fulfilled), (state, action) => {
        state.loading = false;
        state.user = action.payload.data;
        if(action.payload.data?.authorities?.includes(AUTHORITIES.BANK_STAFF))
          state.bankStaff=action.payload.data;
      })
      .addCase((getBranchManagerUser.fulfilled), (state, action) => {
        state.loading = false;
        state.branchManagers = action.payload.data;
      })
      .addCase((getAlshmokhAdminAcc.fulfilled), (state, action) => {
        state.loading = false;
        state.alshmokhAdmin = action.payload.data;
      })
      .addCase((getAllAlshmokhAdminUsers.fulfilled), (state, action) => {
        state.loading = false;
        state.allAlshmokhAdmin = action.payload.data;
      })
      .addCase((getAllAlshmokhStaffUsers.fulfilled), (state, action) => {
        state.loading = false;
        state.allAlshmokhStaff = action.payload.data;
      })
      .addCase((generateOTPByLogin.fulfilled), (state, action) => {
        state.loading = false;
        state.user = action.payload.data;
      })
      .addCase((verifyOTP.fulfilled), (state, action) => {
        state.loading = false;
        state.updateSuccess=true;
        state.updating=false;
        state.verifyOTP = action.payload.data;
      })
        .addCase(checkEmailExistsExceptUser.fulfilled, (state, action) => {
            state.updateSuccess = action.payload;
            state.loading = false;
            state.emailExists = action.payload;
        })
      .addCase(checkPhoneExistsExceptUser.fulfilled, (state, action) => {
        state.updateSuccess = action.payload;
        state.loading = false;
        state.phoneExists = action.payload;
      })
      .addCase(getUserRoleAccessByUserId.fulfilled, (state, action) => {
        state.loading = false;
        state.userRoleAccessList = action.payload;
      })
      .addMatcher(isFulfilled(deleteUser, deleteUserByAlshmokhAdmin, deleteUserByAlshmokhStaff), state => {
        state.updating = false;
        state.updateSuccess = true;
        state.user = defaultValue;
      })
      .addMatcher(isFulfilled(getUsers, getUsersAsAdmin, getUserListByBranchId, getAllUsersAsAlshmokhAdmin, getAllUsersAsAlshmokhStaff), (state, action) => {
        state.loading = false;
        state.users = action.payload.data;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addMatcher(isFulfilled(getAccountsByBranchId), (state, action) => {
        state.loading = false;
        state.accountsByBranchId = action.payload.data;
      })
      .addMatcher(isFulfilled(getAllBankStaffFromBranch, getBankStaffListByBranchList), (state, action) => {
        state.loading = false;
        state.users = action.payload.data;
      })
      .addMatcher(isFulfilled(createUser, createUserByAlshmokhAdmin, updateUser, updateUserLanguage, updateUserLanguageForAllRoles, updateUserByAlshmokhAdmin, updateUserByAlshmokhStaff, updateUserByOtherRoles, updateAuthoritiesSubAdmin, updateAuthoritiesSubAdminAsAlshmokhAdmin), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.user = action.payload.data;
      })
      .addMatcher(isFulfilled(updateUserRoleAccess), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.userRoleAccessList = action.payload;
      })
      .addMatcher(isPending(getUsers, getAllUsersAsAlshmokhAdmin, getAllUsersAsAlshmokhStaff, getUsersAsAdmin, getUserByIdAsAdmin, getUserByIdAsOtherRoles, getUserByAlshmokhAdmin, getUser, getUserListByBranchId, getBranchManagerUser, getAlshmokhAdminAcc, getAccountsByBranchId, getAllAlshmokhAdminUsers, getAllAlshmokhStaffUsers), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createUser, createUserByAlshmokhAdmin, updateUser,verifyOTP, updateUserByAlshmokhAdmin, updateUserByAlshmokhStaff, updateUserLanguage, updateUserLanguageForAllRoles, updateUserByOtherRoles, updateAuthoritiesSubAdmin, updateAuthoritiesSubAdminAsAlshmokhAdmin, deleteUser, deleteUserByAlshmokhAdmin, deleteUserByAlshmokhStaff, updateUserRoleAccess), state => {

        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isRejected(getUsers,getBranchManagerByUserId, verifyOTP,getAllUsersAsAlshmokhAdmin, getAllUsersAsAlshmokhStaff, getVerifyExistingLoginUsername, getVerifyExistingEmailUser, getVerifyExistingPhoneUser, getUsersAsAdmin, getUserByIdAsAdmin, getUserByIdAsOtherRoles, getUserListByBranchId, getUser, getUserByAlshmokhAdmin, getRoles, createUser, updateUser,updateUserLanguage, updateUserLanguageForAllRoles, updateAuthoritiesSubAdmin, updateAuthoritiesSubAdminAsAlshmokhAdmin, deleteUser, createUserByAlshmokhAdmin, updateUserByAlshmokhAdmin, updateUserByAlshmokhStaff, updateUserByOtherRoles, deleteUserByAlshmokhAdmin, deleteUserByAlshmokhStaff
        , getBranchManagerUser, getAlshmokhAdminAcc, getAccountsByBranchId, getAllAlshmokhAdminUsers, getAllAlshmokhStaffUsers, updateUserRoleAccess), (state, action) => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      })
      .addMatcher(isFulfilled(getUsersAsAlmoskhAdminAndStaff, getUsersAsAdmin), (state, action) => {
        state.loading = false;
        state.accountsUser = action.payload.data;
      })
  },
});

export const { reset } = UserManagementSlice.actions;

// Reducer
export default UserManagementSlice.reducer;

