import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Loadable from 'react-loadable';
import Register from 'app/modules/account/register/register';
import Activate from 'app/modules/account/activate/activate';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import Logout from 'app/modules/login/logout';
import Home from 'app/modules/home/home';
import Entities from 'app/entities';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';
import CancelOrder from "app/modules/cancelOrder/cancelOrder";
import Login from "app/modules/login/login";
import Reports from "app/modules/reports/reports";
import reportPrintTemplate from "app/modules/reports/report-print-template";
import ReportPrintTemplate from "app/modules/reports/report-print-template";
import FeeReports from "app/modules/reports/fee-reports";
import FeeReportPrintTemplate from "app/modules/reports/fee-report-print-template";
import OtpPage from './modules/account/otp/otp';
import Commodity from "app/entities/commodity";
import Customer from "app/entities/customer";
import User from "app/modules/administration/user-management";
import CustomerPrintTemplate from "app/entities/customer/customer-print-template";
import UserPrintTemplate from "app/modules/administration/user-management/user-management-print-template";
import Quotation from "app/entities/quotation";
import Bank from "app/entities/bank";
import Branch from "app/entities/branch";
import CurrencyRates from "app/entities/currency-rates";
import ComexSettings from "app/entities/comex-settings";
import NotificationTemplate from "app/entities/notification-template";
import NotificationRecord from "app/entities/notification-record";
import BursaAPIBidCert from "app/entities/bursa-api-bid-cert";
import BursaAPIOtcCert from "app/entities/bursa-api-otc-cert";
import BursaAPIStbCert from "app/entities/bursa-api-stb-cert";
import PurchaseCancellation from "app/entities/purchase-cancellation";
import BursaAPIOrder from "app/entities/bursa-api-order";
import BursaAPIOrderResult from "app/entities/bursa-api-order-result";
import BursaAPIEndPoints from "app/entities/bursa-api-end-points";
import CurrencyIndex from "app/entities/currency-index";
import CurrencyPairsLog from "app/entities/currency-pairs-log";
import ComexBankFee from "app/entities/comex-bank-fee";
import ComexBankFeeLog from "app/entities/comex-bank-fee-log";
import CommodityLog from "app/entities/commodity-log";
import PurchaseOrder from "app/entities/purchase-order";
import NotifyCancellationEmails from "app/entities/notify-cancellation-emails";
import AuthToken from "app/entities/auth-token";
import Banklog from "app/entities/banklog";

import CommissionLog from 'app/entities/commission-log';
const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => <div>loading ...</div>,
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => <div>loading ...</div>,
});

const UploadTransaction = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/upload-transaction'),
  loading: () => <div>loading ...</div>,
});

const Routes = () => {
  return (
    <div className="view-routes">
      <Switch>
        <ErrorBoundaryRoute path="/login" component={Login} />
        <ErrorBoundaryRoute path="/logout" component={Logout} />
        <ErrorBoundaryRoute path="/account/register" component={Register} />
        <ErrorBoundaryRoute path="/account/activate/:key?" component={Activate} />
        <ErrorBoundaryRoute path="/account/reset/request" component={PasswordResetInit} />
        <ErrorBoundaryRoute path="/account/reset/finish/:key?" component={PasswordResetFinish} />
        <ErrorBoundaryRoute path="/account/otp" component={OtpPage}/>
        <PrivateRoute path="/admin" component={Admin}
            hasAnyAuthorities={[
            AUTHORITIES.ADMIN,
            AUTHORITIES.USER,
            AUTHORITIES.BANK_STAFF,
            AUTHORITIES.BANK_MANAGER,
            AUTHORITIES.BRANCH_MANAGER,
            AUTHORITIES.AUDITOR,
            AUTHORITIES.ALSHMOKH_ADMIN,
            AUTHORITIES.ALSHMOKH_USER,
            AUTHORITIES.AREA_MANAGER,
            AUTHORITIES.CUSTOMER,
          ]}
        />
        <ErrorBoundaryRoute path="/account" component={Account} />
        <ErrorBoundaryRoute path="/" exact component={Login} />
        <PrivateRoute path="/home" component={Home} />
        <ErrorBoundaryRoute path="/cancelOrder" exact component={CancelOrder} />
        <PrivateRoute
          path="/upload-transaction"
          component={UploadTransaction}
          hasAnyAuthorities={[
            AUTHORITIES.BANK_STAFF,
          ]}
        />
        <PrivateRoute
          path="/reports"
          exact component={Reports}
          hasAnyAuthorities={[
            AUTHORITIES.ADMIN,
            AUTHORITIES.AREA_MANAGER,
            AUTHORITIES.ALSHMOKH_ADMIN,
            AUTHORITIES.ALSHMOKH_USER,
            AUTHORITIES.AUDITOR,
            AUTHORITIES.BANK_MANAGER,
            AUTHORITIES.BRANCH_MANAGER,
            AUTHORITIES.BANK_STAFF,
          ]}
        />
        <ErrorBoundaryRoute path="/report-print" component={ReportPrintTemplate}/>
        <PrivateRoute
          path="/fee-reports"
          exact component={FeeReports}
          hasAnyAuthorities={[
            AUTHORITIES.ADMIN,
            AUTHORITIES.AREA_MANAGER,
            AUTHORITIES.ALSHMOKH_ADMIN,
            AUTHORITIES.ALSHMOKH_USER,
            AUTHORITIES.AUDITOR,
            AUTHORITIES.BANK_MANAGER,
            AUTHORITIES.BRANCH_MANAGER,
          ]}
        />
        <ErrorBoundaryRoute path="/fee-report-print" component={FeeReportPrintTemplate}/>
        <PrivateRoute
          path="/commodity"
          component={Commodity}
          hasAnyAuthorities={[
            AUTHORITIES.ADMIN,
            AUTHORITIES.BANK_STAFF,
            AUTHORITIES.BANK_MANAGER,
            AUTHORITIES.BRANCH_MANAGER,
            AUTHORITIES.AUDITOR
          ]}
        />
        <PrivateRoute
          path="/customer"
          component={Customer}
          hasAnyAuthorities={[
            AUTHORITIES.ADMIN,
            AUTHORITIES.ALSHMOKH_ADMIN,
            AUTHORITIES.SUBADMIN_USER,
            AUTHORITIES.BANK_STAFF,
            AUTHORITIES.BANK_MANAGER,
            AUTHORITIES.BRANCH_MANAGER,
            AUTHORITIES.AUDITOR,
          ]}
        />
        <ErrorBoundaryRoute path="/customer-print" component={CustomerPrintTemplate}/>
        <ErrorBoundaryRoute path="/user-print" component={UserPrintTemplate}/>
        <PrivateRoute
          path="/quotation"
          component={Quotation}
          hasAnyAuthorities={[
            AUTHORITIES.ADMIN,
            AUTHORITIES.BANK_STAFF,
            AUTHORITIES.BANK_MANAGER,
            AUTHORITIES.BRANCH_MANAGER,
            AUTHORITIES.AUDITOR,
            AUTHORITIES.ALSHMOKH_ADMIN,
            AUTHORITIES.ALSHMOKH_USER,
            AUTHORITIES.AREA_MANAGER,
            AUTHORITIES.CUSTOMER,
          ]}
        />
        <PrivateRoute
          path="/bank"
          component={Bank}
          hasAnyAuthorities={[
            AUTHORITIES.ADMIN,
            AUTHORITIES.AUDITOR,
            AUTHORITIES.ALSHMOKH_ADMIN,
            AUTHORITIES.SUBADMIN_BANK,
            AUTHORITIES.SUBADMIN_BRANCH,
          ]}
        />
        <PrivateRoute
          path="/branch"
          component={Branch}
          hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.BANK_MANAGER, AUTHORITIES.AUDITOR, AUTHORITIES.ALSHMOKH_ADMIN]}
        />
        <PrivateRoute
          path="/currency-rates"
          component={CurrencyRates}
          hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.AUDITOR, AUTHORITIES.ALSHMOKH_ADMIN]}
        />
        <PrivateRoute
          path="/comex-settings"
          component={ComexSettings}
          hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.BANK_STAFF]}
        />
        <PrivateRoute path="/notification-template" component={NotificationTemplate} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
        <PrivateRoute path="/notification-record" component={NotificationRecord} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
        <PrivateRoute path="/bursa-api-bid-cert" component={BursaAPIBidCert} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
        <PrivateRoute path="/bursa-api-otc-cert" component={BursaAPIOtcCert} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
        <PrivateRoute path="/bursa-api-stb-cert" component={BursaAPIStbCert} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
        <PrivateRoute path="/purchase-cancellation" component={PurchaseCancellation} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
        <PrivateRoute path="/bursa-api-order" component={BursaAPIOrder} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
        <PrivateRoute path="/bursa-api-order-result" component={BursaAPIOrderResult} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
        <PrivateRoute path="/bursa-api-end-points" component={BursaAPIEndPoints} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
        <PrivateRoute
          path="/currency-index"
          component={CurrencyIndex}
          hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.AUDITOR, AUTHORITIES.ALSHMOKH_ADMIN]}
        />
        <PrivateRoute
          path="/currency-pairs-log"
          component={CurrencyPairsLog}
          hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.AUDITOR, AUTHORITIES.ALSHMOKH_ADMIN]}
        />
        <PrivateRoute
          path="/comex-bank-fee"
          component={ComexBankFee}
          hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.AUDITOR, AUTHORITIES.ALSHMOKH_ADMIN]}
        />
        <PrivateRoute
          path="/comex-bank-fee-log"
          component={ComexBankFeeLog}
          hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.AUDITOR, AUTHORITIES.ALSHMOKH_ADMIN]}
        />
        <PrivateRoute
          path="/commodity-log"
          component={CommodityLog}
          hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.AUDITOR, AUTHORITIES.ALSHMOKH_ADMIN]}
        />
        <PrivateRoute
          path="/purchase-order"
          component={PurchaseOrder}
          hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.BANK_STAFF, AUTHORITIES.AUDITOR, AUTHORITIES.ALSHMOKH_ADMIN]}
        />
        <ErrorBoundaryRoute path="/notify-cancellation-emails" component={NotifyCancellationEmails} />
        <ErrorBoundaryRoute path="/auth-token" component={AuthToken} />
        <ErrorBoundaryRoute path="/banklog" component={Banklog} />
        <ErrorBoundaryRoute path="/commission-log" component={CommissionLog} />
        <ErrorBoundaryRoute component={PageNotFound} />
      </Switch>
    </div>
  );
};

export default Routes;
