export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  SUBADMIN_BANK: 'ROLE_SUBADMIN_BANK',
  SUBADMIN_BRANCH: 'ROLE_SUBADMIN_BRANCH',
  SUBADMIN_BURSA_FEE: 'ROLE_SUBADMIN_BURSA_FEE',
  SUBADMIN_COMMODITY: 'ROLE_SUBADMIN_COMMODITIES',
  SUBADMIN_CURRENCIES: 'ROLE_SUBADMIN_CURRENCIES',
  SUBADMIN_NOTIFY_CANCELLATION: 'ROLE_SUBADMIN_NOTIFY_CANCELLATION',
  SUBADMIN_OPERATING_HOURS: 'ROLE_SUBADMIN_OPERATING_HOURS',
  SUBADMIN_USER: 'ROLE_SUBADMIN_USER',
  SUBADMIN_CANCELLATION: 'ROLE_SUBADMIN_CANCELLATION',
  SUBADMIN_KEEP: 'ROLE_SUBADMIN_KEEP',
  USER: 'ROLE_USER',
  BANK_STAFF: 'ROLE_BANK_STAFF',
  BANK_MANAGER: 'ROLE_BANK_MANAGER',
  BRANCH_MANAGER: 'ROLE_BRANCH_MANAGER',
  AREA_MANAGER: 'ROLE_AREA_MANAGER',
  AUDITOR: 'ROLE_AUDITOR',
  ALSHMOKH_ADMIN: 'ROLE_ALSHMOKH_ADMIN',
  ALSHMOKH_USER: 'ROLE_ALSHMOKH_USER',
  CUSTOMER: 'ROLE_CUSTOMER',
  SELL_APPROVAL: 'ROLE_SELL_APPROVAL',
  PURCHASE_APPROVAL: 'ROLE_PURCHASE_APPROVAL'
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
export const EMAILJS_USER_ID = "iFBJ3CYDEseORx1om";
export const EMAILJS_SERVICE_ID = "service_tbg6u4s";
export const EMAILJS_TEMPLATE_ID = "template_u1jjgdw";

export const defaultUser={
    activated: true,
    authorities: null,
    branchId: null,
    createdBy: null,
    createdDate: null,
    email: null,
    firstName: null,
    id: null,
    imageUrl: null,
    langKey: "en",
    lastModifiedBy: null,
    lastModifiedDate: null,
    lastName: null,
    login: null
}

export const USER_TYPE = {
  CUSTOMER: "customer",
  STAFF: "staff"
}
