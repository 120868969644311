import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PrivateRoute from 'app/shared/auth/private-route';

import Customer from './customer';
import CustomerDetail from './customer-detail';
import CustomerUpdate from './customer-update';
import CustomerDeleteDialog from './customer-delete-dialog';
import CustomerPrintTemplate from './customer-print-template';
import { AUTHORITIES } from 'app/config/constants';

const Routes = ({ match }) => (
  <>
    <Switch>
      <PrivateRoute
        exact path={`${match.url}/new`}
        component={CustomerUpdate}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.BANK_MANAGER,
          AUTHORITIES.BRANCH_MANAGER,
          AUTHORITIES.BANK_STAFF,
        ]}
      />
      <PrivateRoute
        exact path={`${match.url}/:id/edit`}
        component={CustomerUpdate}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.BANK_MANAGER,
          AUTHORITIES.BRANCH_MANAGER,
        ]}
      />
      <PrivateRoute
        exact path={`${match.url}/:id`}
        component={CustomerDetail}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.ALSHMOKH_ADMIN,
          AUTHORITIES.SUBADMIN_USER,
          AUTHORITIES.BANK_STAFF,
          AUTHORITIES.BANK_MANAGER,
          AUTHORITIES.BRANCH_MANAGER,
          AUTHORITIES.AUDITOR
        ]}
      />
      <PrivateRoute
        path={match.url}
        component={Customer}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.ALSHMOKH_ADMIN,
          AUTHORITIES.SUBADMIN_USER,
          AUTHORITIES.BANK_STAFF,
          AUTHORITIES.BANK_MANAGER,
          AUTHORITIES.BRANCH_MANAGER,
          AUTHORITIES.AUDITOR
        ]}
      />
      <ErrorBoundaryRoute path="/customer-print" component={CustomerPrintTemplate}/>
    </Switch>
    <PrivateRoute
      exact path={`${match.url}/:id/delete`}
      component={CustomerDeleteDialog}
      hasAnyAuthorities={[
        AUTHORITIES.ADMIN,
        AUTHORITIES.BANK_STAFF,
        AUTHORITIES.BANK_MANAGER,
        AUTHORITIES.BRANCH_MANAGER,
      ]}
    />
  </>
);

export default Routes;
