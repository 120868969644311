import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IBranch, defaultValue } from 'app/shared/model/branch.model';

const initialState: EntityState<IBranch> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  branchForStaffAndManager: null,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/branches';

// Actions

export const getEntities = createAsyncThunk('branch/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IBranch[]>(requestUrl);
});

export const getAllEntities = createAsyncThunk('branch/fetch_entity_all_list', async (entity: IQueryParams) => {
  const requestUrl = `${apiUrl}/all-lists`;
  return axios.get<IBranch[]>(requestUrl);
});

export const getBranchListByBankId = createAsyncThunk(
  'branch/fetch_branch_list_by_bank_id',
  async (bankId: number | string) => {
    const requestUrl = `${apiUrl}/getBranchListByBankId/${bankId}`;
    return axios.get<IBranch[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getBranchByBranchManager = createAsyncThunk(
  'branch/fetch_branch_by_branch_manager',
  async (branchManagerId: number | string) => {
    const requestUrl = `${apiUrl}/getBranchByBranchManager/${branchManagerId}`;
    return axios.get<IBranch[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getBranchByBankList = createAsyncThunk(
  'branch/fetch_branch_by_bank_list',
  async (bankList: number[]) => {
    const requestUrl = `${apiUrl}/report?bank_list=${bankList}`;
    return axios.get<IBranch[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
)

export const getBranchByAreaManager = createAsyncThunk(
  'branch/fetch_branch_by_area_manager',
  async (areaManagerId: number | string) => {
    const requestUrl = `${apiUrl}/areaManager/${areaManagerId}`;
    return axios.get<IBranch[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  'branch/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IBranch>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getBranchForStaffAndBranchManager = createAsyncThunk(
  'branch/getBranchForStaffAndBranchManager/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/getBranchForStaffAndBranchManager/${id}`;
    return axios.get<IBranch>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'branch/create_entity',
  async (entity: IBranch, thunkAPI) => {
    const result = await axios.post<IBranch>(apiUrl, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'branch/update_entity',
  async (entity: IBranch, thunkAPI) => {
    const result = await axios.put<IBranch>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'branch/partial_update_entity',
  async (entity: IBranch, thunkAPI) => {
    const result = await axios.patch<IBranch>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'branch/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IBranch>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const BranchSlice = createEntitySlice({
  name: 'branch',
  initialState,
  extraReducers(builder) {
    builder

      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getAllEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getBranchListByBankId, getBranchByAreaManager, getBranchByBankList), (state, action) => {
        state.entities = action.payload.data;
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(getBranchForStaffAndBranchManager), (state, action) => {
        state.loading = false;
        state.branchForStaffAndManager = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getBranchForStaffAndBranchManager,getEntity, getBranchListByBankId, getAllEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = BranchSlice.actions;

// Reducer
export default BranchSlice.reducer;
