import { IBank } from 'app/shared/model/bank.model';

export interface IBranch {
  id?: number;
  name?: string;
  managerApproval?: boolean;
  escalateApproval?:boolean;
  customerApproval?: boolean;
  bank?: IBank;
  areaManagerId? : number;
  branchManagerId? : number | null;
  createdDate? : Date;
}

export const defaultValue: Readonly<IBranch> = {
  managerApproval: false,
  customerApproval: false,
  areaManagerId: null,
  branchManagerId: null,
};
