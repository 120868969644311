import '../home/home.scss';
import React, {useEffect, useState} from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate, translate, Storage } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {Button, Input, Label} from 'reactstrap';
import { styled, createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Links from '@mui/material/Link';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';
import { visuallyHidden } from '@mui/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Alert } from 'reactstrap';
import moment from "moment/moment";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Clear } from "@mui/icons-material";
import {
  getEntitiesNotDenied,
  getEntitiesByBankStaff,
  getEntitiesAreaManager,
  getEntitiesBranchManager,
  getEntitiesBankManager,
  getEntitiesCustomer,getEntitiesAuditor
} from "app/entities/purchase-cancellation/purchase-cancellation.reducer";
import { AUTHORITIES } from "app/config/constants";
import { getEntitiesByBankId, getEntitiesByCustomerLogin } from "app/entities/quotation/quotation.reducer";
import {getUser, getUserByAlshmokhAdmin} from "app/modules/administration/user-management/user-management.reducer";
import {getEntity as getBranch, getBranchListByBankId, getAllEntities as getBranchs } from "app/entities/branch/branch.reducer";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {ar, enUS} from "date-fns/locale";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import EventIcon from "@mui/icons-material/Event";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { getAllEntities as getCustomers, getUniqueCustomerListByBranchIdinQuotation } from 'app/entities/customer/customer.reducer';

window.onload = function () {
  sessionStorage.removeItem('filterSearch');
}
export const CancelOrder = (props: RouteComponentProps<{ id: string }>) => {

  const dispatch = useAppDispatch();
  const specBranch = useAppSelector(state=> state.branch.entity);
  const user = useAppSelector(state => state.userManagement.user);
  const purchaseCancellations = useAppSelector(state => state.purchaseCancellation.entities);
  const account = useAppSelector(state => state.authentication.account);
  const [pageNo, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchOnChange, setSearchOnChange] = React.useState(sessionStorage.getItem("filterSearch") || '');
  const [searchTerm, setSearchTerm] = React.useState(searchOnChange);
  const [isDone, setIsDone] = useState(false);
  const totalCount = useAppSelector(state => state.purchaseCancellation.totalItems);
  const [open, setOpen] = useState(false);
  const [cancelStatus,setCancelStatus]=useState('');
  const fullWidth=true;
  const [maxWidth, setMaxWidth] = useState<DialogProps['maxWidth']>('sm');
  const bankList = useAppSelector(state => state.bank.entities);
  const branchList = useAppSelector(state => state.branch.entities);
  const customerList = useAppSelector(state => state.customer.entities);
  const commodityList = useAppSelector(state => state.commodity.entities);

  const [bankFilter, setBankFilter] = useState(Number(sessionStorage.getItem("filterBank")) || 0);
  const [branchFilter, setBranchFilter] = useState(Number(sessionStorage.getItem("filterBranch")) || 0);
  const [customerFilter, setCustomerFilter] = useState(Number(sessionStorage.getItem("filterCustomer")) || 0);
  const [commodityFilter, setCommodityFilter] = useState(Number(sessionStorage.getItem("filterCommodity")) || 0);
  const [value, setValue] = React.useState(sessionStorage.getItem("filterDate") || moment(new Date()).format('L'));
  const [queryDate, setQueryDate] = React.useState(value || '');
  const [openCalendar, setOpenCalendar] = useState(false);
  const [statusCheck, setStatusCheck] = useState(JSON.parse(sessionStorage.getItem("filterStatus")) || []);
  const formatTwoDigits = digit => ('0' + digit).slice(-2);
  const tempDate = new Date();
  const currentDate = `${formatTwoDigits(tempDate.getMonth() + 1)}/${formatTwoDigits(tempDate.getDate())}/${tempDate.getFullYear()}`;

  const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-input': {
      textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
    },
  });

  const handleToClose = () => {
    setOpen(false);
  };
  function setNextStep(item: any) {

    if (item?.managerApproval === null && item?.comexAdminApproval === null) {
      return `${translate('home.nextStep.pendingApprovalByBranch')}`;
    }
    else if (item?.managerApproval === true && item?.comexAdminApproval === null) {
      return `${translate('home.nextStep.pendingForConcernedParty')}`;
    }
    else if (item?.managerApproval === true && item?.comexAdminApproval === true && item?.result === 'APPROVED') {
      return `${translate('home.nextStep.pendingApprovalByBursa')}`;
    }
    else if (item?.managerApproval === true && item?.comexAdminApproval === true && item?.result === 'CANCELLED') {
      return `${translate('home.nextStep.done')}`;
    }
    else {
      return "Nil";
    }

  }
  function setInformationButton(entity: any) {

    const nextStep = setNextStep(entity);
    return (
      (nextStep?.toLowerCase().includes('pending') || nextStep?.toLowerCase().includes('في انتظار') || nextStep?.toLowerCase().includes('قيد تأكيد')) &&
      <FontAwesomeIcon icon={faInfoCircle} color="#0275d8" onClick={() => {
        if (entity?.managerApproval === true && entity?.comexAdminApproval === null) {
          setOpen(true);
          setCancelStatus(translate('home.information.cancelApprovedBranch'));
        } else {
          setOpen(true);
          setCancelStatus(translate('home.information.cancelProcessing'));
        }
      }
      } size="lg" style={{cursor: 'pointer'}} />);

  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy]?.toString()?.toLowerCase() < a[orderBy]?.toString()?.toLowerCase()) {
      return -1;
    }
    if (b[orderBy]?.toString()?.toLowerCase() > a[orderBy]?.toString()?.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
    //   const aStatus = setNextStep(a[0]);
    // const bStatus = setNextStep(b[0]);
    // if (aStatus === 'Pending Approval By Branch' && bStatus !== 'Pending Approval By Branch') {
    //   return -1;
    // } else if (aStatus !== 'Pending Approval By Branch' && bStatus === 'Pending Approval By Branch') {
    //   return 1;
    // }
    // else if (aStatus === 'Pending for Concerned Party Approval' && bStatus !== 'Pending for Concerned Party Approval') {
    //   return -1;
    // } else if (aStatus !== 'Pending for Concerned Party Approval' && bStatus === 'Pending for Concerned Party Approval') {
    //   return 1;
    // }
    // else if (aStatus === 'Pending Approval By Bursa' && bStatus !== 'Pending Approval By Bursa') {
    //   return -1;
    // } else if (aStatus !== 'Pending Approval By Bursa' && bStatus === 'Pending Approval By Bursa') {
    //   return 1;
    // }
    // if (aStatus === 'DONE') {
    //   return 1;
    // } else if (bStatus === 'DONE') {
    //   return -1;
    // }
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  // Fetch bank and branches on component mount
  useEffect(() => {
    const storedBankId = sessionStorage.getItem('filterBank');
    if (storedBankId) {
      const bankId = Number(storedBankId);
      setBankFilter(bankId);
      dispatch(getBranchListByBankId(bankId));
    }else {
      dispatch(getBranchs({}));
      sessionStorage.removeItem('filterBank');
    }

    const storedBranchId = sessionStorage.getItem('filterBranch');
    if (storedBranchId) {
      const branchId = Number(storedBranchId);
      setBranchFilter(branchId);
      dispatch(getUniqueCustomerListByBranchIdinQuotation(branchId));
    } else {
      dispatch(getCustomers({}));
      sessionStorage.removeItem('filterBranch');
    }
  }, [dispatch]);

  // Handle bank selection
  const handleBankChange = (e) => {
    const bankId = Number(e.target.value);
    setBankFilter(bankId);
    setPage(0);
    if (bankId !== 0) {
      dispatch(getBranchListByBankId(bankId));
      sessionStorage.setItem("filterBank", bankId.toString());
    } else {
      dispatch(getBranchs({}));
      sessionStorage.removeItem('filterBank');
    }
  };

  // Handle branch selection
  const handleBranchChange = (e) => {
    const branchId = Number(e.target.value);
    setBranchFilter(branchId);
    setPage(0);
    if (branchId !== 0) {
      dispatch(getUniqueCustomerListByBranchIdinQuotation(branchId));
      sessionStorage.setItem("filterBranch", branchId.toString());
    } else {
      dispatch(getCustomers({}));
      sessionStorage.removeItem('filterBranch');
    }
  };

  // Handle customer selection
  const handleCustomerChange = (e) => {
    const customerId = Number(e.target.value);
    setCustomerFilter(customerId);
    setPage(0);
    if (customerId !== 0) {
      sessionStorage.setItem("filterCustomer", customerId.toString());
    } else {
      sessionStorage.removeItem('filterCustomer');
    }
  };

  const statusType = [
    {
      name: `${translate('home.status.pendingApprovalByBranch')}`,
      value: 'Pending Approval By Branch',
    },
    {
      name: `${translate('home.status.pendingForConcernedParty')}`,
      value: 'Pending for concerned party Approval',
    },
    {
      name: `${translate('home.status.pendingApprovalByBursa')}`,
      value: 'Pending Approval By Bursa',
    },
    {
      name: `${translate('home.status.done')}`,
      value: 'Cancelled',
    }
  ];

  const handleChange = e => {
    if (e.target.checked) {
      setStatusCheck([...statusCheck, e.target.value].sort(function (a, b) {
        return statusType.indexOf(a) - statusType.indexOf(b);
      }));
      setPage(0);
      sessionStorage.setItem("filterStatus", JSON.stringify([...statusCheck, e.target.value].sort(function (a, b) {
            return statusType.indexOf(a) - statusType.indexOf(b);
          })
      ));
    } else {
      setStatusCheck(statusCheck.filter(id => id !== e.target.value));
      setPage(0);
      sessionStorage.setItem("filterStatus", JSON.stringify(statusCheck.filter(id => id !== e.target.value)));
    }
  };

  const rows = purchaseCancellations?.map((obj, i)=>({
    ...obj,
    id : obj?.quotation?.id,
    poNumber : obj?.bursaAPIBidCert?.ecertNo === null ? '-' : obj?.bursaAPIBidCert?.ecertNo,
    customerName : obj?.customer?.name,
    bankName : obj?.bank?.name,
    amount : obj?.quotation?.amount?.toLocaleString() + " " + obj?.quotation?.buyCurrency,
  }));

  const headCells = [
    {
      id: 'id',
      numeric: true,
      disablePadding: false,
      label: `${translate('home.table.orderNo')}`,
      disableSorting: false,
    },
    {
      id: 'poNumber',
      numeric: true,
      disablePadding: false,
      label: `${translate('home.table.poNo')}`,
      disableSorting: false,
    },
    {
      id: 'customerName',
      numeric: false,
      disablePadding: false,
      label: `${translate('home.table.customerName')}`,
      disableSorting: false,
    },
    {
      id: 'bankName',
      numeric: false,
      disablePadding: false,
      label: `${translate('home.table.bank')}`,
      disableSorting: false,
    },
    {
      id: 'amount',
      numeric: false,
      disablePadding: false,
      label: `${translate('home.table.value')}`,
      disableSorting: false,
    },
    {
      id: 'createdDate',
      numeric: false,
      disablePadding: false,
      label: `${translate('home.table.cancellationDate')}`,
      disableSorting: false,
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: `${translate('home.table.status')}`,
      disableSorting: true,
    },
    {
      id: 'nextStep',
      numeric: false,
      disablePadding: false,
      label: `${translate('home.table.nextStep')}`,
      disableSorting: true,
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: false,
      label: " ",
      disableSorting: true,
    },
  ];

  function EnhancedTableHead(tableHead) {
    const { order, orderBy, onRequestSort } =
      tableHead;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow className={ "enhanced-table-cancel"}>
          {headCells?.map((headCell) => (
            <StyledTableCell
              key={headCell?.id}
              align="left"
              padding={headCell?.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell?.id ? order : false}
            >
              {headCell?.disableSorting ? headCell.label :
              <StyledTableSortLabel
                active={orderBy === headCell?.id}
                direction={orderBy === headCell?.id ? order : 'asc'}
                onClick={createSortHandler(headCell?.id)}
              >
                {headCell?.label}
                {orderBy === headCell?.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </StyledTableSortLabel>
              }
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleNextStepClick = (entity: any) => {
    const orderId = entity?.quotation?.id;
    const nextStep = setNextStep(entity);

      props.history.push(`/quotation/bought/${orderId}`);

  };

  // to change colour of the table
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgba(255,255,255,0)",
      color: theme.palette.common.white,
      fontWeight: 'bold',
      letterSpacing: 1,
      textAlign:Storage.session.get('locale') === 'en' ?'left':'right',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign:Storage.session.get('locale') === 'en' ?'left':'right',
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
    '&.MuiTableSortLabel-root': {
        color: 'white',
    },
    '&.MuiTableSortLabel-root:hover': {
        color: 'white',
    },
    '&.Mui-active': {
        color: 'white',
    },
    '& .MuiTableSortLabel-icon': {
        color: 'white !important',
    },
  }));

  let responsiveFont = createTheme();
  responsiveFont = responsiveFontSizes(responsiveFont);

  useEffect(() => {
    if (account?.login !== undefined && !isDone && account.authorities.includes(AUTHORITIES.BRANCH_MANAGER)) {
      if (account?.authorities.includes(AUTHORITIES.ADMIN))
      {
        dispatch(getUser(account.login));
      }
      else {
        dispatch(getUserByAlshmokhAdmin(account.login));
      }
    }
  }, [account]);

  useEffect(() => {
    const dispatchGetBranch = async () => {
      const result = await dispatch(getBranch(parseInt(user.branchId, 10)));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if(result?.payload?.data?.bankId) {
        setIsDone(true);
      }
    }
    if (account?.login !== undefined && account.authorities.includes(AUTHORITIES.BRANCH_MANAGER)) {
      if (user?.branchId !== undefined){
        dispatchGetBranch();
      }
    }
  }, [user])

  useEffect(() => {
    if (account?.authorities?.includes(AUTHORITIES.BANK_STAFF))
      dispatch(
        getEntitiesByBankStaff({
          page: 0,
          size: 0,
          sort: `id,asc`,
          createdBy: account?.login,
          date: queryDate,
          status: statusCheck,
          search: searchTerm,
          curDate: currentDate,
        })
      );
    else if (isDone && account?.authorities?.includes(AUTHORITIES.BRANCH_MANAGER) && specBranch?.branchManagerId === account.id)
      dispatch(
        getEntitiesBranchManager({
          page: 0,
          size: 0,
          sort: 'id,asc',
          branchId: account.branchId,
          date: queryDate,
          status: statusCheck,
          search: searchTerm,
          curDate: currentDate,
        })
      );
    else if (account?.authorities?.includes(AUTHORITIES.BANK_MANAGER)) {
      //           alert("true");
      dispatch(
        getEntitiesBankManager({
          page: 0,
          size: 0,
          sort: 'id,asc',
          bankManagerId: account.id,
          date: queryDate,
          status: statusCheck,
          search: searchTerm,
          curDate: currentDate,
        })
      );
    }
    else if (account?.authorities?.includes(AUTHORITIES.AREA_MANAGER)) {
      dispatch(
        getEntitiesAreaManager({
          page: 0,
          size: 0,
          sort: 'id,asc',
          areaManagerId: account.id,
          date: queryDate,
          status: statusCheck,
          search: searchTerm,
          curDate: currentDate,
        })
        // eslint-disable-next-line no-console
      );
    }
    else if (account?.authorities?.includes(AUTHORITIES.AUDITOR)) {
      dispatch(
          getEntitiesAuditor({
            page: 0,
            size: 0,
            sort: 'id,asc',
            bankId: Number(account?.bankId),
            date: queryDate,
            status: statusCheck,
            search: searchTerm,
            curDate: currentDate,
          })
      );
    }
    else if (account?.authorities?.includes(AUTHORITIES.ADMIN) ||
      account?.authorities?.includes(AUTHORITIES.ALSHMOKH_ADMIN) ||
      account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER)) {
      dispatch(getEntitiesNotDenied({
        page: 0,
        size: 0,
        sort: 'id,asc',
        date: queryDate,
        status: statusCheck,
        search: searchTerm,
        curDate: currentDate,
        bankId: bankFilter,
        branchId: branchFilter,
        customerId: customerFilter,
        commodityId: commodityFilter,
      })
      );
    }
    else if (account?.authorities?.includes(AUTHORITIES.CUSTOMER)) {
      dispatch(
        getEntitiesCustomer({
          page: 0,
          size: 0,
          sort: 'id,asc',
          customerLogin: account?.login,
          date: queryDate,
          status: statusCheck,
          search: searchTerm,
          curDate: currentDate,
        })
        // eslint-disable-next-line no-console
      );
    }
  }, [account, pageNo, rowsPerPage, searchTerm, specBranch, isDone, statusCheck, queryDate,bankFilter,branchFilter,customerFilter,commodityFilter ]);
  // console.warn(purchaseCancellations?.quotation?.id);

  return (
    <Container>
      {account?.login ? (
        <div>

          <Grid container spacing={2} sx={{ mt: 1, alignItems: 'center' }}>
            <Grid item xs={12} sm={6} md={6} className="left-side-component-flex">
              <ThemeProvider theme={responsiveFont}>
                <Typography variant="h4" component="div">
                  {translate('home.cancelOrderTitle')}
                </Typography>
              </ThemeProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={6} className="right-side-component-flex">
              <Links onClick={() => props.history.push('/home')} underline="hover">
                <Translate contentKey="home.returnHomeLink" />
              </Links>
            </Grid>
          </Grid>


          {(account?.authorities?.includes(AUTHORITIES.ADMIN) || account?.authorities?.includes(AUTHORITIES.ALSHMOKH_ADMIN) || account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER)) &&
            <Grid container spacing={2} className="filter-row mt-4">
              <Grid item xs={12} sm={6} md={3}>
                <Row className="select-row">
                  <Col>
                    <Label style={{ fontFamily: 'Roboto, sans-serif' }}>
                      <Translate contentKey='home.bank' />
                    </Label>
                  </Col>
                  <Col>
                    <Input className="options-home" type='select' width={"80%"} value={bankFilter || ''} onChange={handleBankChange} >
                      <option value={0} label={translate('home.dropdown.allDropdown')}></option>
                      {bankList?.map((bank, index) => (
                          <option value={bank?.id} key={index}>
                            {bank?.name}
                          </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Row className="select-row">
                  <Col>
                    <Label style={{ fontFamily: 'Roboto, sans-serif' }}>
                      <Translate contentKey='home.branch' />
                    </Label>
                  </Col>
                  <Col>
                    <Input className="options-home" type='select' width={"80%"} value={branchFilter || ''} onChange={handleBranchChange}>
                      <option value={0} label={translate('home.dropdown.allDropdown')}></option>
                      {branchList?.map((branch, index) => (
                          <option value={branch?.id} key={index}>
                            {branch?.name}
                          </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Row className="select-row">
                  <Col>
                    <Label style={{ fontFamily: 'Roboto, sans-serif' }}>
                      <Translate contentKey='home.customer' />
                    </Label>
                  </Col>
                  <Col>
                    <Input className={"options-home"} type='select' width={"80%"} value={customerFilter || ''} onChange={handleCustomerChange}>
                      <option value={0} label={translate('home.dropdown.allDropdown')}></option>
                      {customerList?.map((customer, index) => (
                          <option value={customer?.id} key={index}>
                            {customer?.name}
                          </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Row className="select-row">
                  <Col>
                    <Label style={{ fontFamily: 'Roboto, sans-serif' }}>
                      <Translate contentKey='home.commodity' />
                    </Label>
                  </Col>
                  <Col>
                    <Input className={"options-home"} type='select' width={"80%"} value={commodityFilter || ''} onChange={e => {
                      setCommodityFilter(Number(e.target.value));
                      setPage(0);
                      if (Number(e.target.value) !== 0) {
                        sessionStorage.setItem("filterCommodity", e.target.value);
                      } else {
                        sessionStorage.removeItem("filterCommodity");
                      }
                    }}>
                      <option value={0} label={translate('home.dropdown.allDropdown')}></option>
                      {commodityList?.map((commodity, index) => (
                          <option value={commodity?.id} key={index}>
                            {commodity?.name}
                          </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </Grid>
            </Grid>
          }

          <Grid container spacing={2} className="filter-row mt-4">
            <Grid item xs={12} sm={6} md={3}>
              <Box className="input-styled">
                <LocalizationProvider locale={Storage.session.get('locale') === 'en' ? enUS : ar} dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                      toolbarTitle={translate('entity.action.selectDate')}
                      okText={translate('entity.action.ok')}
                      cancelText={translate('entity.action.cancel')}
                      disableFuture
                      mask='____/__/__'
                      inputFormat="yyyy/MM/dd"
                      views={['year', 'month', 'day']}
                      value={value}
                      onChange={newValue => {
                        setValue(newValue);
                        setQueryDate(moment(newValue).format('L'));
                        setPage(0);
                        sessionStorage.setItem("filterDate", moment(newValue).format('L'));
                      }}
                      open={openCalendar}
                      onOpen={() => setOpenCalendar(true)}
                      onClose={() => setOpenCalendar(false)}
                      renderInput={(params) => {
                        return (
                            <StyledTextField
                                size="small"
                                fullWidth
                                {...params}
                                onClick={(e) => setOpenCalendar(true)}
                                InputProps={{
                                  placeholder: "yyyy/mm/dd",
                                  endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton edge="end" onClick={(e) => setOpenCalendar(true)}>
                                          <EventIcon />
                                        </IconButton>
                                      </InputAdornment>
                                  ),
                                }}
                            />
                        );
                      }}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} style={{ display: 'none' }}>
              <Box className="status-filter-box">
                <FormGroup sx={{ flexDirection: { md: 'column', sm: 'column', xs: 'column' }, alignItems: 'flex-start' }}>
                  <FormLabel className="form-label-status">
                    <Translate contentKey="home.status.title">Status:</Translate>
                  </FormLabel>
                  {statusType.map((status, index) => (
                      <FormControlLabel
                          key={index}
                          control={<Checkbox onChange={handleChange} checked={(statusCheck.indexOf(status.value) > -1) === true} />}
                          label={status.name}
                          value={status.value}
                          sx={{
                            '& .MuiSvgIcon-root': { fontSize: { md: 20, sm: 18, xs: 16 } },
                            '& .MuiTypography-root': { fontSize: { md: 16, sm: 14, xs: 14 } },
                            mx: 1
                          }}
                      />
                  ))}
                </FormGroup>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Box className="input-styled">
                <TextField
                    size="small"
                    fullWidth
                    placeholder={translate('home.search')}
                    value={searchOnChange}
                    InputProps={{
                      startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                      ),
                      endAdornment: (
                          <InputAdornment position="end" onClick={(e) => { setSearchTerm(''); setSearchOnChange(''); setPage(0); sessionStorage.removeItem('filterSearch'); }}>
                            <Clear style={{ fontSize: 12 }} />
                          </InputAdornment>
                      ),
                    }}
                    onChange={e => { setSearchOnChange(e.target.value); sessionStorage.setItem("filterSearch", e.target.value); }}
                    onKeyPress={e => { if (e.key === 'Enter') { setSearchTerm(searchOnChange); setPage(0); } }}
                />
              </Box>
            </Grid>
          </Grid>

          <Paper className={ "paper-table"} sx={{width: '100%', overflow: 'hidden', mb: 8}}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table sx={{ minWidth: 650 }} aria-label="sticky table">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {purchaseCancellations
                    && stableSort(rows, getComparator(order, orderBy))
                    .slice(pageNo * rowsPerPage, pageNo * rowsPerPage + rowsPerPage)
                    .map(entity => (
                      <StyledTableRow key={entity?.id}>
                        <StyledTableCell component="th" scope="row">
                          {entity?.id}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {entity?.poNumber}
                        </StyledTableCell>
                        <StyledTableCell>{entity?.customerName}</StyledTableCell>
                        <StyledTableCell>{entity?.bankName}</StyledTableCell>
                        <StyledTableCell>{entity?.amount}</StyledTableCell>
                        <StyledTableCell>{moment(entity?.createdDate).format('yyyy/MM/DD')}</StyledTableCell>
                        <StyledTableCell>{translate('home.status.cancelled')}</StyledTableCell>
                        <StyledTableCell>
                          <Links
                            underline="hover"
                            onClick={() => {
                              handleNextStepClick(entity);
                            }}
                            sx={{ fontWeight: 'light', textTransform: 'uppercase' }}
                          >
                            {setNextStep(entity)}
                          </Links>
                          &nbsp;
                          {setInformationButton(entity)}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Button className={"btn-detail"}
                            color="primary"
                            onClick={() => {
                              handleNextStepClick(entity);
                            }}
                          >
                            <Translate contentKey="home.table.detailBtn">Detail</Translate>
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              className={Storage.session.get('locale') === 'en' ? "table-pagination" : "table-pagination-arabic"}
              sx={{ display: 'block' }}
              rowsPerPageOptions={[5, 25, 100]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={pageNo}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={translate('home.table.rowsPerPage')}
            />
          </Paper>
          &nbsp;
          <Dialog open={open} onClose={handleToClose} maxWidth={maxWidth}>
            <DialogTitle >
             </DialogTitle>
             <DialogContent >
              <DialogContentText style={{ color: "black"}} className='mb-3'>{cancelStatus}</DialogContentText> </DialogContent>
            <DialogActions className="mx-3 mb-3">
              <DialogContentText>
              <Button color="primary" onClick={handleToClose} autoFocus>
                              {translate('comexApp.quotation.okButton')}
                            </Button>
              </DialogContentText>
            </DialogActions>
          </Dialog>
        </div>

      ) : (
        <></>

      )}
    </Container>
  );
};

export default CancelOrder;
