import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IBursaAPIOrderResult, defaultValue, IQueryErrorResultList } from 'app/shared/model/bursa-api-order-result.model';

const initialState: EntityState<IBursaAPIOrderResult> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/bursa-api-order-results';

// Actions

export const getEntities = createAsyncThunk('bursaAPIOrderResult/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IBursaAPIOrderResult[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'bursaAPIOrderResult/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IBursaAPIOrderResult>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getErrorEntitiesForCustomer = createAsyncThunk('bursaAPIOrderResult/fetch_error_entity_list_for_customer', async ({ id, createdDate }: IQueryErrorResultList) => {
  const requestUrl = `${apiUrl}/customerError/${id}?createdDate=${createdDate}`;
  return axios.get<IBursaAPIOrderResult[]>(requestUrl);
});

export const getErrorEntitiesForBankStaff = createAsyncThunk('bursaAPIOrderResult/fetch_entity_list_for_bankstaff', async ({ id, createdDate }: IQueryErrorResultList) => {
  const requestUrl = `${apiUrl}/bankStaffError/${id}?createdDate=${createdDate}`;
  return axios.get<IBursaAPIOrderResult[]>(requestUrl);
});

export const getErrorEntitiesForBranchManager = createAsyncThunk('bursaAPIOrderResult/fetch_entity_list_for_branchmanager', async ({ id, createdDate }: IQueryErrorResultList) => {
  const requestUrl = `${apiUrl}/branchManagerError/${id}?createdDate=${createdDate}`;
  return axios.get<IBursaAPIOrderResult[]>(requestUrl);
});

export const getErrorEntitiesForBankManager = createAsyncThunk('bursaAPIOrderResult/fetch_entity_list_for_bankmanager', async ({ id, createdDate }: IQueryErrorResultList) => {
  const requestUrl = `${apiUrl}/bankManagerError/${id}?createdDate=${createdDate}`;
  return axios.get<IBursaAPIOrderResult[]>(requestUrl);
});

export const getErrorEntitiesForAreaManager = createAsyncThunk('bursaAPIOrderResult/fetch_entity_list_for_areamanager', async ({ id, createdDate }: IQueryErrorResultList) => {
  const requestUrl = `${apiUrl}/areaManagerError/${id}?createdDate=${createdDate}`;
  return axios.get<IBursaAPIOrderResult[]>(requestUrl);
});

export const getErrorEntitiesForAuditor = createAsyncThunk('bursaAPIOrderResult/fetch_entity_list_for_auditor', async ({ id, createdDate }: IQueryErrorResultList) => {
  const requestUrl = `${apiUrl}/auditorError/${id}?createdDate=${createdDate}`;
  return axios.get<IBursaAPIOrderResult[]>(requestUrl);
});

export const getErrorEntitiesForAdmins = createAsyncThunk('bursaAPIOrderResult/fetch_entity_list_for_admins', async ({ id, createdDate }: IQueryErrorResultList) => {
  const requestUrl = `${apiUrl}/adminError?createdDate=${createdDate}`;
  return axios.get<IBursaAPIOrderResult[]>(requestUrl);
});


export const getEntitiesByEcertNo = createAsyncThunk(
  'bursaAPIOrderResult/fetch_entity/byEcertNo',
  async (ecertNo: string) => {
    const requestUrl = `${apiUrl}/getEntitiesByEcertNo/${ecertNo}`;
    return await axios.get<IBursaAPIOrderResult[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'bursaAPIOrderResult/create_entity',
  async (entity: IBursaAPIOrderResult, thunkAPI) => {
    const result = await axios.post<IBursaAPIOrderResult>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'bursaAPIOrderResult/update_entity',
  async (entity: IBursaAPIOrderResult, thunkAPI) => {
    const result = await axios.put<IBursaAPIOrderResult>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'bursaAPIOrderResult/partial_update_entity',
  async (entity: IBursaAPIOrderResult, thunkAPI) => {
    const result = await axios.patch<IBursaAPIOrderResult>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'bursaAPIOrderResult/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IBursaAPIOrderResult>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const BursaAPIOrderResultSlice = createEntitySlice({
  name: 'bursaAPIOrderResult',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })

      .addMatcher(isFulfilled(getErrorEntitiesForCustomer), (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })

      .addMatcher(isFulfilled(getErrorEntitiesForBankStaff), (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addMatcher(isFulfilled(getErrorEntitiesForBranchManager), (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addMatcher(isFulfilled(getErrorEntitiesForBankManager), (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addMatcher(isFulfilled(getErrorEntitiesForAreaManager), (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })

      .addMatcher(isFulfilled(getErrorEntitiesForAdmins), (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })

      .addMatcher(isFulfilled(getErrorEntitiesForAuditor), (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })

      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getEntitiesByEcertNo), (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = BursaAPIOrderResultSlice.actions;

// Reducer
export default BursaAPIOrderResultSlice.reducer;
