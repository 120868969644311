import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import BursaAPIOtcCert from './bursa-api-otc-cert';
import BursaAPIOtcCertDetail from './bursa-api-otc-cert-detail';
import BursaAPIOtcCertUpdate from './bursa-api-otc-cert-update';
import BursaAPIOtcCertDeleteDialog from './bursa-api-otc-cert-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={BursaAPIOtcCertUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={BursaAPIOtcCertUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={BursaAPIOtcCertDetail} />
      <ErrorBoundaryRoute path={match.url} component={BursaAPIOtcCert} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={BursaAPIOtcCertDeleteDialog} />
  </>
);

export default Routes;
