import React, { useEffect, useState } from 'react';
import { Translate, translate, ValidatedField, ValidatedForm, isEmail, Storage } from 'react-jhipster';
import { Alert, Col, Row, Label, DropdownItem } from 'reactstrap';
import { toast } from 'react-toastify';
import {Link, useHistory} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {
  Box,
  CardMedia,
  createTheme, Dialog, DialogActions,
  DialogContent, DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Theme,
  Typography,
  Tabs,
  Tab
} from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import {createStyles, makeStyles } from "@material-ui/core/styles";
import TranslateIcon from '@mui/icons-material/Translate';
import Paper from "@mui/material/Paper";
import InputAdornment from "@mui/material/InputAdornment";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Button as ButtonReact } from "reactstrap";
import {Button} from "@material-ui/core";
import {isRTL, languages, locales} from "app/config/translation";
import {setLocale} from "app/shared/reducers/locale";
import Card from "@mui/material/Card";
import {NavDropdown} from "app/shared/layout/menus/menu-components";
import {responsiveFontSizes} from "@mui/material/styles";
import {ThemeProvider} from "@mui/styles";
import PhoneInput from 'react-phone-input-2';
import { countryList } from 'app/entities/bank/country';

import { handlePasswordResetInit, handlePasswordResetByPhoneNo, reset } from '../password-reset.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {AUTHORITIES} from "app/config/constants";
import {
  updateUserLanguage,
  updateUserLanguageForAllRoles
} from "app/modules/administration/user-management/user-management.reducer";

const useStyles = makeStyles((theme:Theme) =>
  createStyles({
    formRoot:{
      paddingLeft:45,
      paddingRight:45,
      marginBottom:30,
      [theme.breakpoints.down("xs")]: {
        paddingLeft:15,
        paddingRight:15,
      },
    },
    headerText:{
      margin: '30px 8px 5px  50px',
      [theme.breakpoints.down("xs")]: {
        margin: '50px 8px 5px 10px',
      },
      [theme.breakpoints.down("sm")]: {
        margin: '50px 8px 5px  30px',
      },
    },
    textFieldLabel: {
      "& .MuiFormLabel-root": {
        marginLeft: '32px',
        marginTop: '10px',
      }
    },
    textFieldLabelArabic: {
      "& .MuiFormLabel-root": {
        marginTop: '10px',
        position: 'relative',
        right: '42px',
        transform: 'translateY(20px)',
      }
    },

  })
);

export const PasswordResetInit = () => {
  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);
  const user = useAppSelector(state => state.userManagement.user);
  const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset" };
  const [tabValue, setTabValue] = React.useState('email');
  const [resetPasswordEmail,setResetPasswordEmail]=useState('');
  const [resetPasswordPhoneNo,setResetPasswordPhoneNo]=useState('');
  const [countryCode, setCountryCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showPhoneErrorLabel, setShowPhoneErrorLabel] = useState(false);
  const [openMessageDialog, setOpenMessageDialog] = React.useState(false);
  const [submittedForm, setSubmittedForm] = useState('');
  useEffect(
    () => () => {
      dispatch(reset());
    },
    []
  );

  const getDialogContent = () => {
    if (submittedForm === "email") {
      return <Translate contentKey="reset.request.emailMessages" />;
    } else {
      return <Translate contentKey="reset.request.phoneMessages" />;
    }
  };

  const handleValidSubmitEmail = () => {
    dispatch(handlePasswordResetInit(resetPasswordEmail));
    setSubmittedForm('email');
    setOpenMessageDialog(true);
  };

  const handleValidSubmitPhoneNo = () => {
    if (!phoneNumber) {
      setShowPhoneErrorLabel(true);
    } else {
      setShowPhoneErrorLabel(false);
      dispatch(handlePasswordResetByPhoneNo(resetPasswordPhoneNo));
      setSubmittedForm('phone');
      setOpenMessageDialog(true);
    }
  };

  const handleToClose = () => {
    setOpenMessageDialog(false);
  };

  const successMessage = useAppSelector(state => state.passwordReset.successMessage);

  useEffect(() => {
    if (successMessage) {
      toast.success(translate(successMessage));
    }
  }, [successMessage]);

  const handleLocaleChange = event => {
    const langKey = event.target.value;
    document.querySelector('html').setAttribute('dir', isRTL(langKey) ? 'rtl' : 'ltr');
    Storage.session.set('locale', langKey);
    dispatch(setLocale(langKey));
    try{
      const updatedUser = {
        ...user,
        id : account.id,
        langKey:event.target.value,
      };
      if (account?.authorities.includes(AUTHORITIES.ADMIN))
        dispatch(updateUserLanguage(updatedUser));
      else
        dispatch(updateUserLanguageForAllRoles(updatedUser));
    }
    catch (TypeError){
      console.warn("Account not defined yet");
    }
  };

  const classes = useStyles();
  let responsiveFont = createTheme();
  responsiveFont = responsiveFontSizes(responsiveFont);

  const handleChangeTab = (event: React.SyntheticEvent, newTabValue: string) => {
    setTabValue(newTabValue);
  };

  const handleChangePhone = (value, country) => {
    setResetPasswordPhoneNo(value);
    setCountryCode(country?.dialCode);
    setPhoneNumber(value?.slice(country?.dialCode?.length));
    setShowPhoneErrorLabel(false);
  };

  return (
    <div>

    <Grid container>
      <ThemeProvider theme={responsiveFont}>
        <Grid item xs={12} md={7}>
          <Paper elevation={0} >
            <Card >
              <div style={{ position: "relative" }}>
                <CardMedia  sx={{
                  height: {md:'90vh',sm:'100%',xs:'93vh'},
                  width: '100%',
                  objectFit: 'cover'
                }}  component="img" image={"../content/images/background.png"} alt="Background"/>
                <div className="backgroundTitlePosition">
                  <Typography variant="h4" className="text-sm-center text-xs-left" ml={1} sx={{fontFamily:'Inria Sans',fontWeight:300,fontSize: {md:'2.5rem',sx:'2.2rem'}}}>
                      INTERNATIONAL TRADE & DEVELOPMENT
                  </Typography>
                  <div className="text-sm-center text-sm-justify mt-3 " id="leadText">
                  <Typography variant="h5" ml={1}>
                    Dedicated to Growth, Providing
                  </Typography>
                    {Storage.session.get('locale')==='en'?(<Typography variant="h5" ml={1}>
                     Opportunity - Using Experience,
                  </Typography>):(<Typography variant="h5" ml={1}>
                      ,Opportunity - Using Experience
                    </Typography>)}
                  <Typography variant="h5" ml={1}>
                     Tradition & Technology to Build Our  Future
                  </Typography>
                  </div>
                </div>
              </div>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={12} md={5}>
          <Paper elevation={0} >
            <Row>
              <Col xs={7} md={8}>
                <Grid container direction="row" className={classes.headerText} style={{alignItems: 'center'}}>
                  <Grid item >
                    <Box
                      component="img"
                      sx={{
                        maxWidth: {xs: 60, md: 70},
                        mr:{xs:2},
                        ml:{xs:2,md:2},
                        p:0,
                      }}
                      alt="logo"
                      src="../content/images/img.png"
                    />
                  </Grid>
                  <Grid item>
                    <div className="text-class">
                      {Storage.session.get('locale') === 'ar-ly' ? (
                        <Typography variant="subtitle1">
                          <Translate contentKey="login.logoTitle.fullTitle" />
                        </Typography>
                      ) : (
                        <>
                          <Typography variant="subtitle1">
                            <Translate contentKey="login.logoTitle.nameTitle"/>
                          </Typography>
                          <Typography variant="subtitle1">
                            <Translate contentKey="login.logoTitle.systemTitle"/>
                          </Typography>
                        </>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Col>
              <Col xs={4} md={4}>
                <div className='languageSection'>
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <NavDropdown icon="language" name={
                      <div className="locale-icon-login">
                        <TranslateIcon
                          sx={{
                            fontSize: '24px',
                            marginTop: {md: 0, sm: '16px', xs: '-24px'}
                          }}/>
                        <Translate contentKey="global.menu.language" />
                      </div>
                      }>
                      {locales.map(locale => (
                        <DropdownItem key={locale} value={locale} onClick={handleLocaleChange}>
                          {languages[locale].name}
                        </DropdownItem>
                      ))}
                    </NavDropdown>
                  </div>
                </div>
              </Col>
            </Row>
            <h2 className='loginTitle'><Translate contentKey="reset.request.title"/></h2>
            <Box className={classes.formRoot}>
              <Alert color="warning" className="custom-alert">
                <Translate contentKey="reset.request.messages.info">Enter the email address or phone number you used to register</Translate>
              </Alert>
              <Grid container direction="column" >
                <TabContext value={tabValue}>
                  <Box>
                    <Tabs
                      value={tabValue}
                      onChange={handleChangeTab}
                      variant="fullWidth"
                      sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                      }}
                    >
                      <Tab
                        label={translate('global.form.email.label')} value="email"
                        sx={{
                          fontFamily: 'Poppins',
                          fontWeight: '600',
                          padding: '8px 12px',
                        }}
                      />
                      <Tab
                        label={translate('global.form.phoneNumber.label')} value="phoneNo"
                        sx={{
                          fontFamily: 'Poppins',
                          fontWeight: '600',
                          padding: '8px 12px',
                        }}
                      />
                    </Tabs>
                  </Box>

                  <TabPanel value="email"
                    sx={{
                      padding: '20px 0 0 0',
                    }}
                  >
                    <ValidatedForm onSubmit={handleValidSubmitEmail}>
                      <ValidatedField
                        name="email"
                        value={resetPasswordEmail}
                        onChange={e=> setResetPasswordEmail(e.target.value)}
                        placeholder={translate('global.form.email.placeholder')}
                        id="email"
                        // label={translate('global.form.email.label')}
                        style={{
                          textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
                          width: '100%',
                          WebkitBoxShadow: "0 0 0 1000px white inset"
                        }}
                        sx={{mb:3}}
                        type="email"
                        validate={{
                          required: {
                            value: true,
                            message: translate('global.messages.validate.email.required'),
                          },
                          minLength: {
                            value: 5,
                            message: translate('global.messages.validate.email.minlength'),
                          },
                          maxLength: {
                            value: 254,
                            message: translate('global.messages.validate.email.maxlength'),
                          },
                          pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: translate('global.messages.validate.email.invalid'),
                          },
                        }}
                      />
                      <Button className="mt-2" variant="contained" size="medium" color="primary" type="submit" data-cy="submit" fullWidth>
                        <Translate contentKey="reset.request.form.button">Reset password</Translate>
                      </Button>
                    </ValidatedForm>
                  </TabPanel>

                  <TabPanel value="phoneNo"
                    sx={{
                      padding: '20px 0 0 0',
                    }}
                  >
                    <ValidatedForm onSubmit={handleValidSubmitPhoneNo}>
                      <PhoneInput
                        country={countryList[0]?.code}
                        value={resetPasswordPhoneNo}
                        onChange={handleChangePhone}
                        inputStyle={{
                          padding: Storage.session.get('locale') === 'en' ? '18px 12px 18px 48px' : '18px 48px 18px 12px',
                          textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
                          direction: 'ltr',
                        }}
                      />

                      {showPhoneErrorLabel && (
                        <Label style={{ fontSize: 14, color: 'red', fontWeight: 400 }}>
                          <Translate contentKey="global.messages.validate.phoneNumber.required" />
                        </Label>
                      )}

                      <Button className="mt-2" variant="contained" size="medium" color="primary" type="submit" data-cy="submit" fullWidth>
                        <Translate contentKey="reset.request.form.button">Reset password</Translate>
                      </Button>
                    </ValidatedForm>
                  </TabPanel>
                </TabContext>
              </Grid>
              <div className="mt-3" style={{textAlign: 'center'}}>
                <Link to="" style={{fontWeight: 'normal'}}>
                  <Translate contentKey="reset.request.backBtn"/>
                </Link>
              </div>
            </Box>
          </Paper>
        </Grid>
      </ThemeProvider>
    </Grid>

      <Dialog open={openMessageDialog} onClose={handleToClose}>
        <DialogTitle ></DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: "black"}} className='mb-3'>
            {getDialogContent()}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="mx-3">
          <DialogContentText>
            <ButtonReact color="primary" onClick={handleToClose} autoFocus>
              {translate('comexApp.quotation.okButton')}
            </ButtonReact>
          </DialogContentText>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default PasswordResetInit;
