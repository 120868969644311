import dayjs from 'dayjs';

export interface IComexSettings {
  id?: number;
  operationStartTime?: any;
  operationEndTime?: any;
  currencyRateRefresh?: any;
  serverStatus?: boolean;
  feeNormalValue?: number;
  feeMediumValue?: number;
  notifyCancelUserID?: string | null;
  diffHr?: number;
  diffMin?: number;
  copyright?: string | null;
  email?: string | null;
  hotline?: string | null;
}

export const defaultValue: Readonly<IComexSettings> = {
  serverStatus: true,
};
