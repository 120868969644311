
import './header.scss';
import '../linkcoloractive.scss';
import React, {useEffect, useMemo, useState} from 'react';
import { Storage, translate } from 'react-jhipster';
import { Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, Row } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';
import { isRTL } from 'app/config/translation';
import { AccountMenu, EntitiesMenu, LocaleMenu } from '../menus';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { setLocale } from 'app/shared/reducers/locale';
import moment from "moment/moment";
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Avatar, Badge, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CircleIcon from '@mui/icons-material/Circle';
import { Translate } from 'react-jhipster';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import Box from '@mui/material/Box';
import { AUTHORITIES, USER_TYPE } from 'app/config/constants';
import { locales, languages } from 'app/config/translation';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { styled, useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from "react-router-dom";
import {
  getAllAlshmokhAdminUsers, getAllAlshmokhStaffUsers,
  getUsersAsAdmin,
  updateUser, updateUserLanguage, updateUserLanguageForAllRoles
} from 'app/modules/administration/user-management/user-management.reducer';
import {
  getEntitiesByUserId as getNotificationByUserId,
  getCurrentEntitiesByUserId as getCurrentNotifiicationsByUserId,
  updateEntity as updateNotification,
  dismissedAllNotification,
  getBursaTimeOutNotiByUserId
} from "app/entities/notification-record/notification-record.reducer";
import { getEntity as getQuotationEntity, getEntityWithoutPending } from 'app/entities/quotation/quotation.reducer';
import {
  getAllEntities as getNotificationTemplates
} from 'app/entities/notification-template/notification-template.reducer';
import {
  getEntities as getBursaEmails
} from 'app/entities/notify-cancellation-emails/notify-cancellation-emails.reducer';

export interface IHeaderProps {
  isAuthenticated: boolean;
  currentLocale: string;
  account: any;
}

const Header = (props: IHeaderProps) => {
  const account = (props.account && Object.keys(props.account).length > 0) ? props.account : Storage.session.get("account");
  const notification = useAppSelector(state => state.notificationRecord.entities);
  const currentNotification = useAppSelector(state => state.notificationRecord.currentMonthEntities);
  const [fromCount,setFromCount]=useState(1);
  const [from, setFrom] = useState<Date | null>(moment().subtract(30, 'day').toDate());
  const [to, setTo] = useState<Date | null>(new Date());
  const history = useHistory();
  const [menuOpen, setMenuOpen] = useState(false);
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const user = useAppSelector(state => state.userManagement.user);
  const dismissedNotification = useAppSelector(state => state.notificationRecord.updateSuccess);
  const [maxWidth, setMaxWidth] = useState<DialogProps['maxWidth']>('sm');
  const [openBursaTimeOut, setOpenBursaTimeOut] = useState(false);
  const [failedQuotationIdText, setFailedQuotationIdText] = useState('');
  const [dateTextForFailedQuotation, setDateTextForFailedQuotation] = useState('');

  // useEffect(() => {
  //   dispatch(getNotifications({}));
  // }, [account]);

  const dispatch = useAppDispatch();

  // TEMPORARY NUMBER NOTIFICATION
  // let numberOfNotification = 0;
  // const totalNotif =notification && notification?.filter((n) => {
  //   if (!(n.dismissed)) {
  //     numberOfNotification++;
  //   }
  // });

  const totalNotificationFunction = () => {
    let numberOfNotification = 0;
    notification && notification?.filter((n) => {

      if (n?.notificationTemplate?.type !== 'timeOutBursa' && !(n.dismissed)) {
        numberOfNotification++;
      }
    });
    currentNotification && currentNotification?.filter((n) => {

      if (n?.notificationTemplate?.type !== 'timeOutBursa' && !(n.dismissed)) {
        numberOfNotification++;
      }
    });
    return numberOfNotification;
  };

  const handleUpdateNotification = (updateEntity) => {
    dispatch(updateNotification(updateEntity));

  };

  const handleLocaleChange = event => {
    // document.querySelector('html').setAttribute('dir', isRTL(account?.langKey) ? 'rtl' : 'ltr');
    const langKey = event.target.value;
    document.querySelector('html').setAttribute('dir', isRTL(langKey) ? 'rtl' : 'ltr');
    Storage.session.set('locale', langKey);
    Storage.local.set('locale', langKey);
    dispatch(setLocale(langKey));
    try {
      const updatedUser = {
        ...user,
        id: account?.id,
        langKey: event.target.value,
      };
      //       if (account?.authorities.includes(AUTHORITIES.ADMIN) || account?.authorities.includes(AUTHORITIES.ALSHMOKH_ADMIN))
      if (account?.authorities.includes(AUTHORITIES.ADMIN)) {
        dispatch(updateUserLanguage(updatedUser));
        // dispatch(getUsersAsAdmin({}));
      } else {
        dispatch(updateUserLanguageForAllRoles(updatedUser));
      }
    }
    catch (TypeError) {
      console.warn("Account not defined yet");
    }
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 0,
      top: 8,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
      textAlign: 'left',
    },
  }));

  const [menuClicked, setMenuClicked] = useState(false);
  const handleResponsiveMenu = () => {
    if (!menuClicked) setMenuClicked(true)
    else setMenuClicked(false);
  }

  const [dropdownMenu, setDropdownMenu] = useState(false);

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'inline',
    //     alignItems: 'center',
    //     justifyContent: 'flex-end',
    //     padding: theme.spacing(0, 1),
  }));
  const theme = useTheme();

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const drawerWidth = 265;
  const toggleDrawer = () => {
    if (!openDrawer) setOpenDrawer(true);
    else setOpenDrawer(false);
  };
  const toggleMenu = () => setMenuOpen(!menuOpen);
  const toggleAccountMenu = () => setAccountMenuOpen(!accountMenuOpen);
  const toggleUserMenu = () => setUserMenuOpen(!userMenuOpen);
  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */

  const handleOpenOrder = (orderId, statusType) => {
    if (statusType === "poApprovalRequested" || statusType === "poDelayApprovalForAlshmokhUser") {
      history.push(`/quotation/purchase/${orderId}`);
    } else {
      history.push(`/quotation/bought/${orderId}`);
    }
    dispatch(getQuotationEntity(orderId));
    setOpenDrawer(false);
  }

  const handleOpenOrderAndDismiss = (orderId, statusType, updateEntity) => {
    if (statusType === "poApprovalRequested" || statusType === "poDelayApprovalForAlshmokhUser") {
      history.push(`/quotation/purchase/${orderId}`);
    } else {
      history.push(`/quotation/bought/${orderId}`);
    }
    dispatch(getQuotationEntity(orderId));
    dispatch(updateNotification(updateEntity));
    setOpenDrawer(false);
  }

  const handleDismissedAll = () => {
    dispatch(dismissedAllNotification(account?.id));
  }


  const handleLoadMore = () => {
    const count=fromCount+1;
    setFromCount(count);
    const fromDateFormat=moment().subtract(count*30, 'day').toDate();
    const toDateFormat=moment().subtract(30, 'day').toDate();
    setTo(toDateFormat);
    setFrom(fromDateFormat);
    dispatch(getNotificationByUserId({ userId: account?.id,fromDate: moment(fromDateFormat).format('yyyy/MM/DD') ,toDate:moment(toDateFormat).add(1, 'day').format('yyyy/MM/DD') }));
  }

  const handleLogout = () => {
    window.localStorage.removeItem('tab');
    sessionStorage.removeItem('filterBank');
    sessionStorage.removeItem('filterBranch');
    sessionStorage.removeItem('filterCustomer');
    sessionStorage.removeItem('filterCommodity');
    sessionStorage.removeItem('filterDate');
    sessionStorage.removeItem('filterStatus');
    sessionStorage.removeItem('filterSearch');
    sessionStorage.removeItem('filterQuoSearch');
    sessionStorage.removeItem('otpStatus');
    Storage.local.remove('otpStatus');
    Storage.session.remove('otpStatus');
    sessionStorage.removeItem('jhi-authenticationToken');
    Storage.local.remove('jhi-authenticationToken');
    Storage.session.remove('jhi-authenticationToken');
    localStorage.removeItem('RememberMe');
    localStorage.removeItem('userListType');
    localStorage.removeItem('staff');
    localStorage.removeItem('customerPrint');
    localStorage.removeItem('isCustomerPrint');
    localStorage.removeItem('currency');
    localStorage.removeItem('printQuo');
    localStorage.removeItem('status');
    localStorage.removeItem('branch');
    localStorage.removeItem('print');
    localStorage.removeItem('tableView');
    localStorage.removeItem('tableViewFeeReports');
    localStorage.removeItem('commodity');
    localStorage.removeItem('userName');
    localStorage.removeItem('period');
    localStorage.removeItem('bank');
    localStorage.removeItem('totalAmount');
  }
  const userOffset = new Date().getTimezoneOffset() * 60 * 1000;

   useEffect(() => {
    // Session replication between tabs. IF RememberMe = 0. When all tabs are closed, need to remove the Token from localStorage
    if (account?.login && localStorage.getItem('RememberMe') === '0') {

      // If the currentTab === currentSession are found in the sessionStorage means the TAB was reloaded.
      // A reload is still considered as a "closed tab" so a "closeSession" property will be stored.
      // Because this is a TAB reloaded (not a closed one) need to remove it to allow other tabs to be opened as expected
      if( sessionStorage.getItem('currentTab') !== null && sessionStorage.getItem('currentSession') !== null
      && sessionStorage.getItem('currentTab') === sessionStorage.getItem('currentSession')
      && localStorage.getItem('closeSession') !== null ){
        localStorage.removeItem('closeSession');
      }

      if( localStorage.getItem('closeSession') !== null && localStorage.getItem('currentSession') !== null ){
        if( localStorage.getItem('closeSession') ===  localStorage.getItem('currentSession')){
          // If find in Local Storage closeSession === currentSession remove the Token
          localStorage.removeItem('jhi-authenticationToken');
        }
      }

      // Listener for Tab/Window close
      window.addEventListener('beforeunload', function(e) {
        const currentDate=new Date();
        /* If RememberMe = 0 Store the properties:
        * - CloseTab = Which Tab initiated the close request
        * - CloseTabTime = Time now (might be irrelevant now)
        * - closeSession = Which session to CLOSE = currentSession
        * - currentSession = created at loginTime
        * */
        if( localStorage.getItem('RememberMe') === '0' ){
          localStorage.removeItem('jhi-authenticationToken');
          localStorage.setItem('closeTab', sessionStorage.getItem('currentTab'));
          localStorage.setItem('closeTabTime', currentDate.getTime().toString());
          localStorage.setItem('closeSession', localStorage.getItem('currentSession'));

          // To troubleshoot: Store "TrackingHeader" in localStorage.
          // if( localStorage.getItem('TrackingHeader') !== null ){
          //   localStorage.setItem('TrackingHeader', (Number(localStorage.getItem('TrackingHeader'))+1).toString());
          // }else{
          //   localStorage.setItem('TrackingHeader', '1');
          // }
          // localStorage.setItem('DefaultTrackingHeader', 'Works');

        } // If RememberMe was selected. The local token will be kept in local storage
      }, false);

      if (!sessionStorage.getItem("jhi-authenticationToken") && localStorage.getItem("jhi-authenticationToken") !== null ){
        sessionStorage.setItem("jhi-authenticationToken", localStorage.getItem("jhi-authenticationToken"));
        sessionStorage.setItem("currentSession", localStorage.getItem("currentSession"));
        sessionStorage.setItem('currentTab', Date.now().toString());
      }
      // If the Tab reloads check that the session is shared again in the local storage
      if (!localStorage.getItem("jhi-authenticationToken") && sessionStorage.getItem("jhi-authenticationToken") !== null ){
        localStorage.setItem("jhi-authenticationToken", sessionStorage.getItem("jhi-authenticationToken"));
      }
    }else if( localStorage.getItem('RememberMe') !== '1'){
      window.location.href = '/login';
    }
  }, [account]);

  const settingsLink = () => {
    if (account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER)) {
      if (account?.authorities?.includes(AUTHORITIES.SUBADMIN_OPERATING_HOURS)) {
        return "account/stocksettings";
      } else if (account?.authorities?.includes(AUTHORITIES.SUBADMIN_CURRENCIES)) {
        return "account/currency";
      } else if (account?.authorities?.includes(AUTHORITIES.SUBADMIN_COMMODITY)) {
        return "account/commodity";
      } else if (account?.authorities?.includes(AUTHORITIES.SUBADMIN_BURSA_FEE)) {
        return "account/feesettings";
      } else if (account?.authorities?.includes(AUTHORITIES.SUBADMIN_NOTIFY_CANCELLATION)) {
        return "account/notifycancellation";
      }
    } else {
      return "account/stocksettings";
    }
  }
  const handleBursaTimeOutNotiToClose = () => {

    setOpenBursaTimeOut(false);
  };

  useEffect(() => {
    if (dismissedNotification){
      if(moment(from).format('yyyy/MM/DD')!==moment(moment().subtract(30, 'day').toDate()).format('yyyy/MM/DD')){
        dispatch(getNotificationByUserId({ userId: account?.id,fromDate: moment(from).format('yyyy/MM/DD') ,toDate:moment(to).add(1, 'day').format('yyyy/MM/DD')}));
        dispatch(getCurrentNotifiicationsByUserId({ userId: account?.id ,fromDate:  moment(moment().subtract(30, 'day').toDate()).format('yyyy/MM/DD') ,toDate:moment(new Date()).add(1, 'day').format('yyyy/MM/DD')}));
      }else{
        dispatch(getCurrentNotifiicationsByUserId({ userId: account?.id ,fromDate:  moment(moment().subtract(30, 'day').toDate()).format('yyyy/MM/DD') ,toDate:moment(new Date()).add(1, 'day').format('yyyy/MM/DD')}));
      }
    }

  }, [dismissedNotification]);

  // useEffect(() => {
  //   dispatch(getNotifications({}));
  // }, [props.isAuthenticated]);

  useEffect(() => {

    if (account?.id !== undefined) {
      dispatch(getCurrentNotifiicationsByUserId({ userId: account?.id,fromDate: moment(from).format('yyyy/MM/DD') ,toDate:moment(to).add(1, 'day').format('yyyy/MM/DD') }));
      if (account?.authorities?.includes(AUTHORITIES.ALSHMOKH_ADMIN) || account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER)) {
        dispatch(getBursaTimeOutNotiByUserId(account?.id)).then(
          res => {
            if (res.payload['data']?.id !== undefined && res.payload['data']?.id !== 0) {
              setFailedQuotationIdText(translate('home.failedQuotationId') + "" + res.payload['data']?.quotationId);
              setDateTextForFailedQuotation(translate('home.failedDate') + "" + moment(res.payload['data']?.createdDate).format('YYYY/MM/DD'));
              setOpenBursaTimeOut(true);

              const entity = {
                ...res.payload['data'],
                dismissed: true
              };
              dispatch(updateNotification(entity));
            }
          });
      }

      setInterval(() => {
        dispatch(getCurrentNotifiicationsByUserId({ userId: account?.id ,fromDate:  moment(from).format('yyyy/MM/DD') ,toDate:moment(to).add(1, 'day').format('yyyy/MM/DD')}));

        if (account?.authorities?.includes(AUTHORITIES.ALSHMOKH_ADMIN) || account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER)) {
          dispatch(getBursaTimeOutNotiByUserId(account?.id)).then(
            res => {
              if (res.payload['data']?.id !== undefined && res.payload['data']?.id !== 0) {
                setFailedQuotationIdText(translate('home.failedQuotationId') + "" + res.payload['data']?.quotationId);
                setDateTextForFailedQuotation(translate('home.failedDate') + "" + moment(res.payload['data']?.createdDate).format('YYYY/MM/DD'));
                setOpenBursaTimeOut(true);

                const entity = {
                  ...res.payload['data'],
                  dismissed: true
                };
                dispatch(updateNotification(entity));
              }
            });
        }
      }, 30000);
    }
  }, []);



  // useEffect(() => {
  //   if (account?.login) {
  //     if (account?.branchId) {
  //       branchList.map(branch => {
  //         if (String(branch.id) === account?.branchId) {
  //           dispatch(getBankEntity(branch.bank.id));
  //         }
  //       })
  //     } else {
  //       if (account?.authorities?.includes(AUTHORITIES.AREA_MANAGER)) {
  //         branchList.map(branch => {
  //           if(branch.areaManagerId === account?.id) {
  //             dispatch(getBankEntity(branch.bank.id));
  //           }
  //         })
  //       } else if (account?.authorities?.includes(AUTHORITIES.BANK_MANAGER)) {
  //         bankList.map(bank => {
  //           if(bank.userId === account?.id) {
  //             dispatch(getBankEntity(bank.id));
  //           }
  //         })
  //       }
  //     }
  //   }
  // }, [account]);
  const uniqueNotificationSet = new Set(notification);
  const uniqueNotificationArray = [...uniqueNotificationSet];
  const isBulkEnabled = useMemo(() => account?.isBulkTransactionEnabled ?? false, [account]);
  if (!account || Object.keys(account).length === 0 || !props.isAuthenticated) {
    return (
      <></>
    );
  } else {
    return (
      <div id="app-header">
        <LoadingBar className="loading-bar" />
        <Navbar data-cy="navbar" light expand="lg" fixed="top" className="jh-navbar">
          <Container>
            <div className="menu-navbar">
              <div className="nav-home">
                <div onClick={() => history.push('/home')} className="nav-links cursor-pointer">
                  <img className="imageLogo" src="content/images/img.png" alt="Logo" />
                </div>
                <div >
                  <div onClick={() => history.push('/home')} className="nav-links cursor-pointer">
                    <HomeIcon />
                    <div>
                      <Translate contentKey="global.menu.home" />
                    </div>
                  </div>
                </div>
              </div>
              <Nav id="header-tabs" className="ms-auto">
                <ul className="nav-links-mobile">
                  <div className="nav-links locale-icon-mobile">
                    <LocaleMenu currentLocale={props.currentLocale} onClick={handleLocaleChange} />
                  </div>
                  <li>
                    <a onClick={toggleDrawer} className="nav-links">
                      <StyledBadge badgeContent={totalNotificationFunction()} max={9} color="error">
                        <NotificationsNoneRoundedIcon />
                      </StyledBadge>
                      <div>
                        <Translate contentKey="global.menu.notifications" />
                      </div>
                    </a>
                  </li>
                  <li>
                    <div className="menu-icon mx-3" onClick={handleResponsiveMenu}>
                      {menuClicked ? (
                        <CloseIcon />
                      ) : (
                        <MenuIcon />
                      )}
                    </div>
                  </li>
                </ul>

                <ul className={menuClicked ? "nav-menu active" : "nav-menu"}>
                  <div className="nav-links" style={menuClicked ? { display: 'none' } : { display: 'flex' }}>
                    <LocaleMenu currentLocale={props.currentLocale} onClick={handleLocaleChange} />
                  </div>
                  {(account?.authorities?.includes(AUTHORITIES.ADMIN) || account?.authorities?.includes(AUTHORITIES.ALSHMOKH_ADMIN) ||
                    (account?.authorities?.includes(AUTHORITIES.SUBADMIN_BANK) || account?.authorities?.includes(AUTHORITIES.SUBADMIN_BRANCH))) &&
                    <li className='cursor-pointer'> <div onClick={() => history.push('/bank')} className="nav-links">
                      <AccountBalanceIcon sx={menuClicked ?
                        { display: 'none', width: 22, height: 21, paddingTop: 0.4, paddingBottom: 0, marginBottom: 0.3 } :
                        { display: 'flex', width: 22, height: 21, paddingTop: 0.4, paddingBottom: 0, marginBottom: 0.3 }} />
                      <div onClick={() => history.push('/bank')}>
                        <Translate contentKey="global.menu.banks" />
                      </div>
                    </div>
                    </li>}
                  {(account?.authorities?.includes(AUTHORITIES.ADMIN) || account?.authorities?.includes(AUTHORITIES.ALSHMOKH_ADMIN) ||
                    account?.authorities?.includes(AUTHORITIES.SUBADMIN_USER)) &&
                    <li className='cursor-pointer'>
                    <div className='nav-links' style={{marginBottom: '-20px'}}>
                      <Dropdown isOpen={userMenuOpen} toggle={toggleUserMenu}>
                        <span
                          onClick={toggleUserMenu}
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded={userMenuOpen}
                          className='dropdown-account'
                        >
                          <GroupIcon sx={menuClicked ?
                            { display: 'none', width: 28, height: 35, marginBottom: -0.9, marginTop: -0.5, paddingBottom: 0.5 } :
                            { display: 'flex', width: 28, height: 35, marginBottom: -0.9, marginTop: -0.5, paddingBottom: 0.5, marginRight: Storage.session.get('locale') === 'en' ? 1 : 3, marginLeft: Storage.session.get('locale') === 'en' ? 1 : 0 }} />
                          <div>
                            <Translate contentKey="global.menu.users" />
                          </div>
                        </span>
                        <DropdownMenu className='dropdown-user' style={{width: 'auto', marginTop: '52px'}}>
                          <DropdownItem
                            onClick={() => {
                              localStorage.setItem('userListType', USER_TYPE.STAFF);
                              history.push('/admin/user-management');
                            }}>
                            <Translate contentKey="global.menu.staff" />
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              localStorage.setItem('userListType', USER_TYPE.CUSTOMER);
                              history.push('/customer');
                            }}>
                            <Translate contentKey="global.menu.customer" />
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    </li>}
                  {(account?.authorities?.includes(AUTHORITIES.ADMIN) || account?.authorities?.includes(AUTHORITIES.ALSHMOKH_ADMIN) ||
                    (account?.authorities?.includes(AUTHORITIES.SUBADMIN_BURSA_FEE) || account?.authorities?.includes(AUTHORITIES.SUBADMIN_COMMODITY) ||
                      account?.authorities?.includes(AUTHORITIES.SUBADMIN_CURRENCIES) || account?.authorities?.includes(AUTHORITIES.SUBADMIN_NOTIFY_CANCELLATION) ||
                      account?.authorities?.includes(AUTHORITIES.SUBADMIN_OPERATING_HOURS))) && <li className='cursor-pointer'> <div onClick={() => history.push(`/${settingsLink()}`)} className="nav-links">
                        <SettingsIcon sx={menuClicked ?
                          { display: 'none', width: 23, height: 22, paddingTop: 0.1, marginBottom: 0.3 } :
                          { display: 'flex', width: 23, height: 22, paddingTop: 0.1, marginBottom: 0.3 }} />
                        <div>
                          <Translate contentKey="global.menu.appSetting" />
                        </div>
                      </div>
                    </li>}
                  {account?.authorities?.includes(AUTHORITIES.BANK_STAFF) && isBulkEnabled && <li className='cursor-pointer'> <div onClick={() => history.push('/upload-transaction')} className="nav-links">
                      <DescriptionOutlinedIcon sx={menuClicked ?
                        { display: 'none', width: 23, height: 22, paddingTop: 0.1, marginBottom: 0.3 } :
                        { display: 'flex', width: 23, height: 22, paddingTop: 0.1, marginBottom: 0.3 }} />
                      <div>
                        <Translate contentKey="global.menu.bulkTransaction" />
                      </div>
                    </div>
                  </li>}
                  <li style={menuClicked ? { display: 'none' } : { display: 'flex' }}>
                    <a onClick={toggleDrawer} className="nav-links">
                      <StyledBadge badgeContent={totalNotificationFunction()} max={9} color="error">
                        <NotificationsNoneRoundedIcon sx={menuClicked ?
                          { display: 'none', width: 20.5, height: 35, marginBottom: -0.9, marginTop: -0.5, paddingBottom: 0.5 } :
                          { display: 'flex', width: 20.5, height: 35, marginBottom: -0.9, marginTop: -0.5, paddingBottom: 0.5 }} />
                      </StyledBadge>
                      <div>
                        <Translate contentKey="global.menu.notifications" />
                      </div>
                    </a>
                  </li>
                  <div style={{ marginTop: -10 }}>
                    {props.isAuthenticated &&
                      account?.authorities?.includes(AUTHORITIES.ADMIN) &&
                      <EntitiesMenu account={account} />
                    }
                  </div>
                  <li>
                    <div className="menu-navbar account" style={{ alignItems: "center" }}>
                      <Dropdown isOpen={accountMenuOpen} toggle={toggleAccountMenu}>
                        <span
                          onClick={toggleAccountMenu}
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded={accountMenuOpen}
                          className="dropdown-account"
                        >
                          Hi, {account?.firstName ? account?.firstName : account?.lastName}
                        </span>
                        <DropdownMenu style={{ width: 'auto', marginTop: '32px' }}>
                          {/*  need to be fixed for bank staff view */}
                          {/*  <DropdownItem href={account?.authorities?.includes(AUTHORITIES.ADMIN) ? "/admin/user-management/admin/edit" : "/"}> */}
                          <DropdownItem onClick={() => history.push({
                              pathname: "/admin/user-management/" + account?.login + "/edit",
                              state: { fromMyAccount: true }
                            })}>
                            <Translate contentKey="global.menu.account.myAccount" />
                          </DropdownItem>
                          <DropdownItem href="/logout" onClick={() => handleLogout()}>
                            <Translate contentKey="global.menu.account.logout" />
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      &nbsp;
                      <Avatar>
                        {account?.firstName?.charAt(0).toUpperCase()}
                        {account?.lastName?.charAt(0).toUpperCase()}
                      </Avatar>
                    </div>
                  </li>
                </ul>
              </Nav>
            </div>
          </Container>
        </Navbar>
        <Dialog open={openBursaTimeOut} onClose={handleBursaTimeOutNotiToClose} maxWidth={maxWidth}>
          <DialogTitle></DialogTitle>
          <DialogContent>
            <DialogContentText>
              {translate('home.bursaError')}<br />
              {failedQuotationIdText}<br />
              {dateTextForFailedQuotation}<br />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleBursaTimeOutNotiToClose}>{translate('comexApp.quotation.okButton')}</Button>
          </DialogActions>
        </Dialog>
        <Drawer
          anchor={Storage.session.get('locale') === 'en' ? 'right' : 'left'}
          open={openDrawer}
          onClose={toggleDrawer}
          onClick={toggleDrawer}
          variant="permanent"
          className="drawer-arrow"
        >
          <DrawerHeader>
            <Box className="drawer-arrow-box">
              <IconButton onClick={toggleDrawer}>
                {openDrawer ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </Box>
          </DrawerHeader>
        </Drawer>

        <Drawer
          anchor={Storage.session.get('locale') === 'en' ? 'right' : 'left'}
          open={openDrawer}
          onClose={toggleDrawer}
          variant="persistent"
          className={Storage.session.get('locale') === 'en' ? 'drawer-content' : 'drawer-content arabic'}
        >
          <Box className="drawer-content-box">
            <IconButton onClick={toggleDrawer}>
              {openDrawer ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </Box>

          <div className='drawer-Scroll'>
            <div className="mx-1 mt-3">
              <Button className="mark-all-btn" size="small" onClick={() => handleDismissedAll()}>
                <Translate contentKey="global.rightMenuNotification.markAll" />
              </Button>
            </div>
            {
              currentNotification?.map(entity => {
                // eslint-disable-next-line no-console
                if (entity.notificationTemplate.type !== 'timeOutBursa') {
                  if (entity.dismissed) {
                    // eslint-disable-next-line no-console
                    return (
                      <Card key={entity.id} className="card-info read">
                        <CardContent>
                          <h6>
                            <Translate contentKey={`global.rightMenuNotification.${entity.notificationTemplate.type}`} interpolate={{ id: entity?.notificationTemplate?.message?.includes("quotation no.") ? entity?.quotationId : entity?.bursaAPIBidCert?.ecertNo }} />
                          </h6>
                          <p className="date-text">{moment.utc(entity.createdDate).local().calendar()}</p>
                        </CardContent>
                        <CardActions>
                          <Button size="small" sx={{ textTransform: 'none' }} onClick={() => handleOpenOrder(entity.quotationId, entity.notificationTemplate.type)}>
                            <Translate contentKey="global.rightMenuNotification.openOrder" />
                          </Button>
                        </CardActions>
                      </Card>)
                  } else {
                    const updateEntity = {
                      ...entity,
                      dismissed: true
                    };
                    return (
                      <Card key={entity.id} className="card-info unread">
                        <CardContent>
                          <h6>
                            <Translate contentKey={`global.rightMenuNotification.${entity.notificationTemplate.type}`} interpolate={{ id: entity?.notificationTemplate?.message?.includes("quotation no.") ? entity?.quotationId : entity?.bursaAPIBidCert?.ecertNo }} />
                          </h6>
                          <p className="date-text">{moment.utc(entity.createdDate).local().calendar()}</p>
                        </CardContent>
                        <CardActions>
                          <Button
                            size="small"
                            sx={{ textTransform: 'none' }}
                            onClick={() => handleOpenOrderAndDismiss(entity.quotationId, entity.notificationTemplate.type, updateEntity)}
                          >
                            <Translate contentKey="global.rightMenuNotification.openOrder" />
                          </Button>
                        </CardActions>
                      </Card>)
                  }

                }
              })
            }
            {
              uniqueNotificationArray?.map(entity => {
                // eslint-disable-next-line no-console
                if (entity.notificationTemplate.type !== 'timeOutBursa') {
                  if (entity.dismissed) {
                    // eslint-disable-next-line no-console
                    return (
                      <Card key={entity.id} className="card-info read">
                        <CardContent>
                          <h6>
                            <Translate contentKey={`global.rightMenuNotification.${entity.notificationTemplate.type}`} interpolate={{ id: entity?.notificationTemplate?.message?.includes("quotation no.") ? entity?.quotationId : entity?.bursaAPIBidCert?.ecertNo }} />
                          </h6>
                          <p className="date-text">{moment.utc(entity.createdDate).local().calendar()}</p>
                        </CardContent>
                        <CardActions>
                          <Button size="small" sx={{ textTransform: 'none' }} onClick={() => handleOpenOrder(entity.quotationId, entity.notificationTemplate.type)}>
                            <Translate contentKey="global.rightMenuNotification.openOrder" />
                          </Button>
                        </CardActions>
                      </Card>)
                  } else {
                    const updateEntity = {
                      ...entity,
                      dismissed: true
                    };
                    return (
                      <Card key={entity.id} className="card-info unread">
                        <CardContent>
                          <h6>
                            <Translate contentKey={`global.rightMenuNotification.${entity.notificationTemplate.type}`} interpolate={{ id: entity?.notificationTemplate?.message?.includes("quotation no.") ? entity?.quotationId : entity?.bursaAPIBidCert?.ecertNo }} />
                          </h6>
                          <p className="date-text">{moment.utc(entity.createdDate).local().calendar()}</p>
                        </CardContent>
                        <CardActions>
                          <Button
                            size="small"
                            sx={{ textTransform: 'none' }}
                            onClick={() => handleOpenOrderAndDismiss(entity.quotationId, entity.notificationTemplate.type, updateEntity)}
                          >
                            <Translate contentKey="global.rightMenuNotification.openOrder" />
                          </Button>
                        </CardActions>
                      </Card>)
                  }

                }
              })
            }
            {/* <div className="mx-1 mt-3">
              <Button className="mark-all-btn" size="small" onClick={() => handleLoadMore()}>
                <Translate contentKey="global.rightMenuNotification.loadMore" />
              </Button>
            </div> */}
            {/* <Card className="card-info">
                <div className="card-content">
                  <CircleIcon color="primary" sx={{ fontSize: 15, marginRight: '10px' }}></CircleIcon>
                  <Translate contentKey="global.rightMenuNotification.approvalRequest" />
                  <p style={{ marginLeft: '24px' }}>
                    <Translate contentKey="global.rightMenuNotification.orderNo" />
                    #1234
                  </p>
                </div>
                <CardActions sx={{ marginLeft: '24px' }}>
                  <Button size="small" sx={{ textTransform: 'none' }}>
                    <Translate contentKey="global.rightMenuNotification.openOrder" />
                  </Button>
                </CardActions>
              </Card>
              <Card className="card-info">
                <div className="card-content">
                  <CircleIcon color="warning" sx={{ fontSize: 15, marginRight: '10px' }}></CircleIcon>
                  <Translate contentKey="global.rightMenuNotification.cancelRequest" />
                  <p style={{ marginLeft: '24px' }}>
                    <Translate contentKey="global.rightMenuNotification.orderNo" />
                    #1234
                  </p>
                </div>
                <CardActions sx={{ marginLeft: '24px' }}>
                  <Button size="small" sx={{ textTransform: 'none' }}>
                    <Translate contentKey="global.rightMenuNotification.openOrder" />
                  </Button>
                </CardActions>
              </Card> */}

          </div>
        </Drawer>
      </div>
    );
  }


};

export default Header;
