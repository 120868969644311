import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import locale from './locale';
import authentication from './authentication';
import applicationProfile from './application-profile';

import administration from 'app/modules/administration/administration.reducer';
import userManagement from 'app/modules/administration/user-management/user-management.reducer';
import register from 'app/modules/account/register/register.reducer';
import activate from 'app/modules/account/activate/activate.reducer';
import password from 'app/modules/account/password/password.reducer';
import settings from 'app/modules/account/settings/settings.reducer';
import passwordReset from 'app/modules/account/password-reset/password-reset.reducer';
// prettier-ignore
import commodity from 'app/entities/commodity/commodity.reducer';
// prettier-ignore
import customer from 'app/entities/customer/customer.reducer';
// prettier-ignore
import quotation from 'app/entities/quotation/quotation.reducer';
// prettier-ignore
import bank from 'app/entities/bank/bank.reducer';
// prettier-ignore
import branch from 'app/entities/branch/branch.reducer';
// prettier-ignore
import currencyRates from 'app/entities/currency-rates/currency-rates.reducer';
// prettier-ignore
import comexSettings from 'app/entities/comex-settings/comex-settings.reducer';
// prettier-ignore
import notificationTemplate from 'app/entities/notification-template/notification-template.reducer';
// prettier-ignore
import notificationRecord from 'app/entities/notification-record/notification-record.reducer';
// prettier-ignore
import bursaAPIBidCert from 'app/entities/bursa-api-bid-cert/bursa-api-bid-cert.reducer';
// prettier-ignore
import bursaAPIOtcCert from 'app/entities/bursa-api-otc-cert/bursa-api-otc-cert.reducer';
// prettier-ignore
import bursaAPIStbCert from 'app/entities/bursa-api-stb-cert/bursa-api-stb-cert.reducer';

// prettier-ignore
import purchaseCancellation from 'app/entities/purchase-cancellation/purchase-cancellation.reducer';
// prettier-ignore
import bursaAPIOrder from 'app/entities/bursa-api-order/bursa-api-order.reducer';
// prettier-ignore
import bursaAPIOrderResult from 'app/entities/bursa-api-order-result/bursa-api-order-result.reducer';
// prettier-ignore
import bursaAPIEndPoints from 'app/entities/bursa-api-end-points/bursa-api-end-points.reducer';
// prettier-ignore
import currencyIndex from 'app/entities/currency-index/currency-index.reducer';
// prettier-ignore
import currencyPairsLog from 'app/entities/currency-pairs-log/currency-pairs-log.reducer';
// prettier-ignore
import comexBankFee from 'app/entities/comex-bank-fee/comex-bank-fee.reducer';
// prettier-ignore
import comexBankFeeLog from 'app/entities/comex-bank-fee-log/comex-bank-fee-log.reducer';
// prettier-ignore
import commodityLog from 'app/entities/commodity-log/commodity-log.reducer';
// prettier-ignore
import purchaseOrder from 'app/entities/purchase-order/purchase-order.reducer';
// prettier-ignore
import notifyCancellationEmails from 'app/entities/notify-cancellation-emails/notify-cancellation-emails.reducer';
// prettier-ignore
import authToken from 'app/entities/auth-token/auth-token.reducer';
// prettier-ignore
import banklog from 'app/entities/banklog/banklog.reducer';
// prettier-ignore
import commissionLog from 'app/entities/commission-log/commission-log.reducer';
// prettier-ignore
import bulkTransaction from 'app/entities/bulk-transaction/bulk-transaction.reducer';
// prettier-ignore
import bulkTransactionRecord from 'app/entities/bulk-transaction-record/bulk-transaction-record.reducer';
// prettier-ignore
import bankApiSetting from 'app/entities/bank-api-setting/bank-api-setting.reducer';

import iban from 'app/entities/quotation/iban-reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const rootReducer = {
  authentication,
  locale,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  commodity,
  customer,
  quotation,
  bank,
  branch,
  currencyRates,
  comexSettings,
  notificationTemplate,
  notificationRecord,
  bursaAPIBidCert,
  bursaAPIOtcCert,
  bursaAPIStbCert,
  purchaseCancellation,
  bursaAPIOrder,
  bursaAPIOrderResult,
  bursaAPIEndPoints,
  currencyIndex,
  currencyPairsLog,
  comexBankFee,
  comexBankFeeLog,
  commodityLog,
  purchaseOrder,
  notifyCancellationEmails,
  authToken,
  banklog,
  commissionLog,
  bulkTransaction,
  bulkTransactionRecord,
  bankApiSetting,
  iban,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
};

export default rootReducer;
