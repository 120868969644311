import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './purchase-order.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const PurchaseOrderDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const userAuthorities = useAppSelector(state => state.authentication.account.authorities);

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const purchaseOrderEntity = useAppSelector(state => state.purchaseOrder.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="purchaseOrderDetailsHeading">
          <Translate contentKey="comexApp.purchaseOrder.detail.title">PurchaseOrder</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{purchaseOrderEntity.id}</dd>
          <dt>
            <span id="poNumber">
              <Translate contentKey="comexApp.purchaseOrder.poNumber">Po Number</Translate>
            </span>
          </dt>
          <dd>{purchaseOrderEntity.poNumber}</dd>
        </dl>
        <Button tag={Link} to="/purchase-order" replace data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        {userAuthorities.filter((item: string) => [AUTHORITIES.ADMIN, AUTHORITIES.BANK_STAFF].includes(item)).length !== 0 && <Button tag={Link} to={`/purchase-order/${purchaseOrderEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>}
      </Col>
    </Row>
  );
};

export default PurchaseOrderDetail;
