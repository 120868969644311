import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import './reports.scss';
import {
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled, createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import {Storage, translate, Translate} from "react-jhipster";
import moment from 'moment';
import { getFeeReportsPrint } from 'app/entities/quotation/quotation.reducer';
import { getAllEntities as getBankEntities } from '../../entities/bank/bank.reducer';
import { getAllEntities as getBranchEntities } from '../../entities/branch/branch.reducer';
import { getUsers } from '../administration/user-management/user-management.reducer';


export const FeeReportPrintTemplate = () => {

  const componentRef = useRef();
  const dispatch = useAppDispatch();
  const reportsPrint = useAppSelector(state => state.quotation.reportPrint);
  const bankList = useAppSelector(state => state.bank.entities);
  const branchList = useAppSelector(state => state.branch.entities);
  const users = useAppSelector(state => state.userManagement.users);

  const htmlTag=document.querySelector("html");
  Storage.session.get('locale')==='en' ? htmlTag.setAttribute("dir",'ltr') : htmlTag.setAttribute("dir",'rtl');

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize:'0.55rem',
      fontWeight: 'bold',
      textAlign: Storage.session.get('locale') === 'en' ?'left':'right',
      overflow: 'hidden',
      textOverflow: 'clip',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize:'0.55rem',
      textAlign: Storage.session.get('locale') === 'en' ?'left':'right',
      overflow: 'hidden',
      textOverflow: 'clip',
    },
  }));

  const StyledTable = styled(Table)(({ theme }) => ({
    '&.MuiTable-root': {
      tableLayout: 'fixed',
    }
  }));

  useEffect(() => {
    dispatch(getBankEntities({}));
    dispatch(getBranchEntities({}));
    dispatch(getUsers({}));
  }, []);

  const splitDate = localStorage.getItem('period').split(" - ");
  const splitBank = localStorage.getItem('bank').split(", ");
  const splitBranch = localStorage.getItem('branch').split(", ");
  const splitBankUser = localStorage.getItem('userName').split(",");
  const splitStatus = localStorage.getItem('status').split(",");

  const bankArray = [];
  const branchArray = [];
  const bankUserArray = [];

  useEffect(() => {
    bankList?.map(b => {
      if (splitBank.includes(b?.name)) {
        bankArray.push(b?.id?.toString());
      }
    })

    branchList?.map(b => {
      if (splitBranch.includes(b?.name)) {
        branchArray.push(b?.id?.toString());
      }
    })

    users?.map(u => {
      if (splitBankUser.includes(u?.login)) {
        bankUserArray.push(u?.login?.toString());
      }
    })

  }, [splitBank, splitBranch, splitBankUser]);



  useEffect(() => {
    // moment.suppressDeprecationWarnings = true;
    // const obj = {
    //     bankId: splitBank.includes("All") ? "" : bankArray,
    //     branchId: splitBranch.includes("All") ? "" : branchArray,
    //     bankUserId: splitBankUser.includes("All") ? "" : bankUserArray,
    //     fromDate: moment(splitDate[0]).format('yyyy/MM/DD'),
    //     toDate: moment(splitDate[1]).add(1, 'day').format('yyyy/MM/DD'),
    // };
    //
    // dispatch(getFeeReportsPrint({
    //     ...obj,
    // }));

    const filterBank = JSON.parse(localStorage.getItem('filterBank') || '[]');
    const filterBranch = JSON.parse(localStorage.getItem('filterBranch') || '[]');
    const filterUser = JSON.parse(localStorage.getItem('filterUser') || '[]');
    const filterFromDate = localStorage.getItem('filterFromDate');
    const filterToDate = localStorage.getItem('filterToDate');

    const obj = {
      bankId: filterBank.length ? filterBank.map(item => item.value.toString()) : "",
      branchId: filterBranch.length ? filterBranch.map(item => item.value.toString()) : "",
      bankUserId: filterUser.length ? filterUser.map(item => item.label) : "",
      fromDate: filterFromDate,
      toDate: moment(filterToDate).add(1, 'day').format('yyyy/MM/DD'),
      status: splitStatus.includes("All") ? [] : splitStatus
    };

    dispatch(getFeeReportsPrint(obj));

}, []);

  useEffect(() => {
    const langLocal= localStorage.getItem('locale');
    const langSession= sessionStorage.getItem('locale');
    if(langLocal!==langSession){
      sessionStorage.setItem("locale",langLocal);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      const input = document.getElementById('divToPrint').innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = input;
      window.print();
    }, 10000);
    return () => clearTimeout(timer);
  },[]);

  const [checkboxState, setCheckboxState] = React.useState({
    quotationChecked: (Storage.session.get('tableViewFeeReports')) ? Storage.session.get('tableViewFeeReports').quotationChecked : true,
    poChecked: (Storage.session.get('tableViewFeeReports')) ? Storage.session.get('tableViewFeeReports').poChecked : true,
    dateChecked: (Storage.session.get('tableViewFeeReports')) ? Storage.session.get('tableViewFeeReports').dateChecked : true,
    customerChecked: (Storage.session.get('tableViewFeeReports')) ? Storage.session.get('tableViewFeeReports').customerChecked : true,
    bankChecked: (Storage.session.get('tableViewFeeReports')) ? Storage.session.get('tableViewFeeReports').bankChecked : true,
    branchChecked: (Storage.session.get('tableViewFeeReports')) ? Storage.session.get('tableViewFeeReports').branchChecked : true,
    bankUserChecked: (Storage.session.get('tableViewFeeReports')) ? Storage.session.get('tableViewFeeReports').bankUserChecked : true,
    feeAmountChecked: (Storage.session.get('tableViewFeeReports')) ? Storage.session.get('tableViewFeeReports').feeAmountChecked : true,
    feeCurrencyChecked: (Storage.session.get('tableViewFeeReports')) ? Storage.session.get('tableViewFeeReports').feeCurrencyChecked : true,
    statusChecked: (Storage.local.get('tableViewFeeReports')) ? Storage.local.get('tableViewFeeReports').statusChecked : true,
  });

  const {
    quotationChecked,
    poChecked,
    dateChecked,
    customerChecked,
    bankChecked,
    branchChecked,
    bankUserChecked,
    feeAmountChecked,
    feeCurrencyChecked,
    statusChecked,
  } = checkboxState;

  const getStaffFullName = (createdUser) => {
    if (!createdUser || typeof createdUser !== 'object') {
      return '';
    }

    const { firstName, lastName } = createdUser;
    if (!firstName || !lastName) {
      return '';
    }

    const fullName = `${firstName} ${lastName}`;
    return fullName;
  };

  const setPageSize = () => {
    const style = document.createElement('style');
    style.innerHTML = `@page {size: a4 landscape !important;}`;
    style.id = 'page-oritentation';
    document.head.appendChild(style);
  };

  useEffect(() => {
    setPageSize();
    return () => {
      const child = document.getElementById('page-orientation');
      if (child && child.parentNode) {
        child.parentNode.removeChild(child);
      }
    };
  }, []);

  function translateStatus(q) {
    if(q?.cancelOrder){
      if(Storage.session.get('locale') === 'en') return "Cancelled";
      else return "ملغاة";
    }
    else{
      if(Storage.session.get('locale') === 'en') return q?.status;
      else{
        switch (q?.status) {
          case "Kept":
            return "احتفاظ";
          case "Transferred":
            return "تحويل للعميل";
          case "Sold":
            return "البيع";
          case "Quoted":
            return "عرض سعر";
          case "Bought":
            return "شراء";
          default:
            return "";
        }
      }
    }
  }

  return (
    <>
       <div className="App container mt-5" style={{height: '1100px'}}>
          <div id="divToPrint" ref={componentRef}>
            <div id="headerPrint" style={{margin: '24px 48px 24px 48px'}}>
              <Grid container spacing={2} style={{alignItems: 'center'}}>
                <Grid item xs={6} className="left-side-component-flex">
                  <Typography variant='h4' component='div'> <Translate contentKey= "global.menu.report-print.title" /></Typography>
                </Grid>
                <Grid item xs={6} className="right-side-component-flex">
                  <img src="../content/images/img.png" width={'120px'}/>
                </Grid>
              </Grid>
            </div>

            <Divider sx={{borderBottomWidth: 3, marginLeft: '20px', marginRight: '20px', color: '#01579b'}}/>

            <div id='quotationTablePrint' style={{marginLeft: '20px', marginRight: '20px'}}>
              <Grid container spacing={2}>
                <Grid item xs={8} style={{lineHeight: 1}}>
                  <div style={{lineHeight: 1, marginTop: '24px', marginBottom: '12px'}}>
                    <Typography variant='subtitle1'><strong><Translate contentKey= "global.menu.report-print.order" /></strong></Typography>
                    <Typography variant='subtitle1'><Translate contentKey= "global.menu.report-print.period" /> : {localStorage.getItem('period')}</Typography>
                  </div>
                </Grid>
                <Grid item xs={4} style={{lineHeight: 5}} justifyContent="flex-end">
                  <div style={{lineHeight: 1, marginTop: '24px', marginBottom: '19px', overflow: 'hidden', textOverflow: 'clip', wordWrap: 'break-word'}}>
                    <Typography variant='subtitle1'><strong><Translate contentKey= "global.menu.report-print.bankInfo" /></strong></Typography>
                    <Typography variant='subtitle1'><Translate contentKey= "global.menu.report-print.bank" /> : {Storage.local.get('bank')}</Typography>
                    <Typography variant='subtitle1'><Translate contentKey= "global.menu.report-print.branch" /> : {localStorage.getItem('branch')}</Typography>
                    <Typography variant='subtitle1'><Translate contentKey= "global.menu.report-print.bankUser" /> : {localStorage.getItem('userName')}</Typography>
                  </div>
                </Grid>
              </Grid>
              <TableContainer>
                {/* <Divider sx={{ borderBottomWidth: 3, color: '#01579b'}} /> */}
                <StyledTable>
                  <TableHead>
                    <Grid justifyContent="centre">
                  <div style={{lineHeight: 1, marginTop: '24px', marginBottom: '12px'}}>
                    <TableRow>
                      <StyledTableCell hidden={!quotationChecked}>{translate('home.table.orderNo')}</StyledTableCell>
                      <StyledTableCell hidden={!poChecked}>{translate('home.table.poNo')}</StyledTableCell>
                      <StyledTableCell hidden={!dateChecked}>{translate('home.table.date')}</StyledTableCell>
                      <StyledTableCell hidden={!customerChecked}>{translate('home.table.customerName')}</StyledTableCell>
                      <StyledTableCell hidden={!bankChecked}>{translate('home.table.bankName')}</StyledTableCell>
                      <StyledTableCell hidden={!branchChecked}>{translate('home.table.branchName')}</StyledTableCell>
                      <StyledTableCell hidden={!bankUserChecked}>{translate('home.table.bankUserName')}</StyledTableCell>
                      <StyledTableCell hidden={!feeAmountChecked}>{translate('home.table.feeAmount')}</StyledTableCell>
                      <StyledTableCell hidden={!feeCurrencyChecked}>{translate('home.table.feeCurrency')}</StyledTableCell>
                      <StyledTableCell hidden={!statusChecked}>{translate('home.table.status')}</StyledTableCell>
                    </TableRow>
                    </div>
                    </Grid>
                  </TableHead>

                  <TableBody>
                    { reportsPrint?.map((q, index) => (
                      <TableRow key={index}>
                        <StyledTableCell hidden={!quotationChecked}>{q?.id}</StyledTableCell>
                        <StyledTableCell hidden={!poChecked}>{q?.bursaAPIBidCertId !== null ? q?.bursaAPIBidCert?.ecertNo : '-'}</StyledTableCell>
                        <StyledTableCell hidden={!dateChecked}>{moment(q?.createdDate).format('yyyy/MM/DD')}</StyledTableCell>
                        <StyledTableCell hidden={!customerChecked}>{q?.customer.name}</StyledTableCell>
                        <StyledTableCell hidden={!bankChecked}>{q?.bank.name}</StyledTableCell>
                        <StyledTableCell hidden={!branchChecked}>{q?.branch.name}</StyledTableCell>
                        <StyledTableCell hidden={!bankUserChecked}>{getStaffFullName(q?.createdUser)}</StyledTableCell>
                        <StyledTableCell hidden={!feeAmountChecked}>{((q?.fee || 0) + (q?.commission || 0)).toLocaleString()}</StyledTableCell>
                        <StyledTableCell hidden={!feeCurrencyChecked}>{q?.feeCurrencyIndex}</StyledTableCell>
                        <StyledTableCell hidden={!statusChecked}>{translateStatus(q)}</StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </StyledTable>
              </TableContainer>
            </div>
            <div id='quotationTablePrint' style={{margin: '48px'}}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div style={{lineHeight: 1, marginBottom: '19px'}}>
                    <Typography variant='subtitle1'><Translate contentKey= "global.menu.report-print.summary" /></Typography>
                    <hr/>
                  </div>
                  <div style={{lineHeight: 1, marginBottom: '12px'}}>
                    <Typography variant='subtitle1'><Translate contentKey= "global.menu.report-print.comment" /></Typography>
                    <hr/>
                  </div>
                </Grid>
              </Grid>
            </div>

            <Typography variant='subtitle1' style={{margin: '48px'}}> <Translate contentKey= "global.menu.report-print.signature" /> : _________________________</Typography>

            <Divider sx={{borderBottomWidth: 3, marginLeft: '48px', marginRight: '48px', color: '#01579b'}}/>

            <div id="informationPrint" style={{margin: '24px 48px 24px 48px'}}>
              <Grid container>
                <Grid item xs={4} style={{lineHeight: 1, display: 'inline-flex', justifyContent: 'center'}}>
                  <div>
                    {/* <Typography variant='body1' ><strong>Bank</strong></Typography> */}
                  </div>
                </Grid>
                <Grid item xs={4} style={{lineHeight: 1, display: 'inline-flex', justifyContent: 'center'}}>
                  <div>
                    {/* <Typography variant='body1' ><strong>Alsmokh</strong></Typography> */}
                  </div>
                </Grid>
                <Grid item xs={4} style={{lineHeight: 1, display: 'inline-flex', justifyContent: 'center'}}>
                  <div>
                    {/* <Typography variant='body1' ><strong>Created By</strong></Typography> */}
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>

    </>
    );
}

export default FeeReportPrintTemplate;
