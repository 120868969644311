
import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import 'app/config/dayjs.ts';

import React, { useEffect } from 'react';
import { Button, Card } from 'reactstrap';
import {BrowserRouter as Router} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getAccount, getSession } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import { setLocale } from 'app/shared/reducers/locale';
import Header from 'app/shared/layout/header/header';
import Footer from 'app/shared/layout/footer/footer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { AUTHORITIES } from 'app/config/constants';
import AppRoutes from 'app/routes';
import { Storage, translate } from "react-jhipster";
import { isRTL } from './config/translation';
import { useLocation } from 'react-router-dom'
import OtpPage from './modules/account/otp/otp';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle } from '@mui/material';
import { useHistory } from "react-router-dom";

import { generateOTPByLogin } from "app/modules/administration/user-management/user-management.reducer";
const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export const App = () => {
  const dispatch = useAppDispatch();
  const [otpRequired, setOtpRequired] = React.useState(false);
  // const defaultCurrency = currencyIndexList?.filter(c => c?.id === branch2?.bank?.currencyId || c?.id === banks[0]?.currencyId || c?.id === branches[0]?.bank?.currencyId );

  const verifyOTPstatus = useAppSelector(state => state.userManagement.verifyOTP);
  const otpStatus = verifyOTPstatus ? true : ((sessionStorage.getItem('otpStatus') !==null && sessionStorage.getItem('otpStatus') === "1" ) ? true : false || (Storage.session.get('otpStatus')!==null && Storage.session.get('otpStatus') === true) ? true : false || (Storage.local.get('otpStatus')!==null && Storage.local.get('otpStatus') === true) ? true : false);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');
  const history = useHistory();

  useEffect(() => {
    // Handles session replication between tabs. 
    window.addEventListener('storage', function (event) {
      // If a new Tab doesn't have the Token in session, take it from the localStorage.
      if (!sessionStorage.getItem("jhi-authenticationToken") && localStorage.getItem("jhi-authenticationToken") !== null ){
        // Save token to this TAB's session
        sessionStorage.setItem("jhi-authenticationToken", localStorage.getItem("jhi-authenticationToken"));
        // Save existing session to this TAB's session
        sessionStorage.setItem("currentSession", localStorage.getItem("currentSession"));        
        // Create an identifier (time now) for this new TAB (will be used to compare against other tabs when closing them)
        sessionStorage.setItem('currentTab', Date.now().toString());
      }      
      // If another tab is still open, remove the closeSession instruction
      if( sessionStorage.getItem('currentTab') !== null && localStorage.getItem('closeTab') !== sessionStorage.getItem('currentTab') 
        && localStorage.getItem('closeSession') !== null && localStorage.getItem('closeTabTime') && sessionStorage.getItem("jhi-authenticationToken") !== null ){
          // Anothar tab was closed. Storing "closeSession" If it's a different TAB. Remove it, to let other tabs be reopened
          localStorage.removeItem('closeTab');
          localStorage.removeItem('closeTabTime');
          localStorage.removeItem('closeSession');
          // Restore the Token in localStorage
          localStorage.setItem('jhi-authenticationToken', sessionStorage.getItem("jhi-authenticationToken"));
      }
    });
  }, []);

  useEffect(() => {
   (Storage.session.get('jhi-authenticationToken') || Storage.local.get('jhi-authenticationToken')) && dispatch(getSession());
    dispatch(getProfile());
   }, []);
  
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const account = useAppSelector(state => state.authentication.account);
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);

  const paddingTop = '72px';
  useEffect(()=>{
    if(account?.login !== undefined &&  account?.otp === "1"  && !otpStatus)
       dispatch(generateOTPByLogin(account?.login));
   },[account,otpStatus]);

  useEffect(() => {
    let langKey: string;

    if (!account || Object.keys(account).length === 0) {
      langKey = Storage.session.get('locale') || 'ar-ly';
    } else {
      langKey = account.langKey;
    }

    Storage.session.set('locale', langKey);
    dispatch(setLocale(langKey));
    document.querySelector('html').setAttribute('dir', isRTL(langKey) ? "rtl" : "ltr");

  }, [account]);

  useEffect(() => {
    if (!Storage.session.get("account") || Object.keys(Storage.session.get("account")).length === 0) {
      Storage.session.set("account", account);
    };
  })
  const location = useLocation()

  // useEffect(() => {
  //   const reloadCount = sessionStorage.getItem('reloadCount');
  //   if(location.pathname==='/login'){
  //   if(+reloadCount < 2) {
  //     sessionStorage.setItem('reloadCount', String(reloadCount + 1));
  //     window.location.reload();
  //   } else {
  //     sessionStorage.removeItem('reloadCount');
  //   }}
  // }, [location]);

  return (
    <Router basename={baseHref} >
      <div className="app-container" style={{ paddingTop: isAuthenticated && (account?.otp !== "1" || (account?.otp === "1" && otpStatus)) ? paddingTop : 0 }}>
        {(isAuthenticated && (account?.otp !== "1" || (account?.otp === "1" && otpStatus))) && <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />}
        {(isAuthenticated && (account?.otp !== "1" || (account?.otp === "1" && otpStatus))) && <ErrorBoundary>
          <Header
            isAuthenticated={isAuthenticated}
            account={account}
            currentLocale={currentLocale}
          />
        </ErrorBoundary>}
        <div className="container-fluid view-container" id="app-view-container">
          <ErrorBoundary>
            <AppRoutes />
            {/* {showDialog && !dialogShown &&  account?.authorities?.includes(AUTHORITIES.ADMIN) && (
                <Dialog open={true} onClose={handleDialogClose}>
                  <DialogTitle></DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                    {translate('home.bursaError')}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleDialogClose}>{translate('comexApp.quotation.okButton')}</Button>
                  </DialogActions>
                </Dialog>
              )} */}
          </ErrorBoundary>
        </div>
        <Footer />
      </div>
    </Router>
  );

};

export default App;
