
import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import 'bootstrap/dist/css/bootstrap.min.css';
// import './reports.scss';
import {
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled, createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import {Storage, translate, Translate} from "react-jhipster";
import moment from 'moment';
import { getEntities as getBankEntities } from "app/entities/bank/bank.reducer";

export const CustomerPrintTemplate = () => {

  const componentRef = useRef();
  const bankList = useAppSelector(state => state.bank.entities);
  const [customersPrint, setCustomersPrint] = useState([]) as any;

  const htmlTag = document.querySelector("html");
  Storage.session.get('locale') === 'en' ? htmlTag.setAttribute("dir",'ltr') : htmlTag.setAttribute("dir",'rtl');

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize:'0.55rem',
      fontWeight: 'bold',
      textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize:'0.55rem',
      textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
    },
  }));

  useEffect(() => {
    setCustomersPrint(JSON.parse(localStorage.getItem('customerPrint')));
  }, []);

  useEffect(() => {
    const langLocal = localStorage.getItem('locale');
    const langSession = sessionStorage.getItem('locale');
    if(langLocal !== langSession){
      sessionStorage.setItem("locale",langLocal);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      const input = document.getElementById('divToPrint').innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = input;
      window.print();
    }, 1000);
    return () => clearTimeout(timer);
  },[]);

  const setPageSize = () => {
    const style = document.createElement('style');
    style.innerHTML = `@page {size: a4 landscape !important;}`;
    style.id = 'page-orientation';
    document.head.appendChild(style);
  };

  useEffect(() => {
    setPageSize();
    return () => {
      const child = document.getElementById('page-orientation');
      child.parentNode.removeChild(child);
    };
  }, []);

  const rows = customersPrint?.map((obj, i)=>({
    ...obj,
  }));

  return (
    <>
      <div className="App container mt-5" style={{height: '1100px'}}>
        <div id="divToPrint" ref={componentRef}>
          <div id="headerPrint" style={{margin: '24px 48px 24px 48px'}}>
            <Grid container spacing={2} style={{alignItems: 'center'}}>
              <Grid item xs={6} className="left-side-component-flex">
                <Typography variant='h4' component='div'> <Translate contentKey= "comexApp.customer.home.title" /></Typography>
              </Grid>
              <Grid item xs={6} className="right-side-component-flex">
                <img src="../content/images/img.png" width={'120px'}/>
              </Grid>
            </Grid>
          </div>

          <Divider sx={{borderBottomWidth: 3, marginLeft: '48px', marginRight: '48px', color: '#01579b'}}/>
          <div id='quotationTablePrint' style={{margin: '48px'}}>
            <TableContainer>
              {/* <Divider sx={{ borderBottomWidth: 3, color: '#01579b'}} /> */}
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>{translate('comexApp.customer.name')}</StyledTableCell>
                    <StyledTableCell>{translate('comexApp.customer.ic')}</StyledTableCell>
                    <StyledTableCell>{translate("comexApp.customer.nationality")}</StyledTableCell>
                    <StyledTableCell>{translate("comexApp.customer.tel")}</StyledTableCell>
                    <StyledTableCell>{translate("comexApp.customer.email")}</StyledTableCell>
                    <StyledTableCell>{translate("userManagement.bank")}</StyledTableCell>
                    <StyledTableCell>{translate("home.branch")}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((q, index) => (
                    <TableRow key={index}>
                      <StyledTableCell>{q?.name}</StyledTableCell>
                      <StyledTableCell>{q?.ic}</StyledTableCell>
                      <StyledTableCell>{q?.nationality}</StyledTableCell>
                      <StyledTableCell>{q?.tel}</StyledTableCell>
                      <StyledTableCell>{q?.email}</StyledTableCell>
                      <StyledTableCell>{q?.bankName}</StyledTableCell>
                      <StyledTableCell>{q?.branchName}</StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Typography variant='subtitle1' style={{margin: '48px'}}> <Translate contentKey= "global.menu.report-print.signature" /> : _________________________</Typography>
          <Divider sx={{borderBottomWidth: 3, marginLeft: '48px', marginRight: '48px', color: '#01579b'}}/>
        </div>
      </div>
    </>
  );
}

export default CustomerPrintTemplate;
