import React, { useEffect, useState, useRef } from 'react';
import { Translate, translate, ValidatedField, ValidatedForm, isEmail, Storage } from 'react-jhipster';
import { Alert, Col, Row, DropdownItem } from 'reactstrap';
import { toast } from 'react-toastify';
import { Link, Redirect, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {
  Box,
  CardMedia,
  createTheme, Dialog, DialogActions,
  DialogContent, DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import TranslateIcon from '@mui/icons-material/Translate';
import Paper from "@mui/material/Paper";
import InputAdornment from "@mui/material/InputAdornment";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Button as ButtonReact } from "reactstrap";
import { Button } from "@material-ui/core";
import { isRTL, languages, locales } from "app/config/translation";
import { setLocale } from "app/shared/reducers/locale";
import Card from "@mui/material/Card";
import { NavDropdown } from "app/shared/layout/menus/menu-components";
import { responsiveFontSizes } from "@mui/material/styles";
import { styled, ThemeProvider } from "@mui/styles";

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { AUTHORITIES } from "app/config/constants";
import {
  updateUserLanguage,
  updateUserLanguageForAllRoles,
  generateOTPByLogin,verifyOTP
} from "app/modules/administration/user-management/user-management.reducer";

import OTPInput from 'react-otp-input';
import { width } from '@mui/system';
import { color } from 'html2canvas/dist/types/css/types/color';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formRoot: {
      paddingLeft: 45,
      paddingRight: 45,
      marginBottom: 30,
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 15,
        paddingRight: 15,
      },
    },
    headerText: {
      margin: '30px 8px 5px  50px',
      [theme.breakpoints.down("xs")]: {
        margin: '50px 8px 5px 10px',
      },
      [theme.breakpoints.down("sm")]: {
        margin: '50px 8px 5px  30px',
      },
    },
    textFieldLabel: {
      "& .MuiFormLabel-root": {
        marginLeft: '32px',
        marginTop: '10px',
      }
    },
    textFieldLabelArabic: {
      "& .MuiFormLabel-root": {
        marginTop: '10px',
        position: 'relative',
        right: '42px',
        transform: 'translateY(20px)',
      }
    },

  })
);

export const OtpPage = () => {
  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);
  const user = useAppSelector(state => state.userManagement.user);
  const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset" };
  const [resetPasswordEmail, setResetPasswordEmail] = useState('');
  const [openMessageDialog, setOpenMessageDialog] = React.useState(false);
  const [otp, setOtp] = useState('');
  const [remainingTime, setRemainingTime] = useState(300);
  const [isOtpError, setIsOtpError] = useState(false);
  const verifyOTPUpdateStatus = useAppSelector(state => state.userManagement.updateSuccess);
  const [invalidOTP,setInvalidOTP]=useState(true);
  const verifyOTPUpdating = useAppSelector(state => state.userManagement.updating);
  const verifyOTPstatus = useAppSelector(state => state.userManagement.verifyOTP);
  const history = useHistory();

  const handleLogout = () => {
    window.localStorage.removeItem('tab');
    sessionStorage.removeItem('filterBank');
    sessionStorage.removeItem('filterBranch');
    sessionStorage.removeItem('filterCustomer');
    sessionStorage.removeItem('filterCommodity');
    sessionStorage.removeItem('filterDate');
    sessionStorage.removeItem('filterStatus');
    sessionStorage.removeItem('filterSearch');
    sessionStorage.removeItem('filterQuoSearch');
    sessionStorage.removeItem('otpStatus');
    localStorage.removeItem('otpStatus');
    setTimeout(() => {
      window.location.reload();
    }, 2000);
    setIsOtpError(false);
  }

  // useEffect(() => {

  //   if (verifyOTPstatus && verifyOTPUpdateStatus) {
  //     setInvalidOTP(true);
  //     sessionStorage.setItem('otpStatus', "1");
  //     history.push('/');
  //     Storage.session.set("otpStatus", true);
  //     Storage.local.set('otpStatus', true);
  //   } else if (!verifyOTPstatus && verifyOTPUpdateStatus) {
  //     setInvalidOTP(false);
  //     sessionStorage.setItem('otpStatus', "0");
  //     Storage.session.set("otpStatus", false);
  //     Storage.local.set('otpStatus', false);
  //   }
  // }, [verifyOTPstatus, verifyOTPUpdateStatus]);

  useEffect(() => {
    // update the remaining time every second
    const timer = setInterval(() => {
      if (remainingTime > 0) {
        setRemainingTime(prevTime => prevTime - 1);
      }
    }, 1000);

    // clear the timer when the component unmounts
    return () => clearInterval(timer);
  }, [remainingTime]);
  function formatTime(time) {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
  function handleSendOTP() {
    // simulate sending the OTP
    setTimeout(() => {
      alert('OTP sent!');
      setRemainingTime(300); // set remaining time to 5 minutes
    }, 5000); // wait for 5 seconds before sending the OTP
  }


  const handleClearOTP = () => {
    setOtp('');
  }
  // const handleValidSubmit = () => {
  //
  //   dispatch(verifyOTP(otp));
  //   if(verifyOTPstatus === false){ setIsOtpError(true); }
  // };

  const handleValidSubmit = async () => {
    const result = await dispatch(verifyOTP(otp));
    if (result.meta.requestStatus === 'rejected') {
      setIsOtpError(true);
    } else if (result.meta.requestStatus === 'fulfilled') {
      const payload = result.payload as { data: boolean };
      if (payload.data === false) {
        setIsOtpError(true);
      }
    }
  };

  const handleToClose = () => {
    setOpenMessageDialog(false);
  };

  const successMessage = useAppSelector(state => state.passwordReset.successMessage);

  useEffect(() => {
    if (successMessage) {
      toast.success(translate(successMessage));
    }
  }, [successMessage]);

  const otpRef = useRef(otp);
  useEffect(() => {
    otpRef.current = otp;
  }, [otp]);

  const handleLocaleChange = event => {
    setOtp(otpRef.current);
    const langKey = event.target.value;
    document.querySelector('html').setAttribute('dir', isRTL(langKey) ? 'rtl' : 'ltr');
    Storage.session.set('locale', langKey);
    dispatch(setLocale(langKey));
    try {
      const updatedUser = {
        ...user,
        id: account.id,
        langKey: event.target.value,
      };
      if (account?.authorities.includes(AUTHORITIES.ADMIN))
        dispatch(updateUserLanguage(updatedUser));
      else
        dispatch(updateUserLanguageForAllRoles(updatedUser));
    }
    catch (TypeError) {
      console.warn("Account not defined yet");
    }
  };


  const classes = useStyles();
  let responsiveFont = createTheme();
  responsiveFont = responsiveFontSizes(responsiveFont);

  return (
    <div>

      <Grid container>
        <ThemeProvider theme={responsiveFont}>
          <Grid item xs={12} md={7}>
            <Paper elevation={0} >
              <Card >
                <div style={{ position: "relative" }}>
                  <CardMedia sx={{
                    height: { md: '90vh', sm: '100%', xs: '93vh' },
                    width: '100%',
                    objectFit: 'cover'
                  }} component="img" image={"../content/images/background.png"} alt="Background" />
                  <div className="backgroundTitlePosition">
                    <Typography variant="h4" className="text-sm-center text-xs-left" ml={1} sx={{ fontFamily: 'Inria Sans', fontWeight: 300, fontSize: { md: '2.5rem', sx: '2.2rem' } }}>
                      INTERNATIONAL TRADE & DEVELOPMENT
                    </Typography>
                    <div className="text-sm-center text-sm-justify mt-3 " id="leadText">
                      <Typography variant="h5" ml={1}>
                        Dedicated to Growth, Providing
                      </Typography>
                      {Storage.session.get('locale') === 'en' ? (<Typography variant="h5" ml={1}>
                        Opportunity - Using Experience,
                      </Typography>) : (<Typography variant="h5" ml={1}>
                        ,Opportunity - Using Experience
                      </Typography>)}
                      <Typography variant="h5" ml={1}>
                        Tradition & Technology to Build Our  Future
                      </Typography>
                    </div>
                  </div>
                </div>
              </Card>
            </Paper>
          </Grid>
          <Grid item xs={12} md={5}>
            <Paper elevation={0} >
              <Row>
                <Col xs={7} md={8}>
                  <Grid container direction="row" className={classes.headerText} style={{ alignItems: 'center' }}>
                    <Grid item >
                      <Box
                        component="img"
                        sx={{
                          maxWidth: { xs: 60, md: 70 },
                          mr: { xs: 2 },
                          ml: { xs: 2, md: 2 },
                          p: 0,
                        }}
                        alt="logo"
                        src="../content/images/img.png"
                      />
                    </Grid>
                    <Grid item>
                      <div className="text-class">
                        {Storage.session.get('locale') === 'ar-ly' ? (
                          <Typography variant="subtitle1">
                            <Translate contentKey="login.logoTitle.fullTitle" />
                          </Typography>
                        ) : (
                          <>
                            <Typography variant="subtitle1">
                              <Translate contentKey="login.logoTitle.nameTitle" />
                            </Typography>
                            <Typography variant="subtitle1">
                              <Translate contentKey="login.logoTitle.systemTitle" />
                            </Typography>
                          </>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Col>
                <Col xs={4} md={4}>
                  <div className='languageSection'>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <NavDropdown icon="language" name={
                        <div className="locale-icon-login">
                          <TranslateIcon
                            sx={{
                              fontSize: '24px',
                              marginTop: { md: 0, sm: '16px', xs: '-24px' }
                            }} />
                          <Translate contentKey="global.menu.language" />
                        </div>
                      }>
                        {locales.map(locale => (
                          <DropdownItem key={locale} value={locale} onClick={handleLocaleChange}>
                            {languages[locale].name}
                          </DropdownItem>
                        ))}
                      </NavDropdown>
                    </div>
                  </div>
                </Col>
              </Row>
              <h2 className='loginTitle'><Translate contentKey="otp.request.title" /></h2>
              <Box className={classes.formRoot}>
                <Alert color="warning">
                  <Translate contentKey="otp.request.messages.info">Receive your otp code in your registered email/phone</Translate>
                </Alert>
                <Grid container direction="column" >
                {isOtpError ? (
                  <Alert color="danger" data-cy="loginError" style={{textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',}}>
                    <Translate contentKey="otp.request.messages.error">
                      <strong>Failed to enter OTP!</strong> Please check your OTP and try again.
                    </Translate>
                  </Alert>
                ) : null}
                  <ValidatedForm onSubmit={handleValidSubmit}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <div dir="ltr">
                        <OTPInput value={otp}
                          onChange={(value) => {
                            setOtp(value);
                            otpRef.current = value;
                            setIsOtpError(false); // Clear error message when input changes
                          }}
                          numInputs={4}
                          renderSeparator={<span style={{ padding: '0 20px' }}>-</span>}
                          renderInput={(props) => <input {...props} />}
                          inputStyle={{ width: "50px", height: "50px"}}
                          inputType="tel"
                        />
                      </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , color:'red',  marginTop: '1rem'}} hidden={invalidOTP} >{translate('otp.invalidotp')}</div>


                    <Row >
                      <Col style={{ marginTop: "15px" }}>
                      </Col>
                      <Col >
                      <div id='resendOtp' style={{marginTop: "20px"}}>
                          <p> <Translate contentKey='otp.request.messages.resendCode' /> <Link to="/" type="button" onClick={() => dispatch(generateOTPByLogin(account?.login))} style={{ fontWeight: 'normal' }}>
                          <Translate contentKey='otp.request.form.link' />
                        </Link></p>

                        </div>
                      </Col>
                    </Row>
                    <Button variant="contained" size="medium" color="primary" type="submit" data-cy="submit" fullWidth disabled={otp.length < 4}>
                      <Translate contentKey="otp.request.form.button">Send OTP</Translate>
                    </Button>
                  </ValidatedForm>
                </Grid>
                <div className="mt-3" style={{ textAlign: 'center' }}>
                  <Link to="/logout" onClick={() => handleLogout()} style={{ fontWeight: 'normal' }}>
                    <Translate contentKey="reset.request.backBtn" />
                  </Link>
                </div>
              </Box>
            </Paper>
          </Grid>
        </ThemeProvider>
      </Grid>

      <Dialog open={openMessageDialog} onClose={handleToClose}>
        <DialogTitle ></DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: "black" }} className='mb-3'>
            {translate('reset.request.emailMessages')}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="mx-3">
          <DialogContentText>
            <ButtonReact color="primary" onClick={handleToClose} autoFocus>
              {translate('comexApp.quotation.okButton')}
            </ButtonReact>
          </DialogContentText>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default OtpPage;
