import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import NotifyCancellationEmails from './notify-cancellation-emails';
import NotifyCancellationEmailsDetail from './notify-cancellation-emails-detail';
import NotifyCancellationEmailsUpdate from './notify-cancellation-emails-update';
import NotifyCancellationEmailsDeleteDialog from './notify-cancellation-emails-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={NotifyCancellationEmailsUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={NotifyCancellationEmailsUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={NotifyCancellationEmailsDetail} />
      <ErrorBoundaryRoute path={match.url} component={NotifyCancellationEmails} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={NotifyCancellationEmailsDeleteDialog} />
  </>
);

export default Routes;
