import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled, createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import {Storage, translate, Translate} from "react-jhipster";
import moment from 'moment';
import { getEntities as getBankEntities } from "app/entities/bank/bank.reducer";
import { getAllEntities as getBranchEntities } from 'app/entities/branch/branch.reducer';

export const UserPrintTemplate = () => {
  const dispatch = useAppDispatch();
  const componentRef = useRef();
  const bankList = useAppSelector(state => state.bank.entities);
  const branchList = useAppSelector(state => state.branch.entities);
  const [usersPrint, setUsersPrint] = useState([]) as any;

  const htmlTag = document.querySelector("html");
  Storage.session.get('locale') === 'en' ? htmlTag.setAttribute("dir",'ltr') : htmlTag.setAttribute("dir",'rtl');

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: '0.55rem',
      fontWeight: 'bold',
      textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
      overflow: 'hidden',
      textOverflow: 'clip',
      padding: '8px', 
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: '0.55rem',
      textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
      overflow: 'hidden',
      textOverflow: 'clip',
      padding: '8px', 
      wordWrap: 'break-word', 
      whiteSpace: 'pre-line', 
    },
  }));
  
  const StyledTable = styled(Table)(({ theme }) => ({
    '&.MuiTable-root': {
      tableLayout: 'fixed',
      fontSize: '0.55rem', 
      borderCollapse: 'collapse', 
      width: '100%', 
      marginTop: '8px', 
    },
    '& .MuiTableRow-root': {
      height: 'auto', 
    },
    '& .MuiTableCell-root': {
      borderBottom: '1px solid rgba(224, 224, 224, 1)', 
    },
  }));
  

  useEffect(() => {
        dispatch(getBranchEntities({}));
        dispatch(getBankEntities({}));
      }, []);

  useEffect(() => {
    setUsersPrint(JSON.parse(localStorage.getItem('userPrint')));
  }, []);

  useEffect(() => {
    const langLocal = localStorage.getItem('locale');
    const langSession = sessionStorage.getItem('locale');
    if(langLocal !== langSession){
      sessionStorage.setItem("locale",langLocal);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      const input = document.getElementById('divToPrint').innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = input;
      window.print();
    }, 1000);
    return () => clearTimeout(timer);
  },[]);

  const setPageSize = () => {
    const style = document.createElement('style');
    style.innerHTML = `@page {size: a4 landscape !important;}`;
    style.id = 'page-orientation';
    document.head.appendChild(style);
  };

  useEffect(() => {
    setPageSize();
    return () => {
      const child = document.getElementById('page-orientation');
      child.parentNode.removeChild(child);
    };
  }, []);

  const rows = usersPrint?.map((obj, i)=>({
    ...obj,
  }));

  function displayBankName(user: any) {
    // alert("Hello displayBankName"+ user.authorities)
    if(user.authorities?.includes("ROLE_AREA_MANAGER"))
    {
      const found_branch = branchList.filter(branch => branch.areaManagerId === user.id);
      // alert(found_branch.length)
      if(found_branch.length > 0)
      {

        const found_bank = bankList.filter(filterBank => filterBank.id === found_branch[0]?.bank?.id);
        return found_bank[0]?.name;
      }
      else
        return '-';
    }
    else if(user.authorities?.includes("ROLE_AUDITOR"))
    {
      const found_bank = bankList.filter(filterBank => filterBank?.id?.toString() === user?.bankId);
      return found_bank[0]?.name;
    }
    else
    {
      const found = bankList.find(filterBank => filterBank?.userId === user.id);
      if (found) {
        return found?.name;
      }
      else { return '-'; }
    }
  }

  function displayBranchName(user: any) {
    if (user.authorities?.includes("ROLE_AREA_MANAGER")) {
      const found_branch   = branchList?.filter(branch => branch?.areaManagerId === user?.id )?.map(b  => b.name).join(", ");
      return found_branch ? found_branch : '-';
    }
    else {

      return '-';
    }
  }

  return (
    <>
      <div className="App container mt-5" style={{height: '1100px'}}>
        <div id="divToPrint" ref={componentRef}>
          <div id="headerPrint" style={{margin: '24px 48px 24px 48px'}}>
            <Grid container spacing={2} style={{alignItems: 'center'}}>
              <Grid item xs={6} className="left-side-component-flex">
                <Typography variant='h4' component='div'> <Translate contentKey= "userManagement.home.title" /></Typography>
              </Grid>
              <Grid item xs={6} className="right-side-component-flex">
                <img src="../content/images/img.png" width={'120px'}/>
              </Grid>
            </Grid>
          </div>

          <Divider sx={{borderBottomWidth: 3, marginLeft: '48px', marginRight: '48px', color: '#01579b'}}/>
          <div id='quotationTablePrint' style={{margin: '48px'}}>
            <TableContainer>
              {/* <Divider sx={{ borderBottomWidth: 3, color: '#01579b'}} /> */}
              <StyledTable>
                <TableHead>
                <Grid justifyContent="centre">
                  <div style={{lineHeight: 1, marginTop: '24px', marginBottom: '12px'}}>
                  <TableRow>
                    <StyledTableCell>{translate('userManagement.login')}</StyledTableCell>
                    <StyledTableCell>{translate('userManagement.firstName')}</StyledTableCell>
                    <StyledTableCell>{translate("userManagement.lastName")}</StyledTableCell>
                    <StyledTableCell>{translate("userManagement.email")}</StyledTableCell>
                    <StyledTableCell>{translate("userManagement.activated")}</StyledTableCell>
                    <StyledTableCell>{translate("userManagement.createdBy")}</StyledTableCell>
                    <StyledTableCell>{translate("userManagement.createdDate")}</StyledTableCell>
                    <StyledTableCell>{translate("userManagement.lastModifiedBy")}</StyledTableCell>
                    <StyledTableCell>{translate("userManagement.lastModifiedDate")}</StyledTableCell>
                    <StyledTableCell>{translate("userManagement.bank")}</StyledTableCell>
                    <StyledTableCell>{translate("home.branch")}</StyledTableCell>
                    <StyledTableCell>{translate("userManagement.phone")}</StyledTableCell>
                    <StyledTableCell>{translate("userManagement.roles")}</StyledTableCell>
                  </TableRow>
                  </div>
                  </Grid>
                </TableHead>
                <TableBody>
                  {rows
                  .filter(q => !q?.authorities?.includes('ROLE_CUSTOMER'))
                  .map((q, index) => (
                    <TableRow key={index}>
                      <StyledTableCell>{q?.login}</StyledTableCell>
                      <StyledTableCell>{q?.firstName}</StyledTableCell>
                      <StyledTableCell>{q?.lastName}</StyledTableCell>
                      <StyledTableCell>{q?.email}</StyledTableCell>
                      <StyledTableCell>{q?.activated.toString()}</StyledTableCell>
                      <StyledTableCell>{q?.createdBy}</StyledTableCell>
                      <StyledTableCell>{moment (q?.createdDate).format('yyyy/MM/DD HH:mm:ss')}</StyledTableCell>
                      <StyledTableCell>{q?.lastModifiedBy}</StyledTableCell>
                      <StyledTableCell>{moment (q?.lastModifiedDate).format('yyyy/MM/DD HH:mm:ss')}</StyledTableCell>
                      <StyledTableCell>{q?.branchId ? branchList?.find(branch=> branch?.id?.toString() === q?.branchId?.toString())?.bank?.name : displayBankName(q)}</StyledTableCell>
                      <StyledTableCell>{q.branchId ? branchList.map(branch => {if (String(branch.id) === q.branchId) {return `${branch.name}`;}}) : displayBranchName(q)}</StyledTableCell>  
                      <StyledTableCell>{q?.phone ? q.phone : "-"}</StyledTableCell>
                      <StyledTableCell>{q?.authorities.toString().replace("ROLE_AUDITOR", "ROLE_MONITORING_USER").split(',').map((authority, idx, array) => (<React.Fragment key={idx}>{authority.trim()}{idx !== array.length - 1 && ','}
              <br />
            </React.Fragment>
          ))}</StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
          </div>
          <Typography variant='subtitle1' style={{margin: '48px'}}> <Translate contentKey= "global.menu.report-print.signature" /> : _________________________</Typography>
          <Divider sx={{borderBottomWidth: 3, marginLeft: '48px', marginRight: '48px', color: '#01579b'}}/>
        </div>
      </div>
    </>
  );
}

export default UserPrintTemplate;
