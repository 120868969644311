import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import BursaAPIEndPoints from './bursa-api-end-points';
import BursaAPIEndPointsDetail from './bursa-api-end-points-detail';
import BursaAPIEndPointsUpdate from './bursa-api-end-points-update';
import BursaAPIEndPointsDeleteDialog from './bursa-api-end-points-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={BursaAPIEndPointsUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={BursaAPIEndPointsUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={BursaAPIEndPointsDetail} />
      <ErrorBoundaryRoute path={match.url} component={BursaAPIEndPoints} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={BursaAPIEndPointsDeleteDialog} />
  </>
);

export default Routes;
