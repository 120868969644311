import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import { ICurrencyIndex } from 'app/shared/model/currency-index.model';
import { getAllEntities as getCurrencyIndices } from 'app/entities/currency-index/currency-index.reducer';
import { getEntity, updateEntity, createEntity, reset, getEntities } from './currency-rates.reducer';
import { ICurrencyRates } from 'app/shared/model/currency-rates.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const CurrencyRatesUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const currencyIndices = useAppSelector(state => state.currencyIndex.entities);
  const currencyRatesEntity = useAppSelector(state => state.currencyRates.entity);
  const loading = useAppSelector(state => state.currencyRates.loading);
  const updating = useAppSelector(state => state.currencyRates.updating);
  const updateSuccess = useAppSelector(state => state.currencyRates.updateSuccess);
  const handleClose = () => {
    props.history.push('/currency-rates' + props.location.search);
  };
  const [currencyRatesList, setCurrencyRatesList] = useState([]);

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getCurrencyIndices({}))

    dispatch(getEntities({})).then(res => {
      setCurrencyRatesList(res.payload['data']);
    })
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...currencyRatesEntity,
      ...values,
      buyFrom: currencyIndices.find(it => it.id.toString() === values.buyFrom.toString()),
      buyFromId: currencyIndices.find(it => it.id.toString() === values.buyFrom.toString()).id,
      sellTo: currencyIndices.find(it => it.id.toString() === values.sellTo.toString()),
      sellToId: currencyIndices.find(it => it.id.toString() === values.sellTo.toString()).id,
    };
    const duplicateList = currencyRatesList.filter(item => item.buyFromId === entity.buyFromId && item.sellToId === entity.sellToId && item.id !== entity.id);

    if (duplicateList.length === 0) {
      if (isNew) {
        dispatch(createEntity(entity));
      } else {
        dispatch(updateEntity(entity));
      }
    } else {
     toast.error(`Failed to update item ${entity.id} due to duplication with item ${duplicateList[0].id}!`);
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...currencyRatesEntity,
          buyFrom: currencyRatesEntity?.buyFrom?.id,
          sellTo: currencyRatesEntity?.sellTo?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="comexApp.currencyRates.home.createOrEditLabel" data-cy="CurrencyRatesCreateUpdateHeading">
            <Translate contentKey="comexApp.currencyRates.home.createOrEditLabel">Create or edit a CurrencyRates</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="currency-rates-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('comexApp.currencyRates.exchangeRate')}
                id="currency-rates-exchangeRate"
                name="exchangeRate"
                data-cy="exchangeRate"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                id="currency-rates-buyFrom"
                name="buyFrom"
                data-cy="buyFrom"
                label={translate('comexApp.currencyRates.buyFrom')}
                type="select"
                validate={{
                  required: { value: true  , message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {currencyIndices
                  ? currencyIndices.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="currency-rates-sellTo"
                name="sellTo"
                data-cy="sellTo"
                label={translate('comexApp.currencyRates.sellTo')}
                type="select"
                validate={{
                  required: { value: true  , message: translate('entity.validation.required') },
                }}
              >
                <option value="" key="0" />
                {currencyIndices
                  ? currencyIndices.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/currency-rates" replace>
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CurrencyRatesUpdate;
