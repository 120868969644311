import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import NotificationRecord from './notification-record';
import NotificationRecordDetail from './notification-record-detail';
import NotificationRecordUpdate from './notification-record-update';
import NotificationRecordDeleteDialog from './notification-record-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={NotificationRecordUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={NotificationRecordUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={NotificationRecordDetail} />
      <ErrorBoundaryRoute path={match.url} component={NotificationRecord} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={NotificationRecordDeleteDialog} />
  </>
);

export default Routes;
