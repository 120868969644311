import { ICurrencyRates } from 'app/shared/model/currency-rates.model';

export interface ICurrencyPairsLog {
  id?: number;
  rate?: number;
  currencyRates?: ICurrencyRates;
  currencyRatesId?: number;
  createdDate?: string;
  createdBy?: string;
  modifiedDate?: string;
  modifiedBy?: string;
}

export const defaultValue: Readonly<ICurrencyPairsLog> = {};
