import { ICommodity } from "./commodity.model";
import { ICurrencyIndex } from "./currency-index.model";

export interface IBank {
  id?: number;
  name?: string;
  description?: string | null;
  imgURL?: string | null;
  userId?: number | null;
  currencyId?: number | null;
  currencyIndex?: ICurrencyIndex | null;
  reportForBankStaff?: boolean;
  reportForBranchManager?: boolean;
  reportForAreaManager?: boolean;
  reportForBankManager?: boolean;
  reportForAuditor?: boolean;
  country?:string;
  otp?:boolean;
  transaction_forms?:boolean;
  displayTransactionFee?:boolean;
  displayFeeInTransactionCurrency?:boolean;
  approvalPeriod?: number;
  transactionAmountLimit?: number;
  countryCode?: string;
  agreementDate?: string;
  commodities?:ICommodity[];
  commission?:boolean;
  commissionAmount?:number;
  commissionForEachAmount?:number;
  createdDate?: Date;
  feeCurrency?: string;
  quotationValidityDays?:number;
}

export const defaultValue: Readonly<IBank> = {};
