import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import PurchaseOrder from './purchase-order';
import PurchaseOrderDetail from './purchase-order-detail';
import PurchaseOrderUpdate from './purchase-order-update';
import PurchaseOrderDeleteDialog from './purchase-order-delete-dialog';
import PrivateRoute from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

const Routes = ({ match }) => (
  <>
    <Switch>
      <PrivateRoute
        exact path={`${match.url}/new`}
        component={PurchaseOrderUpdate}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.BANK_STAFF,
        ]}
      />
      <PrivateRoute
        exact path={`${match.url}/:id/edit`}
        component={PurchaseOrderUpdate}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.BANK_STAFF,
        ]}
      />
      <PrivateRoute
        exact path={`${match.url}/:id`}
        component={PurchaseOrderDetail}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.BANK_STAFF,
          AUTHORITIES.AUDITOR,
        ]}
      />
      <PrivateRoute
        path={match.url}
        component={PurchaseOrder}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.BANK_STAFF,
          AUTHORITIES.AUDITOR,
        ]}
      />
    </Switch>
    <PrivateRoute
      exact path={`${match.url}/:id/delete`}
      component={PurchaseOrderDeleteDialog}
      hasAnyAuthorities={[
        AUTHORITIES.ADMIN
      ]}
    />
  </>
);

export default Routes;
