import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './currency-rates.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const CurrencyRatesDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const userAuthorities = useAppSelector(state => state.authentication.account.authorities);

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const currencyRatesEntity = useAppSelector(state => state.currencyRates.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="currencyRatesDetailsHeading">
          <Translate contentKey="comexApp.currencyRates.detail.title">CurrencyRates</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{currencyRatesEntity.id}</dd>
          <dt>
            <span id="exchangeRate">
              <Translate contentKey="comexApp.currencyRates.exchangeRate">Exchange Rate</Translate>
            </span>
          </dt>
          <dd>{currencyRatesEntity.exchangeRate?.toLocaleString()}</dd>
          <dt>
            <Translate contentKey="comexApp.currencyRates.buyFrom">Buy From</Translate>
          </dt>
          <dd>{currencyRatesEntity.buyFrom ? currencyRatesEntity.buyFrom.name : ''}</dd>
          <dt>
            <Translate contentKey="comexApp.currencyRates.sellTo">Sell To</Translate>
          </dt>
          <dd>{currencyRatesEntity.sellTo ? currencyRatesEntity.sellTo.name : ''}</dd>
        </dl>
        <Button tag={Link} to="/currency-rates" replace data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        {userAuthorities.includes(AUTHORITIES.ADMIN) && <Button tag={Link} to={`/currency-rates/${currencyRatesEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>}
      </Col>
    </Row>
  );
};

export default CurrencyRatesDetail;
