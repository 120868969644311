
let countryList = [
  {
    "code": "",
    "Desc_en": "",
    "Desc_ar": ""
  },
  {
    "code": "AD",
    "Desc_en": "Andorra",
    "Desc_ar": "أندورا"
  },
  {
    "code": "AE",
    "Desc_en": "United Arab Emirates",
    "Desc_ar": "الامارات العربية المتحدة"
  },
  {
    "code": "AF",
    "Desc_en": "Afghanistan",
    "Desc_ar": "أفغانستان"
  },
  {
    "code": "AG",
    "Desc_en": "Antigua and Barbuda",
    "Desc_ar": "أنتيغوا وباربودا"
  },
  {
    "code": "AI",
    "Desc_en": "Anguilla",
    "Desc_ar": "أنجويلا"
  },
  {
    "code": "AL",
    "Desc_en": "Albania",
    "Desc_ar": "ألبانيا"
  },
  {
    "code": "AM",
    "Desc_en": "Armenia",
    "Desc_ar": "أرمينيا"
  },
  {
    "code": "AO",
    "Desc_en": "Angola",
    "Desc_ar": "أنجولا"
  },
  {
    "code": "AQ",
    "Desc_en": "Antarctica",
    "Desc_ar": "القارة القطبية الجنوبية"
  },
  {
    "code": "AR",
    "Desc_en": "Argentina",
    "Desc_ar": "الأرجنتين"
  },
  {
    "code": "AS",
    "Desc_en": "American Samoa",
    "Desc_ar": "ساموا الأمريكية"
  },
  {
    "code": "AT",
    "Desc_en": "Austria",
    "Desc_ar": "النمسا"
  },
  {
    "code": "AU",
    "Desc_en": "Australia",
    "Desc_ar": "أستراليا"
  },
  {
    "code": "AW",
    "Desc_en": "Aruba",
    "Desc_ar": "آروبا"
  },
  {
    "code": "AX",
    "Desc_en": "Åland Islands",
    "Desc_ar": "جزر أولان"
  },
  {
    "code": "AZ",
    "Desc_en": "Azerbaijan",
    "Desc_ar": "أذربيجان"
  },
  {
    "code": "BA",
    "Desc_en": "Bosnia and Herzegovina",
    "Desc_ar": "البوسنة والهرسك"
  },
  {
    "code": "BB",
    "Desc_en": "Barbados",
    "Desc_ar": "بربادوس"
  },
  {
    "code": "BD",
    "Desc_en": "Bangladesh",
    "Desc_ar": "بنجلاديش"
  },
  {
    "code": "BE",
    "Desc_en": "Belgium",
    "Desc_ar": "بلجيكا"
  },
  {
    "code": "BF",
    "Desc_en": "Burkina Faso",
    "Desc_ar": "بوركينا فاسو"
  },
  {
    "code": "BG",
    "Desc_en": "Bulgaria",
    "Desc_ar": "بلغاريا"
  },
  {
    "code": "BH",
    "Desc_en": "Bahrain",
    "Desc_ar": "البحرين"
  },
  {
    "code": "BI",
    "Desc_en": "Burundi",
    "Desc_ar": "بوروندي"
  },
  {
    "code": "BJ",
    "Desc_en": "Benin",
    "Desc_ar": "بنين"
  },
  {
    "code": "BL",
    "Desc_en": "Saint Barthelemy",
    "Desc_ar": "سان بارتيلمي"
  },
  {
    "code": "BM",
    "Desc_en": "Bermuda",
    "Desc_ar": "برمودا"
  },
  {
    "code": "BN",
    "Desc_en": "Brunei Darussalam",
    "Desc_ar": "بروناي"
  },
  {
    "code": "BO",
    "Desc_en": "Bolivia (Plurinational State of)",
    "Desc_ar": "بوليفيا"
  },
  {
    "code": "BQ",
    "Desc_en": "Bonaire, Sint Eustatius and Saba",
    "Desc_ar": "الجزر الكاريبية الهولندية"
  },
  {
    "code": "BR",
    "Desc_en": "Brazil",
    "Desc_ar": "البرازيل"
  },
  {
    "code": "BS",
    "Desc_en": "Bahamas",
    "Desc_ar": "الباهاما"
  },
  {
    "code": "BT",
    "Desc_en": "Bhutan",
    "Desc_ar": "بوتان"
  },
  {
    "code": "BV",
    "Desc_en": "Bouvet Island",
    "Desc_ar": "جزيرة بوفيه"
  },
  {
    "code": "BW",
    "Desc_en": "Botswana",
    "Desc_ar": "بتسوانا"
  },
  {
    "code": "BY",
    "Desc_en": "Belarus",
    "Desc_ar": "روسيا البيضاء"
  },
  {
    "code": "BZ",
    "Desc_en": "Belize",
    "Desc_ar": "بليز"
  },
  {
    "code": "CA",
    "Desc_en": "Canada",
    "Desc_ar": "كندا"
  },
  {
    "code": "CC",
    "Desc_en": "Cocos (Keeling) Islands",
    "Desc_ar": "جزر كوكوس"
  },
  {
    "code": "CD",
    "Desc_en": "Congo (Democratic Republic of the)",
    "Desc_ar": "جمهورية الكونغو الديمقراطية"
  },
  {
    "code": "CF",
    "Desc_en": "Central African Republic",
    "Desc_ar": "جمهورية أفريقيا الوسطى"
  },
  {
    "code": "CG",
    "Desc_en": "Congo",
    "Desc_ar": "جمهورية الكونغو"
  },
  {
    "code": "CH",
    "Desc_en": "Switzerland",
    "Desc_ar": "سويسرا"
  },
  {
    "code": "CI",
    "Desc_en": "Cote d’Ivoire",
    "Desc_ar": "ساحل العاج"
  },
  {
    "code": "CK",
    "Desc_en": "Cook Islands",
    "Desc_ar": "جزر كوك"
  },
  {
    "code": "CL",
    "Desc_en": "Chile",
    "Desc_ar": "شيلي"
  },
  {
    "code": "CM",
    "Desc_en": "Cameroon",
    "Desc_ar": "الكاميرون"
  },
  {
    "code": "CN",
    "Desc_en": "China",
    "Desc_ar": "الصين"
  },
  {
    "code": "CO",
    "Desc_en": "Colombia",
    "Desc_ar": "كولومبيا"
  },
  {
    "code": "CR",
    "Desc_en": "Costa Rica",
    "Desc_ar": "كوستاريكا"
  },
  {
    "code": "CU",
    "Desc_en": "Cuba",
    "Desc_ar": "كوبا"
  },
  {
    "code": "CV",
    "Desc_en": "Cabo Verde",
    "Desc_ar": "الرأس الأخضر"
  },
  {
    "code": "CW",
    "Desc_en": "Curaçao",
    "Desc_ar": "كوراساو"
  },
  {
    "code": "CX",
    "Desc_en": "Christmas Island",
    "Desc_ar": "جزيرة عيد الميلاد"
  },
  {
    "code": "CY",
    "Desc_en": "Cyprus",
    "Desc_ar": "قبرص"
  },
  {
    "code": "CZ",
    "Desc_en": "Czechia",
    "Desc_ar": "جمهورية التشيك"
  },
  {
    "code": "DE",
    "Desc_en": "Germany",
    "Desc_ar": "ألمانيا"
  },
  {
    "code": "DJ",
    "Desc_en": "Djibouti",
    "Desc_ar": "جيبوتي"
  },
  {
    "code": "DK",
    "Desc_en": "Denmark",
    "Desc_ar": "الدانمرك"
  },
  {
    "code": "DM",
    "Desc_en": "Dominica",
    "Desc_ar": "دومينيكا"
  },
  {
    "code": "DO",
    "Desc_en": "Dominican Republic",
    "Desc_ar": "جمهورية الدومينيكان"
  },
  {
    "code": "DZ",
    "Desc_en": "Algeria",
    "Desc_ar": "الجزائر"
  },
  {
    "code": "EC",
    "Desc_en": "Ecuador",
    "Desc_ar": "الاكوادور"
  },
  {
    "code": "EE",
    "Desc_en": "Estonia",
    "Desc_ar": "استونيا"
  },
  {
    "code": "EG",
    "Desc_en": "Egypt",
    "Desc_ar": "مصر"
  },
  {
    "code": "EH",
    "Desc_en": "Western Sahara",
    "Desc_ar": "الصحراء الغربية"
  },
  {
    "code": "ER",
    "Desc_en": "Eritrea",
    "Desc_ar": "اريتريا"
  },
  {
    "code": "ES",
    "Desc_en": "Spain",
    "Desc_ar": "أسبانيا"
  },
  {
    "code": "ET",
    "Desc_en": "Ethiopia",
    "Desc_ar": "اثيوبيا"
  },
  {
    "code": "FI",
    "Desc_en": "Finland",
    "Desc_ar": "فنلندا"
  },
  {
    "code": "FJ",
    "Desc_en": "Fiji",
    "Desc_ar": "فيجي"
  },
  {
    "code": "FK",
    "Desc_en": "Falkland Islands (Malvinas)",
    "Desc_ar": "جزر فوكلاند"
  },
  {
    "code": "FM",
    "Desc_en": "Micronesia (Federated States of)",
    "Desc_ar": "ميكرونيزيا"
  },
  {
    "code": "FO",
    "Desc_en": "Faroe Islands",
    "Desc_ar": "جزر فارو"
  },
  {
    "code": "FR",
    "Desc_en": "France",
    "Desc_ar": "فرنسا"
  },
  {
    "code": "GA",
    "Desc_en": "Gabon",
    "Desc_ar": "الجابون"
  },
  {
    "code": "GB",
    "Desc_en": "United Kingdom of Great Britain and Northern Ireland",
    "Desc_ar": "المملكة المتحدة"
  },
  {
    "code": "GD",
    "Desc_en": "Grenada",
    "Desc_ar": "جرينادا"
  },
  {
    "code": "GE",
    "Desc_en": "Georgia",
    "Desc_ar": "جورجيا"
  },
  {
    "code": "GF",
    "Desc_en": "French Guiana",
    "Desc_ar": "غويانا"
  },
  {
    "code": "GG",
    "Desc_en": "Guernsey",
    "Desc_ar": "جيرنزي"
  },
  {
    "code": "GH",
    "Desc_en": "Ghana",
    "Desc_ar": "غانا"
  },
  {
    "code": "GI",
    "Desc_en": "Gibraltar",
    "Desc_ar": "جبل طارق"
  },
  {
    "code": "GL",
    "Desc_en": "Greenland",
    "Desc_ar": "جرينلاند"
  },
  {
    "code": "GM",
    "Desc_en": "Gambia",
    "Desc_ar": "غامبيا"
  },
  {
    "code": "GN",
    "Desc_en": "Guinea",
    "Desc_ar": "غينيا"
  },
  {
    "code": "GP",
    "Desc_en": "Guadeloupe",
    "Desc_ar": "جوادلوب"
  },
  {
    "code": "GQ",
    "Desc_en": "Equatorial Guinea",
    "Desc_ar": "غينيا الاستوائية"
  },
  {
    "code": "GR",
    "Desc_en": "Greece",
    "Desc_ar": "اليونان"
  },
  {
    "code": "GS",
    "Desc_en": "South Georgia and the South Sandwich Islands",
    "Desc_ar": "جورجيا الجنوبية وجزر ساندويتش الجنوبية"
  },
  {
    "code": "GT",
    "Desc_en": "Guatemala",
    "Desc_ar": "جواتيمالا"
  },
  {
    "code": "GU",
    "Desc_en": "Guam",
    "Desc_ar": "جوام"
  },
  {
    "code": "GW",
    "Desc_en": "Guinea-Bissau",
    "Desc_ar": "غينيا بيساو"
  },
  {
    "code": "GY",
    "Desc_en": "Guyana",
    "Desc_ar": "غيانا"
  },
  {
    "code": "HK",
    "Desc_en": "Hong Kong",
    "Desc_ar": "هونغ كونغ"
  },
  {
    "code": "HM",
    "Desc_en": "Heard Island and McDonald Islands",
    "Desc_ar": "جزيرة هيرد وجزر ماكدونالد"
  },
  {
    "code": "HN",
    "Desc_en": "Honduras",
    "Desc_ar": "هندوراس"
  },
  {
    "code": "HR",
    "Desc_en": "Croatia",
    "Desc_ar": "كرواتيا"
  },
  {
    "code": "HT",
    "Desc_en": "Haiti",
    "Desc_ar": "هايتي"
  },
  {
    "code": "HU",
    "Desc_en": "Hungary",
    "Desc_ar": "المجر"
  },
  {
    "code": "ID",
    "Desc_en": "Indonesia",
    "Desc_ar": "اندونيسيا"
  },
  {
    "code": "IE",
    "Desc_en": "Ireland",
    "Desc_ar": "أيرلندا"
  },
  {
    "code": "IL",
    "Desc_en": "Israel",
    "Desc_ar": "اسرائيل"
  },
  {
    "code": "IM",
    "Desc_en": "Isle of Man",
    "Desc_ar": "جزيرة مان"
  },
  {
    "code": "IN",
    "Desc_en": "India",
    "Desc_ar": "الهند"
  },
  {
    "code": "IO",
    "Desc_en": "British Indian Ocean Territory",
    "Desc_ar": "إقليم المحيط الهندي البريطاني"
  },
  {
    "code": "IQ",
    "Desc_en": "Iraq",
    "Desc_ar": "العراق"
  },
  {
    "code": "IR",
    "Desc_en": "Iran (Islamic Republic of)",
    "Desc_ar": "ايران"
  },
  {
    "code": "IS",
    "Desc_en": "Iceland",
    "Desc_ar": "أيسلندا"
  },
  {
    "code": "IT",
    "Desc_en": "Italy",
    "Desc_ar": "ايطاليا"
  },
  {
    "code": "JE",
    "Desc_en": "Jersey",
    "Desc_ar": "جيرسي"
  },
  {
    "code": "JM",
    "Desc_en": "Jamaica",
    "Desc_ar": "جامايكا"
  },
  {
    "code": "JO",
    "Desc_en": "Jordan",
    "Desc_ar": "الأردن"
  },
  {
    "code": "JP",
    "Desc_en": "Japan",
    "Desc_ar": "اليابان"
  },
  {
    "code": "KE",
    "Desc_en": "Kenya",
    "Desc_ar": "كينيا"
  },
  {
    "code": "KG",
    "Desc_en": "Kyrgyzstan",
    "Desc_ar": "قرغيزستان"
  },
  {
    "code": "KH",
    "Desc_en": "Cambodia",
    "Desc_ar": "كمبوديا"
  },
  {
    "code": "KI",
    "Desc_en": "Kiribati",
    "Desc_ar": "كيريباتي"
  },
  {
    "code": "KM",
    "Desc_en": "Comoros",
    "Desc_ar": "جزر القمر"
  },
  {
    "code": "KN",
    "Desc_en": "Saint Kitts and Nevis",
    "Desc_ar": "سانت كيتس ونيفيس"
  },
  {
    "code": "KP",
    "Desc_en": "Korea (Democratic People’s Republic of)",
    "Desc_ar": "كوريا الشمالية"
  },
  {
    "code": "KR",
    "Desc_en": "Korea (Republic of)",
    "Desc_ar": "كوريا الجنوبية"
  },
  {
    "code": "KW",
    "Desc_en": "Kuwait",
    "Desc_ar": "الكويت"
  },
  {
    "code": "KY",
    "Desc_en": "Cayman Islands",
    "Desc_ar": "جزر كايمان"
  },
  {
    "code": "KZ",
    "Desc_en": "Kazakhstan",
    "Desc_ar": "كازاخستان"
  },
  {
    "code": "LA",
    "Desc_en": "Lao People’s Democratic Republic",
    "Desc_ar": "لاوس"
  },
  {
    "code": "LB",
    "Desc_en": "Lebanon",
    "Desc_ar": "لبنان"
  },
  {
    "code": "LC",
    "Desc_en": "Saint Lucia",
    "Desc_ar": "سانت لوسيا"
  },
  {
    "code": "LI",
    "Desc_en": "Liechtenstein",
    "Desc_ar": "ليختنشتاين"
  },
  {
    "code": "LK",
    "Desc_en": "Sri Lanka",
    "Desc_ar": "سريلانكا"
  },
  {
    "code": "LR",
    "Desc_en": "Liberia",
    "Desc_ar": "ليبيريا"
  },
  {
    "code": "LS",
    "Desc_en": "Lesotho",
    "Desc_ar": "ليسوتو"
  },
  {
    "code": "LT",
    "Desc_en": "Lithuania",
    "Desc_ar": "ليتوانيا"
  },
  {
    "code": "LU",
    "Desc_en": "Luxembourg",
    "Desc_ar": "لوكسمبورج"
  },
  {
    "code": "LV",
    "Desc_en": "Latvia",
    "Desc_ar": "لاتفيا"
  },
  {
    "code": "LY",
    "Desc_en": "Libya",
    "Desc_ar": "ليبيا"
  },
  {
    "code": "MA",
    "Desc_en": "Morocco",
    "Desc_ar": "المغرب"
  },
  {
    "code": "MC",
    "Desc_en": "Monaco",
    "Desc_ar": "موناكو"
  },
  {
    "code": "MD",
    "Desc_en": "Moldova (Republic of)",
    "Desc_ar": "مولدافيا"
  },
  {
    "code": "ME",
    "Desc_en": "Montenegro",
    "Desc_ar": "الجبل الأسود"
  },
  {
    "code": "MF",
    "Desc_en": "Saint Martin (French Part)",
    "Desc_ar": "تجمع سان مارتين"
  },
  {
    "code": "MG",
    "Desc_en": "Madagascar",
    "Desc_ar": "مدغشقر"
  },
  {
    "code": "MH",
    "Desc_en": "Marshall Islands",
    "Desc_ar": "جزر مارشال"
  },
  {
    "code": "MK",
    "Desc_en": "North Macedonia",
    "Desc_ar": "مقدونيا"
  },
  {
    "code": "ML",
    "Desc_en": "Mali",
    "Desc_ar": "مالي"
  },
  {
    "code": "MM",
    "Desc_en": "Myanmar",
    "Desc_ar": "ميانمار"
  },
  {
    "code": "MN",
    "Desc_en": "Mongolia",
    "Desc_ar": "منغوليا"
  },
  {
    "code": "MO",
    "Desc_en": "Macao",
    "Desc_ar": "ماكاو"
  },
  {
    "code": "MP",
    "Desc_en": "Northern Mariana Islands",
    "Desc_ar": "جزر ماريانا الشمالية"
  },
  {
    "code": "MQ",
    "Desc_en": "Martinique",
    "Desc_ar": "مارتينيك"
  },
  {
    "code": "MR",
    "Desc_en": "Mauritania",
    "Desc_ar": "موريتانيا"
  },
  {
    "code": "MS",
    "Desc_en": "Montserrat",
    "Desc_ar": "مونتسرات"
  },
  {
    "code": "MT",
    "Desc_en": "Malta",
    "Desc_ar": "مالطا"
  },
  {
    "code": "MU",
    "Desc_en": "Mauritius",
    "Desc_ar": "موريشيوس"
  },
  {
    "code": "MV",
    "Desc_en": "Maldives",
    "Desc_ar": "جزر المالديف"
  },
  {
    "code": "MW",
    "Desc_en": "Malawi",
    "Desc_ar": "ملاوي"
  },
  {
    "code": "MX",
    "Desc_en": "Mexico",
    "Desc_ar": "المكسيك"
  },
  {
    "code": "MY",
    "Desc_en": "Malaysia",
    "Desc_ar": "ماليزيا"
  },
  {
    "code": "MZ",
    "Desc_en": "Mozambique",
    "Desc_ar": "موزمبيق"
  },
  {
    "code": "NA",
    "Desc_en": "Namibia",
    "Desc_ar": "ناميبيا"
  },
  {
    "code": "NC",
    "Desc_en": "New Caledonia",
    "Desc_ar": "كاليدونيا الجديدة"
  },
  {
    "code": "NE",
    "Desc_en": "Niger",
    "Desc_ar": "النيجر"
  },
  {
    "code": "NF",
    "Desc_en": "Norfolk Island",
    "Desc_ar": "جزيرة نورفولك"
  },
  {
    "code": "NG",
    "Desc_en": "Nigeria",
    "Desc_ar": "نيجيريا"
  },
  {
    "code": "NI",
    "Desc_en": "Nicaragua",
    "Desc_ar": "نيكاراجوا"
  },
  {
    "code": "NL",
    "Desc_en": "Netherlands",
    "Desc_ar": "هولندا"
  },
  {
    "code": "NO",
    "Desc_en": "Norway",
    "Desc_ar": "النرويج"
  },
  {
    "code": "NP",
    "Desc_en": "Nepal",
    "Desc_ar": "نيبال"
  },
  {
    "code": "NR",
    "Desc_en": "Nauru",
    "Desc_ar": "نورو"
  },
  {
    "code": "NU",
    "Desc_en": "Niue",
    "Desc_ar": "نيوي"
  },
  {
    "code": "NZ",
    "Desc_en": "New Zealand",
    "Desc_ar": "نيوزيلاندا"
  },
  {
    "code": "OM",
    "Desc_en": "Oman",
    "Desc_ar": "عمان"
  },
  {
    "code": "PA",
    "Desc_en": "Panama",
    "Desc_ar": "بنما"
  },
  {
    "code": "PE",
    "Desc_en": "Peru",
    "Desc_ar": "بيرو"
  },
  {
    "code": "PF",
    "Desc_en": "French Polynesia",
    "Desc_ar": "بولينزيا الفرنسية"
  },
  {
    "code": "PG",
    "Desc_en": "Papua New Guinea",
    "Desc_ar": "بابوا غينيا الجديدة"
  },
  {
    "code": "PH",
    "Desc_en": "Philippines",
    "Desc_ar": "الفيلبين"
  },
  {
    "code": "PK",
    "Desc_en": "Pakistan",
    "Desc_ar": "باكستان"
  },
  {
    "code": "PL",
    "Desc_en": "Poland",
    "Desc_ar": "بولندا"
  },
  {
    "code": "PM",
    "Desc_en": "Saint Pierre and Miquelon",
    "Desc_ar": "سان بيير وميكلون"
  },
  {
    "code": "PN",
    "Desc_en": "Pitcairn",
    "Desc_ar": "بتكايرن"
  },
  {
    "code": "PR",
    "Desc_en": "Puerto Rico",
    "Desc_ar": "بورتوريكو"
  },
  {
    "code": "PS",
    "Desc_en": "Palestinian, State of",
    "Desc_ar": "فلسطين"
  },
  {
    "code": "PT",
    "Desc_en": "Portugal",
    "Desc_ar": "البرتغال"
  },
  {
    "code": "PW",
    "Desc_en": "Palau",
    "Desc_ar": "بالاو"
  },
  {
    "code": "PY",
    "Desc_en": "Paraguay",
    "Desc_ar": "باراجواي"
  },
  {
    "code": "QA",
    "Desc_en": "Qatar",
    "Desc_ar": "قطر"
  },
  {
    "code": "RE",
    "Desc_en": "Réunion",
    "Desc_ar": "روينيون"
  },
  {
    "code": "RO",
    "Desc_en": "Romania",
    "Desc_ar": "رومانيا"
  },
  {
    "code": "RS",
    "Desc_en": "Serbia",
    "Desc_ar": "صربيا"
  },
  {
    "code": "RU",
    "Desc_en": "Russian Federation",
    "Desc_ar": "روسيا"
  },
  {
    "code": "RW",
    "Desc_en": "Rwanda",
    "Desc_ar": "رواندا"
  },
  {
    "code": "SA",
    "Desc_en": "Saudi Arabia",
    "Desc_ar": "السعودية"
  },
  {
    "code": "SB",
    "Desc_en": "Solomon Islands",
    "Desc_ar": "جزر سليمان"
  },
  {
    "code": "SC",
    "Desc_en": "Seychelles",
    "Desc_ar": "سيشل"
  },
  {
    "code": "SD",
    "Desc_en": "Sudan",
    "Desc_ar": "السودان"
  },
  {
    "code": "SE",
    "Desc_en": "Sweden",
    "Desc_ar": "السويد"
  },
  {
    "code": "SG",
    "Desc_en": "Singapore",
    "Desc_ar": "سنغافورة"
  },
  {
    "code": "SH",
    "Desc_en": "Saint Helena, Ascension and Tristan da Cunha",
    "Desc_ar": "سانت هيلينا وأسينشين وتريستان دا كونا"
  },
  {
    "code": "SI",
    "Desc_en": "Slovenia",
    "Desc_ar": "سلوفينيا"
  },
  {
    "code": "SJ",
    "Desc_en": "Svalbard and Jan Mayen",
    "Desc_ar": "سفالبارد ويان ماين"
  },
  {
    "code": "SK",
    "Desc_en": "Slovakia",
    "Desc_ar": "سلوفاكيا"
  },
  {
    "code": "SL",
    "Desc_en": "Sierra Leone",
    "Desc_ar": "سيراليون"
  },
  {
    "code": "SM",
    "Desc_en": "San Marino",
    "Desc_ar": "سان مارينو"
  },
  {
    "code": "SN",
    "Desc_en": "Senegal",
    "Desc_ar": "السنغال"
  },
  {
    "code": "SO",
    "Desc_en": "Somalia",
    "Desc_ar": "الصومال"
  },
  {
    "code": "SR",
    "Desc_en": "Suriname",
    "Desc_ar": "سورينام"
  },
  {
    "code": "SS",
    "Desc_en": "South Sudan",
    "Desc_ar": "جنوب السودان"
  },
  {
    "code": "ST",
    "Desc_en": "Sao Tome and Principe",
    "Desc_ar": "ساو تومي وبرينسيب"
  },
  {
    "code": "SV",
    "Desc_en": "El Salvador",
    "Desc_ar": "السلفادور"
  },
  {
    "code": "SX",
    "Desc_en": "Sint Maarten (Dutch Part)",
    "Desc_ar": "سينت مارتن"
  },
  {
    "code": "SY",
    "Desc_en": "Syrian Arab Republic",
    "Desc_ar": "سوريا"
  },
  {
    "code": "SZ",
    "Desc_en": "Eswatini",
    "Desc_ar": "سوازيلاند"
  },
  {
    "code": "TC",
    "Desc_en": "Turks and Caicos Islands",
    "Desc_ar": "جزر توركس وكايكوس"
  },
  {
    "code": "TD",
    "Desc_en": "Chad",
    "Desc_ar": "تشاد"
  },
  {
    "code": "TF",
    "Desc_en": "French Southern Territories",
    "Desc_ar": "أراض فرنسية جنوبية وأنتارتيكية"
  },
  {
    "code": "TG",
    "Desc_en": "Togo",
    "Desc_ar": "توجو"
  },
  {
    "code": "TH",
    "Desc_en": "Thailand",
    "Desc_ar": "تايلند"
  },
  {
    "code": "TJ",
    "Desc_en": "Tajikistan",
    "Desc_ar": "طاجكستان"
  },
  {
    "code": "TK",
    "Desc_en": "Tokelau",
    "Desc_ar": "توكيلو"
  },
  {
    "code": "TL",
    "Desc_en": "Timor-Leste",
    "Desc_ar": "تيمور الشرقية"
  },
  {
    "code": "TM",
    "Desc_en": "Turkmenistan",
    "Desc_ar": "تركمانستان"
  },
  {
    "code": "TN",
    "Desc_en": "Tunisia",
    "Desc_ar": "تونس"
  },
  {
    "code": "TO",
    "Desc_en": "Tonga",
    "Desc_ar": "تونجا"
  },
  {
    "code": "TR",
    "Desc_en": "Türkiye",
    "Desc_ar": "تركيا"
  },
  {
    "code": "TT",
    "Desc_en": "Trinidad and Tobago",
    "Desc_ar": "ترينيداد وتوباغو"
  },
  {
    "code": "TV",
    "Desc_en": "Tuvalu",
    "Desc_ar": "توفالو"
  },
  {
    "code": "TW",
    "Desc_en": "Taiwan (Province of China)",
    "Desc_ar": "تايوان"
  },
  {
    "code": "TZ",
    "Desc_en": "Tanzania, United Republic of",
    "Desc_ar": "تانزانيا"
  },
  {
    "code": "UA",
    "Desc_en": "Ukraine",
    "Desc_ar": "أوكرانيا"
  },
  {
    "code": "UG",
    "Desc_en": "Uganda",
    "Desc_ar": "أوغندا"
  },
  {
    "code": "UM",
    "Desc_en": "United States Minor Outlying Islands",
    "Desc_ar": "جزر الولايات المتحدة الصغيرة النائية"
  },
  {
    "code": "US",
    "Desc_en": "United States of America",
    "Desc_ar": "الولايات المتحدة"
  },
  {
    "code": "UY",
    "Desc_en": "Uruguay",
    "Desc_ar": "أورجواي"
  },
  {
    "code": "UZ",
    "Desc_en": "Uzbekistan",
    "Desc_ar": "أوزبكستان"
  },
  {
    "code": "VA",
    "Desc_en": "Holy See",
    "Desc_ar": "الفاتيكان"
  },
  {
    "code": "VC",
    "Desc_en": "Saint Vincent and the Grenadines",
    "Desc_ar": "سانت فينسنت والغرينادين"
  },
  {
    "code": "VE",
    "Desc_en": "Venezuela (Bolivarian Republic of)",
    "Desc_ar": "فنزويلا"
  },
  {
    "code": "VG",
    "Desc_en": "Virgin Islands (British)",
    "Desc_ar": "جزر العذراء البريطانية"
  },
  {
    "code": "VI",
    "Desc_en": "Virgin Islands (U.S.)",
    "Desc_ar": "جزر العذراء الأمريكية"
  },
  {
    "code": "VN",
    "Desc_en": "Viet Nam",
    "Desc_ar": "فيتنام"
  },
  {
    "code": "VU",
    "Desc_en": "Vanuatu",
    "Desc_ar": "فانواتو"
  },
  {
    "code": "WF",
    "Desc_en": "Wallis and Futuna",
    "Desc_ar": "والس وفوتونا"
  },
  {
    "code": "WS",
    "Desc_en": "Samoa",
    "Desc_ar": "ساموا"
  },
  {
    "code": "YE",
    "Desc_en": "Yemen",
    "Desc_ar": "اليمن"
  },
  {
    "code": "YT",
    "Desc_en": "Mayotte",
    "Desc_ar": "مايوت"
  },
  {
    "code": "ZA",
    "Desc_en": "South Africa",
    "Desc_ar": "جنوب أفريقيا"
  },
  {
    "code": "ZM",
    "Desc_en": "Zambia",
    "Desc_ar": "زامبيا"
  },
  {
    "code": "ZW",
    "Desc_en": "Zimbabwe",
    "Desc_ar": "زيمبابوي"
  }
]

countryList = countryList.sort((a, b) => {
  if (a.Desc_en < b.Desc_en) return -1;
  if (a.Desc_en > b.Desc_en) return 1;
  return 0;
});

export { countryList };
