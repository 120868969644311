export interface ICommodity {
  id?: number;
  name?: string;
  description?: string | null;
  price?: number;
  stock?: number ;
  currency?: string;
}
export interface ICustomerCommoditiesSold{
  id?:number;
  name?:string;
  amount?:number;
}

export interface ICommodityRequest {
  bankId?: string | string[];
  branchId?: string | string[];
  bankUserId?:string | string[];
  fromDate?: string | Date;
  toDate?: string | Date;
}

export interface ITopCommodities{
  id?:number;
  name?:string;
  soldCommodities?:number;
}

export const defaultValue: Readonly<ICommodity> = {};
