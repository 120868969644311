import { IQuotation } from 'app/shared/model/quotation.model';
import {IBank} from "app/shared/model/bank.model";
import {IPurchaseOrder} from "app/shared/model/purchase-order.model";
import {ICustomer} from "app/shared/model/customer.model";
import { IBursaAPIBidCert } from "app/shared/model/bursa-api-bid-cert.model";

export interface IPurchaseCancellation {
  id?: number;
  reason?: string;
  managerApproval?: boolean;
  managerId?:string | null;
  comexAdminApproval?: boolean;
  comexAdminId?:string | null;
  quotationId?:number;
  status?: string;
  result?: string;
  quotation?: IQuotation;
  bank?: IBank;
  purchaseOrder?: IPurchaseOrder;
  customer?: ICustomer;
  bursaAPIBidCert?: IBursaAPIBidCert;
  createdBy?:string;
  createdDate?:Date;
}

export const defaultValue: Readonly<IPurchaseCancellation> = {};
