import { IBank } from 'app/shared/model/bank.model';

export interface IComexBankFeeLog {
  id?: number;
  fromTo?: number;
  feeType?: string;
  amount?: number;
  status?: string;
  bank?: IBank;
}

export const defaultValue: Readonly<IComexBankFeeLog> = {};
