import './footer.scss';

import React, {useEffect, useState} from 'react';
import { Storage, Translate, translate, ValidatedField } from 'react-jhipster';
import {Col, Dropdown, DropdownItem, DropdownMenu, Row} from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntityByUserId as getBankByBankManagerId, getEntity as getBank } from 'app/entities/bank/bank.reducer';
import { getBranchByAreaManager, getEntity as getBranch } from 'app/entities/branch/branch.reducer';
import { getEntity as getComexSetting } from "app/entities/comex-settings/comex-settings.reducer";
import { Container, Grid, Link, Typography } from '@mui/material';
import {AUTHORITIES, USER_TYPE} from 'app/config/constants';
import { useHistory } from "react-router-dom";
import GroupIcon from "@mui/icons-material/Group";

const Footer = () => {

  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);
  const myAccount = (account && Object.keys(account).length > 0) ? account : Storage.session.get("account");

  const bank = useAppSelector(state => state.bank.entity);
  const bankManagerBank = useAppSelector(state => state.bank.entities);
  const bankManagerCondition=bankManagerBank?.length>0 && bankManagerBank[0]?.reportForBankManager;
  const branch = useAppSelector(state => state.branch.entity);
  const areaManagerBranchList = useAppSelector(state => state.branch.entities);
  const areaManagerCondition = areaManagerBranchList?.length > 0 && areaManagerBranchList[0]?.bank?.reportForAreaManager;
  const history = useHistory();

  const comexEntity = useAppSelector(state => state.comexSettings.entity);

  const [reportMenuOpen, setReportMenuOpen] = useState(false);
  const toggleReportMenu = () => setReportMenuOpen(!reportMenuOpen);
  const [reportForBankStaff, setReportForBankStaff] = useState(false);
  const [reportForBranchManager, setReportForBranchManager] = useState(false);
  const [reportForAreaManager, setReportForAreaManager] = useState(false);
  const [reportForBankManager, setReportForBankManager] = useState(false);
  const [reportForAuditor, setReportForAuditor] = useState(false);

  useEffect(() => {
    if (account?.login) {
      if (account?.branchId) {
        dispatch(getBranch(account?.branchId));
      } else if (account?.bankId) {
        dispatch(getBank(account?.bankId));
      } else {
        if (account?.authorities?.includes(AUTHORITIES.AREA_MANAGER)) {
          dispatch(getBranchByAreaManager(account?.id));
          setReportForAreaManager(areaManagerCondition);
        } else if (account?.authorities?.includes(AUTHORITIES.BANK_MANAGER)) {
          dispatch(getBankByBankManagerId(account?.id));
        }
      }
    }else{
      // If not logged in, redirect to login page
      if (!window.location.href.includes('/')) {
      setTimeout(() => {
        if( !localStorage.getItem('jhi-authenticationToken') && !sessionStorage.getItem('jhi-authenticationToken')  ){
          if( !window.location.href.includes('/login') && !window.location.href.includes('/account/reset/request') ){
            localStorage.setItem('currentPage', window.location.href );
            window.location.href = '/login';
          }
        }
      }, 3000); // wait for 5 seconds before checking the login
      }
    }
  }, [account, areaManagerCondition]);

  useEffect(() => {
    dispatch(getComexSetting(1));
  }, [dispatch]);

  useEffect(() => {
    if (branch?.bank !== undefined) {
      setReportForBankStaff(branch?.bank?.reportForBankStaff);
      setReportForBranchManager(branch?.bank?.reportForBranchManager);
      setReportForBankManager(branch?.bank?.reportForBankManager);
      setReportForAuditor(branch?.bank?.reportForAuditor);
    } else if (bank !== undefined) {
      setReportForBankStaff(bank?.reportForBankStaff);
      setReportForBranchManager(bank?.reportForBranchManager);
      setReportForBankManager(bank?.reportForBankManager);
      setReportForAuditor(bank?.reportForAuditor);
    }
  }, [branch, bank]);


  return (
      <footer className="footer-styled">
        <Container sx={{ padding: { xs: 2, sm: 4 } }}>
          <Grid container spacing={1} sx={{ alignItems: 'center' }}>
            {/* Logo and Report Link */}
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div className="company-section">
                <img className="img-footer" src="content/images/img-white.png" alt="Alshmokh-logo" />
              </div>
              {myAccount?.login
              && (myAccount?.authorities?.includes(AUTHORITIES.ADMIN)
                  || (myAccount?.authorities?.includes(AUTHORITIES.BANK_MANAGER) && reportForBankManager)
                  || (myAccount?.authorities?.includes(AUTHORITIES.BRANCH_MANAGER) && reportForBranchManager)
                  || (myAccount?.authorities?.includes(AUTHORITIES.AREA_MANAGER) && reportForAreaManager)
                  || (myAccount?.authorities?.includes(AUTHORITIES.AUDITOR) && reportForAuditor)
                  || myAccount?.authorities?.includes(AUTHORITIES.ALSHMOKH_ADMIN)
                  || myAccount?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER)) ? (
                  <Dropdown isOpen={reportMenuOpen} toggle={toggleReportMenu}>
                    <span
                      onClick={toggleReportMenu}
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded={reportMenuOpen}
                    >
                      <div className="link-styled" style={{ fontFamily: 'Roboto, sans-serif', color: '#ffffff', cursor: 'pointer' }}>
                        <Translate contentKey="comexApp.footer.link.reports" />
                      </div>
                    </span>
                    <DropdownMenu style={{width: 'auto', marginTop: '24px'}}>
                      <DropdownItem
                        onClick={() => {
                          history.push('/reports');
                        }}>
                        <Translate contentKey="global.menu.report.dropdown.transaction" />
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          history.push('/fee-reports');
                        }}>
                        <Translate contentKey="global.menu.report.dropdown.fee" />
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
              ) : null}
              {myAccount?.login
                && myAccount?.authorities?.includes(AUTHORITIES.BANK_STAFF) && reportForBankStaff && (
                  <Link style={{ fontFamily: 'Roboto, sans-serif' }} onClick={() => history.push("/reports")} underline="none" color="white" className="link-styled">
                    <Translate contentKey="comexApp.footer.link.reports" />
                  </Link>
                )
              }
            </Grid>
            {/* Hotline */}
            <Grid item xs={12}>
              <div className="company-name">
                <Translate contentKey="comexApp.footer.hotline" />:
                <span style={{ unicodeBidi: 'bidi-override', direction: 'ltr' }}>
                  {comexEntity?.hotline ? `\u200E${comexEntity.hotline}` : 'Not Available'}
                </span>
              </div>
            </Grid>

            {/* Email */}
            <Grid item xs={12}>
              <div className="company-name">
                <Translate contentKey="comexApp.footer.email" />: {comexEntity?.email || 'Not Available'}
              </div>
            </Grid>
            {/* Company Name & Copyright */}
            <Grid item xs={12}>
              <div className="company-name">
                <Translate contentKey="comexApp.footer.name" />
                &reg; {comexEntity?.copyright || '2023'}
              </div>
              <div>
                <span style={{fontSize:'smaller'}}>v{VERSION}</span>
              </div>
            </Grid>
          </Grid>
        </Container>
      </footer>
  )
};

export default Footer;
