import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PrivateRoute from 'app/shared/auth/private-route';

import CurrencyPairsLog from './currency-pairs-log';
import CurrencyPairsLogDetail from './currency-pairs-log-detail';
import CurrencyPairsLogUpdate from './currency-pairs-log-update';
import CurrencyPairsLogDeleteDialog from './currency-pairs-log-delete-dialog';
import { AUTHORITIES } from 'app/config/constants';

const Routes = ({ match }) => (
  <>
    <Switch>
      <PrivateRoute
        exact path={`${match.url}/:id`}
        component={CurrencyPairsLogDetail}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.AUDITOR,
        ]}
      />
      <PrivateRoute
        path={match.url}
        component={CurrencyPairsLog}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.AUDITOR,
        ]}
      />
    </Switch>
  </>
);

export default Routes;
