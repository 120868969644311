export interface IBursaAPIOrder {
  id?: number;
  endpointURL?: string;
  memberShortName?: string;
  uuid?: string;
  serialNumber?: string;
  bidOption?: string;
  stbOption?: string;
  otcOption?: string;
  productCode?: string;
  purchaseType?: string;
  clientName?: string;
  currency?: string;
  bidValue?: string;
  valueDate?: string;
  tenor?: string;
  otcCounterParty?: string | null;
  otcMurabaha?: string | null;
  otcMurabahaValue?: string;
  ecertNo?: string | null;
  errorCode?: string | null;
  errorMsg?: string | null;
  statusCode?: string | null;
  statusMessage?: string | null;
  quotationId?: string;
}

export const defaultValue: Readonly<IBursaAPIOrder> = {};
