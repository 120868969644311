import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PrivateRoute from 'app/shared/auth/private-route';

import Branch from './branch';
import BranchDetail from './branch-detail';
import BranchUpdate from './branch-update';
import BranchDeleteDialog from './branch-delete-dialog';
import { AUTHORITIES } from 'app/config/constants';

const Routes = ({ match }) => (
  <>
    <Switch>
      <PrivateRoute
        exact path={`${match.url}/new`}
        component={BranchUpdate}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.ALSHMOKH_ADMIN,
          AUTHORITIES.SUBADMIN_BRANCH,
        ]}
      />
      <PrivateRoute
        exact path={`${match.url}/:id/edit`}
        component={BranchUpdate}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.ALSHMOKH_ADMIN,
          AUTHORITIES.SUBADMIN_BRANCH,
        ]}
      />
      <PrivateRoute
        exact path={`${match.url}/:id`}
        component={BranchDetail}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.AUDITOR,
          AUTHORITIES.BANK_MANAGER,
          AUTHORITIES.BRANCH_MANAGER,
          AUTHORITIES.BANK_STAFF,
          AUTHORITIES.ALSHMOKH_ADMIN,
          AUTHORITIES.SUBADMIN_BRANCH,
        ]}
      />
      <PrivateRoute
        path={match.url}
        component={Branch}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.AUDITOR,
          AUTHORITIES.BANK_MANAGER,
          AUTHORITIES.BRANCH_MANAGER,
          AUTHORITIES.BANK_STAFF,
          AUTHORITIES.ALSHMOKH_ADMIN,
          AUTHORITIES.SUBADMIN_BRANCH,
        ]}
      />
    </Switch>
    <PrivateRoute
      exact path={`${match.url}/:id/delete`}
      component={BranchDeleteDialog}
      hasAnyAuthorities={[
        AUTHORITIES.ADMIN,
        AUTHORITIES.ALSHMOKH_ADMIN,
        AUTHORITIES.SUBADMIN_BRANCH,
      ]}
    />
  </>
);

export default Routes;
