import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './bursa-api-otc-cert.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const BursaAPIOtcCertDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const bursaAPIOtcCertEntity = useAppSelector(state => state.bursaAPIOtcCert.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="bursaAPIOtcCertDetailsHeading">
          <Translate contentKey="comexApp.bursaAPIOtcCert.detail.title">BursaAPIOtcCert</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOtcCertEntity.id}</dd>
          <dt>
            <span id="endpointURL">
              <Translate contentKey="comexApp.bursaAPIOtcCert.endpointURL">Endpoint URL</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOtcCertEntity.endpointURL}</dd>
          <dt>
            <span id="ecertNo">
              <Translate contentKey="comexApp.bursaAPIOtcCert.ecertNo">Ecert No</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOtcCertEntity.ecertNo}</dd>
          <dt>
            <span id="memberShortName">
              <Translate contentKey="comexApp.bursaAPIOtcCert.memberShortName">Member Short Name</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOtcCertEntity.memberShortName}</dd>
          <dt>
            <span id="successYN">
              <Translate contentKey="comexApp.bursaAPIOtcCert.successYN">Success YN</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOtcCertEntity.successYN}</dd>
          <dt>
            <span id="msg">
              <Translate contentKey="comexApp.bursaAPIOtcCert.msg">Msg</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOtcCertEntity.msg}</dd>
          <dt>
            <span id="seller">
              <Translate contentKey="comexApp.bursaAPIOtcCert.seller">Seller</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOtcCertEntity.seller}</dd>
          <dt>
            <span id="buyer">
              <Translate contentKey="comexApp.bursaAPIOtcCert.buyer">Buyer</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOtcCertEntity.buyer}</dd>
          <dt>
            <span id="totalValue">
              <Translate contentKey="comexApp.bursaAPIOtcCert.totalValue">Total Value</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOtcCertEntity.totalValue}</dd>
          <dt>
            <span id="currency">
              <Translate contentKey="comexApp.bursaAPIOtcCert.currency">Currency</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOtcCertEntity.currency}</dd>
          <dt>
            <span id="price">
              <Translate contentKey="comexApp.bursaAPIOtcCert.price">Price</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOtcCertEntity.price}</dd>
          <dt>
            <span id="priceMYR">
              <Translate contentKey="comexApp.bursaAPIOtcCert.priceMYR">Price MYR</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOtcCertEntity.priceMYR}</dd>
          <dt>
            <span id="murabahValue">
              <Translate contentKey="comexApp.bursaAPIOtcCert.murabahValue">Murabah Value</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOtcCertEntity.murabahValue}</dd>
          <dt>
            <span id="reportingTimeDate">
              <Translate contentKey="comexApp.bursaAPIOtcCert.reportingTimeDate">Reporting Time Date</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOtcCertEntity.reportingTimeDate}</dd>
          <dt>
            <span id="valueDate">
              <Translate contentKey="comexApp.bursaAPIOtcCert.valueDate">Value Date</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOtcCertEntity.valueDate}</dd>
          <dt>
            <span id="pName">
              <Translate contentKey="comexApp.bursaAPIOtcCert.pName">P Name</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOtcCertEntity.pName}</dd>
          <dt>
            <span id="pVolume">
              <Translate contentKey="comexApp.bursaAPIOtcCert.pVolume">P Volume</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOtcCertEntity.pVolume}</dd>
          <dt>
            <span id="supplier">
              <Translate contentKey="comexApp.bursaAPIOtcCert.supplier">Supplier</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOtcCertEntity.supplier}</dd>
          <dt>
            <span id="volume">
              <Translate contentKey="comexApp.bursaAPIOtcCert.volume">Volume</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOtcCertEntity.volume}</dd>
        </dl>
        <Button tag={Link} to="/bursa-api-otc-cert" replace data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/bursa-api-otc-cert/${bursaAPIOtcCertEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default BursaAPIOtcCertDetail;
