import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ComexBankFee from './comex-bank-fee';
import ComexBankFeeDetail from './comex-bank-fee-detail';
import ComexBankFeeUpdate from './comex-bank-fee-update';
import ComexBankFeeDeleteDialog from './comex-bank-fee-delete-dialog';
import PrivateRoute from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

const Routes = ({ match }) => (
  <>
    <Switch>
      <PrivateRoute exact path={`${match.url}/new`} component={ComexBankFeeUpdate} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <PrivateRoute exact path={`${match.url}/:id/edit`} component={ComexBankFeeUpdate} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ComexBankFeeDetail} />
      <ErrorBoundaryRoute path={match.url} component={ComexBankFee} />
    </Switch>
    <PrivateRoute exact path={`${match.url}/:id/delete`} component={ComexBankFeeDeleteDialog} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
  </>
);

export default Routes;
