import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity, updateEntity, createEntity, reset } from './bursa-api-order-result.reducer';
import { IBursaAPIOrderResult } from 'app/shared/model/bursa-api-order-result.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const BursaAPIOrderResultUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const bursaAPIOrderResultEntity = useAppSelector(state => state.bursaAPIOrderResult.entity);
  const loading = useAppSelector(state => state.bursaAPIOrderResult.loading);
  const updating = useAppSelector(state => state.bursaAPIOrderResult.updating);
  const updateSuccess = useAppSelector(state => state.bursaAPIOrderResult.updateSuccess);
  const handleClose = () => {
    props.history.push('/bursa-api-order-result' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...bursaAPIOrderResultEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...bursaAPIOrderResultEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="comexApp.bursaAPIOrderResult.home.createOrEditLabel" data-cy="BursaAPIOrderResultCreateUpdateHeading">
            <Translate contentKey="comexApp.bursaAPIOrderResult.home.createOrEditLabel">Create or edit a BursaAPIOrderResult</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="bursa-api-order-result-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('comexApp.bursaAPIOrderResult.endpointURL')}
                id="bursa-api-order-result-endpointURL"
                name="endpointURL"
                data-cy="endpointURL"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrderResult.memberShortName')}
                id="bursa-api-order-result-memberShortName"
                name="memberShortName"
                data-cy="memberShortName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 8, message: translate('entity.validation.maxlength', { max: 8 }) },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrderResult.uuid')}
                id="bursa-api-order-result-uuid"
                name="uuid"
                data-cy="uuid"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrderResult.serialNumbers')}
                id="bursa-api-order-result-serialNumbers"
                name="serialNumbers"
                data-cy="serialNumbers"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrderResult.forceYN')}
                id="bursa-api-order-result-forceYN"
                name="forceYN"
                data-cy="forceYN"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrderResult.maxWaitTime')}
                id="bursa-api-order-result-maxWaitTime"
                name="maxWaitTime"
                data-cy="maxWaitTime"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrderResult.waitAllDoneYN')}
                id="bursa-api-order-result-waitAllDoneYN"
                name="waitAllDoneYN"
                data-cy="waitAllDoneYN"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrderResult.bidErrNo')}
                id="bursa-api-order-result-bidErrNo"
                name="bidErrNo"
                data-cy="bidErrNo"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrderResult.bidMsg')}
                id="bursa-api-order-result-bidMsg"
                name="bidMsg"
                data-cy="bidMsg"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrderResult.otcErrNo')}
                id="bursa-api-order-result-otcErrNo"
                name="otcErrNo"
                data-cy="otcErrNo"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrderResult.otcMsg')}
                id="bursa-api-order-result-otcMsg"
                name="otcMsg"
                data-cy="otcMsg"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrderResult.stbErrNo')}
                id="bursa-api-order-result-stbErrNo"
                name="stbErrNo"
                data-cy="stbErrNo"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrderResult.stbMsg')}
                id="bursa-api-order-result-stbMsg"
                name="stbMsg"
                data-cy="stbMsg"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrderResult.regTime')}
                id="bursa-api-order-result-regTime"
                name="regTime"
                data-cy="regTime"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrderResult.orderTime')}
                id="bursa-api-order-result-orderTime"
                name="orderTime"
                data-cy="orderTime"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrderResult.resultTime')}
                id="bursa-api-order-result-resultTime"
                name="resultTime"
                data-cy="resultTime"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrderResult.purchaseTime')}
                id="bursa-api-order-result-purchaseTime"
                name="purchaseTime"
                data-cy="purchaseTime"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrderResult.reportTime')}
                id="bursa-api-order-result-reportTime"
                name="reportTime"
                data-cy="reportTime"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrderResult.sellingTime')}
                id="bursa-api-order-result-sellingTime"
                name="sellingTime"
                data-cy="sellingTime"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrderResult.unit')}
                id="bursa-api-order-result-unit"
                name="unit"
                data-cy="unit"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrderResult.price')}
                id="bursa-api-order-result-price"
                name="price"
                data-cy="price"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrderResult.totalOrderCount')}
                id="bursa-api-order-result-totalOrderCount"
                name="totalOrderCount"
                data-cy="totalOrderCount"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrderResult.processingCount')}
                id="bursa-api-order-result-processingCount"
                name="processingCount"
                data-cy="processingCount"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIOrderResult.bursaAPIOrderId')}
                id="bursa-api-order-result-bursaAPIOrderId"
                name="bursaAPIOrderId"
                data-cy="bursaAPIOrderId"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/bursa-api-order-result" replace>
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default BursaAPIOrderResultUpdate;
