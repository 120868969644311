import { IBank } from 'app/shared/model/bank.model';

export interface ICommissionLog {
  id?: number;
  commission?: boolean | null;
  commissionAmount?: number | null;
  commissionForEachAmount?: number | null;
  bank?: IBank | null;
  bankId?:number|null;
}

export const defaultValue: Readonly<ICommissionLog> = {
  commission: false,
};
