import React, { useState, useEffect } from 'react';
import { Col, Row, DropdownItem, Button as ButtonReact } from 'reactstrap';
import { Translate, translate, getUrlParameter, ValidatedField, ValidatedForm, Storage } from 'react-jhipster';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import {Link, useHistory} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {
  Box,
  CardMedia,
  createTheme, Dialog, DialogActions,
  DialogContent, DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Theme,
  Typography
} from "@mui/material";
import {createStyles, makeStyles } from "@material-ui/core/styles";
import TranslateIcon from '@mui/icons-material/Translate';
import Paper from "@mui/material/Paper";
import {Button} from "@material-ui/core";
import {isRTL, languages, locales} from "app/config/translation";
import {setLocale} from "app/shared/reducers/locale";
import Card from "@mui/material/Card";
import {NavDropdown} from "app/shared/layout/menus/menu-components";
import {responsiveFontSizes} from "@mui/material/styles";
import {ThemeProvider} from "@mui/styles";

import { handlePasswordResetFinish, reset } from '../password-reset.reducer';
import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {AUTHORITIES} from "app/config/constants";
import {
  updateUserLanguage,
  updateUserLanguageForAllRoles
} from "app/modules/administration/user-management/user-management.reducer";

const useStyles = makeStyles((theme:Theme) =>
  createStyles({
    formRoot:{
      paddingLeft:45,
      paddingRight:45,
      marginBottom:30,
      [theme.breakpoints.down("xs")]: {
        paddingLeft:15,
        paddingRight:15,
      },
    },
    headerText:{
      margin: '30px 8px 5px  50px',
      [theme.breakpoints.down("xs")]: {
        margin: '50px 8px 5px 10px',
      },
      [theme.breakpoints.down("sm")]: {
        margin: '50px 8px 5px  30px',
      },
    },
    textFieldLabel: {
      "& .MuiFormLabel-root": {
        marginLeft: '32px',
        marginTop: '10px',
      }
    },
    textFieldLabelArabic: {
      "& .MuiFormLabel-root": {
        marginTop: '10px',
        position: 'relative',
        right: '42px',
        transform: 'translateY(20px)',
      }
    },

  })
);

export const PasswordResetFinishPage = (props: RouteComponentProps<{ key: string }>) => {
  const [password, setPassword] = useState('');
  const [key] = useState(getUrlParameter('key', props.location.search));
  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);
  const user = useAppSelector(state => state.userManagement.user);
  const isSuccessReset = useAppSelector(state => state.passwordReset.resetPasswordSuccess);
  const statusNumber = useAppSelector(state => state.passwordReset.statusNumber);
  const isUpdated = useAppSelector(state => state.passwordReset.isUpdated);
  const [openMessageDialog, setOpenMessageDialog] = React.useState(false);

  useEffect(
    () => () => {
      dispatch(reset());
    },
    []
  );

  const handleMessageDialog = () => {
    setOpenMessageDialog(true);
  }

  const handleToClose = () => {
    setOpenMessageDialog(false);
  };

  const handleToLogin = () => {
    props.history.push("");
  }

  const handleValidSubmit = ({ newPassword }) => dispatch(handlePasswordResetFinish({ key, newPassword }));

  const updatePassword = event => setPassword(event.target.value);

  const handleLocaleChange = event => {
    const langKey = event.target.value;
    document.querySelector('html').setAttribute('dir', isRTL(langKey) ? 'rtl' : 'ltr');
    Storage.session.set('locale', langKey);
    dispatch(setLocale(langKey));
    try{
      const updatedUser = {
        ...user,
        id : account.id,
        langKey:event.target.value,
      };
      if (account?.authorities.includes(AUTHORITIES.ADMIN))
        dispatch(updateUserLanguage(updatedUser));
      else
        dispatch(updateUserLanguageForAllRoles(updatedUser));
    }
    catch (TypeError){
      console.warn("Account not defined yet");
    }
  };

  const classes = useStyles();
  let responsiveFont = createTheme();
  responsiveFont = responsiveFontSizes(responsiveFont);

  const getResetForm = () => {
    return (
      <ValidatedForm onSubmit={handleValidSubmit}>
        <ValidatedField
          name="newPassword"
          label={translate('global.form.newpassword.label')}
          placeholder={translate('global.form.newpassword.placeholder')}
          type="password"
          validate={{
            required: { value: true, message: translate('global.messages.validate.newpassword.required') },
            minLength: { value: 4, message: translate('global.messages.validate.newpassword.minlength') },
            maxLength: { value: 50, message: translate('global.messages.validate.newpassword.maxlength') },
          }}
          onChange={updatePassword}
          data-cy="resetPassword"
        />
        <PasswordStrengthBar password={password} />
        <ValidatedField
          name="confirmPassword"
          label={translate('global.form.confirmpassword.label')}
          placeholder={translate('global.form.confirmpassword.placeholder')}
          type="password"
          validate={{
            required: { value: true, message: translate('global.messages.validate.confirmpassword.required') },
            minLength: { value: 4, message: translate('global.messages.validate.confirmpassword.minlength') },
            maxLength: { value: 50, message: translate('global.messages.validate.confirmpassword.maxlength') },
            validate: v => v === password || translate('global.messages.error.dontmatch'),
          }}
          data-cy="confirmResetPassword"
        />
        <Button
          variant="contained"
          size="medium"
          color="primary"
          type="submit"
          data-cy="submit"
          onClick={handleMessageDialog}
          disabled={isUpdated}
          fullWidth>
          <Translate contentKey="reset.finish.form.button">Validate new password</Translate>
        </Button>
        <Dialog open={openMessageDialog} onClose={handleToClose}>
          <DialogContent>
            {successMessage && statusNumber === 200 &&
              <DialogContentText style={{ color: "black"}} className='mb-3'>
                <strong>{translate('reset.finish.dialog.success.title')}</strong> {translate('reset.finish.dialog.success.desc')}
              </DialogContentText>
            }
            {statusNumber === undefined &&
              <DialogContentText style={{ color: "black"}} className='mb-3'>
                <strong>{translate('reset.finish.dialog.failed.title')}</strong> {translate('reset.finish.dialog.failed.desc')}
              </DialogContentText>
            }
          </DialogContent>
          <DialogActions className="mx-3">
            <DialogContentText>
              {successMessage && statusNumber === 200 &&
                <ButtonReact color="primary" onClick={handleToLogin} autoFocus>
                  {translate('reset.finish.dialog.loginBtn')}
                </ButtonReact>
              }
              {statusNumber === undefined &&
                <ButtonReact color="primary" onClick={handleToClose} autoFocus>
                  {translate('comexApp.quotation.okButton')}
                </ButtonReact>
              }
            </DialogContentText>
          </DialogActions>
        </Dialog>
      </ValidatedForm>
    );
  };

  const successMessage = useAppSelector(state => state.passwordReset.successMessage);

  useEffect(() => {
    if (successMessage && statusNumber === 200) {
      // toast.success(translate(successMessage));
      setOpenMessageDialog(true);
    }
  }, [successMessage]);

  useEffect(() => {
    if (statusNumber === undefined) {
      setOpenMessageDialog(true);
    }
  }, [statusNumber]);

  return (
    <div>
      <Grid container>
        <ThemeProvider theme={responsiveFont}>
          <Grid item xs={12} md={7}>
            <Paper elevation={0} >
              <Card >
                <div style={{ position: "relative" }}>
                  <CardMedia  sx={{
                    height: {md:'90vh',sm:'100%',xs:'93vh'},
                    width: '100%',
                    objectFit: 'cover'
                  }}  component="img" image={"../content/images/background.png"} alt="Background"/>
                  <div className="backgroundTitlePosition">
                    <Typography variant="h4" className="text-sm-center text-xs-left" ml={1} sx={{fontFamily:'Inria Sans',fontWeight:300,fontSize: {md:'2.5rem',sx:'2.2rem'}}}>
                        INTERNATIONAL TRADE & DEVELOPMENT
                    </Typography>
                    <div className="text-sm-center text-sm-justify mt-3 " id="leadText">
                    <Typography variant="h5" ml={1}>
                      Dedicated to Growth, Providing
                    </Typography>
                      {Storage.session.get('locale')==='en'?(<Typography variant="h5" ml={1}>
                       Opportunity - Using Experience,
                    </Typography>):(<Typography variant="h5" ml={1}>
                        ,Opportunity - Using Experience
                      </Typography>)}
                    <Typography variant="h5" ml={1}>
                       Tradition & Technology to Build Our  Future
                    </Typography>
                    </div>
                  </div>
                </div>
              </Card>
            </Paper>
          </Grid>
          <Grid item xs={12} md={5}>
            <Paper elevation={0} >
              <Row>
                <Col xs={7} md={8}>
                  <Grid container direction="row" className={classes.headerText} style={{alignItems: 'center'}}>
                    <Grid item >
                      <Box
                        component="img"
                        sx={{
                          maxWidth: {xs: 60, md: 70},
                          mr:{xs:2},
                          ml:{xs:2,md:2},
                          p:0,
                        }}
                        alt="logo"
                        src="../content/images/img.png"
                      />
                    </Grid>
                    <Grid item>
                      <div className="text-class">
                        {Storage.session.get('locale') === 'ar-ly' ? (
                          <Typography variant="subtitle1">
                            <Translate contentKey="login.logoTitle.fullTitle" />
                          </Typography>
                        ) : (
                          <>
                            <Typography variant="subtitle1">
                              <Translate contentKey="login.logoTitle.nameTitle"/>
                            </Typography>
                            <Typography variant="subtitle1">
                              <Translate contentKey="login.logoTitle.systemTitle"/>
                            </Typography>
                          </>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Col>
                <Col xs={4} md={4}>
                  <div className='languageSection'>
                    <div style={{display: "flex", justifyContent: "center"}}>
                      <NavDropdown icon="language" name={
                        <div className="locale-icon-login">
                          <TranslateIcon
                            sx={{
                              fontSize: '24px',
                              marginTop: {md: 0, sm: '16px', xs: '-24px'}
                            }}/>
                          <Translate contentKey="global.menu.language" />
                        </div>
                        }>
                        {locales.map(locale => (
                          <DropdownItem key={locale} value={locale} onClick={handleLocaleChange}>
                            {languages[locale].name}
                          </DropdownItem>
                        ))}
                      </NavDropdown>
                    </div>
                  </div>
                </Col>
              </Row>
              <h2 className='loginTitle'><Translate contentKey="reset.finish.title">Reset password</Translate></h2>
              <Box className={classes.formRoot}>
                <Grid container direction="column" >
                  {key ? getResetForm() : null}
                </Grid>
                <div className="mt-3" style={{textAlign: 'center'}}>
                  <Link to="" style={{fontWeight: 'normal'}}>
                    <Translate contentKey="reset.request.backBtn"/>
                  </Link>
                </div>
              </Box>
            </Paper>
          </Grid>
        </ThemeProvider>
      </Grid>
    </div>
  );
};

export default PasswordResetFinishPage;
