export interface ICurrencyIndex {
  id?: number;
  name?: string;
  description?: string;
  active?: boolean;
}

export interface ICurrencyIndexParams {
  roleId?: string | number;
  userRole?: string;
}

export const defaultValue: Readonly<ICurrencyIndex> = {
  active: false,
};
