import './reports.scss';
import React, {useEffect, useRef, useState} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Storage, Translate, translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Button, Row, Col, Input, Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { enUS, ar } from 'date-fns/locale';
import moment from 'moment/moment';
import {Link} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import EventIcon from '@mui/icons-material/Event';
import ButtonMui from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import SettingsIcon from '@mui/icons-material/Settings';
import { getBranchByAreaManager, getBranchByBankList, getBranchListByBankId, getEntity as getBranch } from '../../entities/branch/branch.reducer';
import {
  getAllEntities as getBankEntities,
  getEntityByUserId as getBankByUserId,
  getEntity as getBankEntity,
  getEntityByBranchId as getBankByBranchId
} from '../../entities/bank/bank.reducer';
import { Chart, registerables } from 'chart.js';
import { Pie, Line } from 'react-chartjs-2';
Chart.register(...registerables);
import { AUTHORITIES } from 'app/config/constants';

import {
  getFeeReports,
  getFeeReportsPrint,
} from 'app/entities/quotation/quotation.reducer';
import { getUsers, getAllBankStaffFromBranch, getUserByAlshmokhAdmin, getBankStaffListByBranchList, getUserByIdAsOtherRoles } from '../administration/user-management/user-management.reducer';
import { getEntities as getCommodities, getTopCommodities } from 'app/entities/commodity/commodity.reducer';
import { getAllEntities as getCurrencyIndex } from 'app/entities/currency-index/currency-index.reducer';
import { MultiSelect } from 'react-multi-select-component';
import { IQuotation } from "app/shared/model/quotation.model";
import { ICommodity } from 'app/shared/model/commodity.model';

interface Option {
  label: string;
  value: number;
}

export const FeeReports = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);
  const myAccount = account && Object.keys(account).length > 0 ? account : Storage.session.get('account');
  const notificationEntity = useAppSelector(state => state.notificationRecord.entities);
  const bank = useAppSelector(state => state.bank.entity);
  const banks = useAppSelector(state => state.bank.entities);
  const branches = useAppSelector(state => state.branch.entities);
  const branch = useAppSelector(state => state.branch.entity);
  const quotations = useAppSelector(state => state.quotation.entities);
  const currencyIndexList = useAppSelector(state => state.currencyIndex.entities);
  const reportsPrint = useAppSelector(state => state.quotation.reportPrint);
  const users = useAppSelector(state => state.userManagement.users);
  const commodities = useAppSelector(state => state.commodity.entities);
  const [totalAmountForReports, setTotalAmountForReports] = useState(0);
  const [totalAmountForExcel, setTotalAmountForExcel] = useState(0);
  const user = useAppSelector(state => state.userManagement.user);
  const [selectedBank, setSelectedBank] = useState<Option[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<Option[]>([]);
  const [selectedUser, setSelectedUser] = useState<Option[]>([]);
  const currentDateTime = new Date();
  const [fromDate, setFromDate] = useState<Date | null>(moment().subtract(7, 'day').toDate());
  const [toDate, setToDate] = useState<Date | null>(currentDateTime);
  const [dateError, setDateError] = useState<string>();
  const [filter, setFilter] = useState(false);
  const [openCalendarFrom, setOpenCalendarFrom] = useState(false);
  const [openCalendarTo, setOpenCalendarTo] = useState(false);
  const [queryDate, setQueryDate] = useState('');
  const [pageNo, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedBankName, setSelectedBankName] = useState('');
  const [selectedBranchName, setSelectedBranchName] = useState('');
  const totalCount = useAppSelector(state => state.quotation.totalItems);
  const tempDate = new Date();
  const formatTwoDigits = digit => ('0' + digit).slice(-2);
  const today_date = `${tempDate.getFullYear()}/${formatTwoDigits(tempDate.getMonth() + 1)}/${formatTwoDigits(tempDate.getDate())}`;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const defaultCurrency = currencyIndexList?.filter(c => c?.id === branch?.bank?.currencyId || c?.id === branches[0]?.bank?.currencyId);
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const bankCommoditiesArr = [] as Array<ICommodity>;
  const areaManagerCondition = branches?.length > 0 && branches[0]?.id;
  const [userList, setUserList]  = useState<any[]>([]);

  const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-input': {
      textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
    },
  });

  function bankCommoditiesFunction() {
    // bank commodities for bank staff, branch manager, area manager, and auditor
    if (Array.isArray(bank?.commodities) && bank?.commodities?.length) {
      for (let i = 0; i < bank?.commodities?.length; i++) {
        bankCommoditiesArr.push(bank?.commodities[i]);
      }
    }
    else {
      // bank commodities for bank manager
      if (banks[0]?.commodities?.length > 0 && banks[0]?.commodities) {
        for (let i = 0; i < banks[0]?.commodities?.length; i++) {
          bankCommoditiesArr.push(banks[0]?.commodities[i]);
        }
      }
      // bank commodities for other
      else {
        for (let i = 0; i < commodities?.length; i++) {
          bankCommoditiesArr.push(commodities[i]);
        }
      }
    }
  }
  bankCommoditiesFunction();

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  }

  useEffect(() => {
    dispatch(getBranchByBankList(selectedBank?.map(item => item?.value)));
    setFilter(false);
    selectedBank && selectedBank?.map((b,index)=>
    {index===0 ? setSelectedBankName(b?.label) :  setSelectedBankName(selectedBankName.concat(" , ",b?.label))}
    );
  }, [selectedBank]);

  useEffect(() => {
    dispatch(getBankStaffListByBranchList(selectedBranch?.map(item => item?.value?.toString())))
      .then(res => {
        setUserList(res.payload['data']);
      });
    setFilter(false);
  }, [selectedBranch]);

  useEffect(() => {
    setFilter(false);
  }, [selectedUser]);

  useEffect(() => {
    dispatch(getCommodities({}));
    dispatch(getCurrencyIndex({}));
    dispatch(getUsers({}));

    if (account?.authorities?.includes(AUTHORITIES.BRANCH_MANAGER)) {
      dispatch(getBranch(account?.branchId));
      dispatch(getAllBankStaffFromBranch(account?.branchId))
        .then(res => {
          setUserList(res.payload['data']);
        });
      dispatch(getBankByBranchId(account?.branchId));
    } else if (account?.authorities?.includes(AUTHORITIES.AUDITOR)) {
      dispatch(getBankEntity(account?.bankId)).then(res => dispatch(getBranchListByBankId(res.payload['data'].id)));
    } else if (account?.authorities?.includes(AUTHORITIES.BANK_MANAGER)) {
      dispatch(getBankByUserId(account?.id)).then(res => dispatch(getBranchListByBankId(res.payload['data'].id)));
    } else if (account?.authorities?.includes(AUTHORITIES.AREA_MANAGER) && areaManagerCondition) {
      dispatch(getBranchByAreaManager(account?.id));
      dispatch(getBankByBranchId(areaManagerCondition));
    } else if (account?.authorities?.includes(AUTHORITIES.BANK_STAFF)) {
      dispatch(getBranch(account?.branchId));
      dispatch(getBankByBranchId(account?.branchId));
    }  else if(account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER) || account?.authorities?.includes(AUTHORITIES.ALSHMOKH_ADMIN) || account?.authorities?.includes(AUTHORITIES.ADMIN)) {
      dispatch(getBankEntities({}));
    }


  }, [areaManagerCondition]);

  const getStaffFullName = (createdUser) => {
    if (!createdUser || typeof createdUser !== 'object') {
      return '';
    }

    const { firstName, lastName } = createdUser;
    if (!firstName || !lastName) {
      return '';
    }

    const fullName = `${firstName} ${lastName}`;
    return fullName;
  };

  const getDataToExportExcel = () => {
    const maxLength = Math.max(...(reportsPrint?.map(q => q.branch?.name.length) || [])); // Find the longest branch name

    const updatedQuotations = reportsPrint?.map(q => ({
      ...q,
      ecertNo1: (q?.bursaAPIBidCertId !== null) ? q?.bursaAPIBidCert?.ecertNo : '-' ,
      formattedCreatedDate: moment(q?.createdDate).format('yyyy/MM/DD'),
      amount: (q?.amount !== null) ? q?.amount?.toLocaleString() : 0,
      fee: ((q?.fee || 0) + (q?.commission || 0)).toLocaleString(),
      createdBy: getStaffFullName(q?.createdUser),
      branchName: `${q?.branch?.name} ${' '.repeat(maxLength - (q?.branch?.name.length || 0))} ${q?.branch?.createdDate ? moment(q?.branch?.createdDate).format('yyyy/MM/DD') : ' '}`,
      feeCurrencyIndex: (q?.feeCurrencyIndex !== null) ? q?.feeCurrencyIndex : '-'
    }));
    const dataToExport = updatedQuotations?.map( ({id, ecertNo1, formattedCreatedDate, customer: {name: customerName}, fee,
                                             bank: {name: bankName}, branchName, createdBy, feeCurrencyIndex}) => ({
         id,
         ecertNo1,
         formattedCreatedDate,
         customerName,
         bankName,
         branchName,
         createdBy,
         fee,
         feeCurrencyIndex
    }) )

    return dataToExport;
  };

  const getDataToExportExcelArabic = () => {
    const maxLength = Math.max(...(reportsPrint?.map(q => q.branch?.name.length) || [])); // Find the longest branch name

    const updatedQuotations = reportsPrint?.map(q => ({
      ...q,
      ecertNo1: (q?.bursaAPIBidCertId !== null) ? q?.bursaAPIBidCert?.ecertNo : '-' ,
      formattedCreatedDate: moment(q?.createdDate).format('yyyy/MM/DD'),
      amount: (q?.amount !== null) ? q?.amount?.toLocaleString() : 0,
      fee: ((q?.fee || 0) + (q?.commission || 0)).toLocaleString(),
      createdBy: getStaffFullName(q?.createdUser),
      branchName: `${q?.branch?.name} ${' '.repeat(maxLength - (q?.branch?.name.length || 0))} ${q?.branch?.createdDate ? moment(q?.branch?.createdDate).format('yyyy/MM/DD') : ' '}`,
      feeCurrencyIndex: (q?.feeCurrencyIndex !== null) ? q?.feeCurrencyIndex : '-'
    }));
    const dataToExport = updatedQuotations?.map( ({id, ecertNo1, formattedCreatedDate, customer: {name: customerName}, fee,
                                                    bank: {name: bankName}, branchName, createdBy, feeCurrencyIndex}) => ({
      feeCurrencyIndex,
      fee,
      createdBy,
      branchName,
      bankName,
      customerName,
      formattedCreatedDate,
      ecertNo1,
      id
    }) )

    return dataToExport;
  };

  function addRow(ws, data, section) {
    const borderStyles = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    const row = ws.addRow(data);
    // console.log('addRow', section, data);
    row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      if (section?.border) {
        cell.border = borderStyles;
      }
      if (section?.money && typeof cell.value === 'number') {
        cell.alignment = { horizontal: 'right', vertical: 'middle' };
        cell.numFmt = '$#,##0.00;[Red]-$#,##0.00';
      }
      if (section?.alignment) {
        cell.alignment = section.alignment;
      } else {
        cell.alignment = { vertical: 'middle' };
      }
      if (section?.font) {
        cell.font = section.font;
      }
      if (section?.fill) {
        cell.fill = section.fill;
      }
    });
    if (section?.height > 0) {
      row.height = section.height;
    }
    return row;
  }

  async function handleExportExcel() {
    const fileName = "fee-report";
    const data = getDataToExportExcel();
    const dataArabic = getDataToExportExcelArabic();
    const widths = [{width: 20}, {width: 30}, {width: 20},
      {width: 20}, {width: 20}, {width: 20}, {width: 20}, {width: 20}, {width: 20}];
    if(data?.length > 0){
      if(Storage.session.get('locale') === 'en') {
        const wb = new ExcelJS.Workbook();
        const ws = wb.addWorksheet('My Sheet');

        const headerTitle = ["Quotation No",
          "Bursa Reference Number",
          "Date",
          "Customer Name",
          "Bank Name",
          "Branch Name",
          "Bank User",
          `Fee Amount`,
          `Fee Currency`];
        const columns = headerTitle.length;

        const headerCustom = {
          border: true,
          money: false,
          height: 30,
          font: { size: 15, bold: false, color: { argb: 'FFFFFF' } },
          alignment: { horizontal: 'center', vertical: 'middle' },
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: '01579B',
            },
          },
        };
        const dataCustom = {
          border: true,
          money: false,
          height: 0,
          font: { size: 12, bold: false, color: { argb: '000000' } },
          alignment: { horizontal: 'left', vertical: 'left' },
          fill: null,
        };
        if (widths && widths.length > 0) {
          ws.columns = widths;
        }
        addRow(ws, headerTitle, headerCustom);
        data.forEach(( rowData ) => {
          addRow(ws, Object.values(rowData), dataCustom);
        })

        const buf = await wb.xlsx.writeBuffer();
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
      }
      else{
        const wb = new ExcelJS.Workbook();
        const ws = wb.addWorksheet('My Sheet');

        const headerTitle2 = ["عملة الرسوم", "مبلغ الرسوم", "مستخدم البنك", "اسم الفرع",
          "اسم البنك", "العميل", "التاريخ", "الرقم المرجعي لبورصة", "رقم عرض السعر"];
        const columns = headerTitle2.length;

        const headerCustom = {
          border: true,
          money: false,
          height: 30,
          font: { size: 15, bold: false, color: { argb: 'FFFFFF' } },
          alignment: { horizontal: 'center', vertical: 'middle' },
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: '01579B',
            },
          },
        };
        const dataCustom = {
          border: true,
          money: false,
          height: 0,
          font: { size: 12, bold: false, color: { argb: '000000' } },
          alignment: { horizontal: 'right', vertical: 'right' },
          fill: null,
        };
        if (widths && widths.length > 0) {
          ws.columns = widths;
        }
        addRow(ws, headerTitle2, headerCustom);
        dataArabic.forEach(( rowData ) => {
          addRow(ws, Object.values(rowData), dataCustom);
        })

        const buf = await wb.xlsx.writeBuffer();
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
      }
    }

  };

  const handleBankChange = event => {
    setSelectedBank(event.target.value);
    setFilter(false);

    const bankName = event.target.value;
    banks?.map(a => {
      if (bankName === String(a?.id)) {
        setSelectedBankName(a?.name);
      }
    });
  };

  const handleBranchChange = event => {
    setSelectedBranch(event.target.value);
    setFilter(false);

    const branchName = event.target.value;
    branches?.map(b => {
      if (branchName === String(b?.id)) {
        setSelectedBranchName(b?.name);
      }
    });
  };

  const handleBankUserChange = event => {
    setSelectedUser(event.target.value);
    setFilter(false);
  };

  const handleFromDateChange = (newDate: Date | null) => {
    setDateError(undefined);
    setFromDate(newDate);
    setFilter(false);
  };

  const handleToDateChange = (newDate: Date | null) => {
    setDateError(undefined);
    setToDate(newDate);
    setFilter(false);
  };

  const handleBtnClick = () => {
    setPage(0);
    if (fromDate > toDate) {
      setDateError('From date cannot be larger than to date');
    }
    setFilter(true);

    let updatedSelectedBank = selectedBank;
    let updatedSelectedBranch = selectedBranch;
    let updatedSelectedUser = selectedUser;
    if (account?.authorities?.includes(AUTHORITIES.BRANCH_MANAGER) && branch) {
      updatedSelectedBank = [{
        label: branch?.bank?.name,
        value: branch?.bank?.id
      }];

      updatedSelectedBranch = [{
        label: branch?.name,
        value: branch?.id
      }];
    }   else if (account.authorities.includes(AUTHORITIES.AREA_MANAGER) && branches) {
      selectedBranch.length===0 && branches.map(br =>{
        const selectBankData={
          label: br.bank.name,
          value: br.bank.id,
        }
        updatedSelectedBank.push(selectBankData);
        const selectBranchData={
          label: br.name,
          value: br.id,
        }
        updatedSelectedBranch.push(selectBranchData);
      });

    } else if (account.authorities.includes(AUTHORITIES.AUDITOR) && bank ) {
      updatedSelectedBank = [{
        label: bank?.name,
        value: bank?.id
      }];

    } else if (account.authorities.includes(AUTHORITIES.BANK_MANAGER)  && banks) {
      updatedSelectedBank = [{
        label: banks[0]?.name,
        value: banks[0]?.id
      }];

    } else if (account.authorities.includes(AUTHORITIES.BANK_STAFF) && branch){
      updatedSelectedBank = [{
        label: branch?.bank?.name,
        value: branch?.bank?.id
      }];

      updatedSelectedBranch = [{
        label: branch?.name,
        value: branch?.id
      }];

      updatedSelectedUser = [{
        label: account?.login,
        value: account?.id
      }];
    }

    localStorage.setItem('filterBank', JSON.stringify(updatedSelectedBank));
    localStorage.setItem('filterBranch', JSON.stringify(updatedSelectedBranch));
    localStorage.setItem('filterUser', JSON.stringify(updatedSelectedUser));
    localStorage.setItem('filterFromDate', moment(fromDate).format('yyyy/MM/DD'));
    localStorage.setItem('filterToDate', moment(toDate).format('yyyy/MM/DD'));
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgba(255,255,255,0)",
      color: theme.palette.common.white,
      fontWeight: 'bold',
      letterSpacing: 1,
      textAlign:Storage.session.get('locale') === 'en' ?'left':'right',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign:Storage.session.get('locale') === 'en' ?'left':'right',
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const customStyles = {
    dropdownIndicator: base => ({
      ...base,
      color: "red" // Custom colour
    })
  };

  function ccyFormat(num) {
    return `${num.toFixed(2)}`;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const selectedBankIdArr=[];
  const selectedBranchIdArr=[];
  const selectedUserNameArr=[];
  const selectedCommodityIdArr=[];
  selectedBank && selectedBank?.map(b=>selectedBankIdArr.push(b?.value?.toString()));
  selectedBranch && selectedBranch?.map(b=>selectedBranchIdArr.push(b?.value?.toString()));
  selectedUser && selectedUser?.map(b=>selectedUserNameArr.push(b?.label));

  useEffect(() => {
    moment.suppressDeprecationWarnings = true;
    const momentToDate=moment(toDate).add(1, 'day').format('yyyy/MM/DD');
    const obj={
      bankId: branches ? branches[0]?.bank?.id.toString():"",
      branchId: !selectedBranchIdArr.length ? "":selectedBranchIdArr,
      bankUserId:!selectedUserNameArr.length ? "":selectedUserNameArr,
      fromDate:moment(fromDate).format('yyyy/MM/DD'),
      toDate:momentToDate
    };

    if(filter) {
      if (account.authorities.includes(AUTHORITIES.BRANCH_MANAGER) && branch) {
        dispatch(getFeeReports({
          ...obj,
          bankId: branch.bank?.id.toString(),
          branchId: branch.id?.toString(),
          page:pageNo,
          size:rowsPerPage,
        }));
        dispatch(getFeeReportsPrint({
          ...obj,
          bankId: branch.bank?.id.toString(),
          branchId: branch.id?.toString(),
        }));

      } else if (account.authorities.includes(AUTHORITIES.AREA_MANAGER) && branches) {
        selectedBranchIdArr.length===0  && branches?.map(br => selectedBranchIdArr.push(br?.id.toString()));
        dispatch(getFeeReports({
          ...obj,
          branchId: selectedBranchIdArr.length ? selectedBranchIdArr : "",
          page:pageNo,
          size:rowsPerPage,
        }));
        dispatch(getFeeReportsPrint({
          ...obj,
          branchId: selectedBranchIdArr.length ? selectedBranchIdArr : "",
        }));

      } else if (account.authorities.includes(AUTHORITIES.AUDITOR) && bank) {
        dispatch(getFeeReports({
          ...obj,
          bankId: bank?.id.toString(),
          page:pageNo,
          size:rowsPerPage,
        }));
        dispatch(getFeeReportsPrint({
          ...obj,
          bankId: bank?.id.toString(),
        }));

      } else if (account.authorities.includes(AUTHORITIES.BANK_MANAGER) && banks) {
        dispatch(getFeeReports({
          ...obj,
          bankId: banks[0]?.id.toString(),
          page:pageNo,
          size:rowsPerPage,
        }));
        dispatch(getFeeReportsPrint({
          ...obj,
          bankId: banks[0]?.id.toString(),
        }));

      } else if (account.authorities.includes(AUTHORITIES.BANK_STAFF) && branch){
        dispatch(getFeeReports({
          ...obj,
          bankId: branch.bank?.id.toString(),
          branchId: branch.id?.toString(),
          bankUserId:account.login,
          page:pageNo,
          size:rowsPerPage,
        }));
        dispatch(getFeeReportsPrint({
          ...obj,
          bankId: branch.bank?.id.toString(),
          branchId: branch.id?.toString(),
          bankUserId:account.login,
        }));

      } else {
        dispatch(getFeeReports({
          ...obj,
          bankId: !selectedBankIdArr.length ? "" : selectedBankIdArr,
          page:pageNo,
          size:rowsPerPage,
        }));
        dispatch(getFeeReportsPrint({
          ...obj,
          bankId: !selectedBankIdArr.length ? "" : selectedBankIdArr,
        }));
      }
    }

  }, [account, selectedUser, selectedBranch, filter, fromDate, toDate, pageNo, rowsPerPage]);

  const dates = [];
  const xValue = [];
  function getDatesInRange(startDate, endDate) {
    while (moment(startDate).startOf('day') <= moment(endDate).startOf('day')) {
      dates.push(moment(startDate).format('yyyy/MM/DD'));
      startDate.setDate(startDate.getDate() + 1);
    }
  }

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  }
  const [checkboxState, setCheckboxState] = React.useState({
    selectAllChecked: (Storage.session.get('tableViewFeeReports')) ? Storage.session.get('tableViewFeeReports').selectAllChecked : true,
    quotationChecked: (Storage.session.get('tableViewFeeReports')) ? Storage.session.get('tableViewFeeReports').quotationChecked : true,
    poChecked: (Storage.session.get('tableViewFeeReports')) ? Storage.session.get('tableViewFeeReports').poChecked : true,
    dateChecked: (Storage.session.get('tableViewFeeReports')) ? Storage.session.get('tableViewFeeReports').dateChecked : true,
    customerChecked: (Storage.session.get('tableViewFeeReports')) ? Storage.session.get('tableViewFeeReports').customerChecked : true,
    bankChecked: (Storage.session.get('tableViewFeeReports')) ? Storage.session.get('tableViewFeeReports').bankChecked : true,
    branchChecked: (Storage.session.get('tableViewFeeReports')) ? Storage.session.get('tableViewFeeReports').branchChecked : true,
    bankUserChecked: (Storage.session.get('tableViewFeeReports')) ? Storage.session.get('tableViewFeeReports').bankUserChecked : true,
    feeAmountChecked: (Storage.session.get('tableViewFeeReports')) ? Storage.session.get('tableViewFeeReports').feeAmountChecked : true,
    feeCurrencyChecked: (Storage.session.get('tableViewFeeReports')) ? Storage.session.get('tableViewFeeReports').feeCurrencyChecked : true,
  });

  const handleCheckedAll = (event) => {

    setCheckboxState({
      selectAllChecked: event.target.checked,
      quotationChecked: true,
      poChecked: event.target.checked,
      dateChecked: event.target.checked,
      customerChecked: event.target.checked,
      bankChecked: event.target.checked,
      branchChecked: event.target.checked,
      bankUserChecked: event.target.checked,
      feeAmountChecked: event.target.checked,
      feeCurrencyChecked: event.target.checked,
    });

    window.sessionStorage.setItem('tableViewFeeReports', JSON.stringify({
      selectAllChecked: event.target.checked,
      quotationChecked: true,
      poChecked: event.target.checked,
      dateChecked: event.target.checked,
      customerChecked: event.target.checked,
      bankChecked: event.target.checked,
      branchChecked: event.target.checked,
      bankUserChecked: event.target.checked,
      feeAmountChecked: event.target.checked,
      feeCurrencyChecked: event.target.checked,
    }));

  };
  const handleChecked = (event) => {
    setCheckboxState({
      ...checkboxState,
      [event.target.name]: event.target.checked,
    });
    window.sessionStorage.setItem('tableViewFeeReports', JSON.stringify({
      ...checkboxState,
      [event.target.name]: event.target.checked,
    }));
  };
  const {
    selectAllChecked,
    quotationChecked,
    poChecked,
    dateChecked,
    customerChecked,
    bankChecked,
    branchChecked,
    bankUserChecked,
    feeAmountChecked,
    feeCurrencyChecked,
  } = checkboxState;

  const errorCheckbox = [
    quotationChecked,
    poChecked,
    dateChecked,
    customerChecked,
    bankChecked,
    branchChecked,
    bankUserChecked,
    feeAmountChecked,
    feeCurrencyChecked,
  ].filter((v) => v).length < 2;

  const handleApply = e => {
    e.preventDefault();
    setAnchorEl(null);
  };

  let responsiveFont = createTheme();
  responsiveFont = responsiveFontSizes(responsiveFont);

  const handleBankSelect = event => {
    setSelectedBank(event);
    setSelectedBranch([]);
    setSelectedUser([]);
  };

  const handleBranchSelect = event => {
    setSelectedBranch(event);
    setSelectedUser([]);
  };
  const componentRef = useRef();

  const handlePrint = () => {
    if(filter){
      const authToken = Storage.session.get('jhi-authenticationToken');
      Storage.local.set('jhi-authenticationToken', authToken);
      localStorage.setItem('print','true');

    }
  };

  useEffect(() => {
    if (filter) {
      localStorage.setItem('period', moment(fromDate).format('yyyy/MM/DD') + ' - ' + moment(toDate).format('yyyy/MM/DD'));

      if (account?.authorities?.includes(AUTHORITIES.BANK_STAFF)) {
        localStorage.setItem('bank', branch?.bank?.name);
        localStorage.setItem('branch', branch?.name);
        localStorage.setItem('userName', account?.login);

      } else if (account?.authorities?.includes(AUTHORITIES.BRANCH_MANAGER)) {
        localStorage.setItem('bank', branch?.bank?.name);
        localStorage.setItem('branch', branch?.name);
        selectedUserNameArr.length ? localStorage.setItem('userName', String(selectedUserNameArr)) : localStorage.setItem('userName', 'All');

      } else if (account?.authorities?.includes(AUTHORITIES.BANK_MANAGER) || account?.authorities?.includes(AUTHORITIES.AUDITOR)) {
        if (account?.authorities?.includes(AUTHORITIES.BANK_MANAGER)) {
          localStorage.setItem('bank', banks[0]?.name);
        } else {
          localStorage.setItem('bank', bank?.name);
        }
        selectedBranch.length ? localStorage.setItem('branch', selectedBranch?.map(({label}) => (label)).join(', ')) : localStorage.setItem('branch', 'All');
        selectedUserNameArr.length ? localStorage.setItem('userName', String(selectedUserNameArr)) : localStorage.setItem('userName', 'All');

      } else if (account?.authorities?.includes(AUTHORITIES.AREA_MANAGER)) {
        localStorage.setItem('bank', branches.map(b => b.bank.name).join(', '));
        localStorage.setItem('branch', branches.map(b => b.name).join(', '));
        selectedBranch.length ? localStorage.setItem('branch', selectedBranch?.map(({label}) => (label)).join(', ')) : localStorage.setItem('branch', 'All');
        selectedUserNameArr.length ? localStorage.setItem('userName', String(selectedUserNameArr)) : localStorage.setItem('userName', 'All');

      } else {
        selectedBank.length ? localStorage.setItem('bank', selectedBank?.map(({label}) => (label)).join(', ')) : localStorage.setItem('bank', 'All');
        selectedBranch.length ? localStorage.setItem('branch', selectedBranch?.map(({label}) => (label)).join(', ')) : localStorage.setItem('branch', 'All');
        selectedUserNameArr.length ? localStorage.setItem('userName', String(selectedUserNameArr)) : localStorage.setItem('userName', 'All');

      }

      window.localStorage.setItem('tableViewFeeReports', JSON.stringify({
        ...checkboxState,
      }));
      localStorage.setItem('print', 'false');

    } else {
      localStorage.removeItem('period');
      localStorage.removeItem('bank');
      localStorage.removeItem('branch');
      localStorage.removeItem('userName');
      localStorage.removeItem('tableViewFeeReports');
    }
  },[fromDate, toDate, selectedBank, selectedBranch, selectedUserNameArr, checkboxState, filter]);

  return (
    <Container>

      <div className="mt-4"  ref={componentRef}>
        <Grid container spacing={2} sx={{ alignItems: 'center' }}>
          <Grid item sm={6} md={6} sx={{ mb: 0, justifyContent: 'flex-start' }}>
            <ThemeProvider theme={responsiveFont}>
              <Typography variant="h4" component="div">
                <Translate contentKey="global.menu.report.feeReportTitle" />
              </Typography>
            </ThemeProvider>
          </Grid>
        </Grid>

        <div className="filter-select">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Row className="select-row">
                <Col>
                  <Label>
                    <Translate contentKey="home.bank" />
                  </Label>
                </Col>
                <Col>
                  {account?.authorities?.includes(AUTHORITIES.BRANCH_MANAGER) ||
                  account?.authorities?.includes(AUTHORITIES.AUDITOR) ||
                  account?.authorities?.includes(AUTHORITIES.BANK_MANAGER) ||
                  account?.authorities?.includes(AUTHORITIES.AREA_MANAGER) ||
                  account?.authorities?.includes(AUTHORITIES.BANK_STAFF) ? (
                    <Input type="text" placeholder={branch?.bank?.name || bank?.name || banks[0]?.name || branches[0]?.bank?.name} disabled />
                  ) : (
                    <MultiSelect
                      options={banks?.map(item => ({
                        label: item?.name,
                        value: item?.id,
                      }))}
                      value={selectedBank}
                      onChange={handleBankSelect}
                      labelledBy="Select"
                      className="dropdown-style"
                      overrideStrings={{
                        "allItemsAreSelected": `${translate('global.menu.report.multiSelect.allItemsAreSelected')}`,
                        "noOptions": `${translate('global.menu.report.multiSelect.noOptions')}`,
                        "search": `${translate('home.search')}`,
                        "selectAll": `${translate('global.menu.report.multiSelect.selectAll')}`,
                        "selectSomeItems": `${translate('global.menu.report.multiSelect.select')}`,
                      }}
                    />
                  )}
                </Col>
              </Row>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Row className="select-row">
                <Col>
                  <Label>
                    <Translate contentKey="home.branch" />
                  </Label>
                </Col>
                <Col>
                  {account?.authorities?.includes(AUTHORITIES.BRANCH_MANAGER) || account?.authorities?.includes(AUTHORITIES.BANK_STAFF) ? (
                    <Input type="text" placeholder={branch?.name} disabled />
                  ) : (
                    <MultiSelect
                      options={branches?.map(item => ({
                        label: item?.name,
                        value: item?.id,
                      }))}
                      value={selectedBranch}
                      onChange={handleBranchSelect}
                      labelledBy="Select"
                      className="dropdown-style"
                      overrideStrings={{
                        "allItemsAreSelected": `${translate('global.menu.report.multiSelect.allItemsAreSelected')}`,
                        "noOptions": `${translate('global.menu.report.multiSelect.noOptions')}`,
                        "search": `${translate('home.search')}`,
                        "selectAll": `${translate('global.menu.report.multiSelect.selectAll')}`,
                        "selectSomeItems": `${translate('global.menu.report.multiSelect.select')}`,
                      }}
                    />
                  )}
                </Col>
              </Row>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Row className="select-row">
                <Col>
                  <Label>
                    <Translate contentKey= "global.menu.report.bankUser" />
                  </Label>
                </Col>
                <Col>
                  {!account?.authorities?.includes(AUTHORITIES.BANK_STAFF) ? (
                    <MultiSelect
                      options={userList?.map(item => ({
                        value: item?.id,
                        label: item?.login,
                      }))}
                      value={selectedUser}
                      onChange={setSelectedUser}
                      labelledBy="Select"
                      className="dropdown-style"
                      overrideStrings={{
                        "allItemsAreSelected": `${translate('global.menu.report.multiSelect.allItemsAreSelected')}`,
                        "noOptions": `${translate('global.menu.report.multiSelect.noOptions')}`,
                        "search": `${translate('home.search')}`,
                        "selectAll": `${translate('global.menu.report.multiSelect.selectAll')}`,
                        "selectSomeItems": `${translate('global.menu.report.multiSelect.select')}`,
                      }}
                    />
                  ) : (
                    <Input type="text" placeholder={account?.login} disabled />
                  )}
                </Col>
              </Row>
            </Grid>
          </Grid>
        </div>

        <div className="mt-4">
          <Grid container spacing={2} sx={{ alignItems: 'flex-end' }}>
            <Grid item xs={12} sm={6} md={3} sx={{ justifyContent: 'flex-start' }}>
              <Row className="select-row">
                <Col>
                  <Label>
                    <Translate contentKey= "global.menu.report.fromDate" />
                  </Label>
                </Col>
                <Col>
                  <LocalizationProvider locale={Storage.session.get('locale') === 'en' ? enUS : ar} dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      toolbarTitle={translate('entity.action.selectDate')}
                      okText={translate('entity.action.ok')}
                      cancelText={translate('entity.action.cancel')}
                      disableFuture
                      inputFormat="yyyy/MM/dd"
                      mask="____/__/__"
                      views={['year', 'month', 'day']}
                      value={fromDate}
                      onChange={handleFromDateChange}
                      open={openCalendarFrom}
                      onOpen={() => setOpenCalendarFrom(true)}
                      onClose={() => setOpenCalendarFrom(false)}
                      renderInput={params => (
                        <StyledTextField
                          size="small"
                          fullWidth
                          {...params}
                          onClick={(e) => setOpenCalendarFrom(true)}
                          InputProps={{
                            style: { borderBottom: 'none' },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end" onClick={(e) => setOpenCalendarFrom(true)}>
                                  <EventIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Col>
              </Row>
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{ justifyContent: 'flex-start' }}>
              <Row className="select-row">
                <Col>
                  <Label>
                    <Translate contentKey= "global.menu.report.toDate" />
                  </Label>
                </Col>
                <Col>
                  <LocalizationProvider locale={Storage.session.get('locale') === 'en' ? enUS : ar} dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      toolbarTitle={translate('entity.action.selectDate')}
                      okText={translate('entity.action.ok')}
                      cancelText={translate('entity.action.cancel')}
                      disableFuture
                      inputFormat="yyyy/MM/dd"
                      mask="____/__/__"
                      views={['year', 'month', 'day']}
                      value={toDate}
                      onChange={handleToDateChange}
                      open={openCalendarTo}
                      onOpen={() => setOpenCalendarTo(true)}
                      onClose={() => setOpenCalendarTo(false)}
                      renderInput={params => (
                        <StyledTextField
                          size="small"
                          fullWidth
                          {...params}
                          onClick={(e) => setOpenCalendarTo(true)}
                          InputProps={{
                            style: { borderBottom: 'none' },
                            endAdornment: (
                              <InputAdornment position="end" onClick={(e) => setOpenCalendarTo(true)}>
                                <IconButton edge="end">
                                  <EventIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Col>
              </Row>
            </Grid>
            <Grid item sm={12} md={3}>
              <p>{dateError}</p>
            </Grid>
            <Grid item sm={12} md={3} sx={{ display: 'inline-flex', justifyContent: 'flex-end' }}>
              <Button color="primary" onClick={handleBtnClick}>
                <Translate contentKey= "global.menu.report.filterBtn" />
              </Button>
            </Grid>
          </Grid>
        </div>

        <Grid container spacing={1} sx={{ mt: '40px', alignItems: 'center' }}>
          <Grid item xs={12} sm={12} md={12} lg={4} sx={{ justifyContent: 'flex-start', flexDirection: 'row', display: 'inline-flex' }}>
            <div>
              {(myAccount.authorities.includes(AUTHORITIES.ADMIN) || myAccount.authorities.includes(AUTHORITIES.ALSHMOKH_ADMIN)) && (
                <div>
                  <Button
                    color="primary"
                    outline
                    onClick={handleClick}
                    >
                    <SettingsIcon sx={{ fontSize: '20px', marginTop: '-4px' }} /> <Translate contentKey="global.menu.report.tableViewBtn" />
                  </Button>
                  <Menu
                    id="tableViewMenu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <FormControl
                      required
                      error={errorCheckbox}
                    >
                      <FormGroup>
                        <MenuItem>
                          <FormControlLabel
                            label={translate('home.table.selectAll')}
                            control={<Checkbox
                              checked={selectAllChecked}
                              onChange={handleCheckedAll}
                              name="selectAllChecked"
                            />}
                          />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            label={translate('home.table.orderNo')}
                            control={<Checkbox
                              checked={quotationChecked}
                              onChange={handleChecked}
                              name="quotationChecked"
                              disabled
                              />}
                            />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            label={translate('home.table.poNo')}
                            control={<Checkbox
                              checked={poChecked}
                              onChange={handleChecked}
                              name="poChecked"
                              />}
                            />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            label={translate('home.table.date')}
                            control={<Checkbox
                              checked={dateChecked}
                              onChange={handleChecked}
                              name="dateChecked"
                              />}
                            />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            label={translate('home.table.customerName')}
                            control={<Checkbox
                              checked={customerChecked}
                              onChange={handleChecked}
                              name="customerChecked"
                              />}
                            />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            label={translate('home.table.bankName')}
                            control={<Checkbox
                              checked={bankChecked}
                              onChange={handleChecked}
                              name="bankChecked"
                              />}
                            />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            label={translate('home.table.branchName')}
                            control={<Checkbox
                              checked={branchChecked}
                              onChange={handleChecked}
                              name="branchChecked"
                              />}
                            />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            label={translate('home.table.bankUserName')}
                            control={<Checkbox
                              checked={bankUserChecked}
                              onChange={handleChecked}
                              name="bankUserChecked"
                            />}
                          />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            label={translate('home.table.feeAmount')}
                            control={<Checkbox
                              checked={feeAmountChecked}
                              onChange={handleChecked}
                              name="feeAmountChecked"
                            />}
                          />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            label={translate('home.table.feeCurrency')}
                            control={<Checkbox
                              checked={feeCurrencyChecked}
                              onChange={handleChecked}
                              name="feeCurrencyChecked"
                            />}
                          />
                        </MenuItem>
                      </FormGroup>
                      <FormHelperText>
                        {translate('global.menu.report.errCheckboxMsg')}
                      </FormHelperText>
                    </FormControl>
                  </Menu>
                </div>
              )}
            </div>
            &nbsp;
            <Dropdown isOpen={dropdownOpen} toggle={toggle} variant="contained" onClick={handleButtonClick}>
              <DropdownToggle caret color="primary"><FontAwesomeIcon icon="print" /> <Translate contentKey="global.menu.report.exportBtn"/></DropdownToggle>
            <DropdownMenu>
              <MenuItem data-export-type="excel">
                <div>
                  <DropdownItem onClick={handleExportExcel}>
                    <Translate contentKey="global.menu.report.excelBtn" />
                  </DropdownItem >
                </div>
              </MenuItem>
              <MenuItem data-export-type="pdf">
                <div>
                  <Link
                    to={{
                      pathname: "/fee-report-print",
                    }}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <DropdownItem onClick={handlePrint}>
                      <Translate contentKey="global.menu.report.printBtn" />
                    </DropdownItem >
                  </Link>
                </div>
              </MenuItem>
            </DropdownMenu>
            </Dropdown>
          </Grid>
        </Grid>
        <Paper className="paper-table mt-3 mb-4">
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow className={"enhanced-table-reports"}>
                  <StyledTableCell hidden={!quotationChecked}>{translate('home.table.orderNo')}</StyledTableCell>
                  <StyledTableCell hidden={!poChecked}>{translate('home.table.poNo')}</StyledTableCell>
                  <StyledTableCell hidden={!dateChecked}>{translate('home.table.date')}</StyledTableCell>
                  <StyledTableCell hidden={!customerChecked}>{translate('home.table.customerName')}</StyledTableCell>
                  <StyledTableCell hidden={!bankChecked}>{translate('home.table.bankName')}</StyledTableCell>
                  <StyledTableCell hidden={!branchChecked}>{translate('home.table.branchName')}</StyledTableCell>
                  <StyledTableCell hidden={!bankUserChecked}>{translate('home.table.bankUserName')}</StyledTableCell>
                  <StyledTableCell hidden={!feeAmountChecked}>{translate('home.table.feeAmount')}</StyledTableCell>
                  <StyledTableCell hidden={!feeCurrencyChecked}>{translate('home.table.feeCurrency')}</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {filter && quotations?.length>0 && quotations?.map(q => (
                  <StyledTableRow key={q?.id}>
                    <StyledTableCell hidden={!quotationChecked}>{q?.id}</StyledTableCell>
                    <StyledTableCell hidden={!poChecked}>{q?.bursaAPIBidCertId !== null ? q?.bursaAPIBidCert?.ecertNo : '-'}</StyledTableCell>
                    <StyledTableCell hidden={!dateChecked}>{moment(q?.createdDate).format('yyyy/MM/DD')}</StyledTableCell>
                    <StyledTableCell hidden={!customerChecked}>{q?.customer.name}</StyledTableCell>
                    <StyledTableCell hidden={!bankChecked}>{q?.bank.name}</StyledTableCell>
                    <StyledTableCell hidden={!branchChecked}>{q?.branch.name}</StyledTableCell>
                    <StyledTableCell hidden={!bankUserChecked}>{getStaffFullName(q?.createdUser)}</StyledTableCell>
                    <StyledTableCell hidden={!feeAmountChecked}>{((q?.fee || 0) + (q?.commission || 0)).toLocaleString()}</StyledTableCell>
                    <StyledTableCell hidden={!feeCurrencyChecked}>{q?.feeCurrencyIndex}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className={Storage.session.get('locale') === 'en' ? "table-pagination" : "table-pagination-arabic"}
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={pageNo}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={translate('home.table.rowsPerPage')}
          />
        </Paper>
      </div>

    </Container>
  );
};

export default FeeReports;
