export interface IBursaAPIStbCert {
  id?: number;
  endpointURL?: string;
  ecertNo?: string;
  memberShortName?: string;
  sucessYN?: string | null;
  msg?: string | null;
  seller?: string;
  buyer?: string;
  totalValue?: string;
  currency?: string;
  price?: number;
  priceMYR?: number;
  sellingTimeDate?: string;
  valueDate?: string;
  pName?: string;
  pVolume?: string;
  supplier?: string;
  volume?: string;
  uploadedCert?: string | null;
  createdDate?: string;
}

export const defaultValue: Readonly<IBursaAPIStbCert> = {};
