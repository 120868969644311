export interface IBursaAPIBidCert {
  id?: number;
  endpointURL?: string;
  ecertNo?: string;
  memberShortName?: string;
  successYN?: string | null;
  msg?: string | null;
  buyer?: string;
  owner?: string;
  bidNo?: string;
  totalValue?: string;
  currency?: string;
  price?: number;
  priceMYR?: number;
  purchaseTimeDate?: string;
  valueDate?: string;
  pName?: string;
  pVolume?: string;
  supplier?: string;
  volume?: string;
  uploadedCert?: string | null;
  createdDate?: string;
}

export const defaultValue: Readonly<IBursaAPIBidCert> = {};
