import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntitesByCreatedUser, getEntities } from './quotation.reducer';
import { IQuotation } from 'app/shared/model/quotation.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const Quotation = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();
  const userAuthorities = useAppSelector(state => state.authentication.account.authorities);
  const account = useAppSelector(state => state.authentication.account);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE, 'id'), props.location.search)
  );

  const quotationList = useAppSelector(state => state.quotation.entities);
  const loading = useAppSelector(state => state.quotation.loading);
  const totalItems = useAppSelector(state => state.quotation.totalItems);

  useEffect(() => {
    if (userAuthorities.includes(AUTHORITIES.ADMIN) || userAuthorities.includes(AUTHORITIES.AUDITOR)) {
      dispatch(getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }));
    }
    else dispatch(getEntitesByCreatedUser(account?.login));
  }, []);

  // useEffect(() => {
  //   sortEntities();
  // }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  // const handleSyncList = () => {
  //   sortEntities();
  // };

  const { match } = props;

  return (
    <div>
      <h2 id="quotation-heading" data-cy="QuotationHeading">
        <Translate contentKey="comexApp.quotation.home.title">Quotations</Translate>
        <div className="d-flex justify-content-end">
          {userAuthorities.filter((item: string) => [AUTHORITIES.BANK_MANAGER, AUTHORITIES.BRANCH_MANAGER].includes(item)).length !== 0 && <Link to={`${match.url}/new`} className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="comexApp.quotation.home.createLabel">Create new Quotation</Translate>
          </Link>}
        </div>
      </h2>
      <div className="table-responsive">
        {quotationList && quotationList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="comexApp.quotation.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('amount')}>
                  <Translate contentKey="comexApp.quotation.amount">Amount</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('description')}>
                  <Translate contentKey="comexApp.quotation.description">Description</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('commodityUnitPrice')}>
                  <Translate contentKey="comexApp.quotation.commodityUnitPrice">Commodity Unit Price</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('commodityQuantity')}>
                  <Translate contentKey="comexApp.quotation.commodityQuantity">Commodity Quantity</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('currencyRate')}>
                  <Translate contentKey="comexApp.quotation.currencyRate">Currency Rate</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('buyCurrency')}>
                  <Translate contentKey="comexApp.quotation.buyCurrency">Buy Currency</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('bankManagerApproval')}>
                  <Translate contentKey="comexApp.quotation.bankManagerApproval">Bank Manager Approval</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('bankCustomerApproval')}>
                  <Translate contentKey="comexApp.quotation.bankCustomerApproval">Bank Customer Approval</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                {userAuthorities.includes(AUTHORITIES.ADMIN) && <th className="hand" onClick={sort('smsLanguage')}>
                  <Translate contentKey="comexApp.quotation.smsLanguage">Sms Language</Translate> <FontAwesomeIcon icon="sort" />
                </th>}
                {userAuthorities.includes(AUTHORITIES.ADMIN) && <th className="hand" onClick={sort('smsSent')}>
                  <Translate contentKey="comexApp.quotation.smsSent">Sms Sent</Translate> <FontAwesomeIcon icon="sort" />
                </th>}
                {userAuthorities.includes(AUTHORITIES.ADMIN) && <th className="hand" onClick={sort('bursaAPIBidCertId')}>
                  <Translate contentKey="comexApp.quotation.bursaAPIBidCertId">Bursa API Bid Cert Id</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>}
                {userAuthorities.includes(AUTHORITIES.ADMIN) && <th className="hand" onClick={sort('bursaAPIOtcCertId')}>
                  <Translate contentKey="comexApp.quotation.bursaAPIOtcCertId">Bursa API Otc Cert Id</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>}
                {userAuthorities.includes(AUTHORITIES.ADMIN) && <th className="hand" onClick={sort('bursaAPIStbCertId')}>
                  <Translate contentKey="comexApp.quotation.bursaAPIStbCertId">Bursa API Stb Cert Id</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>}
                {userAuthorities.includes(AUTHORITIES.ADMIN) && <th className="hand" onClick={sort('stbOption')}>
                  <Translate contentKey="comexApp.quotation.stbOption">Stb Option</Translate> <FontAwesomeIcon icon="sort" />
                </th>}
                {userAuthorities.includes(AUTHORITIES.ADMIN) && <th className="hand" onClick={sort('comexAdminDecision')}>
                  <Translate contentKey="comexApp.quotation.comexAdminDecision">Comex Admin Decision</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>}
                <th>
                  <Translate contentKey="comexApp.quotation.customer">Customer</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="comexApp.quotation.commodity">Commodity</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="comexApp.quotation.branch">Branch</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="comexApp.quotation.purchaseOrder">Purchase Order</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="comexApp.quotation.currencyIndex">Currency Index</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {quotationList.map((quotation, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`${match.url}/${quotation.id}`} color="link" size="sm">
                      {quotation.id}
                    </Button>
                  </td>
                  <td>{quotation.amount?.toLocaleString()}</td>
                  <td>{quotation.description}</td>
                  <td>{quotation.commodityUnitPrice?.toLocaleString()}</td>
                  <td>{quotation.commodityQuantity?.toLocaleString()}</td>
                  <td>{quotation.currencyRate?.toLocaleString()}</td>
                  <td>{quotation.buyCurrency}</td>
                  <td>{quotation.bankManagerApproval ? 'true' : 'false'}</td>
                  <td>{quotation.bankCustomerApproval ? 'true' : 'false'}</td>
                  {userAuthorities.includes(AUTHORITIES.ADMIN) && <td>{quotation.smsLanguage}</td>}
                  {userAuthorities.includes(AUTHORITIES.ADMIN) && <td>{quotation.smsSent ? 'true' : 'false'}</td>}
                  {userAuthorities.includes(AUTHORITIES.ADMIN) && <td>{quotation.bursaAPIBidCertId}</td>}
                  {userAuthorities.includes(AUTHORITIES.ADMIN) && <td>{quotation.bursaAPIOtcCertId}</td>}
                  {userAuthorities.includes(AUTHORITIES.ADMIN) && <td>{quotation.bursaAPIStbCertId}</td>}
                  {userAuthorities.includes(AUTHORITIES.ADMIN) && <td>{quotation.stbOption}</td>}
                  {userAuthorities.includes(AUTHORITIES.ADMIN) && <td>{quotation.comexAdminDecision}</td>}
                  <td>{quotation.customer ? <Link to={`customer/${quotation.customer.id}`}>{quotation.customer.name}</Link> : ''}</td>
                  <td>{quotation.commodity ? <Link to={`commodity/${quotation.commodity.id}`}>{quotation.commodity.name}</Link> : ''}</td>
                  <td>{quotation.branch ? <Link to={`branch/${quotation.branch.id}`}>{quotation.branch.name}</Link> : ''}</td>
                  <td>
                    {quotation.purchaseOrder ? (
                      <Link to={`purchase-order/${quotation.purchaseOrder.id}`}>{quotation.purchaseOrder.poNumber}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {quotation.currencyIndex ? (
                      <Link to={`currency-index/${quotation.currencyIndex.id}`}>{quotation.currencyIndex.name}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${quotation.id}`} size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      {userAuthorities.filter((item: string) => [AUTHORITIES.BANK_MANAGER, AUTHORITIES.BRANCH_MANAGER, AUTHORITIES.BANK_STAFF].includes(item)).length !== 0 && <Button
                        tag={Link}
                        to={`${match.url}/${quotation.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>}
                      {userAuthorities.filter((item: string) => [AUTHORITIES.BANK_MANAGER, AUTHORITIES.BRANCH_MANAGER, AUTHORITIES.BANK_STAFF].includes(item)).length !== 0 && <Button
                        tag={Link}
                        to={`${match.url}/${quotation.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="comexApp.quotation.home.notFound">No Quotations found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={quotationList && quotationList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Quotation;
