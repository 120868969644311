import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './comex-settings.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const ComexSettingsDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const userAuthorities = useAppSelector(state => state.authentication.account.authorities);

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const comexSettingsEntity = useAppSelector(state => state.comexSettings.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="comexSettingsDetailsHeading">
          <Translate contentKey="comexApp.comexSettings.detail.title">ComexSettings</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{comexSettingsEntity.id}</dd>
          <dt>
            <span id="operationStartTime">
              <Translate contentKey="comexApp.comexSettings.operationStartTime">Operation Start Time</Translate>
            </span>
          </dt>
          <dd>
            {comexSettingsEntity.operationStartTime ? (
              <TextFormat value={comexSettingsEntity.operationStartTime} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="operationEndTime">
              <Translate contentKey="comexApp.comexSettings.operationEndTime">Operation End Time</Translate>
            </span>
          </dt>
          <dd>
            {comexSettingsEntity.operationEndTime ? (
              <TextFormat value={comexSettingsEntity.operationEndTime} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="currencyRateRefresh">
              <Translate contentKey="comexApp.comexSettings.currencyRateRefresh">Currency Rate Refresh</Translate>
            </span>
          </dt>
          <dd>
            {comexSettingsEntity.currencyRateRefresh ? (
              <TextFormat value={comexSettingsEntity.currencyRateRefresh} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="serverStatus">
              <Translate contentKey="comexApp.comexSettings.serverStatus">Server Status</Translate>
            </span>
          </dt>
          <dd>{comexSettingsEntity.serverStatus ? 'true' : 'false'}</dd>
          <dt>
            <span id="feeNormalValue">
              <Translate contentKey="comexApp.comexSettings.feeNormalValue">Fee Normal Value</Translate>
            </span>
          </dt>
          <dd>{comexSettingsEntity.feeNormalValue}</dd>
          <dt>
            <span id="feeMediumValue">
              <Translate contentKey="comexApp.comexSettings.feeMediumValue">Fee Medium Value</Translate>
            </span>
          </dt>
          <dd>{comexSettingsEntity.feeMediumValue}</dd>
          <dt>
            <span id="notifyCancelUserID">
              <Translate contentKey="comexApp.comexSettings.notifyCancelUserID">Notify Cancel User ID</Translate>
            </span>
          </dt>
          <dd>{comexSettingsEntity.notifyCancelUserID}</dd>
        </dl>
        <Button tag={Link} to="/comex-settings" replace data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        {userAuthorities.includes(AUTHORITIES.ADMIN) && <Button tag={Link} to={`/comex-settings/${comexSettingsEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>}
      </Col>
    </Row>
  );
};

export default ComexSettingsDetail;
