import axios from 'axios';
import {createAsyncThunk, isFulfilled, isPending} from "@reduxjs/toolkit";
import { IIban } from  'app/shared/model/iban.model';
import {createEntitySlice, EntityState, serializeAxiosError} from "app/shared/reducers/reducer.utils";
import {defaultValue} from "app/shared/model/iban.model";
import {cleanEntity} from "app/shared/util/entity-utils";

const initialState: EntityState<IIban> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
}

const apiUrl = 'api/ibans';

export const getEntityByQuotationId = createAsyncThunk(
  'iban/fetch_entity',
  async (quotationId: string | number) => {
    const requestUrl = `${apiUrl}/${quotationId}`;
    return axios.get<IIban>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'iban/create_entity',
  async (entity: IIban) => {
    const result = await axios.post<IIban>(apiUrl, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

const IbanSlice = createEntitySlice({
  name: 'iban',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntityByQuotationId.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(createEntity.fulfilled, (state,action) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isFulfilled(getEntityByQuotationId), (state, action) => {
          state.loading = false;
          state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(createEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntityByQuotationId), state => {
      state.errorMessage = null;
      state.updateSuccess = false;
      state.loading = true;
      })
      .addMatcher(isPending(createEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = IbanSlice.actions;
export default IbanSlice.reducer;
