import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

const ErrorDialog = ({ history }) => {
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const showError = localStorage.getItem('showErrorDialog');
    const errorMsg = localStorage.getItem('errorMessage');

    if (showError === 'true') {
      setErrorMessage(errorMsg);
      setOpenErrorDialog(true);
    }
  }, []);

  const handleCloseErrorDialog = () => {
    setOpenErrorDialog(false);
    localStorage.setItem('showErrorDialog', 'false');
    localStorage.removeItem('errorMessage');
    setTimeout(() => {
      history.push(`/home`);
    }, 3000);
  };

  return (
    <Dialog open={openErrorDialog} onClose={handleCloseErrorDialog}>
      <DialogTitle style={{ fontWeight: 'bold' }}>Bursa Error</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ color: "black" }} className='mb-3'>
          <strong>Error:</strong> {errorMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="mx-3">
        <Button color="primary" onClick={handleCloseErrorDialog} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
