import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import AuthToken from './auth-token';
import AuthTokenDetail from './auth-token-detail';
import AuthTokenUpdate from './auth-token-update';
import AuthTokenDeleteDialog from './auth-token-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={AuthTokenUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={AuthTokenUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={AuthTokenDetail} />
      <ErrorBoundaryRoute path={match.url} component={AuthToken} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={AuthTokenDeleteDialog} />
  </>
);

export default Routes;
