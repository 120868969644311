import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import {INotificationRecord, defaultValue, ISendEmail, ISendPoCancelEmail, ISendEmailCustomer} from 'app/shared/model/notification-record.model';
import {IQuotation} from "app/shared/model/quotation.model";

const initialState: EntityState<INotificationRecord> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  currentMonthEntities:[],
};

const apiUrl = 'api/notification-records';

// Actions

export const getEntities = createAsyncThunk('notificationRecord/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<INotificationRecord[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'notificationRecord/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<INotificationRecord>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntitiesByUserId = createAsyncThunk('notificationRecord/fetch_entity_list_by_id', async ({userId,fromDate,toDate }: IQueryParams) => {
  const requestUrl = `${apiUrl}/users?userId=${userId}&fromDate=${fromDate}&toDate=${toDate}` ;
  return axios.get<INotificationRecord[]>(requestUrl);
});

export const getCurrentEntitiesByUserId = createAsyncThunk('notificationRecord/fetch_current_entity_list_by_id', async ({userId,fromDate,toDate }: IQueryParams) => {
  const requestUrl = `${apiUrl}/users?userId=${userId}&fromDate=${fromDate}&toDate=${toDate}` ;
  return axios.get<INotificationRecord[]>(requestUrl);
});

export const getBursaTimeOutNotiByUserId = createAsyncThunk('notificationRecord/fetch_bursa_timeout_by_id', async (userId : string | number) => {
  const requestUrl = `${apiUrl}/getBursaTimeOutNotiByUserId/${userId}`;
  return axios.get<INotificationRecord>(requestUrl);
});


export const createEntity = createAsyncThunk(
  'notificationRecord/create_entity',
  async (entity: INotificationRecord, thunkAPI) => {
    const result = await axios.post<INotificationRecord>(apiUrl, cleanEntity(entity));
//     thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const sendEmail = createAsyncThunk(
  'notificationRecord/sendEmail',
  async (entity: ISendEmail, thunkAPI) => {
    const sendEmailUrl='api/notification/sendMail';
    const result = await axios.post<ISendEmail>(sendEmailUrl, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const sendEmailSoldCustomer = createAsyncThunk(
  'notificationRecord/sendMailCustomer',
  async (entity: ISendEmailCustomer, thunkAPI) => {
    const sendEmailUrl='api/notification/sendMailSold';
    const result = await axios.post<ISendEmailCustomer>(sendEmailUrl, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const sendEmailTransferredCustomer = createAsyncThunk(
  'notificationRecord/sendMailCustomer',
  async (entity: ISendEmailCustomer, thunkAPI) => {
    const sendEmailUrl='api/notification/sendMailTransferred';
    const result = await axios.post<ISendEmailCustomer>(sendEmailUrl, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const sendEmailKeepRequestedCustomer = createAsyncThunk(
  'notificationRecord/sendMailCustomer',
  async (entity: ISendEmailCustomer, thunkAPI) => {
    const sendEmailUrl='api/notification/sendMailKeepRequested';
    const result = await axios.post<ISendEmailCustomer>(sendEmailUrl, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const sendPoCancelEmail = createAsyncThunk(
  'notificationRecord/sendPoCancelEmail',
  async (entity: ISendPoCancelEmail, thunkAPI) => {
    const sendPoCancelEmailUrl='api/notification/sendPoCancelMail';
    const result = await axios.post<ISendPoCancelEmail>(sendPoCancelEmailUrl, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const sendEmailQuotationDetail = createAsyncThunk(
  'notificationRecord/sendEmailQuotation',
  async (entity: IQuotation, thunkAPI) => {
    const sendEmailQuotationUrl='api/notification/sendEmailQuotation';
    const result = await axios.post<IQuotation>(sendEmailQuotationUrl, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const dismissedAllNotification = createAsyncThunk(
  'notificationRecord/dismissedAllNotification',
  async (userId: string | number, thunkAPI) => {
    const dismissedNotificationUrl = `api/notification/dismissedAll/${userId}`;
    const result = await axios.patch<INotificationRecord>(dismissedNotificationUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'notificationRecord/update_entity',
  async (entity: INotificationRecord, thunkAPI) => {
    const result = await axios.put<INotificationRecord>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
//     thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'notificationRecord/partial_update_entity',
  async (entity: INotificationRecord, thunkAPI) => {
    const result = await axios.patch<INotificationRecord>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'notificationRecord/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<INotificationRecord>(requestUrl);
//     thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const NotificationRecordSlice = createEntitySlice({
  name: 'notificationRecord',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getBursaTimeOutNotiByUserId.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getEntitiesByUserId), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
        };
      })
      .addMatcher(isFulfilled(getCurrentEntitiesByUserId), (state, action) => {
        return {
          ...state,
          loading: false,
          currentMonthEntities: action.payload.data,
        };
      })

      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity, dismissedAllNotification), (state, action) => {
        state.updating = false;
        state.loading = true;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(sendEmail), (state, action) => {
        state.updating = false;
        state.loading = true;
        state.updateSuccess = true;
      })
      .addMatcher(isFulfilled(sendEmailSoldCustomer), (state, action) => {
        state.updating = false;
        state.loading = true;
        state.updateSuccess = true;
      })
      .addMatcher(isFulfilled(sendPoCancelEmail), (state, action) => {
        state.updating = false;
        state.loading = true;
        state.updateSuccess = true;
      })
      .addMatcher(isPending(getEntities,getBursaTimeOutNotiByUserId, getEntity, getEntitiesByUserId,getCurrentEntitiesByUserId), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = NotificationRecordSlice.actions;

// Reducer
export default NotificationRecordSlice.reducer;
