import './login.css';
import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {Alert, Col, DropdownItem, Row} from "reactstrap";
import {Box, CardMedia, createTheme, IconButton, TextField, Theme, Typography} from "@mui/material";
import {createStyles, makeStyles } from "@material-ui/core/styles";
import TranslateIcon from '@mui/icons-material/Translate';
import {Storage, ValidatedForm, Translate, translate} from "react-jhipster";
import Paper from "@mui/material/Paper";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import InputAdornment from "@mui/material/InputAdornment";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {Button} from "@material-ui/core";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import {Link, useHistory} from "react-router-dom";
import {login} from "app/shared/reducers/authentication";
import {useAppDispatch, useAppSelector} from "app/config/store";
import {isRTL, languages, locales} from "app/config/translation";
import {setLocale} from "app/shared/reducers/locale";
import {AUTHORITIES} from "app/config/constants";
import {
  updateUserLanguage,
  updateUserLanguageForAllRoles
} from "app/modules/administration/user-management/user-management.reducer";
import Card from "@mui/material/Card";
import {NavDropdown} from "app/shared/layout/menus/menu-components";
import WebFont from 'webfontloader';
import {responsiveFontSizes} from "@mui/material/styles";
import {ThemeProvider} from "@mui/styles";

const Theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 830,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const useStyles = makeStyles((theme:Theme) =>
  createStyles({
    formRoot:{
      paddingLeft:45,
      paddingRight:45,
      marginBottom:30,
      [theme.breakpoints.down("xs")]: {
        paddingLeft:15,
        paddingRight:15,
      },
    },
    headerText:{
      margin: '30px 8px 5px  50px',
      [theme.breakpoints.down("xs")]: {
        margin: '50px 8px 5px 10px',
      },
      [theme.breakpoints.down("sm")]: {
        margin: '50px 8px 5px  30px',
      },
    },
    textFieldLabel: {
      "& .MuiFormLabel-root": {
        marginLeft: '32px',
        marginTop: '10px',
      }
    },
    textFieldLabelArabic: {
      "& .MuiFormLabel-root": {
        marginTop: '10px',
        position: 'relative',
        right: '42px',
        transform: 'translateY(20px)',
        // [theme.breakpoints.up("sm")]: {
        //   right: '42px',
        // },
        // [theme.breakpoints.down("xs")]: {
        //   right: '42px',
        // },
        // [theme.breakpoints.up("xl")]: {
        //   right: '42px',
        // },
      }
    },

  })
);

const Login = () => {

  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const account = useAppSelector(state => state.authentication.account);
  const user = useAppSelector(state => state.userManagement.user);
  const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset" };
  const [values, setValues] = React.useState({
    userName:'',
    password: '',
    showPassword: false,
    rememberMe:false,
  });
  const [clickSignIn, setClickSignIn] = useState(false);
  const [isLoginError, setIsLoginError] = useState(false);
  const history = useHistory();

  const handleChange = (prop) => (event) => {
    if(prop==='rememberMe')
    {
      setValues({...values,[prop]:!values.rememberMe})
    }else {
      setValues({...values, [prop]: event.target.value});
    }
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    isAuthenticated && history.push('/home');
  }, [isAuthenticated]);

  const handleLogin = () => {
    dispatch(login(values.userName,values.password, values.rememberMe));
    setClickSignIn(true);
    Storage.session.set('locale', account?.langKey || Storage.session.get('locale'));

  };

  const handleLocaleChange = event => {
    const langKey = event.target.value;
    document.querySelector('html').setAttribute('dir', isRTL(langKey) ? 'rtl' : 'ltr');
    Storage.session.set('locale', langKey);
    dispatch(setLocale(langKey));
    try{
      const updatedUser = {
        ...user,
        id : account.id,
        langKey:event.target.value,
      };
      if (account?.authorities.includes(AUTHORITIES.ADMIN))
        dispatch(updateUserLanguage(updatedUser));
      else
        dispatch(updateUserLanguageForAllRoles(updatedUser));
    }
    catch (TypeError){
      console.warn("Account not defined yet");
    }
  };

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Inria Sans']
      }
    });
  }, []);

  useEffect(() => {
    if ((values.userName !== "") && (values.password!=="") && clickSignIn && !isAuthenticated)
      setIsLoginError(true);
  }, [isAuthenticated]);

  const classes = useStyles();
  let responsiveFont = createTheme();
  responsiveFont = responsiveFontSizes(responsiveFont);

  return (

    <Grid container>
      <ThemeProvider theme={responsiveFont}>
      <Grid item xs={12} md={7}>
        <Paper elevation={0} >
          <Card >
            <div style={{ position: "relative" }}>
              <CardMedia  sx={{
                height: {md:'90vh',sm:'100%',xs:'93vh'},
                width: '100%',
                objectFit: 'cover'
              }}  component="img" image={"../content/images/background.png"} alt="Background"/>
              <div className="backgroundTitlePosition">
                <Typography variant="h4" className="text-sm-center text-xs-left" ml={1} sx={{fontFamily:'Inria Sans',fontWeight:300,fontSize: {md:'2.5rem',sx:'2.2rem'}}}>
                    INTERNATIONAL TRADE & DEVELOPMENT
                </Typography>
                <div className="text-sm-center text-sm-justify mt-3 " id="leadText">
                <Typography variant="h5" ml={1}>
                  Dedicated to Growth, Providing
                </Typography>
                  {Storage.session.get('locale')==='en'?(<Typography variant="h5" ml={1}>
                   Opportunity - Using Experience,
                </Typography>):(<Typography variant="h5" ml={1}>
                    ,Opportunity - Using Experience
                  </Typography>)}
                <Typography variant="h5" ml={1}>
                   Tradition & Technology to Build Our  Future
                </Typography>
                </div>
              </div>
            </div>
          </Card>
        </Paper>
      </Grid>
      <Grid item xs={12} md={5}>
        <Paper elevation={0} >
          <Row>
            <Col xs={7} md={8}>
              <Grid container direction="row" className={classes.headerText} style={{alignItems: 'center'}}>
                <Grid item >
                  <Box
                    component="img"
                    sx={{
                      maxWidth: {xs: 60, md: 70},
                      mr:{xs:2},
                      ml:{xs:2,md:2},
                      p:0,
                    }}
                    alt="logo"
                    src="../content/images/img.png"
                  />
                </Grid>
                <Grid item>
                  <div className="text-class">
                    {Storage.session.get('locale') === 'ar-ly' ? (
                      <Typography variant="subtitle1">
                        <Translate contentKey="login.logoTitle.fullTitle" />
                      </Typography>
                    ) : (
                      <>
                        <Typography variant="subtitle1">
                          <Translate contentKey="login.logoTitle.nameTitle"/>
                        </Typography>
                        <Typography variant="subtitle1">
                          <Translate contentKey="login.logoTitle.systemTitle"/>
                        </Typography>
                      </>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Col>
            <Col xs={4} md={4}>
              <div className='languageSection'>
                <div style={{display: "flex", justifyContent: "center"}}>
                  <NavDropdown icon="language" name={
                    <div className="locale-icon-login">
                      <TranslateIcon
                        sx={{
                          fontSize: '24px',
                          marginTop: {md: 0, sm: '16px', xs: '-24px'}
                        }}/>
                      <Translate contentKey="global.menu.language" />
                    </div>
                    }>
                    {locales.map(locale => (
                      <DropdownItem key={locale} value={locale} onClick={handleLocaleChange}>
                        {languages[locale].name}
                      </DropdownItem>
                    ))}
                  </NavDropdown>
                </div>
              </div>
            </Col>
          </Row>
          <h2 className='loginTitle'><Translate contentKey='login.login'/></h2>
          <Box className={classes.formRoot} >
            <ValidatedForm onSubmit={handleLogin}>
              <Grid container direction="column" >
                {isLoginError ? (
                  <Alert color="danger" data-cy="loginError" style={{textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',}}>
                    <Translate contentKey="login.messages.error.authentication">
                      <strong>Failed to sign in!</strong> Please check your credentials and try again.
                    </Translate>
                  </Alert>
                ) : null}
                <TextField
                  id={'name'}
                  value={values.userName}
                  className={Storage.session.get('locale')==='en'? classes.textFieldLabel :classes.textFieldLabelArabic}
                  onChange={handleChange('userName')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonOutlineIcon/>
                      </InputAdornment>
                    ),
                    // className:classes.textFieldLabelArabic
                  }}
                  // inputProps={{ style: inputStyle }}
                  label={translate('global.form.username.label')}
                  sx={{mb:3}}
                  required
                  fullWidth
                />

                <TextField
                  id="password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  className={Storage.session.get('locale')==='en'? classes.textFieldLabel :classes.textFieldLabelArabic}
                  onChange={handleChange('password')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOpenIcon sx={{mr:Storage.session.get('locale')==='en'? 0: -2}}/>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  // inputProps={{ style: inputStyle }}
                  label={translate('login.form.password')}

                  sx={{mb:3}}
                  required
                  fullWidth
                />
                <Row id='lastRow'>
                  <Col>
                    <Checkbox checked={values.rememberMe} onChange={handleChange('rememberMe')} size={'small'} />
                    <label style={{marginLeft:'3px'}}><Translate contentKey='login.form.rememberme'/></label>
                  </Col>
                  <Col id='resetPw'>
                    <Link to="/account/reset/request">
                      <Translate contentKey='login.resetPassword'/>
                    </Link>
                  </Col>
                </Row>
                <Button variant="contained" size="medium" color="primary" type="submit" fullWidth>
                  <Translate contentKey='login.login'/>
                </Button>
              </Grid>
            </ValidatedForm>
          </Box>
        </Paper>
      </Grid>

      </ThemeProvider>
      </Grid>

  )
}
export default Login;
