import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PrivateRoute from 'app/shared/auth/private-route';

import Commodity from './commodity';
import CommodityDetail from './commodity-detail';
import CommodityUpdate from './commodity-update';
import CommodityDeleteDialog from './commodity-delete-dialog';
import { AUTHORITIES } from 'app/config/constants';

const Routes = ({ match }) => (
  <>
    <Switch>
      <PrivateRoute
        exact path={`${match.url}/new`}
        component={CommodityUpdate}
        hasAnyAuthorities={[AUTHORITIES.ADMIN]}
      />
      <PrivateRoute
        exact path={`${match.url}/:id/edit`}
        component={CommodityUpdate}
        hasAnyAuthorities={[AUTHORITIES.ADMIN]}
      />
      <PrivateRoute
        exact path={`${match.url}/:id`}
        component={CommodityDetail}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.BANK_STAFF,
          AUTHORITIES.BANK_MANAGER,
          AUTHORITIES.BRANCH_MANAGER,
          AUTHORITIES.AUDITOR
        ]}
      />
      <PrivateRoute
        exact path={match.url}
        component={Commodity}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.BANK_STAFF,
          AUTHORITIES.BANK_MANAGER,
          AUTHORITIES.BRANCH_MANAGER,
          AUTHORITIES.AUDITOR
        ]}
      />
    </Switch>
    <PrivateRoute
      exact path={`${match.url}/:id/delete`}
      component={CommodityDeleteDialog}
      hasAnyAuthorities={[AUTHORITIES.ADMIN]}
    />
  </>
);

export default Routes;
