import { IBank } from 'app/shared/model/bank.model';

export interface IComexBankFee {
  id?: number;
  fromTo?: number;
  feeType?: string;
  amount?: number;
  bank?: IBank;
  currencyId?: number;
  bankId?: number;
}

export const defaultValue: Readonly<IComexBankFee> = {};
