import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import CommodityLog from './commodity-log';
import CommodityLogDetail from './commodity-log-detail';
import CommodityLogUpdate from './commodity-log-update';
import CommodityLogDeleteDialog from './commodity-log-delete-dialog';
import PrivateRoute from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

const Routes = ({ match }) => (
  <>
    <Switch>
      <PrivateRoute
        exact path={`${match.url}/:id`}
        component={CommodityLogDetail}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.AUDITOR,
        ]}
      />
      <PrivateRoute
        path={match.url}
        component={CommodityLog}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.AUDITOR,
        ]}
      />
    </Switch>
  </>
);

export default Routes;
