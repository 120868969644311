import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createEntity as createComexBankFeeLog } from '../comex-bank-fee-log/comex-bank-fee-log.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  getEntity,
  deleteEntityFromBank,
  getFeeListByBankId
} from '../comex-bank-fee/comex-bank-fee.reducer';
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";


export const BankDetailFeeDeleteDialog = (props: {id: number, showModal: number, closeModal:  Dispatch<SetStateAction<number>>}) => {
  const {id, showModal, closeModal} = props
  const [loadModal, setLoadModal] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getFeeListByBankId(bankEntity.id));
    setLoadModal(true);
  }, []);

  const comexBankFeeEntity = useAppSelector(state => state.comexBankFee.entity);
  const comexBankFeeList = useAppSelector(state => state.comexBankFee.entities);
  const updateSuccess = useAppSelector(state => state.comexBankFee.updateSuccess);
  const bankEntity = useAppSelector(state => state.bank.entity);

  useEffect(() => {
    if (updateSuccess && loadModal) {
      closeModal(null);
    }
  }, [updateSuccess]);

  // const confirmDelete = () => {
  //   dispatch(deleteEntity(comexBankFeeEntity.id));
  // };

  const confirmDelete = () => {
    const feesWithSameFromTo = comexBankFeeList.filter((item) =>
      item.fromTo === comexBankFeeEntity.fromTo && item.feeType === comexBankFeeEntity.feeType
    );

    feesWithSameFromTo?.forEach((item) => {
      const comexBankFeelog={
        ...comexBankFeeEntity,
        id:null,
        status:'DELETE',
        amount: item?.amount,
        currencyId: item?.currencyId,
      }
      dispatch(createComexBankFeeLog(comexBankFeelog));
      dispatch(deleteEntityFromBank({ id: item?.id, bankId: bankEntity?.id, fromTo: item?.fromTo }));
    });
    dispatch(getFeeListByBankId(bankEntity.id));
  };

  return (
    <Dialog open={true}  onClick={() => closeModal(null)}  fullWidth>
      <DialogTitle style={{ fontWeight: "bold" }}>
        <Grid container spacing={2}>
          <Grid item xs={10} sx={{display: 'flex', justifyContent: 'flex-start'}}>
            <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
          </Grid>

        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ color: "black"}} className='mb-3'>
          <Translate contentKey="comexApp.comexBankFee.delete.questionWithoutId">
            Are you sure you want to delete this ComexBankFee?
          </Translate>
        </DialogContentText>
      </DialogContent>
      <DialogContent>
        <Grid sx={{display: 'flex', justifyContent: 'flex-end'}}>
          <IconButton
            aria-label="close"
            onClick={() => closeModal(null)}
            sx={{
              position: 'absolute',
              top: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>

        <DialogActions className='mx-3 mb-3'>
          <DialogContentText>
            <Button
              variant="contained"
              id="save-entity"
              data-cy="entityCreateSaveButton"
              onClick={() => closeModal(null)}
            >
              <Translate contentKey="comexApp.quotation.cancelBtnLabel">Cancel</Translate>
            </Button>
            &nbsp;
            <Button
              variant="contained"
              color="danger"
              id="save-entity"
              data-cy="entityCreateSaveButton"
              type="submit"
              onClick={confirmDelete}
            >
              <Translate contentKey="entity.action.delete">Delete</Translate>
            </Button>
            &nbsp;
          </DialogContentText>
        </DialogActions>
      </DialogContent>
    </Dialog>
    /* <Modal isOpen={showModal !== null} toggle={() => closeModal(null)}>
      <ModalHeader data-cy="comexBankFeeDeleteDialogHeading">
        <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
      </ModalHeader>
      <ModalBody id="comexApp.comexBankFee.delete.question">
        <Translate contentKey="comexApp.comexBankFee.delete.question" interpolate={{ id: comexBankFeeEntity.id }}>
          Are you sure you want to delete this ComexBankFee?
        </Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => closeModal(null)}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button id="jhi-confirm-delete-comexBankFee" data-cy="entityConfirmDeleteButton" color="danger" onClick={confirmDelete}>
          <FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </Button>
      </ModalFooter>
    </Modal> */
  );
};

export default BankDetailFeeDeleteDialog;
