import axios from 'axios';
import {createAsyncThunk, isFulfilled, isPending} from '@reduxjs/toolkit';

import {IQueryParams, createEntitySlice, EntityState, serializeAxiosError,} from 'app/shared/reducers/reducer.utils';
import {IBulkTransactionRecord, defaultValue} from "app/shared/model/bulk-transaction-record.model";
import {IBulkTransaction} from "app/shared/model/bulk-transaction.model";
import {cleanEntity} from "app/shared/util/entity-utils";
import {getEntities} from "app/entities/bulk-transaction/bulk-transaction.reducer";

const initialState: EntityState<IBulkTransactionRecord> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/bulk-transaction-records';

// Actions

export const getEntity = createAsyncThunk(
  'bulkTransactionRecord/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/entity/${id}`;
    return axios.get<IBulkTransactionRecord>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'bulkTransactionRecord/update_entity',
  async (entity: IBulkTransactionRecord, thunkAPI) => {
    const result = await axios.put<IBulkTransaction>(`${apiUrl}/entity/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getEntitiesByBulkTransactionId = createAsyncThunk('bulkTransactionRecord/fetch_entity_list', async ({ page, size, sort, search,bulkTransactionId }: IQueryParams) => {
  const requestUrl = `${apiUrl}/${bulkTransactionId}${sort ? `?page=${page}&size=${size}&sort=${sort}&search=${search}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IBulkTransactionRecord[]>(requestUrl);
});

// slice

export const BulkTransactionRecordSlice = createEntitySlice({
  name: 'bulkTransactionRecord',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(updateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(getEntitiesByBulkTransactionId), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isPending(getEntitiesByBulkTransactionId, getEntity, updateEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = BulkTransactionRecordSlice.actions;

// Reducer
export default BulkTransactionRecordSlice.reducer;
