import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity, updateEntity, createEntity, reset } from './bursa-api-stb-cert.reducer';
import { IBursaAPIStbCert } from 'app/shared/model/bursa-api-stb-cert.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const BursaAPIStbCertUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const bursaAPIStbCertEntity = useAppSelector(state => state.bursaAPIStbCert.entity);
  const loading = useAppSelector(state => state.bursaAPIStbCert.loading);
  const updating = useAppSelector(state => state.bursaAPIStbCert.updating);
  const updateSuccess = useAppSelector(state => state.bursaAPIStbCert.updateSuccess);
  const handleClose = () => {
    props.history.push('/bursa-api-stb-cert' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...bursaAPIStbCertEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...bursaAPIStbCertEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="comexApp.bursaAPIStbCert.home.createOrEditLabel" data-cy="BursaAPIStbCertCreateUpdateHeading">
            <Translate contentKey="comexApp.bursaAPIStbCert.home.createOrEditLabel">Create or edit a BursaAPIStbCert</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="bursa-api-stb-cert-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('comexApp.bursaAPIStbCert.endpointURL')}
                id="bursa-api-stb-cert-endpointURL"
                name="endpointURL"
                data-cy="endpointURL"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIStbCert.ecertNo')}
                id="bursa-api-stb-cert-ecertNo"
                name="ecertNo"
                data-cy="ecertNo"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIStbCert.memberShortName')}
                id="bursa-api-stb-cert-memberShortName"
                name="memberShortName"
                data-cy="memberShortName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 8, message: translate('entity.validation.maxlength', { max: 8 }) },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIStbCert.sucessYN')}
                id="bursa-api-stb-cert-sucessYN"
                name="sucessYN"
                data-cy="sucessYN"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIStbCert.msg')}
                id="bursa-api-stb-cert-msg"
                name="msg"
                data-cy="msg"
                type="text"
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIStbCert.seller')}
                id="bursa-api-stb-cert-seller"
                name="seller"
                data-cy="seller"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIStbCert.buyer')}
                id="bursa-api-stb-cert-buyer"
                name="buyer"
                data-cy="buyer"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIStbCert.totalValue')}
                id="bursa-api-stb-cert-totalValue"
                name="totalValue"
                data-cy="totalValue"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIStbCert.currency')}
                id="bursa-api-stb-cert-currency"
                name="currency"
                data-cy="currency"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIStbCert.price')}
                id="bursa-api-stb-cert-price"
                name="price"
                data-cy="price"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  min: { value: 0, message: translate('entity.validation.min', { min: 0 }) },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIStbCert.priceMYR')}
                id="bursa-api-stb-cert-priceMYR"
                name="priceMYR"
                data-cy="priceMYR"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  min: { value: 0, message: translate('entity.validation.min', { min: 0 }) },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIStbCert.sellingTimeDate')}
                id="bursa-api-stb-cert-sellingTimeDate"
                name="sellingTimeDate"
                data-cy="sellingTimeDate"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIStbCert.valueDate')}
                id="bursa-api-stb-cert-valueDate"
                name="valueDate"
                data-cy="valueDate"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIStbCert.pName')}
                id="bursa-api-stb-cert-pName"
                name="pName"
                data-cy="pName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIStbCert.pVolume')}
                id="bursa-api-stb-cert-pVolume"
                name="pVolume"
                data-cy="pVolume"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIStbCert.supplier')}
                id="bursa-api-stb-cert-supplier"
                name="supplier"
                data-cy="supplier"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.bursaAPIStbCert.volume')}
                id="bursa-api-stb-cert-volume"
                name="volume"
                data-cy="volume"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/bursa-api-stb-cert" replace>
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default BursaAPIStbCertUpdate;
