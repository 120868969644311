import { IBursaAPIOrder } from "./bursa-api-order.model";

export interface IBursaAPIOrderResult {
  id?: number;
  endpointURL?: string;
  memberShortName?: string;
  uuid?: string;
  serialNumbers?: string | null;
  forceYN?: string;
  maxWaitTime?: number | null;
  waitAllDoneYN?: string | null;
  bidErrNo?: string | null;
  bidMsg?: string | null;
  otcErrNo?: string | null;
  otcMsg?: string | null;
  stbErrNo?: string | null;
  stbMsg?: string | null;
  regTime?: string | null;
  orderTime?: string | null;
  resultTime?: string | null;
  purchaseTime?: string | null;
  reportTime?: string | null;
  sellingTime?: string | null;
  unit?: string | null;
  price?: number | null;
  totalOrderCount?: string | null;
  processingCount?: string | null;
  bursaAPIOrderId?: string;
  bursaAPIOrder?:IBursaAPIOrder;
}

export interface IQueryErrorResultList{
  id?:string | number;
  createdDate?:string;
}

export const defaultValue: Readonly<IBursaAPIOrderResult> = {};
