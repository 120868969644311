import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './bursa-api-end-points.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const BursaAPIEndPointsDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const bursaAPIEndPointsEntity = useAppSelector(state => state.bursaAPIEndPoints.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="bursaAPIEndPointsDetailsHeading">
          <Translate contentKey="comexApp.bursaAPIEndPoints.detail.title">BursaAPIEndPoints</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{bursaAPIEndPointsEntity.id}</dd>
          <dt>
            <span id="order">
              <Translate contentKey="comexApp.bursaAPIEndPoints.order">Order</Translate>
            </span>
          </dt>
          <dd>{bursaAPIEndPointsEntity.order}</dd>
          <dt>
            <span id="orderResult">
              <Translate contentKey="comexApp.bursaAPIEndPoints.orderResult">Order Result</Translate>
            </span>
          </dt>
          <dd>{bursaAPIEndPointsEntity.orderResult}</dd>
          <dt>
            <span id="bidCert">
              <Translate contentKey="comexApp.bursaAPIEndPoints.bidCert">Bid Cert</Translate>
            </span>
          </dt>
          <dd>{bursaAPIEndPointsEntity.bidCert}</dd>
          <dt>
            <span id="otcCert">
              <Translate contentKey="comexApp.bursaAPIEndPoints.otcCert">Otc Cert</Translate>
            </span>
          </dt>
          <dd>{bursaAPIEndPointsEntity.otcCert}</dd>
          <dt>
            <span id="stbCert">
              <Translate contentKey="comexApp.bursaAPIEndPoints.stbCert">Stb Cert</Translate>
            </span>
          </dt>
          <dd>{bursaAPIEndPointsEntity.stbCert}</dd>
        </dl>
        <Button tag={Link} to="/bursa-api-end-points" replace data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/bursa-api-end-points/${bursaAPIEndPointsEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default BursaAPIEndPointsDetail;
