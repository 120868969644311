import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import {Translate, getSortState, translate, Storage} from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'app/app.scss';
import { getEntities } from './bank.reducer';
import { AUTHORITIES } from 'app/config/constants';
import { ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import {Avatar, TableBody} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import {visuallyHidden} from "@mui/utils";
import PropTypes from "prop-types";
import {createTheme, responsiveFontSizes, styled, ThemeProvider} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";
import {Clear} from "@mui/icons-material";
import axios from 'axios';
import {getUserRoleAccessByUserId} from 'app/modules/administration/user-management/user-management.reducer';

export const Bank = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();
  const userAuthorities = useAppSelector(state => state.authentication.account.authorities);
  const account = useAppSelector(state => state.authentication.account);
  const userRoleAccessList = useAppSelector(state => state.userManagement.userRoleAccessList);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE, 'name'), props.location.search)
  );
  const allList = useAppSelector(state => state.bank.entities);
  const [bankList, setBankList] = useState([]);
  const loading = useAppSelector(state => state.bank.loading);
  const totalItems = useAppSelector(state => state.bank.totalItems);
  const [searchOnChange, setSearchOnChange] = useState('');
  const [searchItem, setSearchItem] = useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [pageNo, setPage] = React.useState(0);

  const [s3BucketName, setS3BucketName] = useState<string | null>(null);
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await axios.get('/api/config/bucketName');
        const bucketName = response.data.bucketName;
        setS3BucketName(bucketName);
      } catch (error) {
        console.error('Error fetching bucket name:', error);
      }
    };
    fetchConfig();
  }, []);

  useEffect(() => {
    if (account?.authorities.includes(AUTHORITIES.SUBADMIN_BANK)) {
      dispatch(getUserRoleAccessByUserId(account?.id));
    }
  }, [account]);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: 0,
        size: 0,
        sort: 'id,asc',
        search: searchItem,
      })
    ).then(res => {
      setBankList(res.payload['data']);
    });
  };

  // const sortEntities = () => {
  //   getAllEntities();
  //
  //   const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}&search=${searchItem}`;
  //   if (props.location.search !== endURL) {
  //     props.history.push(`${props.location.pathname}${endURL}`);
  //   }
  // };

  useEffect(() => {
    // sortEntities();
    getAllEntities();
  }, [pageNo, rowsPerPage, searchItem]);


  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }

  }, [props.location.search]);

  const handleSearchItem = e => {
    setBankList([]);
    if (e.target.value.length > 0)
      setSearchItem(e.target.value);
    else
      setSearchItem('');
    setPaginationState({
      ...paginationState,
      activePage: 1,
      sort: `name`,
      order: `asc`,
    });
    getAllEntities();

    setBankList(allList.map(item => item));

  }
  const { match } = props;
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgba(255,255,255,0)",
      color: theme.palette.common.white,
      fontWeight: 'bold',
      letterSpacing: 1,
      textAlign:Storage.session.get('locale') === 'en' ?'left':'right',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign:Storage.session.get('locale') === 'en' ?'left':'right',
    },
  }));

  const StyledTableCellCenter = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgba(255,255,255,0)",
      color: theme.palette.common.white,
      fontWeight: 'bold',
      letterSpacing: 1,
      textAlign:'center',
    },
  }));

  const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
    '&.MuiTableSortLabel-root': {
      color: 'white',
    },
    '&.MuiTableSortLabel-root:hover': {
      color: 'white',
    },
    '&.Mui-active': {
      color: 'white',
    },
    '& .MuiTableSortLabel-icon': {
      color: 'white !important',
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy].toString().toLowerCase() < a[orderBy].toString().toLowerCase()) {
      return -1;
    }
    if (b[orderBy].toString().toLowerCase() > a[orderBy].toString().toLowerCase()) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const rows = bankList.map((obj, i)=>({
    ...obj,
    imgURL: obj.imgURL !== null ? `https://${s3BucketName}.s3.me-south-1.amazonaws.com/bankLogo/${obj.imgURL}` : null,
    name: obj.name,
    description : obj.description,
  }));

  const headCells = [
    {
      id: 'name',
      numeric: true,
      disablePadding: false,
      label: `${translate('comexApp.bank.name')}`,
      disableSorting: false,
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: `${translate('comexApp.bank.description')}`,
      disableSorting: false,
    },
  ];

  function EnhancedTableHead(tableHead) {
    const { order, orderBy, onRequestSort } =
      tableHead;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow className="enhanced-table-bank" sx={{ fontWeight: '500' }}>
          <StyledTableCellCenter>
            {translate('comexApp.bank.imgURL')}
          </StyledTableCellCenter>
          {headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              align="left"
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.disableSorting? headCell.label:
                <StyledTableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </StyledTableSortLabel>
              }
            </StyledTableCell>
          ))}
          <StyledTableCellCenter>
            {translate('comexApp.bank.action')}
          </StyledTableCellCenter>
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function decodedImgString(entity: any) {
    const imgFromDb = (entity.imgURL);
    const imgStringToNumber = imgFromDb?.split(',').map(i=>Number(i));
    return String.fromCharCode.apply(null, imgStringToNumber);
  };

  let responsiveFont = createTheme();
  responsiveFont = responsiveFontSizes(responsiveFont);

  return (
    <Container>
      <div className="mt-4 mb-4">
        <ThemeProvider theme={responsiveFont}>
          <Typography variant="h4" component="div" data-cy="bankPageHeading" className="mb-4">
            {translate('comexApp.bank.home.title')}
          </Typography>
        </ThemeProvider>
        <Stack direction="row" spacing={2} sx={{ margin: '30px 0px' }}>
          <Grid container >
            <Grid item md={3} sm={5} xs={12}>
              <TextField size='small' value={searchOnChange} placeholder={translate('home.search')} fullWidth
               InputProps={{
                 startAdornment: (
                   <InputAdornment position="start">
                     <Search />
                   </InputAdornment>
                 ),
                 endAdornment: (
                   <InputAdornment position="end" onClick={(e) => {  setSearchItem('');setSearchOnChange('');setPage(0); }}>
                     <Clear style={{ fontSize: 12 }} />
                   </InputAdornment>
                 ),
               }}
               onChange={e => { setSearchOnChange(e.target.value); }}
               onKeyPress={e => { if (e.key === 'Enter') { setSearchItem(searchOnChange); setPage(0);} }}
              />
            </Grid>
            <Grid item md={9} sm={7} xs={12} sx={{display:'flex',justifyContent:{sm:'flex-end'}}}>
              {(userAuthorities.includes(AUTHORITIES.ADMIN) || userAuthorities.includes(AUTHORITIES.ALSHMOKH_ADMIN) || account?.authorities?.includes(AUTHORITIES.SUBADMIN_BANK)) &&
                <Link style={{fontFamily: 'Roboto, sans-serif'}} to={`${match.url}/new`} className="btn btn-primary bank-create-button">
                  <FontAwesomeIcon icon="plus" /> <Translate contentKey="comexApp.bank.home.createLabel">Create a new user</Translate>
                </Link>}
            </Grid>
          </Grid>
        </Stack>
        <Paper className="paper-table mb-4">
          <TableContainer sx={{ maxHeight: 440 }}>
          {bankList && bankList.length > 0 ? (
            <Table sx={{ minWidth: 650 }}>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {bankList && stableSort(rows, getComparator(order, orderBy))
                  .slice(pageNo * rowsPerPage, pageNo * rowsPerPage + rowsPerPage)
                  .map((bank, i) => {
                    const isUserAuthorizedToEdit = userRoleAccessList.length === 0 ||
                      userRoleAccessList.some(role => role.bankId === bank?.id);
                    return (
                      <StyledTableRow key={bank?.id}>
                        <StyledTableCell>
                          {bank?.imgURL === null ?
                            <Avatar style={{margin:'auto'}}>{bank.name.charAt(0)}</Avatar>
                          :
                            <Avatar alt={bank.name} src={bank?.imgURL} style={{margin:'auto'}}><img style={{width:'100%'}} /></Avatar>}
                        </StyledTableCell>
                        <StyledTableCell>{bank?.name}</StyledTableCell>
                        <StyledTableCell>{bank?.description}</StyledTableCell>
                        <StyledTableCell className="text-end">
                          <div className="btn-group flex-btn-group-container" style={{display: 'flex', justifyContent: 'center'}}>
                            <Tooltip title={translate('comexApp.bank.home.view')}>
                              <IconButton className="px-3" color="info" aria-label="view" size="small" onClick={() => props.history.push(`${match.url}/${bank.id}`)}>
                                <VisibilityIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={translate('comexApp.bank.home.edit')} hidden={userAuthorities.includes(AUTHORITIES.ALSHMOKH_USER) && !account?.authorities?.includes(AUTHORITIES.SUBADMIN_BANK)}>
                              <IconButton className="px-3" color="primary" aria-label="edit" size="small"  onClick={() => props.history.push(`${match.url}/${bank.id}/edit`)} disabled={!isUserAuthorizedToEdit} >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                })}
              </TableBody>
            </Table>

          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="comexApp.bank.home.notFound">No Banks found</Translate>
              </div>
            )
          )}
          </TableContainer>
          <TablePagination
            className={Storage.session.get('locale') === 'en' ? "table-pagination" : "table-pagination-arabic"}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={pageNo}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={translate('home.table.rowsPerPage')}
          />
        </Paper>
      </div>
    </Container>
  );
};

export default Bank;
