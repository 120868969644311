import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './bursa-api-order-result.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const BursaAPIOrderResultDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const bursaAPIOrderResultEntity = useAppSelector(state => state.bursaAPIOrderResult.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="bursaAPIOrderResultDetailsHeading">
          <Translate contentKey="comexApp.bursaAPIOrderResult.detail.title">BursaAPIOrderResult</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderResultEntity.id}</dd>
          <dt>
            <span id="endpointURL">
              <Translate contentKey="comexApp.bursaAPIOrderResult.endpointURL">Endpoint URL</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderResultEntity.endpointURL}</dd>
          <dt>
            <span id="memberShortName">
              <Translate contentKey="comexApp.bursaAPIOrderResult.memberShortName">Member Short Name</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderResultEntity.memberShortName}</dd>
          <dt>
            <span id="uuid">
              <Translate contentKey="comexApp.bursaAPIOrderResult.uuid">Uuid</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderResultEntity.uuid}</dd>
          <dt>
            <span id="serialNumbers">
              <Translate contentKey="comexApp.bursaAPIOrderResult.serialNumbers">Serial Numbers</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderResultEntity.serialNumbers}</dd>
          <dt>
            <span id="forceYN">
              <Translate contentKey="comexApp.bursaAPIOrderResult.forceYN">Force YN</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderResultEntity.forceYN}</dd>
          <dt>
            <span id="maxWaitTime">
              <Translate contentKey="comexApp.bursaAPIOrderResult.maxWaitTime">Max Wait Time</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderResultEntity.maxWaitTime}</dd>
          <dt>
            <span id="waitAllDoneYN">
              <Translate contentKey="comexApp.bursaAPIOrderResult.waitAllDoneYN">Wait All Done YN</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderResultEntity.waitAllDoneYN}</dd>
          <dt>
            <span id="bidErrNo">
              <Translate contentKey="comexApp.bursaAPIOrderResult.bidErrNo">Bid Err No</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderResultEntity.bidErrNo}</dd>
          <dt>
            <span id="bidMsg">
              <Translate contentKey="comexApp.bursaAPIOrderResult.bidMsg">Bid Msg</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderResultEntity.bidMsg}</dd>
          <dt>
            <span id="otcErrNo">
              <Translate contentKey="comexApp.bursaAPIOrderResult.otcErrNo">Otc Err No</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderResultEntity.otcErrNo}</dd>
          <dt>
            <span id="otcMsg">
              <Translate contentKey="comexApp.bursaAPIOrderResult.otcMsg">Otc Msg</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderResultEntity.otcMsg}</dd>
          <dt>
            <span id="stbErrNo">
              <Translate contentKey="comexApp.bursaAPIOrderResult.stbErrNo">Stb Err No</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderResultEntity.stbErrNo}</dd>
          <dt>
            <span id="stbMsg">
              <Translate contentKey="comexApp.bursaAPIOrderResult.stbMsg">Stb Msg</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderResultEntity.stbMsg}</dd>
          <dt>
            <span id="regTime">
              <Translate contentKey="comexApp.bursaAPIOrderResult.regTime">Reg Time</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderResultEntity.regTime}</dd>
          <dt>
            <span id="orderTime">
              <Translate contentKey="comexApp.bursaAPIOrderResult.orderTime">Order Time</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderResultEntity.orderTime}</dd>
          <dt>
            <span id="resultTime">
              <Translate contentKey="comexApp.bursaAPIOrderResult.resultTime">Result Time</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderResultEntity.resultTime}</dd>
          <dt>
            <span id="purchaseTime">
              <Translate contentKey="comexApp.bursaAPIOrderResult.purchaseTime">Purchase Time</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderResultEntity.purchaseTime}</dd>
          <dt>
            <span id="reportTime">
              <Translate contentKey="comexApp.bursaAPIOrderResult.reportTime">Report Time</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderResultEntity.reportTime}</dd>
          <dt>
            <span id="sellingTime">
              <Translate contentKey="comexApp.bursaAPIOrderResult.sellingTime">Selling Time</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderResultEntity.sellingTime}</dd>
          <dt>
            <span id="unit">
              <Translate contentKey="comexApp.bursaAPIOrderResult.unit">Unit</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderResultEntity.unit}</dd>
          <dt>
            <span id="price">
              <Translate contentKey="comexApp.bursaAPIOrderResult.price">Price</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderResultEntity.price}</dd>
          <dt>
            <span id="totalOrderCount">
              <Translate contentKey="comexApp.bursaAPIOrderResult.totalOrderCount">Total Order Count</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderResultEntity.totalOrderCount}</dd>
          <dt>
            <span id="processingCount">
              <Translate contentKey="comexApp.bursaAPIOrderResult.processingCount">Processing Count</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderResultEntity.processingCount}</dd>
          <dt>
            <span id="bursaAPIOrderId">
              <Translate contentKey="comexApp.bursaAPIOrderResult.bursaAPIOrderId">Bursa API Order Id</Translate>
            </span>
          </dt>
          <dd>{bursaAPIOrderResultEntity.bursaAPIOrderId}</dd>
        </dl>
        <Button tag={Link} to="/bursa-api-order-result" replace data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/bursa-api-order-result/${bursaAPIOrderResultEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default BursaAPIOrderResultDetail;
