export interface IBulkTransactionRecord {
  id?: number;
  position?: number;
  customerName?: string | null;
  nationalId?: string | null;
  currency?: string | null;
  amount?: string | null;
  itemName?: string | null;
  nationality?: string | null;
  telNo?: string | null;
  certificateNumber?: string | null;
  tons?: string | null;
  purchasingPrice?: string | null;
  transactionFees?: string | null;
  feeCurrency?: string | null;
  status?: string | null;
  processingStarted?: string | null;
  createdDate?: string | null;
  purchaseDone?: boolean;
  transferDone?: boolean;
  sellDone?: boolean;
  bulkTransactionId?: number;
  staleThreshold?:number;
  processLog?: string | null;
  processingTime?: string | null;
  failureReason?: string | null;
}

export const defaultValue: Readonly<IBulkTransactionRecord> = {};
