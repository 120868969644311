import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Row, Col, FormText, Form, FormGroup, Label, Input } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField, Select, MenuItem, Button } from '@mui/material';
import { getEntity, updateEntity } from './quotation.reducer';
import { getEntities as getCustomers } from 'app/entities/customer/customer.reducer';
import { IQuotation } from 'app/shared/model/quotation.model';
import { ICustomer } from 'app/shared/model/customer.model';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { createEntity as createCustomer } from '../customer/customer.reducer';
import { partialUpdateEntity as partialUpdateCustomer } from '../customer/customer.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import './quotation-update.css';
import { Storage } from 'react-jhipster';

export const AmendQuotationByBranchManager = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const quotationEntity = useAppSelector(state => state.quotation.entity);
  const updateSuccess = useAppSelector(state => state.quotation.updateSuccess);
  const customers = useAppSelector(state => state.customer.entities);
  const customerEntity = useAppSelector(state => state.customer.entity);
  const customerUpdateSuccess = useAppSelector(state => state.customer.updateSuccess);
  const formatTwoDigits = digit => ('0' + digit).slice(-2);
  const tempDate = new Date();
  const date = `${formatTwoDigits(tempDate.getDate())}/${formatTwoDigits(tempDate.getMonth() + 1)}/${tempDate.getFullYear()}`;
  const updatedQuotation = [] as IQuotation;
  const [disableBtn, setDisableBtn] = useState(false);
  const [cusId, setCusId] = useState(0);
  const [cusNationality, setNationality] = useState(quotationEntity?.customer?.nationality);
  const [cusName, setCusName] = useState(quotationEntity?.customer?.name);
  const [cusIc, setCusIc] = useState(quotationEntity?.customer?.ic);
  const [cusPhone, setCusPhone] = useState(quotationEntity?.customer?.tel);
  const [disable, setDisable] = useState(true);
  const [isCustomerNew, setIsCustomerNew] = useState(false);
  const [isCustomerUpdateFinish, setIsCustomerUpdateFinish] = useState(false);

  let customerNew;
  const updatedCustomer = [] as ICustomer;

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
    /* use this to manually parse id for testing
        dispatch(getEntity(1));
    */
    dispatch(getCustomers({}));
  }, []);

  useEffect(() => {
    if (isCustomerNew) {
      updatedQuotation.id = quotationEntity.id;
      updatedQuotation.amount = quotationEntity.amount;
      updatedQuotation.description = quotationEntity.description;
      updatedQuotation.commodityUnitPrice = quotationEntity.commodityUnitPrice;
      updatedQuotation.commodityQuantity = quotationEntity.commodityQuantity;
      updatedQuotation.currencyRate = quotationEntity.currencyRate;
      updatedQuotation.buyCurrency = quotationEntity.buyCurrency;
      updatedQuotation.bankManagerApproval = true;
      updatedQuotation.bankCustomerApproval = false;
      updatedQuotation.smsLanguage = null;
      updatedQuotation.smsSent = null;
      updatedQuotation.bursaAPIBidCertId = null;
      updatedQuotation.bursaAPIOtcCertId = null;
      updatedQuotation.bursaAPIStbCertId = null;
      updatedQuotation.stbOption = null;
      updatedQuotation.comexAdminDecision = null;
      const lastCustomer = customers.filter(it => it.ic === cusIc);
      updatedQuotation.customer = lastCustomer[0];
      updatedQuotation.commodity = quotationEntity.commodity;
      updatedQuotation.branch = quotationEntity.branch;
      dispatch(updateEntity(updatedQuotation));
    }
  }, [customers]);

  const handleClose = () => {
    props.history.push('/');
  };

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (customerUpdateSuccess && !isCustomerNew && isCustomerUpdateFinish) {
      handleClose();
    }
  }, [customerUpdateSuccess]);

  const saveEntity = () => {
    const found = customerFound();
    if (found === false) {
      customerNew = {
        ic: cusIc,
        name: cusName,
        nationality: cusNationality,
        tel: cusPhone
      };
      setIsCustomerNew(true);
      dispatch(createCustomer(customerNew));
    }
    else {
      updatedCustomer.id = quotationEntity?.customer?.id;
      updatedCustomer.ic = quotationEntity?.customer?.ic;
      updatedCustomer.name = cusName;
      updatedCustomer.nationality = cusNationality;
      updatedCustomer.tel = cusPhone;
      setIsCustomerUpdateFinish(true);
      dispatch(partialUpdateCustomer(updatedCustomer));
    }
  }


  const defaultValues = () => { };

  function customerFound() {
    let found = false;
    customers.map(customer => {
      if (customer.ic === cusIc) {
        found = true;
        setDisable(false);
        setCusId(customer.id);
        setCusName(customer.name);
        setNationality(customer.nationality);
        setCusPhone(customer.tel);
      }
    });
    return found;
  }

  function customerSearch() {
    const found = customerFound();
    if (found === false) {
      setCusName("");
      setNationality("");
      setCusPhone("");
      setDisable(false);
    }
  }

  return (
    <>
      <div id="quoteform" className="round-border">
        <Row className="justify-content-center">
          <Col md="8">
            <h2>
              <Translate contentKey="comexApp.quotation.home.orderQuoted">Order Quoted</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            <br />
            <h4>
              <Translate contentKey="comexApp.quotation.orderInfoLabel">Order information</Translate>
            </h4>
            <ValidatedForm onSubmit={saveEntity}>
              <ValidatedField
                label={translate('comexApp.quotation.quotationNo')}
                id="quotation_number"
                name="quotation_number"
                data-cy="quotation_number"
                type="number"
                value={quotationEntity.id}
                disabled
                style={{
                  textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
                }}
              />

              <ValidatedField label={translate('comexApp.quotation.date')} id="date" name="date" data-cy="date" value={date} disabled />
              <ValidatedField
                label={translate('comexApp.quotation.amount')}
                id="amount"
                name="amount"
                data-cy="amount"
                type="number"
                value={quotationEntity.amount}
                disabled
                style={{
                  textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
                }}
              />

              <ValidatedField
                label="Item Name"
                id="item_name"
                name="item_name"
                data-cy="item_name"
                type="text"
                value={quotationEntity?.commodity?.name}
                disabled
              />

              <ValidatedField
                label={translate('comexApp.quotation.description')}
                id="description"
                name="description"
                data-cy="description"
                type="textarea"
                value={quotationEntity.description}
                disabled
              />
              <ValidatedField
                label={translate('comexApp.quotation.price')}
                id="price"
                name="price"
                data-cy="price"
                type="number"
                value={quotationEntity.commodityUnitPrice}
                disabled
                style={{
                  textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
                }}
              />

              <ValidatedField
                label={translate('comexApp.quotation.quantity')}
                id="quantity"
                name="quantity"
                data-cy="quantity"
                type="number"
                value={quotationEntity.commodityQuantity}
                disabled
                style={{
                  textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
                }}
              />
              <hr />
              <h4>Requested by</h4>
              <br />
              <div className=' form-group row'>
                <div className="col-sm-11">
                  <ValidatedField
                    label={translate('comexApp.quotation.nationalID')}
                    id="national_id"
                    name="national_id"
                    data-cy="national_id"
                    type="text"
                    validate={{
                      required: { value: true, message: translate('entity.validation.required') },
                    }}
                    value={cusIc ? cusIc : null}
                    onChange={(e => {
                      setCusIc(e.target.value);
                    })}
                  />
                </div>
                <div className="col-sm-1">
                  <FontAwesomeIcon icon={faSearch} onClick={customerSearch} size="lg" />
                </div>
              </div>
              <ValidatedField
                label={translate('comexApp.quotation.customer')}
                id="cust_name"
                name="cust_name"
                data-cy="cust_name"
                disabled={disable}
                type="text"
                value={cusName}
                onChange={(event) => {
                  const cusNameChange = event.target.value;
                  setCusName(cusNameChange);
                }}
              />

              <ValidatedField
                label="Nationality"
                id="cust_nationality"
                name="cust_nationality"
                data-cy="cust_nationality"
                type="text"
                disabled={disable}
                onChange={(event) => {
                  const nationalityChange = event.target.value;
                  setNationality(nationalityChange);
                }}
                value={cusNationality}
              />

              <ValidatedField
                label={translate('comexApp.quotation.phoneNo')}
                id="phoneno"
                name="phoneno"
                data-cy="phoneno"
                type="text"
                disabled={disable}
                onChange={(event) => {
                  const cusPhChange = event.target.value;
                  setCusPhone(cusPhChange);
                }}
                value={cusPhone}
              />
              <div className="btn-group">
                <Button
                  variant="contained"
                  color="primary"
                  id="save-entity"
                  data-cy="entityCreateSaveButton"
                  type="submit"
                >
                  <Translate contentKey="comexApp.quotation.saveBtnLabel">Save</Translate>
                </Button>
              </div>
            </ValidatedForm>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AmendQuotationByBranchManager;
