import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import PurchaseCancellation from './purchase-cancellation';
import PurchaseCancellationDetail from './purchase-cancellation-detail';
import PurchaseCancellationUpdate from './purchase-cancellation-update';
import PurchaseCancellationDeleteDialog from './purchase-cancellation-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={PurchaseCancellationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={PurchaseCancellationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={PurchaseCancellationDetail} />
      <ErrorBoundaryRoute path={match.url} component={PurchaseCancellation} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={PurchaseCancellationDeleteDialog} />
  </>
);

export default Routes;
