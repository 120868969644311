import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import BursaAPIBidCert from './bursa-api-bid-cert';
import BursaAPIBidCertDetail from './bursa-api-bid-cert-detail';
import BursaAPIBidCertUpdate from './bursa-api-bid-cert-update';
import BursaAPIBidCertDeleteDialog from './bursa-api-bid-cert-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={BursaAPIBidCertUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={BursaAPIBidCertUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={BursaAPIBidCertDetail} />
      <ErrorBoundaryRoute path={match.url} component={BursaAPIBidCert} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={BursaAPIBidCertDeleteDialog} />
  </>
);

export default Routes;
