import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import './reports.scss';
import {
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled, createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import {Storage, translate, Translate} from "react-jhipster";
import moment from 'moment';
import { getReportsPrint, getReportsPrintInKWD } from 'app/entities/quotation/quotation.reducer';
import { getAllEntities as getBankEntities } from '../../entities/bank/bank.reducer';
import { getAllEntities as getBranchEntities } from '../../entities/branch/branch.reducer';
import { getUsers } from '../administration/user-management/user-management.reducer';
import { getAllEntities as getCommodityEntities } from '../../entities/commodity/commodity.reducer';


export const ReportPrintTemplate = () => {

  const componentRef = useRef();
  const dispatch = useAppDispatch();
  const reportsPrint = useAppSelector(state => state.quotation.reportPrint);
  const bankList = useAppSelector(state => state.bank.entities);
  const branchList = useAppSelector(state => state.branch.entities);
  const users = useAppSelector(state => state.userManagement.users);
  const commodityList = useAppSelector(state => state.commodity.entities);
  // const [reportsPrint, setReportsPrint]=useState([]) as any;
  // const [staffList, setStaffList] = useState([]) as any;
  const totalAmount = window.localStorage.getItem('totalAmount');

  const htmlTag=document.querySelector("html");
  Storage.session.get('locale')==='en' ? htmlTag.setAttribute("dir",'ltr') : htmlTag.setAttribute("dir",'rtl');

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize:'0.55rem',
      fontWeight: 'bold',
      textAlign: Storage.session.get('locale') === 'en' ?'left':'right',
      overflow: 'hidden',
      textOverflow: 'clip',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize:'0.55rem',
      textAlign: Storage.session.get('locale') === 'en' ?'left':'right',
      overflow: 'hidden',
      textOverflow: 'clip',
    },
  }));

  const StyledTable = styled(Table)(({ theme }) => ({
    '&.MuiTable-root': {
      tableLayout: 'fixed',
      width: '100%'
    }
  }));

  useEffect(() => {
    dispatch(getBankEntities({}));
    dispatch(getBranchEntities({}));
    dispatch(getUsers({}));
    dispatch(getCommodityEntities({}));
  }, []);

  const splitStatus = localStorage.getItem('status').split(",");
  const splitDate = localStorage.getItem('period').split(" - ");
  const splitBank = localStorage.getItem('bank').split(", ");
  const splitBranch = localStorage.getItem('branch').split(", ");
  const splitBankUser = localStorage.getItem('userName').split(",");
  const splitCommodity = localStorage.getItem('commodity').split(", ");

  const bankArray = [];
  const branchArray = [];
  const bankUserArray = [];
  const commodityArray = [];

  useEffect(() => {
    bankList?.map(b => {
      if (splitBank.includes(b?.name)) {
        bankArray.push(b?.id?.toString());
      }
    })

    branchList?.map(b => {
      if (splitBranch.includes(b?.name)) {
        branchArray.push(b?.id?.toString());
      }
    })

    users?.map(u => {
      if (splitBankUser.includes(u?.login)) {
        bankUserArray.push(u?.login?.toString());
      }
    })

    commodityList?.map(c => {
      if (splitCommodity.includes(c?.name)) {
        const id = c?.id?.toString();
        commodityArray.push(id);
        // eslint-disable-next-line no-console
        console.log("Added Commodity ID:", id);
      }
    })

  }, [splitBank, splitBranch, splitBankUser, splitCommodity]);



  useEffect(() => {
   //  moment.suppressDeprecationWarnings = true;
   //
   // const shouldFetchAllData = !commodityArray || (commodityArray.length === 1 && commodityArray[0] === 'All');
   //
   //      const obj = {
   //          bankId: splitBank.includes("All") ? "" : bankArray,
   //          branchId: splitBranch.includes("All") ? "" : branchArray,
   //          bankUserId: splitBankUser.includes("All") ? "" : bankUserArray,
   //          commodityId: shouldFetchAllData ? '' : (commodityArray && commodityArray.length > 0 ? commodityArray.join(',') : ''),
   //          fromDate: moment(splitDate[0]).format('yyyy/MM/DD'),
   //          toDate: moment(splitDate[1]).add(1, 'day').format('yyyy/MM/DD'),
   //          status: splitStatus.includes("All") ? [] : splitStatus,
   //      };
   //
   //      // eslint-disable-next-line no-console
   //      console.log('splitCommodity:', splitCommodity);
   //      // eslint-disable-next-line no-console
   //      console.log('splitCommodityID:', obj.commodityId);
   //      // eslint-disable-next-line no-console
   //      console.log('ArrayCommodity:', commodityArray);
   //
   //      // if (localStorage.getItem('currency') === 'KWD') {
   //      //     dispatch(getReportsPrintInKWD({
   //      //         ...obj,
   //      //     }));
   //      // } else {
   //      //     dispatch(getReportsPrint({
   //      //         ...obj,
   //      //     }));
   //      // }
   //  dispatch(getReportsPrint({
   //    ...obj,
   //  }));

    const filterBank = JSON.parse(localStorage.getItem('filterBank') || '[]');
    const filterBranch = JSON.parse(localStorage.getItem('filterBranch') || '[]');
    const filterUser = JSON.parse(localStorage.getItem('filterUser') || '[]');
    const filterCommodity = JSON.parse(localStorage.getItem('filterCommodity') || '[]');
    const filterFromDate = localStorage.getItem('filterFromDate');
    const filterToDate = localStorage.getItem('filterToDate');

    const obj = {
      bankId: filterBank.length ? filterBank.map(item => item.value.toString()) : "",
      branchId: filterBranch.length ? filterBranch.map(item => item.value.toString()) : "",
      bankUserId: filterUser.length ? filterUser.map(item => item.label) : "",
      commodityId: filterCommodity.length ? filterCommodity.map(item => item.value.toString()) : "",
      fromDate: filterFromDate,
      toDate: moment(filterToDate).add(1, 'day').format('yyyy/MM/DD'),
      status: splitStatus.includes("All") ? [] : splitStatus
    };

    dispatch(getReportsPrint(obj));
}, []);


  // useEffect(() => {
  //   setReportsPrint(JSON.parse(localStorage.getItem('printQuo')));
  //   setStaffList(JSON.parse(localStorage.getItem('staff')));
  // }, []);

  useEffect(() => {
    const langLocal= localStorage.getItem('locale');
    const langSession= sessionStorage.getItem('locale');
    if(langLocal!==langSession){
      sessionStorage.setItem("locale",langLocal);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      const input = document.getElementById('divToPrint').innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = input;
      window.print();
    }, 10000);
    return () => clearTimeout(timer);
  },[]);

  const [checkboxState, setCheckboxState] = React.useState({
    quotationChecked: (Storage.local.get('tableView')) ? Storage.local.get('tableView').quotationChecked : true,
    poChecked: (Storage.local.get('tableView')) ? Storage.local.get('tableView').poChecked : true,
    dateChecked: (Storage.local.get('tableView')) ? Storage.local.get('tableView').dateChecked : true,
    customerChecked: (Storage.local.get('tableView')) ? Storage.local.get('tableView').customerChecked : true,
    commodityChecked: (Storage.local.get('tableView')) ? Storage.local.get('tableView').commodityChecked : true,
    quantityChecked: (Storage.local.get('tableView')) ? Storage.local.get('tableView').quantityChecked : true,
    amountChecked: (Storage.local.get('tableView')) ? Storage.local.get('tableView').amountChecked : true,
    feeChecked: (Storage.local.get('tableView')) ? Storage.local.get('tableView').feeChecked : true,
    feeCurrencyChecked: (Storage.local.get('tableView')) ? Storage.local.get('tableView').feeCurrencyChecked : true,
    totalValueChecked: (Storage.local.get('tableView')) ? Storage.local.get('tableView').totalValueChecked : true,
    approverChecked: (Storage.local.get('tableView')) ? Storage.local.get('tableView').approverChecked : true,
    statusChecked: (Storage.local.get('tableView')) ? Storage.local.get('tableView').statusChecked : true,
    currencyChecked: (Storage.local.get('tableView')) ? Storage.local.get('tableView').currencyChecked : true,
    totalAmountChecked: (Storage.local.get('tableView')) ? Storage.local.get('tableView').totalAmountChecked : true,
  });

  const {
    quotationChecked,
    poChecked,
    dateChecked,
    customerChecked,
    commodityChecked,
    quantityChecked,
    amountChecked,
    feeChecked,
    feeCurrencyChecked,
    totalValueChecked,
    approverChecked,
    statusChecked,
    currencyChecked,
    totalAmountChecked
  } = checkboxState;

  const getStaffFullName = (createdUser) => {
    if (!createdUser || typeof createdUser !== 'object') {
      return '';
    }

    const { firstName, lastName } = createdUser;
    if (!firstName || !lastName) {
      return '';
    }

    const fullName = `${firstName} ${lastName}`;
    return fullName;
  };

  const setPageSize = () => {
    const style = document.createElement('style');
    style.innerHTML = `@page {size: a4 landscape !important;}`;
    style.id = 'page-orientation';
    document.head.appendChild(style);
  };

  useEffect(() => {
    setPageSize();
    return () => {
      const child = document.getElementById('page-orientation');
      if (child && child.parentNode) {
        child.parentNode.removeChild(child);
      }
    };
  }, []);


  return (
    <>
       <div className="App container mt-5" style={{height: '1100px'}}>
          <div id="divToPrint" ref={componentRef}>
            <div id="headerPrint" style={{margin: '24px 48px 24px 48px'}}>
              <Grid container spacing={2} style={{alignItems: 'center'}}>
                <Grid item xs={6} className="left-side-component-flex">
                  <Typography variant='h4' component='div'> <Translate contentKey= "global.menu.report-print.title" /></Typography>
                </Grid>
                <Grid item xs={6} className="right-side-component-flex">
                  <img src="../content/images/img.png" width={'120px'}/>
                </Grid>
              </Grid>
            </div>

            <Divider sx={{borderBottomWidth: 3, marginLeft: '20px', marginRight: '20px', color: '#01579b'}}/>

            <div id='quotationTablePrint' style={{marginLeft: '20px', marginRight: '20px'}}>
              <Grid container spacing={2}>
                <Grid item xs={8} style={{lineHeight: 1}}>
                  <div style={{lineHeight: 1, marginTop: '24px', marginBottom: '12px'}}>
                    <Typography variant='subtitle1'><strong><Translate contentKey= "global.menu.report-print.order" /></strong></Typography>
                    <Typography variant='subtitle1'><Translate contentKey= "global.menu.report-print.status" /> : {localStorage.getItem('status')}</Typography>
                    <Typography variant='subtitle1'><Translate contentKey= "global.menu.report-print.commodity" /> : {localStorage.getItem('commodity')}</Typography>
                    <Typography variant='subtitle1'><Translate contentKey= "global.menu.report-print.period" /> : {localStorage.getItem('period')}</Typography>
                  </div>
                </Grid>
                <Grid item xs={4} style={{lineHeight: 5}} justifyContent="flex-end">
                  <div style={{lineHeight: 1, marginTop: '24px', marginBottom: '19px', overflow: 'hidden', textOverflow: 'clip', wordWrap: 'break-word'}}>
                    <Typography variant='subtitle1'><strong><Translate contentKey= "global.menu.report-print.bankInfo" /></strong></Typography>
                    <Typography variant='subtitle1'><Translate contentKey= "global.menu.report-print.bank" /> : {Storage.local.get('bank')}</Typography>
                    <Typography variant='subtitle1'><Translate contentKey= "global.menu.report-print.branch" /> : {localStorage.getItem('branch')}</Typography>
                    <Typography variant='subtitle1'><Translate contentKey= "global.menu.report-print.bankUser" /> : {localStorage.getItem('userName')}</Typography>
                  </div>
                </Grid>
              </Grid>
              <TableContainer>
                {/* <Divider sx={{ borderBottomWidth: 3, color: '#01579b'}} /> */}
                <StyledTable>
                  <TableHead>
                  {/*  <Grid justifyContent="centre">*/}
                  {/* <div style={{lineHeight: 1, marginTop: '24px', marginBottom: '12px'}}>*/}
                    <TableRow>
                      <StyledTableCell hidden={!poChecked}>{translate('home.table.poNo')}</StyledTableCell>
                      <StyledTableCell hidden={!customerChecked}>{translate('home.table.customerName')}</StyledTableCell>
                      <StyledTableCell hidden={!customerChecked}>{translate("home.table.customerIC")}</StyledTableCell>
                      <StyledTableCell hidden={!customerChecked}>{translate("home.table.buy")}</StyledTableCell>
                      <StyledTableCell hidden={!customerChecked}>{translate("home.table.transfer")}</StyledTableCell>
                      <StyledTableCell hidden={!customerChecked}>{translate("home.table.sell")}</StyledTableCell>
                      <StyledTableCell hidden={!customerChecked}>{translate("home.table.bankName")}</StyledTableCell>
                      <StyledTableCell hidden={!customerChecked}>{translate("home.table.branchName")}</StyledTableCell>
                      <StyledTableCell hidden={!customerChecked}>{translate("home.table.bankStaffName")}</StyledTableCell>
                      <StyledTableCell hidden={!commodityChecked}>{translate('home.table.commodity')}</StyledTableCell>
                      <StyledTableCell hidden={!currencyChecked}>{translate('home.table.currency')}</StyledTableCell>
                      <StyledTableCell hidden={!quantityChecked}>{translate('home.table.quantity')}</StyledTableCell>
                      <StyledTableCell hidden={!amountChecked}>{translate('home.table.amountDefault')}</StyledTableCell>
                      <StyledTableCell hidden={!feeChecked}>{translate('home.table.feeDefault')}</StyledTableCell>
                      <StyledTableCell hidden={!feeCurrencyChecked}>{translate('home.table.feeCurrency')}</StyledTableCell>
                      <StyledTableCell hidden>{translate('home.table.total')}</StyledTableCell>
                    </TableRow>
                    {/* </div>*/}
                    {/* </Grid>*/}
                  </TableHead>

                  <TableBody>
                    { reportsPrint?.map((q, index) => (
                      <TableRow key={index}>
                        <StyledTableCell
                          hidden={!poChecked}>{q?.bursaAPIBidCertId !== null ? q?.bursaAPIBidCert?.ecertNo : '-'}</StyledTableCell>
                        <StyledTableCell hidden={!customerChecked}>{q?.customer?.name}</StyledTableCell>
                        <StyledTableCell hidden={!customerChecked}>{q?.customer?.ic}</StyledTableCell>
                        <StyledTableCell>{ (q?.bursaAPIBidCertId !== null) ? moment(q?.bursaAPIBidCert?.purchaseTimeDate).format('yyyy/MM/DD HH:mm:ss.SSS') : '-'}</StyledTableCell>
                        <StyledTableCell>{ (q?.bursaAPIOtcCertId !== null) ?  moment( q?.bursaAPIOtcCert?.reportingTimeDate).format('yyyy/MM/DD HH:mm:ss.SSS') : '-'}</StyledTableCell>
                        <StyledTableCell>{ (q?.bursaAPIStbCertId !== null) ?   moment( q?.bursaAPIStbCert?.sellingTimeDate).format('yyyy/MM/DD HH:mm:ss.SSS') : '-'}</StyledTableCell>
                        <StyledTableCell hidden={!customerChecked}>{q?.bank?.name}</StyledTableCell>
                        <StyledTableCell hidden={!customerChecked}>{q?.branch?.name} {q?.branch?.createdDate ? moment(q?.branch?.createdDate).format('yyyy/MM/DD') : ' '}
                        </StyledTableCell>
                        <StyledTableCell hidden={!customerChecked}>{getStaffFullName(q?.createdUser)}</StyledTableCell>
                        <StyledTableCell hidden={!commodityChecked}>{q?.commodity?.name}</StyledTableCell>
                        <StyledTableCell hidden={!currencyChecked}>{q?.buyCurrency}</StyledTableCell>
                        <StyledTableCell hidden={!quantityChecked}>{q?.commodityQuantity?.toLocaleString()}</StyledTableCell>
                        <StyledTableCell hidden={!amountChecked}>{q?.amount?.toLocaleString()}</StyledTableCell>
                        <StyledTableCell hidden={!feeChecked}>{((q?.fee || 0) + (q?.commission || 0)).toLocaleString()}</StyledTableCell>
                        <StyledTableCell hidden={!feeCurrencyChecked}>{q?.feeCurrencyIndex}</StyledTableCell>
                        <StyledTableCell hidden>{q?.totalAmount?.toLocaleString()}</StyledTableCell>
                      </TableRow>
                    ))}
                    {/* <TableRow sx={{borderBottom: 0}}>*/}
                    {/*  <TableCell sx={{borderBottom: '0'}} colSpan={12}/>*/}
                    {/*  <StyledTableCell sx={{backgroundColor: '#1569C7'}}><strong>{translate('home.table.total')}</strong></StyledTableCell>*/}
                    {/*  <StyledTableCell sx={{backgroundColor: '#1569C7'}}>{totalAmount === 'null' ? '0' : Number(totalAmount).toLocaleString()}</StyledTableCell>*/}
                    {/* </TableRow>*/}
                  </TableBody>
                </StyledTable>
              </TableContainer>
            </div>
            <div id='quotationTablePrint' style={{margin: '48px'}}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div style={{lineHeight: 1, marginBottom: '19px'}}>
                    <Typography variant='subtitle1'><Translate contentKey= "global.menu.report-print.summary" /></Typography>
                    <hr/>
                  </div>
                  <div style={{lineHeight: 1, marginBottom: '12px'}}>
                    <Typography variant='subtitle1'><Translate contentKey= "global.menu.report-print.comment" /></Typography>
                    <hr/>
                  </div>
                </Grid>
              </Grid>
            </div>

            <Typography variant='subtitle1' style={{margin: '48px'}}> <Translate contentKey= "global.menu.report-print.signature" /> : _________________________</Typography>

            <Divider sx={{borderBottomWidth: 3, marginLeft: '48px', marginRight: '48px', color: '#01579b'}}/>

            <div id="informationPrint" style={{margin: '24px 48px 24px 48px'}}>
              <Grid container>
                <Grid item xs={4} style={{lineHeight: 1, display: 'inline-flex', justifyContent: 'center'}}>
                  <div>
                    {/* <Typography variant='body1' ><strong>Bank</strong></Typography> */}
                  </div>
                </Grid>
                <Grid item xs={4} style={{lineHeight: 1, display: 'inline-flex', justifyContent: 'center'}}>
                  <div>
                    {/* <Typography variant='body1' ><strong>Alsmokh</strong></Typography> */}
                  </div>
                </Grid>
                <Grid item xs={4} style={{lineHeight: 1, display: 'inline-flex', justifyContent: 'center'}}>
                  <div>
                    {/* <Typography variant='body1' ><strong>Created By</strong></Typography> */}
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>

    </>
    );
}

export default ReportPrintTemplate;
