import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PrivateRoute from 'app/shared/auth/private-route';

import Bank from './bank';
import BankDetail from './bank-detail';
import BankUpdate from './bank-update';
import BankDeleteDialog from './bank-delete-dialog';
import { AUTHORITIES } from 'app/config/constants';
import ComexBankFeeDeleteDialog from "app/entities/comex-bank-fee/comex-bank-fee-delete-dialog";

const Routes = ({ match }) => (
  <>
    <Switch>
      <PrivateRoute
        exact path={`${match.url}/new`}
        component={BankUpdate}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.ALSHMOKH_ADMIN,
          AUTHORITIES.SUBADMIN_BANK,
        ]}
      />
      <PrivateRoute
        exact path={`${match.url}/:id/edit`}
        component={BankUpdate}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.ALSHMOKH_ADMIN,
          AUTHORITIES.SUBADMIN_BANK,
        ]}
      />
      <PrivateRoute
        exact path={`${match.url}/:id`}
        component={BankDetail}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.ALSHMOKH_ADMIN,
          AUTHORITIES.SUBADMIN_BANK,
          AUTHORITIES.SUBADMIN_BRANCH,
        ]}
      />
      <PrivateRoute
        path={match.url}
        component={Bank}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.ALSHMOKH_ADMIN,
          AUTHORITIES.SUBADMIN_BANK,
          AUTHORITIES.SUBADMIN_BRANCH,
        ]}
      />
    </Switch>
    <PrivateRoute
      exact path={`${match.url}/:id/delete`}
      component={BankDeleteDialog}
      hasAnyAuthorities={[
        AUTHORITIES.ADMIN,
        AUTHORITIES.ALSHMOKH_ADMIN,
        AUTHORITIES.SUBADMIN_BANK,
      ]}
    />
    <PrivateRoute exact path={`${match.url}/:id/deleteFee`} component={ComexBankFeeDeleteDialog} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
  </>
);

export default Routes;
