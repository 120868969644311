import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import {
  IQueryParams,
  createEntitySlice,
  EntityState,
  serializeAxiosError,
  IReportQueryParam,
  ISummaryQueryParam
} from 'app/shared/reducers/reducer.utils';
import {IQuotation, defaultValue} from 'app/shared/model/quotation.model';
import { IEachTotalSales } from 'app/shared/model/each-total-sales.model';

const initialState: EntityState<IQuotation> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  valueSold:[],
  branchPerformance:[],
  valueSoldLoadingSuccess:false,
  quotationCreatedCount:0,
  quotationSoldCount:0,
  quotationPendingTransferCount:0,
  quotationPurchaseOrderCount:0,
  quotationKeepCount:0,
  quotationPOApprovalToday:0,
  quotationcancellationApprovalToday:0,
  quotationKeepApprovalToday:0,
  eachTotalSales:[],
  totalSoldToday:0,
  reportPrint:[],
  updateCancellationDecisionFromBursa:0,
  keepOrSellOrdersTodayForCustomer:0,
};

const apiUrl = 'api/quotations';
const bankStaffApiUrl = 'api/quotations/bankStaff'
const branchManagerApiURL = 'api/quotations/branchManager'
const bankManagerApiURL = 'api/quotations/bankManager'
const areaManagerApiURL = 'api/quotations/areaManager'
const auditorApiURL = 'api/quotations/auditor'
const customerApiURL = 'api/quotations/customer'

// Actions


export const getEntities = createAsyncThunk('quotation/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IQuotation[]>(requestUrl);
});

export const getEntitiesWithoutPending = createAsyncThunk('quotation/fetch_entity_list_without_pending', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IQuotation[]>(requestUrl);
});

export const getEntitiesByQuotationTab = createAsyncThunk('quotation/fetch_entity_list', async ({ page, size, sort, quotationTab, curDate }: IQueryParams) => {
  const requestUrl = `${apiUrl}/quotation${sort ? `?page=${page}&size=${size}&sort=${sort}&quotationTab=${quotationTab}&curDate=${curDate}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IQuotation[]>(requestUrl);
});

export const getEntitiesByPurchaseOrderTab = createAsyncThunk('quotation/fetch_entity_list', async ({ page, size, sort, poTab }: IQueryParams) => {
  const requestUrl = `${apiUrl}/purchaseOrder${sort ? `?page=${page}&size=${size}&sort=${sort}&poTab=${poTab}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IQuotation[]>(requestUrl);
});

export const getEntitiesFilter = createAsyncThunk('quotation/fetch_entity_list_by_admin', async ({ page, size, sort, search, date, status, tab, curDate , bankId,branchId,customerId,commodityId,searchQuo}: IQueryParams) => {
  const requestUrl = `${apiUrl}/filter${sort ? `?page=${page}&size=${size}&sort=${sort}&date=${date}&status=${status}&search=${search}&searchQuo=${searchQuo}&tab=${tab}&curDate=${curDate}&bankId=${bankId}&branchId=${branchId}&customerId=${customerId}&commodityId=${commodityId}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IQuotation[]>(requestUrl);
});


export const getEntitiesForCustomerReport = createAsyncThunk(
  'quotation/fetch_customer_asc',
  async ({bankId, branchId, bankUserId, commodityId, fromDate, toDate}:IReportQueryParam) => {
    const api = "api/quotations/getForCustomerReport";
    const requestUrl = `${api}${`?bank=${bankId}&branch=${branchId}&bankUser=${bankUserId}&commodity=${commodityId}&fromDate=${fromDate}&toDate=${toDate}&`}`;
    return axios.get<IQuotation[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntitiesByBankStaff = createAsyncThunk('quotation/fetch_entity_list', async ({ page, size, sort, createdBy, search, date, status,
 tab,searchQuo, quoMinDate,curDate}: IQueryParams) => {
  const requestUrl = `${bankStaffApiUrl}/${createdBy}${sort ? `?page=${page}&size=${size}&sort=${sort}&date=${date}&status=${status}&search=${search}&searchQuo=${searchQuo}&tab=${tab}&quoMinDate=${quoMinDate}&curDate=${curDate}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IQuotation[]>(requestUrl);
});

export const getEntitiesByBranchId = createAsyncThunk(
  'quotation/fetch_entities/byBranchId',
  async ({ page, size, sort, branchId, date, status, search,searchQuo, tab, curDate }: IQueryParams) => {
    const requestUrl = `${branchManagerApiURL}/${branchId}${sort ? `?page=${page}&size=${size}&sort=${sort}&date=${date}&status=${status}&search=${search}&searchQuo=${searchQuo}&tab=${tab}&curDate=${curDate}&` : '?'}`;
    return axios.get<IQuotation[]>(requestUrl);
  }, { serializeError: serializeAxiosError }
);

export const getEntitesByCreatedUser = createAsyncThunk(
  'quotation/fetch_entities/createdBankStaff',
  async (createdBy: string) => {
    const requestUrl = `${apiUrl}/createdBy/${createdBy}`;
    return axios.get<IQuotation[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntitiesByBankId = createAsyncThunk(
  'quotation/fetch_entities/byBankManagerId',
  async ({ page, size, sort, bankManagerId, date, status, search,searchQuo, tab, curDate }: IQueryParams) => {
    const requestUrl = `${bankManagerApiURL}/${bankManagerId}${sort ? `?page=${page}&size=${size}&sort=${sort}&date=${date}&status=${status}&search=${search}&searchQuo=${searchQuo}&tab=${tab}&curDate=${curDate}&` : '?'}`;
    return axios.get<IQuotation[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntitiesByAuditor = createAsyncThunk(
  'quotation/fetch_entities/byAuditor',
  async ({ page, size, sort, bankId, date, status, search,searchQuo, tab, curDate }: IQueryParams) => {
    const requestUrl = `${auditorApiURL}/${bankId}${sort ? `?page=${page}&size=${size}&sort=${sort}&date=${date}&status=${status}&search=${search}&searchQuo=${searchQuo}&tab=${tab}&curDate=${curDate}&` : '?'}`;
    return axios.get<IQuotation[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntitiesByAreaManagerId = createAsyncThunk(
  'quotation/fetch_entities/byAreaManagerId',
  async ({ page, size, sort, areaManagerId, date, status, search,searchQuo, tab, curDate }: IQueryParams) => {
    const requestUrl = `${areaManagerApiURL}/${areaManagerId}${sort ? `?page=${page}&size=${size}&sort=${sort}&date=${date}&status=${status}&search=${search}&searchQuo=${searchQuo}&tab=${tab}&curDate=${curDate}&` : '?'}`;
    return axios.get<IQuotation[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntitiesByCustomerLogin = createAsyncThunk(
  'quotation/fetch_entities/byCustomerName',
  async ({ page, size, sort, customerLogin, date, status, search,searchQuo, tab, curDate }: IQueryParams) => {
    const requestUrl = `${customerApiURL}/${customerLogin}${sort ? `?page=${page}&size=${size}&sort=${sort}&date=${date}&status=${status}&search=${search}&searchQuo=${searchQuo}&tab=${tab}&curDate=${curDate}&` : '?'}`;
    return axios.get<IQuotation[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
)
export const getValueSold = createAsyncThunk(
  'quotation/fetch_entities/valueSold',
  async ({bankId, branchId, bankUserId, commodityId, fromDate, toDate}:IReportQueryParam) => {
    const api = "api/quotations/valueSold";
    const requestUrl=`${api}${`?bank=${bankId}&branch=${branchId}&bankUser=${bankUserId}&commodity=${commodityId}&fromDate=${fromDate}&toDate=${toDate}&`}`;
    return axios.get<IQuotation[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
)

export const getValueSoldInKWD = createAsyncThunk(
  'quotation/fetch_entities/valueSoldInKWD',
  async ({bankId, branchId, bankUserId, commodityId, fromDate, toDate}:IReportQueryParam) => {
    const api = "api/quotations/valueSoldInKWD";
    const requestUrl=`${api}${`?bank=${bankId}&branch=${branchId}&bankUser=${bankUserId}&commodity=${commodityId}&fromDate=${fromDate}&toDate=${toDate}&`}`;
    return axios.get<IQuotation[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
)

export const getTotalAmountForReportTable = createAsyncThunk(
  'quotation/fetch-totalAmount',
  async ({bankId, branchId, bankUserId, commodityId, fromDate, toDate,status,size,page}:IReportQueryParam) => {
    const api = "api/quotations/report/table-totalAmount";
    const requestUrl=`${api}${`?page=${page}&size=${size}&bank=${bankId}&branch=${branchId}&bankUser=${bankUserId}&commodity=${commodityId}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&`}`;
    return axios.get<IQuotation>(requestUrl);
  },
  { serializeError: serializeAxiosError },
)

export const getTotalAmountForReportTableInKWD = createAsyncThunk(
  'quotation/fetch-totalAmountInKWD',
  async ({bankId, branchId, bankUserId, commodityId, fromDate, toDate,status,size,page}:IReportQueryParam) => {
    const api = "api/quotations/report/table-totalAmountInKWD";
    const requestUrl=`${api}${`?page=${page}&size=${size}&bank=${bankId}&branch=${branchId}&bankUser=${bankUserId}&commodity=${commodityId}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&`}`;
    return axios.get<IQuotation>(requestUrl);
  },
  { serializeError: serializeAxiosError },
)

export const getBankStaffPerformance = createAsyncThunk(
  'quotation/fetch_entities/performance',
  async ({bankId, branchId, bankUserId, commodityId, fromDate, toDate}:IReportQueryParam) => {
    const api = "api/quotations/performance";
    const requestUrl=`${api}${`?bank=${bankId}&branch=${branchId}&bankUser=${bankUserId}&commodity=${commodityId}&fromDate=${fromDate}&toDate=${toDate}&`}`;
    return axios.get<IQuotation[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
)

export const getBranchPerformance = createAsyncThunk(
  'quotation/fetch_entities/branchPerformance',
  async ({bankId, branchId, bankUserId, commodityId, fromDate, toDate,selectOption}:IReportQueryParam) => {
    const api = "api/quotations/branch/performance";
    const requestUrl=`${api}${`?bank=${bankId}&branch=${branchId}&bankUser=${bankUserId}&commodity=${commodityId}&fromDate=${fromDate}&toDate=${toDate}&selectOption=${selectOption}`}`;
    return axios.get<IQuotation[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
)

export const getBranchPerformanceInKWD = createAsyncThunk(
  'quotation/fetch_entities/branchPerformanceInKWD',
  async ({bankId, branchId, bankUserId, commodityId, fromDate, toDate,selectOption}:IReportQueryParam) => {
    const api = "api/quotations/branch/performanceInKWD";
    const requestUrl=`${api}${`?bank=${bankId}&branch=${branchId}&bankUser=${bankUserId}&commodity=${commodityId}&fromDate=${fromDate}&toDate=${toDate}&selectOption=${selectOption}`}`;
    return axios.get<IQuotation[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
)

export const getReports = createAsyncThunk(
  'quotation/fetch_entities/reports',
  async ({bankId, branchId, bankUserId, commodityId, fromDate, toDate,size,page,status}:IReportQueryParam) => {
    const api = "api/quotations/reports";
    const requestUrl=`${api}${`?page=${page}&size=${size}&bank=${bankId}&branch=${branchId}&bankUser=${bankUserId}&commodity=${commodityId}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&`}`;
    return axios.get<IQuotation[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
)

export const getReportsInKWD = createAsyncThunk(
  'quotation/fetch_entities/reportsInKWD',
  async ({bankId, branchId, bankUserId, commodityId, fromDate, toDate,size,page,status}:IReportQueryParam) => {
    const api = "api/quotations/reportsInKWD";
    const requestUrl=`${api}${`?page=${page}&size=${size}&bank=${bankId}&branch=${branchId}&bankUser=${bankUserId}&commodity=${commodityId}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&`}`;
    return axios.get<IQuotation[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
)

export const getReportsPrint = createAsyncThunk(
  'quotation/fetch_entities/reports-print',
  async ({bankId, branchId, bankUserId, commodityId, fromDate, toDate,status}:IReportQueryParam) => {
    const api = "api/quotations/reports/print";
    const requestUrl=`${api}${`?bank=${bankId}&branch=${branchId}&bankUser=${bankUserId}&commodity=${commodityId}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&`}`;
    return axios.get<IQuotation[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
)

export const getReportsPrintInKWD = createAsyncThunk(
  'quotation/fetch_entities/reports-printInKWD',
  async ({bankId, branchId, bankUserId, commodityId, fromDate, toDate,status}:IReportQueryParam) => {
    const api = "api/quotations/reports/printInKWD";
    const requestUrl=`${api}${`?bank=${bankId}&branch=${branchId}&bankUser=${bankUserId}&commodity=${commodityId}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&`}`;
    return axios.get<IQuotation[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
)

export const getFeeReports = createAsyncThunk(
  'quotation/fetch_entities/fee-reports',
  async ({bankId, branchId, bankUserId, fromDate, toDate, size, page, status}:IReportQueryParam) => {
    const api = "api/quotations/feeReports";
    const requestUrl=`${api}${`?page=${page}&size=${size}&bank=${bankId}&branch=${branchId}&bankUser=${bankUserId}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&`}`;
    return axios.get<IQuotation[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
)

export const getFeeReportsPrint = createAsyncThunk(
  'quotation/fetch_entities/fee-reports-print',
  async ({bankId, branchId, bankUserId, fromDate, toDate, status}:IReportQueryParam) => {
    const api = "api/quotations/feeReports/print";
    const requestUrl=`${api}${`?bank=${bankId}&branch=${branchId}&bankUser=${bankUserId}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&`}`;
    return axios.get<IQuotation[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
)

export const getEntity = createAsyncThunk(
  'quotation/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IQuotation>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntityWithoutPending = createAsyncThunk(
  'quotation/fetch_entity_without_pending',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IQuotation>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getQuotationBybursaAPIBidCertId = createAsyncThunk(
  'quotation/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/getQuotationBybursaAPIBidCertId/${id}`;
    return axios.get<IQuotation>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);
export const getQuotationBybursaAPIOtcCertId = createAsyncThunk(
  'quotation/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/getQuotationBybursaAPIOtcCertId/${id}`;
    return axios.get<IQuotation>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);
export const getQuotationBybursaAPIStbCertId = createAsyncThunk(
  'quotation/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/getQuotationBybursaAPIStbCertId/${id}`;
    return axios.get<IQuotation>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'quotation/create_entity',
  async (entity: IQuotation, thunkAPI) => {
    const result = await axios.post<IQuotation>(apiUrl, cleanEntity(entity));

    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'quotation/update_entity',
  async (entity: IQuotation) => {
    const result = await axios.put<IQuotation>(`${apiUrl}/${entity.id}`, cleanEntity(entity));

    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'quotation/partial_update_entity',
  async (entity: IQuotation, thunkAPI) => {
    const result = await axios.patch<IQuotation>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'quotation/delete_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IQuotation>(requestUrl);

    return result;
  },
  { serializeError: serializeAxiosError }
);

// Quotation Sold Count  in home page
export const getQuotationSoldCount = createAsyncThunk(
  'quotation/fetch_sold_entity',
  async ({id ,userRole} : ISummaryQueryParam ) => {
    const requestUrl = `${apiUrl}/getQuotationSoldCount${`?id=${id}&userRole=${userRole}`}`;
    return axios.get<string>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// Quotation Created Count  in home page
export const getQuotationCreatedCount = createAsyncThunk(
  'quotation/fetch_created_entity',
  async ({id ,userRole} : ISummaryQueryParam ) => {
    const requestUrl = `${apiUrl}/getQuotationCreatedCount${`?id=${id}&userRole=${userRole}`}`;
    return axios.get<number>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// Quotation Pending Transfer Count for bankstaff in home page
export const getQuotationPendingTransferCount = createAsyncThunk(
  'quotation/fetch_pending_transfer_entity',
  async (id: string ) => {
    const requestUrl = `${apiUrl}/getQuotationPendingTransferCount/${id}`;
    return axios.get<number>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// Quotation PurchaseOrder Count in home page
export const getQuotationPurchaseOrderCount = createAsyncThunk(
  'quotation/fetch_purchase_entity',
  async ({id ,userRole} : ISummaryQueryParam ) => {
    const requestUrl = `${apiUrl}/getQuotationPurchaseOrderCount${`?id=${id}&userRole=${userRole}`}`;
    return axios.get<number>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// Quotation Keep Count  in home page
export const getQuotationKeepCount = createAsyncThunk(
  'quotation/fetch_keep_entity',
  async ({id ,userRole} : ISummaryQueryParam  ) => {
    const requestUrl = `${apiUrl}/getQuotationKeepCount${`?id=${id}&userRole=${userRole}`}`;
    return axios.get<number>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// Quotation sold Count  in home page
export const getTotalSoldToday = createAsyncThunk(
  'quotation/fetch_total_entity',
  async ({id ,userRole} : ISummaryQueryParam) => {
    const requestUrl = `${apiUrl}/getTotalSoldToday${`?id=${id}&userRole=${userRole}`}`;
    return axios.get<number>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);


// Quotation PurchaseOrderApproval Count for branchManager in home page
export const getQuotationPurchaseOrderApprovalCount = createAsyncThunk(
  'quotation/fetch_purchaseorderapproval_entity',
  async ({id ,userRole} : ISummaryQueryParam ) => {
    const requestUrl = `${apiUrl}/getPurchaseOrderApprovalToday${`?id=${id}&userRole=${userRole}`}`;
    return axios.get<number>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// Quotation KeepApproval Count for branchManager in home page
export const getQuotationKeepApprovalCount = createAsyncThunk(
  'quotation/fetch_keep_approval_entity',
  async ({id ,userRole} : ISummaryQueryParam ) => {
    const requestUrl = `${apiUrl}/getKeepRequestApprovalToday${`?id=${id}&userRole=${userRole}`}`;
    return axios.get<number>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// Quotation CancellationApproval Count for branchManager in home page
export const getQuotationCancellationApprovalCount = createAsyncThunk(
  'quotation/fetch_cancellation_approval_entity',
  async ({id ,userRole} : ISummaryQueryParam ) => {
    const requestUrl = `${apiUrl}/getCancellationApprovalToday${`?id=${id}&userRole=${userRole}`}`;
    return axios.get<number>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// Quotation EachTotalSales Count  in home page
export const getEachTotalSalesToday = createAsyncThunk(
  'quotation/fetch_each_totalsales_entity',
  async ({id ,userRole} : ISummaryQueryParam ) => {
    const requestUrl = `${apiUrl}/getEachTotalSalesToday${`?id=${id}&userRole=${userRole}`}`
    return axios.get<IEachTotalSales[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);


// Quotation KeepOrSellOrders Count for Customer in home page
export const getKeepOrSellOrdersTodayForCustomer = createAsyncThunk(
  'quotation/fetch_keep_or_sell_orders_for_customer_entity',
  async (id:string ) => {
    const requestUrl = `${apiUrl}/findKeepOrSellOrdersTodayForCustomer/${id}`;
    return axios.get<number>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// Quotation UpdateCancellationDecision Count from Bursa in home page
export const getUpdateCancellationDecisionFromBursaTodayForAlshmokhAdmin = createAsyncThunk(
  'quotation/fetch_cancellation_decision_from_bursa_entity',
  async (id:string ) => {
    const requestUrl = `${apiUrl}/findUpdateCancellationDecisionFromBursaTodayForAlshmokhAdmin/${id}`;
    return axios.get<number>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);
// slice

export const QuotationSlice = createEntitySlice({
  name: 'quotation',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntityWithoutPending.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getTotalAmountForReportTable.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getTotalAmountForReportTableInKWD.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getEntitiesWithoutPending), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getReports, getReportsInKWD), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getReportsPrint, getReportsPrintInKWD), (state, action) => {
        return {
          ...state,
          loading: false,
          reportPrint: action.payload.data,
        };
      })
      .addMatcher(isFulfilled(getFeeReports), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getFeeReportsPrint), (state, action) => {
        return {
          ...state,
          loading: false,
          reportPrint: action.payload.data,
        };
      })
      .addMatcher(isFulfilled(getValueSold, getValueSoldInKWD),  (state, action) => {
        return {
          ...state,
          loading: false,
          valueSoldLoadingSuccess:true,
          valueSold: action.payload.data,
        };
      })
      .addMatcher(isFulfilled(getEntitiesByQuotationTab, getEntitiesByPurchaseOrderTab), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getEntitiesByBankStaff), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          // totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getEntitiesByBankId), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          // totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getEntitiesByAuditor), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          // totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getEntitiesByBranchId), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          // totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getEntitiesFilter), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          // totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getEntitiesByAreaManagerId), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          // totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getEntitesByCreatedUser), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getEntitiesByCustomerLogin), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          // totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getKeepOrSellOrdersTodayForCustomer), (state, action) => {
        state.keepOrSellOrdersTodayForCustomer = action.payload.data;
      })
      .addMatcher(isFulfilled(getUpdateCancellationDecisionFromBursaTodayForAlshmokhAdmin), (state, action) => {
        state.updateCancellationDecisionFromBursa = action.payload.data;
      })
      .addMatcher(isFulfilled(getEachTotalSalesToday), (state, action) => {
        state.eachTotalSales = action.payload.data;
      })
      .addMatcher(isFulfilled(getQuotationCreatedCount), (state, action) => {
        state.quotationCreatedCount = action.payload.data;
      })
      .addMatcher(isFulfilled(getQuotationPurchaseOrderCount), (state, action) => {
        state.quotationPurchaseOrderCount = action.payload.data;
      })
      .addMatcher(isFulfilled(getQuotationPendingTransferCount), (state, action) => {
        state.quotationPendingTransferCount = action.payload.data;
      })
      .addMatcher(isFulfilled(getQuotationSoldCount), (state, action) => {
        state.quotationSoldCount = parseFloat(action.payload.data);
      })
      .addMatcher(isFulfilled(getQuotationKeepCount), (state, action) => {
        state.quotationKeepCount = action.payload.data;
      })
      .addMatcher(isFulfilled(getQuotationPurchaseOrderApprovalCount), (state, action) => {
          state.quotationPOApprovalToday = action.payload.data;
      })
      .addMatcher(isFulfilled(getQuotationKeepApprovalCount), (state, action) => {
          state.quotationKeepApprovalToday= action.payload.data;
      })
      .addMatcher(isFulfilled(getQuotationCancellationApprovalCount), (state, action) => {
          state.quotationcancellationApprovalToday = action.payload.data;
      })
      .addMatcher(isFulfilled(getTotalSoldToday), (state, action) => {
        state.totalSoldToday = action.payload.data;
      })
      .addMatcher(isFulfilled(getEntitiesForCustomerReport), (state, action) => {

        state.totalCustomer = action.payload.data;
      })
      .addMatcher(isFulfilled(getBankStaffPerformance), (state, action) => {
        state.performance = action.payload.data;
      })

      .addMatcher(isFulfilled(getQuotationBybursaAPIBidCertId), (state, action) => {
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(getQuotationBybursaAPIOtcCertId), (state, action) => {
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(getQuotationBybursaAPIStbCertId), (state, action) => {
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(getBranchPerformance, getBranchPerformanceInKWD), (state, action) => {
        state.branchPerformance = action.payload.data;
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntitiesByQuotationTab, getEntitiesByPurchaseOrderTab,getEntitiesForCustomerReport,
         getEntity,getEntitiesFilter,getValueSold, getValueSoldInKWD, getBankStaffPerformance, getBranchPerformance, getBranchPerformanceInKWD, getQuotationBybursaAPIBidCertId,
         getQuotationBybursaAPIOtcCertId,getQuotationBybursaAPIStbCertId,getQuotationCreatedCount,getQuotationKeepCount,getQuotationPendingTransferCount,
         getQuotationPurchaseOrderCount,getQuotationSoldCount,getQuotationPurchaseOrderApprovalCount,getQuotationKeepApprovalCount,getQuotationCancellationApprovalCount,
         getEachTotalSalesToday, getTotalAmountForReportTable, getTotalAmountForReportTableInKWD, getUpdateCancellationDecisionFromBursaTodayForAlshmokhAdmin,getKeepOrSellOrdersTodayForCustomer), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(getEntityWithoutPending), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
        state.entity = null;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});


export const { reset } = QuotationSlice.actions;

// Reducer
export default QuotationSlice.reducer;
