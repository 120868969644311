import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { bindActionCreators } from 'redux';

import getStore from './config/store';
import { registerLocale } from './config/translation';
import setupAxiosInterceptors from './config/axios-interceptor';
import { clearAuthentication } from './shared/reducers/authentication';
import ErrorBoundary from './shared/error/error-boundary';
import AppComponent from './app';
import { loadIcons } from './config/icon-loader';
import {BrowserRouter, Route, Router, Switch} from "react-router-dom";
import PageNotFound from "app/shared/error/page-not-found";

const store = getStore();
registerLocale(store);

const actions = bindActionCreators({ clearAuthentication }, store.dispatch);
setupAxiosInterceptors(() => actions.clearAuthentication('login.error.unauthorized'));

loadIcons();

const rootEl = document.getElementById('root');
const render = Component =>
  // eslint-disable-next-line react/no-render-return-value
  ReactDOM.render(
    <BrowserRouter>
      <ErrorBoundary>
        <Provider store={store}>
          <div>
            <Component />
          </div>
        </Provider>
      </ErrorBoundary>
    </BrowserRouter>,
    rootEl
  );

render(AppComponent);
