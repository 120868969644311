export interface IBursaAPIOtcCert {
  id?: number;
  endpointURL?: string;
  ecertNo?: string;
  memberShortName?: string;
  successYN?: string;
  msg?: string;
  seller?: string;
  buyer?: string;
  totalValue?: string;
  currency?: string;
  price?: number;
  priceMYR?: number;
  murabahValue?: string;
  reportingTimeDate?: string;
  valueDate?: string;
  pName?: string;
  pVolume?: string;
  supplier?: string;
  volume?: string;
  uploadedCert?: string | null;
  createdDate?: string;
}

export const defaultValue: Readonly<IBursaAPIOtcCert> = {};
