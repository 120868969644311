export interface IUser {
  id?: any;
  login?: string;
  firstName?: string | " ";
  lastName?: string | " ";
  email?: string;
  activated?: boolean;
  langKey?: string;
  authorities?: any[];
  createdBy?: string;
  createdDate?: Date | null;
  lastModifiedBy?: string;
  lastModifiedDate?: Date | null;
  password?: string;
  branchId?: any;
  bankId?: string;
  phone?:string | null;
  otpSetting?:string | null;
}

export const defaultValue: Readonly<IUser> = {
  id: '',
  login: '',
  firstName: '',
  lastName: '',
  email: '',
  activated: true,
  langKey: '',
  authorities: [],
  createdBy: '',
  createdDate: null,
  lastModifiedBy: '',
  lastModifiedDate: null,
  password: '',
  phone: '',
  otpSetting: ''
};
