import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Row, Col, FormText, Form, FormGroup, Label, Input } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField, Select, MenuItem, Button } from '@mui/material';
import { getEntity, updateEntity } from './quotation.reducer';
import { IQuotation } from 'app/shared/model/quotation.model';
import { Storage } from 'react-jhipster';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import './quotation-update.css';

export const ApproveQuotationByBranchManager = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const quotationEntity = useAppSelector(state => state.quotation.entity);
  const updateSuccess = useAppSelector(state => state.quotation.updateSuccess);
  const formatTwoDigits = digit => ('0' + digit).slice(-2);
  const tempDate = new Date();
  const date = `${formatTwoDigits(tempDate.getDate())}/${formatTwoDigits(tempDate.getMonth() + 1)}/${tempDate.getFullYear()}`;
  const updatedQuotation = [] as IQuotation;
  const [disableBtn, setDisableBtn] = useState(false);
  const [isApprovePurchase, setIsApprovePurchase] = useState(false);

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
    /* use this to manually parse id for testing
        dispatch(getEntity(1));
    */
  }, []);

  //   useEffect(() => {
  //     updatedQuotation.id = quotationEntity.id;
  //     updatedQuotation.amount = quotationEntity.amount;
  //     updatedQuotation.description = quotationEntity.description;
  //     updatedQuotation.commodityUnitPrice = quotationEntity.commodityUnitPrice;
  //     updatedQuotation.commodityQuantity = quotationEntity.commodityQuantity;
  //     updatedQuotation.currencyRate = quotationEntity.currencyRate;
  //     updatedQuotation.buyCurrency = quotationEntity.buyCurrency;
  //     updatedQuotation.bankManagerApproval = true;
  //     updatedQuotation.bankCustomerApproval = false;
  //     updatedQuotation.smsLanguage = null;
  //     updatedQuotation.smsSent = null;
  //     updatedQuotation.bursaAPIBidCertId = null;
  //     updatedQuotation.bursaAPIOtcCertId = null;
  //     updatedQuotation.bursaAPIStbCertId = null;
  //     updatedQuotation.stbOption = null;
  //     updatedQuotation.comexAdminDecision = null;
  //     updatedQuotation.customer = quotationEntity.customer;
  //     updatedQuotation.commodity = quotationEntity.commodity;
  //     updatedQuotation.branch = quotationEntity.branch;
  //   }, [quotationEntity]);

  const saveEntity = () => {
    const updatedQuotationEntity = {
      ...quotationEntity,
      bankManagerApproval: true,
      bankCustomerApproval: false,
    }
    setIsApprovePurchase(true);
    dispatch(updateEntity(updatedQuotationEntity));

  }


  useEffect(() => {
    if (updateSuccess && isApprovePurchase) {
      setDisableBtn(true);
    }
  }, [updateSuccess]);


  const defaultValues = () => { };

  const handleAmendClick = () => {
    props.history.push(`/quotation/amend-by-branch-manager/${quotationEntity.id}`);

  }


  return (
    <>
      <div id="quoteform" className="round-border">
        <Row className="justify-content-center">
          <Col md="8">
            <h2>
              <Translate contentKey="comexApp.quotation.home.orderQuoted">Order Quoted</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            <br />
            <h4>
              <Translate contentKey="comexApp.quotation.orderInfoLabel">Order information</Translate>
            </h4>
            <ValidatedForm onSubmit={saveEntity}>
              <ValidatedField
                label={translate('comexApp.quotation.quotationNo')}
                id="quotation_number"
                name="quotation_number"
                data-cy="quotation_number"
                type="number"
                value={quotationEntity.id}
                disabled
                style={{
                  textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
                }}
              />

              <ValidatedField label={translate('comexApp.quotation.date')} id="date" name="date" data-cy="date" value={date} disabled />
              <ValidatedField
                label={translate('comexApp.quotation.amount')}
                id="amount"
                name="amount"
                data-cy="amount"
                type="number"
                value={quotationEntity.amount}
                disabled
                style={{
                  textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
                }}
              />

              <ValidatedField
                label="Item Name"
                id="item_name"
                name="item_name"
                data-cy="item_name"
                type="text"
                value={quotationEntity?.commodity?.name}
                disabled
              />

              <ValidatedField
                label={translate('comexApp.quotation.description')}
                id="description"
                name="description"
                data-cy="description"
                type="textarea"
                value={quotationEntity.description}
                disabled
              />
              <ValidatedField
                label={translate('comexApp.quotation.price')}
                id="price"
                name="price"
                data-cy="price"
                type="number"
                value={quotationEntity.commodityUnitPrice}
                disabled
                style={{
                  textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
                }}
              />

              <ValidatedField
                label={translate('comexApp.quotation.quantity')}
                id="quantity"
                name="quantity"
                data-cy="quantity"
                type="number"
                value={quotationEntity.commodityQuantity}
                disabled
                style={{
                  textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
                }}
              />
              <hr />
              <h4>Requested by</h4>
              <br />

              <ValidatedField
                label={translate('comexApp.quotation.customer')}
                id="cust_name"
                name="cust_name"
                data-cy="cust_name"
                type="text"
                value={quotationEntity?.customer?.name}
                disabled
              />

              <ValidatedField
                label={translate('comexApp.quotation.nationalId')}
                id="national_id"
                name="national_id"
                data-cy="national_id"
                type="text"
                value={quotationEntity?.customer?.ic}
                disabled
              />

              <ValidatedField
                label={translate('comexApp.quotation.phoneNo')}
                id="phoneno"
                name="phoneno"
                data-cy="phoneno"
                value={quotationEntity?.customer?.tel}
                disabled
              />
              <div className="btn-group">
                <Button
                  color="primary"
                  id="save-entity"
                  data-cy="entityCreateSaveButton"
                  disabled={disableBtn}
                  onClick={handleAmendClick}
                >
                  <Translate contentKey="comexApp.quotation.amendBtnLabel">Amend</Translate>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  id="save-entity"
                  data-cy="entityCreateSaveButton"
                  type="submit"
                  disabled={disableBtn}
                >
                  <Translate contentKey="comexApp.quotation.approvePurchaseBtnLabel">Approve Purchase</Translate>
                </Button>
              </div>
            </ValidatedForm>
          </Col>
        </Row>
      </div>
      <div id="cancel-btn" className="btn-group">
        <Button variant="text" onClick={() => props.history.push(`/`)} >
          <Translate contentKey="comexApp.quotation.backBtnLabel" />
        </Button>
      </div>
    </>
  );
};

export default ApproveQuotationByBranchManager;
