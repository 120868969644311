import { INotificationTemplate } from 'app/shared/model/notification-template.model';
import { IUser } from "app/shared/model/user.model";
import { ICustomer } from "app/shared/model/customer.model";
import { IPurchaseOrder } from "app/shared/model/purchase-order.model";
import { IBursaAPIBidCert } from "app/shared/model/bursa-api-bid-cert.model";

export interface INotificationRecord {
  id?: number;
  message?: string;
  status?: string;
  customerID?: number | null;
  dismissed?: boolean | null;
  userID?: number | null;
  quotationId?: number;
  notificationTemplate?: INotificationTemplate;
  notificationTemplateId?: number;
  purchaseOrder?:IPurchaseOrder;
  bursaAPIBidCert?: IBursaAPIBidCert;
  createdDate?: string;
}

export interface ISendEmail {
  user?:IUser,
  notificationTemplate?: INotificationTemplate;
}

export interface ISendEmailCustomer {
  customer?:ICustomer,
  notificationTemplate?: INotificationTemplate;
  orderId: string;
}

export interface ISendPoCancelEmail {
  user?:IUser,
  notificationTemplate?: INotificationTemplate;
  orderId: string;
  productCode: string;
  clientName: string;
  purchaseDate: string;
  bankBranchName: string;
  reason: string;
}

export const defaultValue: Readonly<INotificationRecord> = {};
