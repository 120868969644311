import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { getEntity } from './customer.reducer';
import { getEntities as getBankEntities } from "app/entities/bank/bank.reducer";
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const CustomerDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
    dispatch(getBankEntities({}));
  }, []);

  const customerEntity = useAppSelector(state => state.customer.entity);
  const bankList = useAppSelector(state => state.bank.entities);
  const getBank = bankList?.find(b => b?.id === customerEntity?.bankId);

  let responsiveFont = createTheme();
  responsiveFont = responsiveFontSizes(responsiveFont);

  return (
    <div>
      <Row className="justify-content-center">
        <Col xs="8" md="6" lg="8">
          <ThemeProvider theme={responsiveFont}>
            <Typography variant="h4" component="div" data-cy="userManagementPageHeading" className="mb-4">
              {translate('comexApp.customer.detail.title')}
            </Typography>
          </ThemeProvider>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          <Form>
            <FormGroup hidden>
              <Label>{translate('global.field.id')}</Label>
              <Input
                type="text"
                name="id"
                readOnly
                value={customerEntity?.id || ''}
              />
            </FormGroup>
            <FormGroup>
              <Label>{translate('comexApp.customer.name')}</Label>
              <Input
                type="text"
                name="name"
                readOnly
                value={customerEntity?.name || ''}
              />
            </FormGroup>
            <FormGroup>
              <Label>{translate('comexApp.customer.ic')}</Label>
              <Input
                type="text"
                name="ic"
                readOnly
                value={customerEntity?.ic || ''}
              />
            </FormGroup>
            <FormGroup>
              <Label>{translate('comexApp.customer.nationality')}</Label>
              <Input
                type="text"
                name="nationality"
                readOnly
                value={customerEntity?.nationality || ''}
              />
            </FormGroup>
            <FormGroup>
              <Label>{translate('comexApp.customer.tel')}</Label>
              <Input
                type="text"
                name="tel"
                readOnly
                value={customerEntity?.tel || ''}
              />
            </FormGroup>
            <FormGroup>
              <Label>{translate('comexApp.customer.email')}</Label>
              <Input
                type="text"
                name="email"
                readOnly
                value={customerEntity?.email || ''}
              />
            </FormGroup>
            <FormGroup>
              <Label>{translate('userManagement.bank')}</Label>
              <Input
                type="text"
                name="bank"
                readOnly
                value={getBank?.name || ''}
              />
            </FormGroup>

            <br/>

            <Button tag={Link} to="/customer" replace data-cy="entityDetailsBackButton">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
            &nbsp;
            <Button tag={Link} to={`/customer/${customerEntity.id}/edit`} replace color="primary" hidden>
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default CustomerDetail;
