import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './comex-bank-fee-log.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const ComexBankFeeLogDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const comexBankFeeLogEntity = useAppSelector(state => state.comexBankFeeLog.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="comexBankFeeLogDetailsHeading">
          <Translate contentKey="comexApp.comexBankFeeLog.detail.title">ComexBankFeeLog</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{comexBankFeeLogEntity.id}</dd>
          <dt>
            <span id="fromTo">
              <Translate contentKey="comexApp.comexBankFeeLog.fromTo">From To</Translate>
            </span>
          </dt>
          <dd>{comexBankFeeLogEntity.fromTo?.toLocaleString()}</dd>
          <dt>
            <span id="feeType">
              <Translate contentKey="comexApp.comexBankFeeLog.feeType">Fee Type</Translate>
            </span>
          </dt>
          <dd>{comexBankFeeLogEntity.feeType}</dd>
          <dt>
            <span id="amount">
              <Translate contentKey="comexApp.comexBankFeeLog.amount">Amount</Translate>
            </span>
          </dt>
          <dd>{comexBankFeeLogEntity.amount?.toLocaleString()}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="comexApp.comexBankFeeLog.status">Status</Translate>
            </span>
          </dt>
          <dd>{comexBankFeeLogEntity.status}</dd>
          <dt>
            <Translate contentKey="comexApp.comexBankFeeLog.bank">Bank</Translate>
          </dt>
          <dd>{comexBankFeeLogEntity.bank ? comexBankFeeLogEntity.bank.name : ''}</dd>
        </dl>
        <Button tag={Link} to="/comex-bank-fee-log" replace data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ComexBankFeeLogDetail;
