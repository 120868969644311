import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IPurchaseCancellation, defaultValue } from 'app/shared/model/purchase-cancellation.model';

const initialState: EntityState<IPurchaseCancellation> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/purchase-cancellations';

// Actions

export const getEntities = createAsyncThunk('purchaseCancellation/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IPurchaseCancellation[]>(requestUrl);
});

export const getEntitiesNotDenied = createAsyncThunk('purchaseCancellation/fetch_entity_lists/notDenied', async ({ page, size, sort,search, date, status, curDate, bankId,branchId,customerId,commodityId }: IQueryParams) => {
  const requestUrl = `${apiUrl}/notDenied${sort ? `?page=${page}&size=${size}&sort=${sort}&date=${date}&status=${status}&search=${search}&curDate=${curDate}&bankId=${bankId}&branchId=${branchId}&customerId=${customerId}&commodityId=${commodityId}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IPurchaseCancellation[]>(requestUrl);
});

export const getEntitiesByBankStaff = createAsyncThunk('purchaseCancellation/fetch_entity_lists/bankStaff', async ({ page, size, sort,createdBy,search, date, status, curDate }: IQueryParams) => {
  const requestUrl = `${apiUrl}/bankStaff/${createdBy}${sort ? `?page=${page}&size=${size}&sort=${sort}&date=${date}&status=${status}&search=${search}&curDate=${curDate}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IPurchaseCancellation[]>(requestUrl);
});

export const getEntitiesBranchManager = createAsyncThunk('purchaseCancellation/fetch_entity_lists/branchManager', async ({ page, size, sort,branchId,search, date, status, curDate}: IQueryParams) => {
  const requestUrl = `${apiUrl}/branchManager/${branchId}${sort ? `?page=${page}&size=${size}&sort=${sort}&date=${date}&status=${status}&search=${search}&curDate=${curDate}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IPurchaseCancellation[]>(requestUrl);
});

export const getEntitiesBankManager = createAsyncThunk('purchaseCancellation/fetch_entity_lists/bankManager', async ({ page, size, sort,bankManagerId,search, date, status, curDate }: IQueryParams) => {
  const requestUrl = `${apiUrl}/bankManager/${bankManagerId}${sort ? `?page=${page}&size=${size}&sort=${sort}&date=${date}&status=${status}&search=${search}&curDate=${curDate}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IPurchaseCancellation[]>(requestUrl);
});

export const getEntitiesAreaManager = createAsyncThunk('purchaseCancellation/fetch_entity_lists/bankStaff', async ({ page, size, sort,areaManagerId,search, date, status, curDate }: IQueryParams) => {
  const requestUrl = `${apiUrl}/areaManager/${areaManagerId}${sort ? `?page=${page}&size=${size}&sort=${sort}&date=${date}&status=${status}&search=${search}&curDate=${curDate}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IPurchaseCancellation[]>(requestUrl);
});

export const getEntitiesCustomer = createAsyncThunk('purchaseCancellation/fetch_entity_lists/customer', async ({ page, size, sort,customerLogin,search, date, status, curDate }: IQueryParams) => {
  const requestUrl = `${apiUrl}/customer/${customerLogin}${sort ? `?page=${page}&size=${size}&sort=${sort}&date=${date}&status=${status}&search=${search}&curDate=${curDate}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IPurchaseCancellation[]>(requestUrl);
});

export const getEntitiesAuditor = createAsyncThunk('purchaseCancellation/fetch_entity_lists/auditor', async ({ page, size, sort,bankId,search, date, status, curDate }: IQueryParams) => {
    const requestUrl = `${apiUrl}/auditor/${bankId}${sort ? `?page=${page}&size=${size}&sort=${sort}&date=${date}&status=${status}&search=${search}&curDate=${curDate}&` : '?'}cacheBuster=${new Date().getTime()}`;
    return axios.get<IPurchaseCancellation[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'purchaseCancellation/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IPurchaseCancellation>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);
export const getEntityByQuotation = createAsyncThunk(
  'purchaseCancellation/fetch_entityByQuotation',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/quotationId/${id}`;
    return axios.get<IPurchaseCancellation>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);
export const getEntitiesByQuotation = createAsyncThunk(
  'purchaseCancellation/fetch_entitiesByQuotation',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/quotationId/${id}`;
    return axios.get<IPurchaseCancellation>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);


export const createEntity = createAsyncThunk(
  'purchaseCancellation/create_entity',
  async (entity: IPurchaseCancellation, thunkAPI) => {
    const result = await axios.post<IPurchaseCancellation>(apiUrl, cleanEntity(entity));

    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'purchaseCancellation/update_entity',
  async (entity: IPurchaseCancellation, thunkAPI) => {
    const result = await axios.put<IPurchaseCancellation>(`${apiUrl}/${entity.id}`, cleanEntity(entity));

    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'purchaseCancellation/partial_update_entity',
  async (entity: IPurchaseCancellation, thunkAPI) => {
    const result = await axios.patch<IPurchaseCancellation>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'purchaseCancellation/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IPurchaseCancellation>(requestUrl);

    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const PurchaseCancellationSlice = createEntitySlice({
  name: 'purchaseCancellation',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntityByQuotation.fulfilled, (state, action) => {
        state.entity = action.payload.data;
        state.loading = true;
      })
      .addCase(getEntitiesByQuotation.fulfilled, (state, action) => {
        state.entities.push(action.payload.data);
        state.loading = true;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities,getEntitiesNotDenied,getEntitiesByBankStaff,getEntitiesAreaManager,getEntitiesBranchManager,getEntitiesBankManager,getEntitiesCustomer, getEntitiesAuditor), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity,getEntitiesNotDenied,getEntitiesByBankStaff,getEntitiesAreaManager,getEntitiesBranchManager,getEntitiesBankManager,getEntitiesCustomer), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = PurchaseCancellationSlice.actions;

// Reducer
export default PurchaseCancellationSlice.reducer;
